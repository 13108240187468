import * as S from './styles';
import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import IconFolha from 'assets/icons/icon_folha.png';
import { Header } from 'components/Header';
import Input from 'components/Input';
import BalanceInput from 'components/BalanceInput';
import Button from 'components/Button';
import { cpfFix, mask, unMask } from 'utils/mask';
import { currencyFormat } from 'utils/mask';

import CarDetails from './CarDetails';
import Parcels, { parcelObj } from './Parcels';
import EmptyParcels from './EmptyParcels';
import ErrorBoundary from './ErrorBoundary';

import Skeleton from 'react-loading-skeleton';
import firebase from 'firebase/compat/app';
import 'firebase/analytics';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: '00621c4c68a1b2bd34e7458c0f8d29bc',
  plugins: [new BugsnagPluginReact()],
});

import {
  Simulation,
  returnFetchForm,
  simulationFetchForm,
  cedFetchForm,
  ltvFetchForm,
} from 'services/simulation';
import * as CXT from 'context/globalContext';
import { useDebouncedCallback } from 'hooks/debouncer';
import { Company } from 'components/Header/types/typesUsingInHeader';
import SpecialParcels, { parcelObjSpecial } from './Parcels/SpecialParcels';

type backUp = {
  ret: number;
  valL: number;
  praz: string;
};

export default function Conditions() {
  const [starting, setStarting] = useState(true);
  const globalContext = useContext(CXT.Context);
  const history = useHistory();
  const analytics = firebase.analytics();

  const [cliente, setCliente] = useState('');
  const [listLimit, setListLimit] = useState(4);

  const [shop, setShop] = useState('');
  const [valTotal, setValTotal] = useState('');
  const [valCED, setValCED] = useState('');
  const [valFipe, setValFipe] = useState(0);
  const [valMaxFipe, setValMaxFipe] = useState(0);

  const [valLiberado, setValLiberado] = useState(0);
  const [vLTheme, setVLTheme] = useState<'valid' | 'invalid'>('valid');
  const [sNTheme, setSNTheme] = useState<'valid' | 'invalid'>('valid');

  const [emptyParcel, setEmptyParcel] = useState(false);
  const [emptyParcelSpecial, setEmptyParcelSpecial] = useState(false);
  const [returnOpt, setReturnOpt] = useState<number[]>([]);
  const [selectedReturnOpt, setSelectedReturnOpt] = useState(0);

  const Client = JSON.parse(localStorage.getItem('client') || '{}');
  const carData = JSON.parse(localStorage.getItem('car') || '{}');
  const company = JSON.parse(
    localStorage.getItem('company') || '{}'
  ) as Company;
  let Car: any;
  try {
    Car = carData.marcas[0].linhas[0].versoes[0].modelos[0].veiculos[0];
  } catch {
    history.push('/client');
  }

  const [Sim, setSim] = useState<any>({});
  const [parcels, setParcels] = useState<parcelObj[]>([]);
  const [parcelsSpecial, setParcelsSpecial] = useState<parcelObjSpecial[]>([]);
  const [selectedParcel, setSelectedParcel] = useState(-1);
  const [selectedParcelNumber, setSelectedParcelNumber] = useState('0');
  const [emptyData, setEmptyData] = useState({});

  const HandleSimResponse = (sim: any, valFin?: number) => {
    const newParcels: any = [];

    if (sim.data.data.agrupamentos.length === 0) {
      setEmptyParcel(true);
      setSelectedParcel(-1);
      globalContext.setIsLoading(false);
      setStarting(false);
      return;
    }

    if (sim.data.data.agrupamentos[0].prazos.length === 0) {
      setEmptyParcel(true);
      setSelectedParcel(-1);
      globalContext.setIsLoading(false);
      setStarting(false);
      return;
    }
    setEmptyParcel(false);

    sim.data.data.agrupamentos[0].prazos.map((prazo: any) => {
      newParcels.push({
        parcelNum: prazo.prazo,
        value: prazo.opcoes[0].valorParcelasFormatado,
        juros: `${prazo.opcoes[0].percentualJuros}%`,
        entradaMin: currencyFormat(prazo.opcoes[0].entradaMinima, true),
        total: currencyFormat(prazo.opcoes[0].valorTotal, true),
        totalFinancia: prazo.opcoes[0].percentualJuros,
        active: 'noneActive',
        open: 'close',
      });
    });

    let numPrz = selectedParcelNumber;
    if (starting) {
      const bck = JSON.parse(localStorage.getItem('parcelBck') || '{}');
      numPrz = bck.praz ?? selectedParcelNumber;
    }

    if (numPrz !== '0') {
      const index = newParcels.findIndex(
        (val: any) => val.parcelNum === numPrz
      );
      if (index >= 0) {
        activeIndex(index, sim, newParcels, valFin);
      } else {
        setSelectedParcelNumber('0');
        setSelectedParcel(-1);
        setParcels(newParcels);
      }
    } else {
      globalContext.setIsLoading(false);
      setStarting(false);
      setSelectedParcel(-1);
      setParcels(newParcels);
    }

    setSim(sim);
  };

  const HandleSimResponseSpecial = (sim: any, valFin?: number) => {
    const newParcels: any = [];

    if (sim.data.data.agrupamentos.length === 0) {
      setEmptyParcelSpecial(true);
      setSelectedParcel(-1);
      globalContext.setIsLoading(false);
      setStarting(false);
      return;
    }

    if (sim.data.data.agrupamentos[0].prazos.length === 0) {
      setEmptyParcelSpecial(true);
      setSelectedParcel(-1);
      globalContext.setIsLoading(false);
      setStarting(false);
      return;
    }
    setEmptyParcelSpecial(false);

    sim.data.data.agrupamentos[0].prazos.map((prazo: any) => {
      newParcels.push({
        parcelaValor: prazo.opcoes[0].agrupadorPrazo,
        valorParcelas: prazo.opcoes[0].valorParcelasFormatado,
        quantidadeMaximaParcelas: prazo.prazo,
        juros: `${prazo.opcoes[0].percentualJuros}%`,
        entradaMin: currencyFormat(prazo.opcoes[0].entradaMinima, false),
        percentualEntradaMinima:
          prazo.opcoes[0].percentualEntradaMinimaFormatado,
        active: 'noneActive',
        open: 'close',
        vezesAdicionais: prazo.opcoes[0].parcelaAdicionalFinal,
        valorAdicionais: prazo.opcoes[0].parcelaAdicionalValor,
      });
    });

    let numPrz = selectedParcelNumber;
    if (starting) {
      const bck = JSON.parse(localStorage.getItem('parcelBck') || '{}');
      numPrz = bck.praz ?? selectedParcelNumber;
    }

    if (numPrz !== '0') {
      const index = newParcels.findIndex(
        (val: any) => val.parcelNum === numPrz
      );
      if (index >= 0) {
        activeIndexSpecial(index, sim, newParcels, valFin);
      } else {
        setSelectedParcelNumber('0');
        setSelectedParcel(-1);
        setParcelsSpecial(newParcels);
      }
    } else {
      globalContext.setIsLoading(false);
      setStarting(false);
      setSelectedParcel(-1);
      setParcelsSpecial(newParcels);
    }

    setSim(sim);
  };

  const HandleRetResponse = (res: any, valFin: number) => {
    if (res.data.data.length === 0) {
      setEmptyParcel(true);
      setEmptyParcelSpecial(true);
      setSelectedParcel(-1);
      globalContext.setIsLoading(false);
      return;
    }

    let aux = res.data.data;
    aux = aux.sort((n1: number, n2: number) => n1 - n2);

    let index = 0;
    const bck = JSON.parse(localStorage.getItem('parcelBck') || '{}');
    if (bck.ret) {
      index = bck.ret;
    } else {
      if (aux.findIndex((v: number) => v === 3.6) >= 0)
        index = aux.findIndex((v: number) => v === 3.6);
      else if (aux.findIndex((v: number) => v === 2.4) >= 0)
        index = aux.findIndex((v: number) => v === 2.4);
      else if (aux.findIndex((v: number) => v === 1.2) >= 0)
        index = aux.findIndex((v: number) => v === 1.2);
      else if (aux.findIndex((v: number) => v === 0) >= 0)
        index = aux.findIndex((v: number) => v === 0);
      else index = 0;
    }
    if (starting) {
      setStarting(false);
    }

    const simForm: simulationFetchForm = {
      valorTotal: Car.precoFipe ?? 0,
      valorEntrada: Car.precoFipe ? Car.precoFipe - valFin : 0,
      veiculoId: Car.veiculoId,
      pessoaClienteId: Client.id,
      percentualRetorno: aux[index].toFixed(2),
    };

    setReturnOpt(aux);
    setSelectedReturnOpt(index);

    globalContext.setIsLoading(true);
    Simulation.SimulationRequest(simForm)
      .then((ev) => {
        HandleSimResponse(ev, valFin);
        // analytics.setUserProperties(globalContext.properties);
        analytics.logEvent('CK_CHANGE_PERCENTUAL_RETURN', {
          percentual: aux[index].toFixed(2),
          cliente: Client.cpf,
          placa: Car.plate,
        });
      })
      .catch((err) => {
        Bugsnag.notify(err);
        globalContext.setIsLoading(false);
        setStarting(false);
      })
      .finally(() => {
        globalContext.setIsLoading(false);
        setStarting(false);
      });

    Simulation.SimulationRequestSpecial(simForm)
      .then((ev) => {
        HandleSimResponseSpecial(ev, valFin);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        globalContext.setIsLoading(false);
        setStarting(false);
      })
      .finally(() => {
        globalContext.setIsLoading(false);
        setStarting(false);
      });
  };

  const CalculateParcels = () => {
    globalContext.setIsLoading(true);
    setValTotal('');
    setValCED('');
    setEmptyParcel(true);
    setEmptyParcelSpecial(true);
    setSelectedParcelNumber('0');
    setSelectedParcel(-1);

    setCliente(
      Client.nome +
        '     ( CPF ' +
        mask(cpfFix(Client.cpf), '###.###.###-##') +
        ' )'
    );

    setShop(company.nomeReduzido);
    setValFipe(Car.precoFipe ?? 0);

    const ltvForm: ltvFetchForm = {
      veiculoId: Car.veiculoId,
    };

    Simulation.LTVRequest(ltvForm)
      .then((response) => {
        const retForm: returnFetchForm = {
          valorTotal: Car.precoFipe ?? 0,
          veiculoId: Car.veiculoId,
        };
        const aux = Math.floor(response.data.data.valorLTV);
        setValLiberado(aux);
        setValMaxFipe(aux);

        Simulation.ReturnsRequest(retForm)
          .then((response) => {
            if (response.data.data.length === 0) {
              const simForm = {
                valorTotal: Car.precoFipe ?? 0,
                valorEntrada: Car.precoFipe ? Car.precoFipe - aux : 0,
                veiculoId: Car.veiculoId,
                pessoaClienteId: Client.id,
              };

              Simulation.MessageSimulation(simForm).then((response) => {
                setEmptyData(response.data.data.controle);
              });
            }
            HandleRetResponse(response, aux);
          })
          .catch((err) => {
            Bugsnag.notify(err);
            setEmptyParcel(true);
            setEmptyParcelSpecial(true);
            globalContext.setIsLoading(false);
          });
      })
      .catch((err) => {
        Bugsnag.notify(err);
        setEmptyParcel(true);
        setEmptyParcelSpecial(true);
        globalContext.setIsLoading(false);
      });
  };

  const reSimulate = useDebouncedCallback((valor: number) => {
    const simForm: simulationFetchForm = {
      valorTotal: Car.precoFipe ?? 0,
      valorEntrada: Car.precoFipe ? Car.precoFipe - valor : 0,
      veiculoId: Car.veiculoId,
      pessoaClienteId: Client.id,
      percentualRetorno:
        returnOpt && returnOpt.length > 0
          ? returnOpt[selectedReturnOpt].toFixed(2)
          : '',
    };

    setValTotal('');
    setValCED('');

    if (valor > valMaxFipe) {
      setVLTheme('invalid');
      setSNTheme('invalid');
      setSelectedParcel(-1);
      setEmptyParcel(true);
      setEmptyParcelSpecial(true);
      return;
    } else if (valor < 10) {
      setVLTheme('invalid');
      setSNTheme('valid');
      setSelectedParcel(-1);
      setEmptyParcel(true);
      setEmptyParcelSpecial(true);
      return;
    } else {
      setVLTheme('valid');
      setSNTheme('valid');
      setEmptyParcel(false);
      setEmptyParcelSpecial(false);
    }
    globalContext.setIsLoading(true);
    Simulation.SimulationRequest(simForm)
      .then((ev) => {
        HandleSimResponse(ev, valor);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        globalContext.setIsLoading(false);
        setStarting(false);
      });
    Simulation.SimulationRequestSpecial(simForm)
      .then((ev) => {
        HandleSimResponseSpecial(ev, valor);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        globalContext.setIsLoading(false);
        setStarting(false);
      });
  }, 2000);

  const handleChangeSelect = (ev: any) => {
    if (valLiberado > valMaxFipe) {
      toast.error('Valor liberado para loja maior que o máximo permitido!');
      return;
    }

    const simForm: simulationFetchForm = {
      valorTotal: Car.precoFipe ?? 0,
      valorEntrada: Car.precoFipe ? Car.precoFipe - valLiberado : 0,
      veiculoId: Car.veiculoId,
      pessoaClienteId: Client.id,
      percentualRetorno: returnOpt[ev.target.value].toFixed(2),
    };

    setSelectedReturnOpt(ev.target.value);
    setValTotal('');
    setValCED('');
    globalContext.setIsLoading(true);
    Simulation.SimulationRequest(simForm)
      .then((response) => {
        HandleSimResponse(response);
        // analytics.setUserProperties(globalContext.properties);
        analytics.logEvent('CK_CHANGE_PERCENTUAL_RETURN', {
          percentual: returnOpt[ev.target.value].toFixed(2),
          cliente: Client.cpf,
          placa: Car.plate,
        });
      })
      .catch((err) => {
        Bugsnag.notify(err);
        globalContext.setIsLoading(false);
        setStarting(false);
      });

    Simulation.SimulationRequestSpecial(simForm)
      .then((response) => {
        HandleSimResponseSpecial(response);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        globalContext.setIsLoading(false);
        setStarting(false);
      });
  };

  const activeIndex = (
    number: number,
    sim?: any,
    NewParcels?: any,
    valFin?: number
  ) => {
    if (globalContext.isLoading) return;

    globalContext.setIsLoading(true);
    const newParcelArray = [...(NewParcels ?? parcels)];

    setSelectedParcel(number);
    setSelectedParcelNumber(newParcelArray[number].parcelNum);

    newParcelArray.forEach((value, index) => {
      index === number
        ? (value.active = 'active')
        : (value.active = 'inactive');
    });

    let aux: any;
    try {
      aux = sim.data.data.agrupamentos[0].prazos[number].opcoes[0];
    } catch {
      aux = Sim.data.data.agrupamentos[0].prazos[number].opcoes[0] ?? '';
    }

    const valAux =
      (valFin ?? valLiberado) + aux.taxaCadastro + aux.valorGravame;

    const CEDform: cedFetchForm = {
      pessoaFinanceiraId: Client.id,
      seqTabelaFinanceira: aux.seqTabelaFinanceira,
      prazo: aux.quantidadeParcelas,
      valorTotal: valAux,
    };

    Simulation.CEDRequest(CEDform)
      .then((response) => {
        setValTotal(
          `R$ ${currencyFormat(Math.floor(response.data.data.valorTotal))}`
        );
        setValCED(`${response.data.data.percentualCet.toFixed(2)}`);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        setValTotal('');
        setValCED('');
        toast.error('Erro ao buscar o Valor, Tente novamente mais tarde');

        newParcelArray.forEach((value) => {
          value.active = 'inactive';
        });
      })
      .finally(() => {
        globalContext.setIsLoading(false);
        setStarting(false);
      });

    setParcels(newParcelArray);
  };

  const disableParcel = (number: number) => {
    const parcelas = [...parcels, ...parcelsSpecial];

    parcelas.map((value, index) => {
      index === number
        ? (value.active = 'active')
        : (value.active = 'inactive');
    });
  };

  const activeIndexSpecial = (
    number: number,
    sim?: any,
    NewParcels?: any,
    valFin?: number
  ) => {
    if (globalContext.isLoading) return;

    globalContext.setIsLoading(true);
    const newParcelArray = [...(NewParcels ?? parcelsSpecial)];

    setSelectedParcel(number);
    setSelectedParcelNumber(newParcelArray[number].parcelNum);

    newParcelArray.forEach((value, index) => {
      index === number
        ? (value.active = 'active')
        : (value.active = 'inactive');
    });

    let aux: any;
    try {
      aux = sim.data.data.agrupamentos[0].prazos[number].opcoes[0];
    } catch {
      aux = Sim.data.data.agrupamentos[0].prazos[number].opcoes[0] ?? '';
    }

    const valAux =
      (valFin ?? valLiberado) + aux.taxaCadastro + aux.valorGravame;

    const CEDform: cedFetchForm = {
      pessoaFinanceiraId: Client.id,
      seqTabelaFinanceira: aux.seqTabelaFinanceira,
      prazo: aux.quantidadeParcelas,
      valorTotal: valAux,
    };

    Simulation.CEDRequest(CEDform)
      .then((response) => {
        setValTotal(
          `R$ ${currencyFormat(Math.floor(response.data.data.valorTotal))}`
        );
        setValCED(`${response.data.data.percentualCet.toFixed(2)}`);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        setValTotal('');
        setValCED('');
        toast.error('Erro ao buscar o Valor, Tente novamente mais tarde');

        newParcelArray.forEach((value) => {
          value.active = 'inactive';
        });
      })
      .finally(() => {
        globalContext.setIsLoading(false);
        setStarting(false);
      });

    setParcelsSpecial(newParcelArray);
  };

  const openInfo = (number: number) => {
    const newParcelArray = [...parcels];

    if (newParcelArray[number].open === 'open')
      newParcelArray.forEach((value) => {
        value.open = 'close';
      });
    else
      newParcelArray.forEach((value, index) => {
        index === number ? (value.open = 'open') : (value.open = 'close');
      });

    setParcels(newParcelArray);
  };
  // eslint-disable-next-line
  const handleSubmit = (ev: any) => {
    {
      if (selectedParcel === -1) {
        toast.error('Selecione Número de Parcelas');
        return;
      }

      const parcela = {
        ...Sim.data.data.agrupamentos[0].prazos[selectedParcel].opcoes[0],
        valorEntradaAVista: valFipe - valLiberado,
        valorEntrada: valFipe - valLiberado,
      };

      // analytics.setUserProperties(globalContext.properties);
      analytics.logEvent('CK_PHASE_TWO', {
        entrada: parcela.valorEntradaAVista,
        valor_financiado: valLiberado,
        valor_veiculo: valFipe,
        num_parcelas: parcela.agrupadorPrazo,
        num_tabela: parcela.seqTabelaFinanceira,
        cliente: Client.cpf,
        placa: Car.plate,
      });

      Sim.data.data.agrupamentos[0].prazos[selectedParcel].opcoes[0] = parcela;

      localStorage.setItem(
        'parcel',
        JSON.stringify(Sim.data.data.agrupamentos[0].prazos[selectedParcel])
      );

      const aux: backUp = {
        ret: selectedReturnOpt,
        valL: valLiberado,
        praz: selectedParcelNumber,
      };

      localStorage.setItem('parcelBck', JSON.stringify(aux));

      history.push('/register');
    }
  };

  useEffect(() => {
    setStarting(true);

    try {
      CalculateParcels();
    } catch (err: any) {
      Bugsnag.notify(err);
      history.goBack();
    }
  }, []);

  return (
    <ErrorBoundary>
      <Header onRefreshClick={CalculateParcels} />
      <S.Wrapper>
        <S.TitleWrapper>
          {globalContext.isLoading ? (
            <div style={{ display: 'flex', alignItems: 'end' }}>
              <Skeleton width="30px" height="40px" />
              <div style={{ width: '5px' }} />
              <Skeleton width="220px" />
            </div>
          ) : (
            <>
              <S.TitleImg src={IconFolha} />
              <S.TitleLabel>
                <span>Etapa 2 de 4:</span>
              </S.TitleLabel>
              <S.TitleLabel>Valores e Condições</S.TitleLabel>
            </>
          )}
        </S.TitleWrapper>
        <S.HardLine />
        <S.MainBody>
          <S.DataBody>
            <S.DataBodyLeft>
              <Input
                readOnly
                name="cliente"
                width="90%"
                title="Cliente"
                value={cliente}
              />
              <Input
                readOnly
                name="shop"
                width="90%"
                title="Loja"
                value={shop}
              />
              <CarDetails />
              <S.ReturnBox>
                {globalContext.isLoading ? (
                  ''
                ) : (
                  <>
                    <S.ReturnLabel>
                      <>% RETORNO PARA A LOJA</>
                    </S.ReturnLabel>
                    <S.ReturnSelect
                      onChange={(ev) => handleChangeSelect(ev)}
                      value={selectedReturnOpt}
                      // defaultValue={defaultReturnValue()}
                    >
                      {returnOpt.map((val, index) => {
                        return (
                          <S.ReturnOpt value={index} key={index}>
                            Retorno de {val.toFixed(2)}%
                          </S.ReturnOpt>
                        );
                      })}
                    </S.ReturnSelect>
                  </>
                )}
              </S.ReturnBox>
            </S.DataBodyLeft>
            <S.DataBodyRight>
              <S.ValueBox>
                <S.Label>
                  {globalContext.isLoading ? (
                    <></>
                  ) : (
                    <>VALOR LIBERADO PARA A LOJA</>
                  )}
                </S.Label>
                <BalanceInput
                  stopEnter
                  name="valLiberado"
                  width="100%"
                  height="50px"
                  title="valLiberado"
                  value={currencyFormat(valLiberado)}
                  inputTheme={vLTheme}
                  disabled={true}
                  onChange={(ev: any) => {
                    const valor = Number(unMask(ev.target.value, '[^0-9]'));
                    setValLiberado(valor);
                    reSimulate(valor);
                  }}
                  onBlur={(ev: any) => {
                    const valor = Number(unMask(ev.target.value, '[^0-9]'));
                    setValLiberado(valor);
                  }}
                  maxInputLength={14}
                />

                <S.SideNote sideNoteTheme={sNTheme}>
                  {globalContext.isLoading ? (
                    <></>
                  ) : (
                    <>
                      VALOR MÁXIMO: R$
                      {' ' + currencyFormat(Math.floor(valMaxFipe) || 0)}
                    </>
                  )}
                </S.SideNote>
              </S.ValueBox>
              <S.ParcelBox>
                {emptyParcel ? (
                  <EmptyParcels emptyData={emptyData} />
                ) : (
                  <>
                    <Parcels
                      data={parcels}
                      activeIndex={activeIndex}
                      openInfo={openInfo}
                      control={listLimit}
                      disableParcels={disableParcel}
                    />
                    {!emptyParcelSpecial && (
                      <SpecialParcels
                        data={parcelsSpecial}
                        activeIndex={activeIndexSpecial}
                        control={listLimit}
                        disableParcels={disableParcel}
                        sizeParcels={parcels.length}
                      />
                    )}
                  </>
                )}
              </S.ParcelBox>
              {emptyParcel ? (
                <div />
              ) : listLimit >= parcels.length ? (
                <S.MoreParcelsSubstitute>
                  {globalContext.isLoading ? (
                    <>
                      <Skeleton width="0px" />
                    </>
                  ) : (
                    <>ver mais opções de parcelas</>
                  )}
                </S.MoreParcelsSubstitute>
              ) : (
                <S.MoreParcels
                  type="button"
                  onClick={() => {
                    if (listLimit === 4) setListLimit(parcels.length);
                    else setListLimit(4);
                  }}
                >
                  {globalContext.isLoading ? (
                    <>
                      <Skeleton width="115px" />
                    </>
                  ) : (
                    <>ver mais opções de parcelas</>
                  )}
                </S.MoreParcels>
              )}
              {valTotal.length > 0 && !globalContext.isLoading ? (
                <S.TotalBox>
                  <S.TotalBoxInside>
                    <S.Label>VALOR TOTAL FINANCIADO</S.Label>
                    <S.Total>{valTotal}</S.Total>
                  </S.TotalBoxInside>
                  <S.TotalBoxInside>
                    <S.Label>CUSTO EFETIVO TOTAL</S.Label>
                    <S.Total>
                      {valCED}
                      {'% a.m.'}
                    </S.Total>
                  </S.TotalBoxInside>
                </S.TotalBox>
              ) : (
                <S.TotalBox />
              )}
              {globalContext.isLoading ? (
                <></>
              ) : (
                <S.ButtonBox>
                  <Button
                    type="button"
                    buttonTheme="goback"
                    width="30%"
                    height="100%"
                    marginTop="0px"
                    onClick={() => history.push('/client')}
                  >
                    VOLTAR
                  </Button>
                  <Button
                    type="button"
                    width="70%"
                    height="100%"
                    marginTop="0px"
                    onClick={(ev: any) => handleSubmit(ev)}
                  >
                    AVANÇAR PARA PRÓXIMA ETAPA
                    <br />
                    <span>(cadastro do cliente)</span>
                  </Button>
                </S.ButtonBox>
              )}
            </S.DataBodyRight>
          </S.DataBody>
        </S.MainBody>
      </S.Wrapper>
    </ErrorBoundary>
  );
}
