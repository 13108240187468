import styled, { css } from 'styled-components';

export const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ErrorBox = styled.div`
  ${({ theme }) => css`
    padding: 2rem;

    background-color: ${theme.colors.thirdWhite};
    border: 1px solid ${theme.colors.gray};
    display: flex;
    flex-direction: column;
    text-align: center;

    font-size: ${theme.font.sizes.medium};

    > b {
      font-family: 'Roboto', sans-serif;
      font-weight: 700;

      color: ${theme.colors.blackTertiary};
    }

    p {
      margin-top: 1.2rem;

      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 1.4rem;

      b {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 1.6rem;

        text-align: center;

        color: ${theme.colors.blackTertiary};
      }
    }
  `}
`;

export const TextLine = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    font-size: ${theme.font.sizes.medium};
    b {
      padding-left: 3px;
      padding-right: 3px;
    }
  `}
`;
