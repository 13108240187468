import styled, { css } from 'styled-components';
// import { ReactSVG } from 'react-svg';
type WrapperProps = {
  show?: boolean;
};
export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, show = true }) => css`
    background-color: ${theme.colors.white};
    width: 935px;
    padding: 25px;
    border-radius: 0 0 8px 8px;
    height: -webkit-fill-available;

    display: ${show ? 'flex' : 'none'};
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const Column = styled.div`
  height: 100%;
  width: calc(50% - 8px);

  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 2px 2px 5px #bbb;
`;

export const Header = styled.div`
  height: 48px;
  width: 100%;
  padding: 8px;

  border-bottom: 2px solid #ccc;

  font-size: 16px;
  color: #888;

  display: flex;
  flex-direction: row;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StageLabelContainer = styled.div`
  width: 70%;
  padding: 8px;
`;

export const StageStatusTitleContainer = styled.div`
  width: 30%;

  display: flex;
  flex-direction: row;
  justify-content: center;
`;

interface StageStatusContainerProps {
  error: boolean;
  progress: boolean;
  completed: boolean;
}
const getIconColor = (
  theme: any,
  error: boolean,
  progress: boolean,
  completed: boolean
) => {
  if (error) return theme.colors.red;
  if (progress) return theme.colors.blueSecondary;
  if (completed) return theme.colors.secondGreen;
  return theme.colors.yellow400;
};
export const StageStatusContainer = styled.div<StageStatusContainerProps>`
  ${({ theme, error, progress, completed }) => css`
    width: 30%;

    display: flex;
    flex-direction: row;
    justify-content: center;

    color: ${getIconColor(theme, error, progress, completed)};
  `}}
`;

interface TitleWrapperProps {
  justify?: 'flex-start' | 'center';
}
export const TitleWrapper = styled.div<TitleWrapperProps>`
  ${({ theme, justify = 'flex-start' }) => css`
    width: 100%;
    height: 100%;
    color: ${theme.colors.grayThird};
    font-weight: bold;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${justify};
  `}
`;

interface StageEntryProps {
  showDivider?: boolean;
}
export const StageEntry = styled.div<StageEntryProps>`
  height: 40px;
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ showDivider = true }) =>
    showDivider
      ? `&:before {
            content : "";
            position: absolute;
            left: 4px;
            bottom: 0;
            height: 0px;
            width: calc(100% - 8px);
            border-radius: 1px;
            border: 1px solid #e5e5e5;
        }`
      : ''};
`;

interface StageLabelWrapperProps {
  disabled: boolean;
  error: boolean;
  selected: boolean;
}
const getFontColor = (
  theme: any,
  disabled: boolean,
  error: boolean,
  selected: boolean
): string => {
  //disabled ? theme.colors.gray : (error ? :  theme.colors.blueSecondary)
  if (disabled) return theme.colors.gray;
  if (selected) return theme.colors.blueSecondary;
  if (error) return theme.colors.red;
  return theme.colors.grayThird;
};
export const StageLabelWrapper = styled.div<StageLabelWrapperProps>`
  ${({ theme, disabled, error, selected }) => css`
    width: 100%;
    cursor: ${disabled ? 'initial' : 'pointer'};

    color: ${getFontColor(theme, disabled, error, selected)};
    font-weight: bold;
  `}
`;

export const NoDataInfoWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.graySecond};
    font-size: 14px;
    margin-top: 16px;
  `}
`;
