import { Checkbox } from '@mui/material';
import Button from 'components/Button';
import Loading from 'components/Loading';
import { find, first } from 'lodash';
import { PENDENTE } from 'pages/Documents/Contract/stageStatus';
import { EtapaDTO } from 'pages/Documents/Contract/types';
import { Proposta } from 'pages/Documents/types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Deals } from 'services/propostas';

import {
  ButtonWrapper,
  CheckboxWrapper,
  DateWrapper,
  InfoOne,
  InfoTwo,
  LabelWrapper,
  ListContainer,
  ListContent,
  ListEntryContainer,
  ListTitle,
  ListTitleContainer,
  Wrapper,
} from './styles';

export type Vencimento = {
  sequencia: number;
  textoColuna1: string;
  textoColuna2: string;
  data: string;
  diaOpcao: number;
  opcaoSelecionada: boolean;
  opcaoDisponivel: boolean;
};

type EscolherVencimentoProps = {
  etapa: EtapaDTO;
  proposta: Proposta;
  readOnly: boolean;
  editing: boolean;
  reloadProposta: () => void;
  finishEditing: () => void;
};

const EscolherVencimento = ({
  etapa,
  proposta,
  readOnly,
  editing,
  reloadProposta,
  finishEditing,
}: EscolherVencimentoProps) => {
  const [loadingVencimentos, setLoadingVencimentos] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [vencimentos, setVencimentos] = useState<Vencimento[]>([]);
  const [selectedVencimento, setSelectedVencimento] =
    useState<Vencimento | null>(null);

  useEffect(() => {
    loadVencimentos();
  }, [proposta, etapa]);

  useEffect(() => {
    return () => {
      if (editing) {
        finishEditing();
      }
    };
  }, []);

  const loadVencimentos = () => {
    setLoadingVencimentos(true);
    Deals.GetVencimentos(proposta.pedidoId)
      .then((response) => {
        const items: Vencimento[] = response.data.data;
        setVencimentos(items);
        setSelectedVencimento(
          find(items, (it) => it.data === proposta.dataPrimeiroVencimento) ??
            first(items) ??
            null
        );
      })
      .catch((err) => {
        console.error(err);
        toast.error('Erro ao carregar os vencimentos');
      })
      .finally(() => {
        setLoadingVencimentos(false);
      });
  };

  const saveVencimento = () => {
    if (selectedVencimento) {
      setSaveLoading(true);
      Deals.SaveVencimento(proposta.pedidoId, selectedVencimento)
        .then(() => {
          reloadProposta();
          if (editing) {
            finishEditing();
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error('Erro ao salvar vencimento');
        })
        .finally(() => {
          setSaveLoading(false);
        });
    }
  };

  return (
    <Wrapper>
      <InfoOne>
        Fique atento! Para parcelas regulares o valor negociado continuará o
        mesmo, para parcelas especiais ocorre ajuste.
      </InfoOne>
      <InfoTwo>
        Você pode escolher vencimentos entre 30 e 45 dias da data de hoje
      </InfoTwo>
      <ListContainer>
        <ListTitleContainer>
          <ListTitle showDivider width="60%">
            Selecione abaixo o dia de vencimento
          </ListTitle>
          <ListTitle width="40%">Data 1ª parcela</ListTitle>
        </ListTitleContainer>

        <ListContent>
          {loadingVencimentos ? (
            <Loading colorToken="blueSecondary" padding="24px" />
          ) : (
            <>
              {vencimentos?.map((vencimento, index) => {
                const disabled =
                  saveLoading ||
                  !vencimento.opcaoDisponivel ||
                  etapa.status == PENDENTE;
                return (
                  <ListEntryContainer
                    key={vencimento.sequencia}
                    showDivider={index !== 0}
                    disabled={disabled}
                    onClick={() => {
                      if (selectedVencimento != vencimento) {
                        setSelectedVencimento(vencimento);
                      }
                    }}
                  >
                    <CheckboxWrapper>
                      <Checkbox
                        disabled={disabled}
                        checked={selectedVencimento === vencimento}
                      ></Checkbox>
                    </CheckboxWrapper>
                    <LabelWrapper disabled={disabled}>
                      {vencimento.textoColuna1}
                    </LabelWrapper>
                    <DateWrapper disabled={disabled}>
                      {vencimento.textoColuna2}
                    </DateWrapper>
                  </ListEntryContainer>
                );
              })}
            </>
          )}
        </ListContent>
        <ButtonWrapper>
          <Button
            onClick={saveVencimento}
            disabled={readOnly || !selectedVencimento}
            loading={saveLoading}
          >
            Salvar
          </Button>
        </ButtonWrapper>
      </ListContainer>
    </Wrapper>
  );
};

export default EscolherVencimento;
