import { Dialog } from '@mui/material';
import Button from 'components/Button';
import Loading from 'components/Loading';
import { Proposta } from 'pages/Documents/types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Deals } from 'services/propostas';
import ShowPDF from 'utils/ShowPDF';
import { CONFERINDO_CONTRATO } from '../../acoesProposta';
import { AGUARDANDO, CONCLUIDO, PENDENTE } from '../../stageStatus';
import { EtapaDTO } from '../../types';

import {
  ContractViewContainer,
  ContractViewWrapper,
  ActionsWrapper,
  InfoTwo,
  FileWrapper,
  HoverMessageWrapper,
  HoverShadow,
  Wrapper,
} from './styles';
import EditPhoneMailDialog from '../EditPhoneEmailDialog';

interface VisualizarProps {
  show: boolean;
  readOnly: boolean;
  etapa: EtapaDTO;
  etapaVencimento: EtapaDTO;
  etapaAssinatura: EtapaDTO;
  proposta: Proposta;
  reloadProposta: () => void;
  goToEtapaConferirContrato: () => void;
  isUserDataUpToDate: boolean;
}

const Visualizar = ({
  show,
  readOnly,
  etapa,
  etapaVencimento,
  etapaAssinatura,
  proposta,
  reloadProposta,
  goToEtapaConferirContrato,
  isUserDataUpToDate,
}: VisualizarProps) => {
  const [contratoBlob, setContratoBlob] = useState<Blob | null>(null);
  const [contratoUrl, setContratoUrl] = useState<string | null>(null);
  const [viewingFile, setViewingFile] = useState<boolean>(false);
  const [waitingVencimento, setWaitingVencimento] = useState<boolean>(false);
  const [loadingContrato, setLoadingContrato] = useState<boolean>();
  const [loadingConferencia, setLoadingConferencia] = useState<boolean>(false);
  const [editingDialogOpen, setEditingDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (
      proposta &&
      etapa.status !== PENDENTE &&
      etapaVencimento?.status !== AGUARDANDO &&
      !contratoBlob &&
      !loadingContrato &&
      isUserDataUpToDate
    ) {
      loadContrato();
    }
  }, [proposta, etapaVencimento, show, isUserDataUpToDate]);

  useEffect(() => {
    if (etapaVencimento.status === AGUARDANDO) {
      setContratoBlob(null);
      setContratoUrl(null);
      setWaitingVencimento(true);
    } else {
      setWaitingVencimento(false);
    }
  }, [etapaVencimento]);

  const base64ToBlob = (base64: string): Blob => {
    const bytes = atob(base64);
    let length = bytes.length;
    const out = new Uint8Array(length);
    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }
    return new Blob([out], { type: 'application/pdf' });
  };

  const editData = () => {
    setEditingDialogOpen(true);
  };

  const handleClose = () => {
    setEditingDialogOpen(false);
  };

  const loadContrato = () => {
    setLoadingContrato(true);
    Deals.GetContrato(proposta.pedidoId)
      .then((response) => {
        const data = response.data.data;
        if (data.pdfContratoBase64) {
          const blob = base64ToBlob(data.pdfContratoBase64);
          const url = URL.createObjectURL(blob);
          setContratoBlob(blob);
          setContratoUrl(url);
        } else {
          toast.error('Erro ao carregar contrato. ' + data.mensagemRetorno);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('Erro ao carregar o contrato');
      })
      .finally(() => setLoadingContrato(false));
  };

  const declararConferindoContrato = () => {
    setLoadingConferencia(true);
    Deals.RealizarAcao(proposta.pedidoId, CONFERINDO_CONTRATO, null)
      .then(() => {
        goToEtapaConferirContrato();
      })
      .catch((err) => {
        console.error(err);
        toast.error('Erro ao declarar conferência em andamento do contrato');
      })
      .finally(() => setLoadingConferencia(false));
  };

  const viewFile = () => {
    setViewingFile(true);
  };

  return (
    <Wrapper show={show}>
      <ContractViewContainer>
        <ContractViewWrapper>
          {loadingContrato ? (
            <Loading padding="48px" />
          ) : (
            <>
              {waitingVencimento || !isUserDataUpToDate ? (
                <InfoTwo>Visualização do contrato não disponível</InfoTwo>
              ) : contratoUrl ? (
                <>
                  <FileWrapper onClick={viewFile}>
                    <HoverShadow className="shadow"></HoverShadow>
                    <HoverMessageWrapper className="message">
                      Clique para expandir
                    </HoverMessageWrapper>
                    <ShowPDF url={contratoUrl} width={400} disableScroll />
                  </FileWrapper>
                  <Dialog
                    open={viewingFile}
                    onClose={() => setViewingFile(false)}
                    maxWidth="lg"
                  >
                    <ShowPDF url={contratoUrl} width={968} />
                  </Dialog>
                </>
              ) : (
                <InfoTwo>Contrato não encontrado</InfoTwo>
              )}
            </>
          )}
        </ContractViewWrapper>
      </ContractViewContainer>
      <ActionsWrapper>
        <Button
          type="submit"
          onClick={editData}
          disabled={etapaAssinatura.status === CONCLUIDO}
        >
          Editar dados
        </Button>
        <EditPhoneMailDialog
          open={editingDialogOpen}
          handleClose={handleClose}
          proposta={proposta}
          reloadProposta={reloadProposta}
        ></EditPhoneMailDialog>
        <Button
          type="submit"
          onClick={declararConferindoContrato}
          loading={loadingConferencia}
          disabled={
            readOnly ||
            etapa.status == CONCLUIDO ||
            loadingContrato ||
            !contratoBlob ||
            !isUserDataUpToDate
          }
        >
          Próxima etapa
        </Button>
      </ActionsWrapper>
    </Wrapper>
  );
};

export default Visualizar;
