export default [
  {
    id: 1,
    label: 'Cliente e Veículo',
  },
  {
    id: 2,
    label: 'Valores e Condições',
  },
  {
    id: 3,
    label: 'Cadastro do cliente',
  },
  {
    id: 4,
    label: 'Enviar para crédito',
  },
];
