import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WrapperContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface TextWrapperType {
  textAlign?: 'center' | 'left';
}
export const TextWrapper = styled.div<TextWrapperType>`
  ${({ theme, textAlign = 'center' }) => css`
    text-align: ${textAlign};
    font-size: 14px;
    width: fit-content;

    color: ${theme.colors.graySecond};
  `}
`;

export const HighlightedTextWrapper = styled.span`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: ${theme.colors.blueSecondary};
  `}
`;

export const InfoTwo = styled.div`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;
    font-size: 13px;
    margin-top: 8px;
    color: ${theme.colors.graySecond};
  `}
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 48px 36px;
`;

export const StatusWrapper = styled.div`
  width: 100%;
  padding: 56px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled.div`
  ${({ theme, color }) => css`
    border-radius: 50%;
    width: 56px;
    height: 56px;

    color: white;
    background-color: ${color ?? theme.colors.grayThird};

    font-size: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `}
`;

export const StatusLabelWrapper = styled.div`
  ${({ theme }) => css`
    height: 48px;
    padding-top: 32px;
    font-size: 20px;

    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${theme.colors.grayThird};
  `}
`;
