import styled, { css } from 'styled-components';

export const Wrapper = styled.button`
  ${({ theme }) => css`
    height: 50px;
    padding: 8px 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 0px hidden;
    border-top: 3px solid ${theme.colors.graySixth};
    border-radius: 8px;

    background-color: ${theme.colors.graySixth};
    color: ${theme.colors.white};

    font-size: ${theme.font.sizes.medium};

    &:disabled {
      font-weight: 800;

      border-top: 3px solid ${theme.colors.graySeventh};

      background-color: ${theme.colors.graySeventh};
      color: #999;

      span {
        margin-left: 3px;

        font-size: ${theme.font.sizes.xx_r_small};
      }
    }

    &.Transparent {
      font-weight: 800;

      border: 0px hidden;

      background-color: transparent;
      color: ${theme.colors.blueSecondary};
    }

    &.squared {
      border-bottom: 3px solid ${theme.colors.graySeventh};
      border-radius: 8px 8px 0px 0px;
    }

    &.selected {
      font-weight: bold;

      border-top: 3px solid ${theme.colors.white};
      border-bottom: 3px solid ${theme.colors.blueSecondary};

      background-color: ${theme.colors.white};
      color: ${theme.colors.blueSecondary};
    }

    &.slim {
      height: 40px;
    }
  `}
`;
