import { ButtonHTMLAttributes } from 'react';
import * as S from './styles';

import * as CXT from 'context/globalContext';
import { useContext } from 'react';

const NavButton = ({
  children,
  className,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const globalContext = useContext(CXT.Context);

  return (
    <S.Wrapper
      disabled={className === 'currentPage' ? true : false}
      className={className}
      {...props}
    >
      {globalContext.isLoading ? 'Carregando...' : !!children && children}
    </S.Wrapper>
  );
};

export default NavButton;
