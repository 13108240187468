import styled, { css } from 'styled-components';

export const Wrapper = styled.button`
  ${({ theme }) => css`
    padding: 1.2rem 1.6rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    border-radius: 8px;

    background-color: ${theme.colors.bluePrimary};
    color: ${theme.colors.white};

    font-size: ${theme.font.sizes.medium};

    &:disabled {
      font-weight: 800;

      background-color: ${theme.colors.grayFourth};
      color: ${theme.colors.white};
    }

    &.Transparent {
      font-weight: 800;

      background-color: transparent;
      color: ${theme.colors.blueSecondary};
    }

    &.selected {
      border-color: ${theme.colors.blueSecondary};
    }
  `}
`;
