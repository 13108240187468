import React, {
  createContext,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import apiKeyCloak from 'api/api-keycloak';
import apiAppdsv from 'api/api-appdsv';
import apiCar from 'api/api-car';

import { getAnalytics, setUserProperties } from 'firebase/analytics';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Company } from 'components/Header/types/typesUsingInHeader';

Bugsnag.start({
  apiKey: '00621c4c68a1b2bd34e7458c0f8d29bc',
  plugins: [new BugsnagPluginReact()],
});

export type WarningType = {
  title: string;
  label: string;
  isVehicleName: boolean;
  isActive: boolean;
  outOfOrderVehicle: boolean;
  returnMessageVehicle: string;
};

type Type = {
  authenticated: boolean;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  activeTab: number;
  numTab: number;
  refresh: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  popUpTutorial: boolean;
  setPopUpTutorial: React.Dispatch<React.SetStateAction<boolean>>;
  setCompany: React.Dispatch<React.SetStateAction<Company | undefined>>;
  currentCompany: Company | undefined;
  warningData: WarningType;
  setWarningData: React.Dispatch<React.SetStateAction<WarningType>>;
  InputValueFetchExists: boolean;
  setInputValueFetchExists: React.Dispatch<React.SetStateAction<boolean>>;
  popUpNumPedido: string;
  setPopUpNumPedido: React.Dispatch<SetStateAction<string>>;
  popUpActive: boolean[];
  setPopUpActive: React.Dispatch<SetStateAction<boolean[]>>;
  idPedido: string;
  setIdPedido: React.Dispatch<SetStateAction<string>>;
  codPropostaExistente: string;
  setCodPropostaExistente: React.Dispatch<SetStateAction<string>>;

  properties: {
    cpf: string;
    user: string;
    rede: string;
    departmento: string;
    loja: number;
  };

  // HandleLogin: (item: any) => void;
  HandleLogout: () => void;
  setIsLoading: (item: boolean) => void;
  setActiveTab: (item: number) => void;
  setNumTab: (item: number) => void;
  RefreshPage: () => void;
  closePopUp: () => void;
  HandleCompanies(companies: any, user: any, props: any): void;
  documentsOnApprovalQueue: Set<number>;
  setDocumentsOnApprovalQueue: React.Dispatch<
    React.SetStateAction<Set<number>>
  >;
};

const Context = createContext({} as Type);

type AuthProps = {
  children: React.ReactNode;
};

function Provider({ children }: AuthProps) {
  const [documentsOnApprovalQueue, setDocumentsOnApprovalQueue] = useState(
    new Set<number>()
  );
  const [authenticated, setAuthenticated] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(2);
  const [numTab, setNumTab] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const [page, setPage] = useState(0);

  const [popUpTutorial, setPopUpTutorial] = useState(true);

  const RefreshPage = () => {
    setRefresh(!refresh);
  };

  // propriedades do showPopUp em /register
  const [popUpNumPedido, setPopUpNumPedido] = useState('0');
  const [popUpActive, setPopUpActive] = useState([false, false, false, false]);
  const closePopUp = () => {
    setPopUpActive([false, false, false, false]);
  };
  const [idPedido, setIdPedido] = useState('');
  const [codPropostaExistente, setCodPropostaExistente] = useState('');

  const analytics = getAnalytics();

  const [properties, setProperties] = useState({
    cpf: '',
    user: '',
    rede: '',
    departmento: '',
    loja: 0,
  });

  const [warningData, setWarningData] = useState<WarningType>({
    title: '',
    label: '',
    isVehicleName: false,
    isActive: false,
    outOfOrderVehicle: false,
    returnMessageVehicle: '',
  });

  const [currentCompany, setCompany] = useState<Company | undefined>(undefined);

  const [InputValueFetchExists, setInputValueFetchExists] = useState(false);

  useEffect(() => {
    const tokenData = JSON.parse(localStorage.getItem('tokenData') || '{}');

    if (tokenData && tokenData.access_token) {
      apiKeyCloak.defaults.headers.Authorization = `Bearer ${tokenData.access_token}`;
      apiAppdsv.defaults.headers.Authorization = `Bearer ${tokenData.access_token}`;
      apiCar.defaults.headers.Authorization = `Bearer ${tokenData.access_token}`;

      if (currentCompany && currentCompany.codigo) {
        apiAppdsv.defaults.headers['Codigo-Empresa'] = currentCompany?.codigo;
        apiCar.defaults.headers['Codigo-Empresa'] = currentCompany?.codigo;
      }

      setAuthenticated(true);
    }
  }, [currentCompany]);

  function HandleCompanies(companies: any, user: any, props: any) {
    if (user === undefined || companies === undefined) {
      return;
    }
    const empresa = user.empresa;

    let company = undefined;
    company = companies.data.data[0].lojaAgrupamentoResponse.find(
      (value: any) => {
        return (
          value.lojas.find((val: any) => val.codigo.toString() === empresa) !==
          undefined
        );
      }
    );

    if (company !== undefined)
      company = company.lojas.find(
        (val: any) => val.codigo.toString() === empresa
      );

    localStorage.setItem('company', JSON.stringify(company) ?? '{}');
    const propsPlusLoja = {
      ...props,
      loja: company.codigo ?? 1,
    };
    setProperties(propsPlusLoja);
    setUserProperties(analytics, propsPlusLoja);
  }

  function HandleLogout() {
    localStorage.setItem('tokenData', '');
    localStorage.setItem('car', '');
    localStorage.setItem('client', '');
    localStorage.setItem('carData', '');
    localStorage.setItem('parcelBck', '');
    localStorage.setItem('userData', '');
    localStorage.setItem('company', '');
    setNumTab(0);

    apiKeyCloak.defaults.headers.Authorization = '';
    apiAppdsv.defaults.headers.Authorization = '';
    apiCar.defaults.headers.Authorization = '';
    setAuthenticated(false);
  }

  return (
    <>
      <Context.Provider
        value={{
          documentsOnApprovalQueue: documentsOnApprovalQueue,
          setDocumentsOnApprovalQueue: setDocumentsOnApprovalQueue,
          authenticated: authenticated,
          setAuthenticated,
          isLoading: isLoading,
          activeTab: activeTab,
          numTab: numTab,
          refresh: refresh,
          page: page,
          setPage: setPage,
          popUpTutorial: popUpTutorial,
          setPopUpTutorial: setPopUpTutorial,
          warningData,
          setWarningData,
          InputValueFetchExists,
          setInputValueFetchExists,
          popUpNumPedido,
          setPopUpNumPedido,
          popUpActive,
          setPopUpActive,
          idPedido,
          setIdPedido,
          codPropostaExistente,
          setCodPropostaExistente,

          properties,
          setCompany,
          currentCompany,
          HandleCompanies,

          HandleLogout,
          setIsLoading,
          setActiveTab,
          setNumTab,
          RefreshPage,
          closePopUp,
        }}
      >
        <div>{children}</div>
      </Context.Provider>
      {/* {isLoading ? <S.BlurWrapper /> : ''} */}
    </>
  );
}

export { Context, Provider };
