import { InputHTMLAttributes, useState, useContext } from 'react';
import { useEffect, createRef } from 'react';
import * as S from './styles';
import Skeleton from 'react-loading-skeleton';
import * as CXT from 'context/globalContext';

import * as Mask from 'utils/mask';

type InputProps = {
  title?: string;
  type?: string;
  onChange?: (item: any) => void;
  onBlur?: (item: any) => void;
  value?: string;
  error?: boolean;
  errorMessage?: string;
  inputTheme?: 'default' | 'valid' | 'invalid';
  valid?: boolean;
  width?: string;
  heigth?: string;
  disabled?: boolean;
  maxInputLength?: number;
  stopEnter?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const Input = ({
  type = 'text',
  onChange,
  onBlur,
  value = '',
  error = false,
  inputTheme,
  valid = false,
  width = '100%',
  heigth = '100%',
  maxInputLength = 50,
  stopEnter = false,
}: InputProps) => {
  const globalContext = useContext(CXT.Context);
  // eslint-disable-next-line
  const [cursorPosition, setCursorPosition] = useState(0);
  const inputRef: React.RefObject<HTMLInputElement> = createRef();
  const [textBuffer, setTextBuffer] = useState<{ text: string; cp: number }>({
    text: '',
    cp: 0,
  });
  const [val, setVal] = useState<{ v: string; cp: number }>({
    v: value,
    cp: 0,
  });

  const setText = (value: string, cursorPosition: number) => {
    let cursorAuxiliar = cursorPosition;
    cursorAuxiliar = Mask.checkNewPosReverse(
      value ?? '',
      cursorPosition,
      value.length - val.v.length
    );
    // eslint-disable-next-line
    setCursorPosition(cursorAuxiliar);

    setVal({
      v: Mask.currencyFormat(Number(Mask.unMask(value, '[^0-9]')) ?? ''),
      cp: cursorAuxiliar,
    });
  };

  const handleOnChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const selectionStart = ev.target?.selectionStart ?? 0;
    if (textBuffer.text === '') {
      setText(ev.target.value, selectionStart ?? 0);
    } else {
      ev.target.value = textBuffer.text;
      setText(textBuffer.text, textBuffer.cp);
    }
    setTextBuffer({ text: '', cp: 0 });
    if (onChange) onChange(ev);
  };

  const handleOnKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') ev.preventDefault();
    else if (ev.key === 'Backspace') {
      let text = '';
      let index = 0;
      let count = 1;
      text = inputRef.current?.value ?? '';
      index = inputRef.current?.selectionStart ?? 0;
      while (
        index - count > 0 &&
        (text[index - count].match(/[^0-9]/g) || []).length
      ) {
        text = `${text.substring(
          0,
          Math.max(index - count, 0)
        )}${text.substring(index - count + 1)}`;
        count += 1;
      }

      text = `${text.substring(0, Math.max(index - count, 0))}${text.substring(
        index - count + 1
      )}`;

      const newPos = Math.max(
        0,
        (inputRef.current?.selectionStart ?? 0) - count
      );
      setTextBuffer({ text: text, cp: newPos });
    } else if (ev.key === 'Delete') {
      let text = '';
      let index = 0;
      text = inputRef.current?.value ?? '';
      index = inputRef.current?.selectionStart ?? 0;

      while (
        index < text.length &&
        (text[index].match(/[^0-9]/g) || []).length
      ) {
        text = `${text.substring(0, index)}${text.substring(index + 1)}`;
      }
      text = `${text.substring(0, index)}${text.substring(index + 1)}`;
      const newPos = Math.max(0, inputRef.current?.selectionStart ?? 0);
      setTextBuffer({ text: text, cp: newPos });
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.selectionStart =
        val.cp === -1 ? val.v.length + 3 : val.v.length - val.cp + 3;
      inputRef.current.selectionEnd =
        val.cp === -1 ? val.v.length + 3 : val.v.length - val.cp + 3;
    }
  }, [val]);

  useEffect(() => {
    if (value !== val.v) globalContext.RefreshPage();
    setVal({
      v: Mask.currencyFormat(Number(Mask.unMask(value, '[^0-9]')) ?? ''),
      cp: 0,
    });
  }, [value]);

  return (
    <S.Wrapper>
      {globalContext.isLoading ? (
        <S.InputDisabled
          width={width}
          heigth={heigth}
          inputTheme={'disabled'}
          error={error}
          valid={valid}
        >
          <Skeleton width={100} />
        </S.InputDisabled>
      ) : (
        <S.Input
          type={type}
          ref={inputRef}
          onKeyDown={handleOnKeyDown}
          onChange={handleOnChange}
          onBlur={onBlur}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && stopEnter) e.preventDefault();
          }}
          value={`R$ ${val.v}`}
          width={width}
          heigth={heigth}
          inputTheme={inputTheme}
          error={error}
          valid={valid}
          maxLength={maxInputLength}
        />
      )}
    </S.Wrapper>
  );
};

export default Input;
