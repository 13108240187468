import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import * as S from './styles';
import { ILoja } from '../index';
import { useLocation } from 'react-router-dom';
import * as CTX from '../../../../../context/globalContext';
import { LoadingNotification } from '../../Notification/AccessToAllNotifications/LoadingNotification';

type AccessToAllStoresProps = {
  openStoreExchange: boolean;
  setOpenStoreExchange: React.Dispatch<SetStateAction<boolean>>;
  allStores: ILoja[];
  onStoreSelected: () => void;
  loading: boolean;
};

export const AccessToAllStores: React.FC<AccessToAllStoresProps> = ({
  openStoreExchange,
  setOpenStoreExchange,
  allStores,
  onStoreSelected,
  loading,
}) => {
  const globalContext = useContext(CTX.Context);
  const pathname = useLocation().pathname;

  const [loja, setLoja] = useState<ILoja>();

  function exchangeForThisStore(store: ILoja) {
    globalContext.setCompany(store);
    onStoreSelected();
    localStorage.setItem('company', JSON.stringify(store));
    if (pathname === '/conditions') {
      globalContext.RefreshPage();
    }
  }

  useEffect(() => {
    if (loja) {
      globalContext.RefreshPage();
    }
  }, [loja]);

  const selectedStoreIndex = allStores.findIndex(
    (store) => globalContext.currentCompany?.codigo === store.codigo
  );

  return (
    <S.WrappingComponent>
      {openStoreExchange && (
        <>
          <S.BackgroundToCloseTheStoreExchange
            onClick={() => setOpenStoreExchange(false)}
          />
          <S.Wrapper>
            <S.HeaderStores>
              <div className="centralizedElements">
                <h5>Lojas</h5>
                <div>
                  <div className="shape"></div>
                </div>
              </div>
            </S.HeaderStores>

            {!loading ? (
              <S.Body>
                {allStores?.map((loja, index) => (
                  <S.StoreRepresentationCard
                    category={getRatingLoja(loja)}
                    key={index + Date.now()}
                    onClick={() => {
                      exchangeForThisStore(loja), setLoja(loja);
                    }}
                    status={
                      index === selectedStoreIndex ? 'selected' : 'notSelected'
                    }
                  >
                    <div className="centralizedElements">
                      <div className="titleAndSubTitle">
                        <h4>{loja.descricao}</h4>
                        <h5>
                          {loja.municipioEmpresa}/{loja.ufEmpresa}
                        </h5>
                      </div>
                      <div className="category">
                        <h3>{getRatingLoja(loja)}</h3>
                      </div>
                    </div>
                  </S.StoreRepresentationCard>
                ))}
              </S.Body>
            ) : (
              <S.Body>
                <LoadingNotification />
              </S.Body>
            )}
          </S.Wrapper>
        </>
      )}
    </S.WrappingComponent>
  );
};
function getRatingLoja(loja: ILoja): string {
  return loja.ratingLojista === null ? '' : loja.ratingLojista;
}
