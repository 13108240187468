import Loading from 'components/Loading';
import { find, findLast, first, size, upperCase } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import * as CXT from 'context/globalContext';
import * as FaIcons from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Deals } from 'services/propostas';
import { Proposta } from '../types';
import Vencimento from './Stage/01.Vencimento';
import Visualizar from './Stage/02.Visualizar';
import Conferir from './Stage/03.Conferir';
import Enviar from './Stage/04.Enviar';
import Assinar from './Stage/05.Assinar';
import {
  ASSINAR_CONTRATO,
  CONFERIR_CONTRATO,
  ENVIAR_CONTRATO,
  VENCIMENTO,
  VISUALIZAR_CONTRATO,
  ENVIAR_NF,
} from './stages';
import {
  AGUARDANDO,
  CONCLUIDO,
  EM_ANDAMENTO,
  EXPIRADO,
  PENDENTE,
  RECUSADO,
} from './stageStatus';

import {
  Column,
  Header,
  StageEntry,
  StageStatusContainer,
  StageStatusTitleContainer,
  StageLabelContainer,
  StageLabelWrapper,
  Wrapper,
  TitleWrapper,
  ContentWrapper,
  NoDataInfoWrapper,
} from './styles';
import { EtapaDTO } from './types';

export type ContractIssuancePropTypes = {
  show: boolean;
  proposta?: Proposta;
  emailCliente: string;
  telefoneCliente: string;
  reloadProposta: () => void;
  goToAprovacaoTab: () => void;
};

const ContractIssuance = ({
  show,
  proposta,
  emailCliente,
  telefoneCliente,
  reloadProposta,
  goToAprovacaoTab,
}: ContractIssuancePropTypes) => {
  const IDENTIFICADOR_MODO_LEITURA = '#MODO_LEITURA#';

  const globalContext = useContext(CXT.Context);

  const [loading, setLoading] = useState<boolean>(false);
  const [etapas, setEtapas] = useState<EtapaDTO[]>([]);
  const [selectedEtapa, setSelectedEtapa] = useState<EtapaDTO | null>(null);
  const [isUserDataUpToDate, setIsUserDataUpToDate] = useState<boolean>(false);
  const [isVencimentoDataUpToDate, setIsVencimentoDataUpToDate] =
    useState<boolean>(false);

  useEffect(() => {
    loadEtapas();
    loadStatusAtualizacaoDadosCliente();
    loadStatusAtualizacaoVencimentoContrato();
  }, [proposta]);

  // Business functions
  const loadEtapas = (etapaToSelect?: number) => {
    if (proposta) {
      setLoading(true);
      setSelectedEtapa(null);
      setEtapas([]);
      Deals.GetEtapasEmissaoContrato(proposta.pedidoId)
        .then((response) => {
          const newEtapas = response.data.data;
          setEtapas(newEtapas);
          selectInitialEtapa(newEtapas, etapaToSelect);
        })
        .catch((err) => {
          console.error(err);
          toast.error('Erro ao carregar as etapas');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const loadStatusAtualizacaoDadosCliente = () => {
    if (proposta) {
      setIsUserDataUpToDate(false);
      Deals.GetStatusAtualizacaoDadosCliente(
        proposta.clienteCpfCnpj,
        proposta.propostaCodigo
      )
        .then((response) => {
          setIsUserDataUpToDate(response.data.data.upToDate);
        })
        .catch((e) => {
          toast.error(
            'Erro ao verificar se os dados do cliente estão atualizados'
          );
          console.error(e);
          setIsUserDataUpToDate(false);
        });
    }
  };

  const loadStatusAtualizacaoVencimentoContrato = () => {
    if (proposta) {
      setIsVencimentoDataUpToDate(false);
      Deals.GetStatusAtualizacaoVencimento(
        proposta.propostaCodigo,
        proposta.pedidoId
      )
        .then((response) => {
          setIsVencimentoDataUpToDate(response.data.data.upToDate);
        })
        .catch((e) => {
          toast.error(
            'Erro ao verificar se a data de vencimento está atualizada'
          );
          console.error(e);
          setIsVencimentoDataUpToDate(false);
        });
    }
  };

  const selectInitialEtapa = (etapas: EtapaDTO[], etapaToSelect?: number) => {
    if (size(etapas) > 0) {
      // Se houver uma etapa pra selecionar, seleciona ela e ignora as outras regras
      // senão, aplica a regra normal
      const etapa =
        (etapaToSelect
          ? find(etapas, (etp) => etp.id === etapaToSelect)
          : null) ??
        find(etapas, (etp) => etp.status === EXPIRADO) ??
        findLast(
          etapas,
          (etp) =>
            [EM_ANDAMENTO, AGUARDANDO, RECUSADO].find(
              (it) => it === etp.status
            ) !== undefined
        ) ??
        findLast(etapas, (etp) => etp.status !== PENDENTE) ??
        first(etapas);
      if (etapa) {
        setSelectedEtapa(etapa);
      }
    }
  };

  const realoadEtapasAndGoToEtapa = (etapaId: number) => {
    loadEtapas(etapaId);
  };

  const clearStateAndReloadProposta = () => {
    setSelectedEtapa(null);
    setEtapas([]);
    reloadProposta();
  };

  // Rendering functions
  const getStatusIcon = (stage: EtapaDTO) => {
    const error = stage.status == EXPIRADO;
    const completed = stage.status == CONCLUIDO;
    const progress =
      [AGUARDANDO, EM_ANDAMENTO, RECUSADO].find((it) => it === stage.status) !=
      null;

    let Icon = FaIcons.FaExclamationTriangle;
    if (error) Icon = FaIcons.FaInfoCircle;
    if (completed) Icon = FaIcons.FaRegCheckCircle;
    if (progress) Icon = FaIcons.FaSyncAlt;
    return (
      <StageStatusContainer
        error={error}
        completed={completed}
        progress={progress}
      >
        <Icon />
      </StageStatusContainer>
    );
  };

  const getStageEntry = (stage: EtapaDTO) => {
    const disabled = stage.status == PENDENTE;
    const error = stage.status == EXPIRADO;
    const selected = stage.id == selectedEtapa?.id;
    return (
      <StageEntry key={stage.id}>
        <StageLabelContainer>
          <StageLabelWrapper
            disabled={disabled}
            error={error}
            selected={selected}
            onClick={() => {
              if (!disabled) {
                setSelectedEtapa(stage);
              }
            }}
          >
            {stage.id}. {stage.etapa}
          </StageLabelWrapper>
        </StageLabelContainer>
        {getStatusIcon(stage)}
      </StageEntry>
    );
  };

  const getEtapa = (etapaId: number): EtapaDTO => {
    return (
      etapas?.find((it) => it.id === etapaId) ?? {
        id: etapaId,
        etapa: 'Não encontrada',
        status: PENDENTE,
      }
    );
  };

  const isSelected = (etapaId: number): boolean => {
    return selectedEtapa?.id === etapaId;
  };

  const containsAcaoSomenteLeitura = () => {
    return (
      find(
        proposta?.propostaAcoes,
        (acao) => upperCase(acao) === IDENTIFICADOR_MODO_LEITURA
      ) !== undefined
    );
  };

  const renderEtapas = () => {
    if (loading || globalContext.isLoading) {
      return <Loading padding="24px" />;
    }
    if (size(etapas) === 0) {
      return <NoDataInfoWrapper>Não há etapas para exibir</NoDataInfoWrapper>;
    }
    return etapas.map(getStageEntry);
  };

  const isReadyOnlyProposal = containsAcaoSomenteLeitura();
  return (
    <Wrapper show={show}>
      <Column>
        <Header>
          <StageLabelContainer>
            <TitleWrapper>Etapas</TitleWrapper>
          </StageLabelContainer>
          <StageStatusTitleContainer>
            <TitleWrapper justify="center">Status</TitleWrapper>
          </StageStatusTitleContainer>
        </Header>
        <ContentWrapper>{renderEtapas()}</ContentWrapper>
      </Column>
      <Column>
        <Header>
          <TitleWrapper justify="center">
            {selectedEtapa?.id ?? '0'}.{' '}
            {selectedEtapa?.etapa ?? 'Etapa não selecionada'}
          </TitleWrapper>
        </Header>
        <ContentWrapper>
          {proposta && (
            <>
              <Vencimento
                show={isSelected(VENCIMENTO)}
                readOnly={isReadyOnlyProposal}
                etapa={getEtapa(VENCIMENTO)}
                etapas={etapas}
                proposta={proposta}
                reloadEtapas={loadEtapas}
                reloadProposta={clearStateAndReloadProposta}
                goToAprovacaoTab={goToAprovacaoTab}
                goToEtapaVisualizarContrato={() =>
                  realoadEtapasAndGoToEtapa(VISUALIZAR_CONTRATO)
                }
                isVencimentoDataUpToDate={isVencimentoDataUpToDate}
              />
              <Visualizar
                show={isSelected(VISUALIZAR_CONTRATO)}
                readOnly={isReadyOnlyProposal}
                etapa={getEtapa(VISUALIZAR_CONTRATO)}
                etapaVencimento={getEtapa(VENCIMENTO)}
                etapaAssinatura={getEtapa(ASSINAR_CONTRATO)}
                proposta={proposta}
                reloadProposta={clearStateAndReloadProposta}
                goToEtapaConferirContrato={() =>
                  realoadEtapasAndGoToEtapa(CONFERIR_CONTRATO)
                }
                isUserDataUpToDate={isUserDataUpToDate}
              />
              <Conferir
                show={isSelected(CONFERIR_CONTRATO)}
                readOnly={isReadyOnlyProposal}
                etapa={getEtapa(CONFERIR_CONTRATO)}
                etapas={etapas}
                proposta={proposta}
                emailCliente={emailCliente}
                telefoneCliente={telefoneCliente}
                reloadProposta={clearStateAndReloadProposta}
                goToEtapaEnviar={() =>
                  realoadEtapasAndGoToEtapa(ENVIAR_CONTRATO)
                }
              />
              <Enviar
                show={isSelected(ENVIAR_CONTRATO)}
                readOnly={isReadyOnlyProposal}
                etapa={getEtapa(ENVIAR_CONTRATO)}
                proposta={proposta}
                goToEtapaAssinar={() =>
                  realoadEtapasAndGoToEtapa(ASSINAR_CONTRATO)
                }
              />
              <Assinar
                show={isSelected(ASSINAR_CONTRATO)}
                readOnly={isReadyOnlyProposal}
                etapa={getEtapa(ASSINAR_CONTRATO)}
                proposta={proposta}
                emailCliente={emailCliente}
                goToEtapaEnviarNf={() => realoadEtapasAndGoToEtapa(ENVIAR_NF)}
              />
            </>
          )}
        </ContentWrapper>
      </Column>
    </Wrapper>
  );
};

export default ContractIssuance;
