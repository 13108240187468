import { ButtonHTMLAttributes } from 'react';
import * as S from './styles';

import * as CXT from 'context/globalContext';
import { useContext } from 'react';

const DocSubmitButton = ({
  children,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const globalContext = useContext(CXT.Context);

  return globalContext.isLoading ? (
    <S.Wrapper {...props} className="Transparent"></S.Wrapper>
  ) : (
    <S.Wrapper {...props}>
      {globalContext.isLoading ? 'Carregando....' : !!children && children}
    </S.Wrapper>
  );
};

export default DocSubmitButton;
