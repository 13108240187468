const theme = {
  colors: {
    white: '#FFF',
    secondWhite: '#f3f9fc',
    thirdWhite: '#f2f2f2',

    gray: '#bfbfbf',
    graySecond: '#707070',
    gray250: '#6f6f6f',
    grayThird: '#747576',
    grayFourth: '#C4C4C4',
    grayFith: '#edeeef',
    graySixth: '#cdcdce',
    graySeventh: '#e8e9eb',
    grayEight: '#f1f1f1',

    black: '#000',
    blackSecondary: '#181818',
    blackTertiary: '#383838',

    bluePrimary: '#012d5c',
    blueSecondary: '#00a2e0',
    blueThird: '#002246',
    online: '#13a841',
    colorFooter: '#181818',
    btnPrev: '#9A9A9A',
    green: '#317D17',
    secondGreen: '#2E910C',
    thirdGreen: '#219652',
    red: '#F00909',
    orange: '#FFA500',
    yellow: '#F29500',
    yellow400: '#fdbe40',
    purple: '#6D437C',
  },

  border: {
    radius: {
      xsmall: '0.5rem',
      small: '1.0rem',
      medium: '1.5rem',
      large: '2rem',
      xlarge: '2.5rem',
    },
  },

  //Refatorar uso do font theme na aplicação inteira
  font: {
    sizes: {
      xxx_r_small: '0.8rem',
      xx_r_small: '0.9rem',
      x_r_small: '1.0rem',
      r_small: '1.1rem',
      xxx_small: '1.2rem',
      xx_small: '1.3rem',
      x_small: '1.4rem',
      small: '1.5rem',
      medium: '1.6rem',
      large: '1.8rem',
      xlarge: '2rem',
      xxlarge: '2.1rem',
      xxxlarge: '2.3rem',
      extraLarge: '2.6rem',
      xextraLarge: '2.8rem',
      xxextraLarge: '3.2rem',
      xxxextraLarge: '3.6rem',
      xxxxextraLarge: '4.2rem',
      extrabiglarge: '4.6rem',
      xextrabiglarge: '5.0rem',
    },
    weight: {
      light: 300,
      regular: 400,
      normal: 500,
      bold: 900,
    },
  },
};

export default theme;
