import { useEffect, useRef, useState } from 'react';

import { useClickOutside } from 'hooks/useClickOutside';

import { FilterValuesType } from '../Filter';

import { ReactComponent as IconCalendar } from 'assets/icons/Calendar_grey.svg';
import { ReactComponent as IconArrow } from 'assets/icons/Arrow_drop.svg';

import * as Styled from './styles';

type SelectFilterProps = {
  multiple?: boolean;
  name: string;
  onHandleChange?: (item: string, name: string) => void;
  options: string[];
  title: string;
  type: 'date' | 'options';
  value: string[] | string;
  setValue: React.Dispatch<React.SetStateAction<FilterValuesType>>;
  isShop?: boolean;
  controllerCheckBox?: string[];
};

export const SelectFilter = ({
  multiple,
  name,
  onHandleChange,
  options,
  title,
  type,
  value,
  setValue,
  isShop,
  controllerCheckBox,
}: SelectFilterProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [arrayState, setArrayState] = useState<string[] | boolean[]>([]);

  useEffect(() => {
    if (isShop) {
      const newArray = options;
      setArrayState(newArray.fill('false', 0));
    }
  }, [isShop]);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (controllerCheckBox === undefined || controllerCheckBox?.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setArrayState(arrayState.map((posicao) => (posicao = 'false')));
    }
  }, [controllerCheckBox]);

  useClickOutside(wrapperRef, () => {
    setIsActive(false);
  });

  const handleOnClickOption = (item: string, index: number) => {
    if (multiple) {
      const arr = value as string[];

      if (arrayState[index] === 'true') {
        arrayState[index] = 'false';
        setArrayState(arrayState);
      } else {
        arrayState[index] = 'true';
        setArrayState(arrayState);
      }

      const valueIndex = arr.findIndex((element: string) => element === item);

      if (valueIndex < 0) {
        arr.push(item);
      } else {
        arr.splice(valueIndex, 1);
      }

      return setValue((oldState) => ({ ...oldState, [name]: arr }));
    }

    setValue((oldState) => ({ ...oldState, [name]: item }));
  };

  return (
    <Styled.Wrapper ref={wrapperRef} onClick={() => setIsActive(!isActive)}>
      <span className="selectTitle">{title}</span>
      <Styled.ValueSelect>
        {multiple
          ? value.length === 0
            ? 'Selecione'
            : [...(value as string[])].join(', ')
          : value || 'Selecione'}
      </Styled.ValueSelect>
      {type === 'date' ? <IconCalendar /> : <IconArrow />}
      {isActive && (
        <Styled.OptionsContainer isShop={isShop ?? false}>
          {options.map((item, index) => (
            <Styled.OptionItem
              key={index}
              onClick={() => {
                handleOnClickOption(item, index);
                if (item == 'Pré Aprovado') {
                  item = 'PRE-APROVADO';
                }
                onHandleChange && onHandleChange(item, name);
              }}
              isActive={item === value ? true : false}
            >
              {item}

              {/* {arrayState[index] === 'true' && (
                <div>
                  <img src={greenChecked} alt="checked" title="checked" />
                </div>
              )} */}

              {isShop && (
                <input
                  type="checkbox"
                  name="check"
                  id="checkLoja"
                  className="checkLoja"
                  checked={
                    arrayState[index] === 'true' &&
                    controllerCheckBox !== undefined &&
                    controllerCheckBox.length > 0
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    event.target.checked
                  }
                />
              )}
            </Styled.OptionItem>
          ))}
        </Styled.OptionsContainer>
      )}
    </Styled.Wrapper>
  );
};
