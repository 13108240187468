import React from 'react';
import { InputHTMLAttributes, useState, useContext } from 'react';
import * as S from './styles';
import { BsCheck, BsExclamationCircle } from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import * as CXT from 'context/globalContext';
import { useEffect, createRef } from 'react';

import * as Mask from 'utils/mask';

type MaskedInputProps = {
  title?: string;
  type?: string;
  onChange?: (item: any) => void;
  MaskType:
    | 'telefone'
    | 'cep'
    | 'cpf'
    | 'dateMA'
    | 'dateDMA'
    | 'currency'
    | 'custom';
  customMask?: string;
  reverseMask?: boolean;
  value?: string;
  error?: boolean;
  errorMessage?: string;
  inputTheme?: 'default' | 'valid' | 'invalid';
  valid?: boolean;
  width?: string;
  disabled?: boolean;
  maxInputLength?: number;
  skellSize?: string;
  inputMethod?:
    | 'text'
    | 'none'
    | 'search'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | undefined;
  placeholder?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const MaskedInput = ({
  title = 'Digite a Label',
  type = 'text',
  onChange,
  MaskType,
  customMask = '##############',
  reverseMask = MaskType === 'currency',
  value = '',
  errorMessage = 'Campo obrigatório',
  error = false,
  inputTheme,
  valid = false,
  width = '100%',
  disabled = false,
  maxInputLength = 14,
  inputMethod = 'text',
  readOnly = false,
  placeholder,
  ...props
}: MaskedInputProps) => {
  const globalContext = useContext(CXT.Context);
  const [textBuffer, setTextBuffer] = useState<{ text: string; cp: number }>({
    text: '',
    cp: 0,
  });
  const [val, setVal] = useState<{ v: string; cp: number }>({
    v: value,
    cp: 0,
  });
  // const inputRef = useRef();
  const [cursorPosition, setCursorPosition] = useState(0);
  const inputRef: React.RefObject<HTMLInputElement> = createRef();

  const Transform = (value: string) => {
    let newValue = '';

    switch (MaskType) {
      case 'telefone':
        newValue = Mask.telMask(value);
        break;
      case 'cep':
        newValue = Mask.cepMask(value);
        break;
      case 'cpf':
        newValue = Mask.cpfMask(value);
        break;
      case 'dateMA':
        newValue = Mask.dateMAMask(value);
        break;
      case 'dateDMA':
        newValue = Mask.dateDMAMask(value);
        break;
      case 'currency':
        newValue = Mask.currencyFormatWithCents(value);
        break;
      case 'custom':
        newValue = Mask.mask(value, customMask);
        break;
      default:
        newValue = value;
        break;
    }

    return newValue;
  };

  useEffect(() => {
    if (Transform)
      setVal((old) => {
        return { ...old, v: Transform(value ?? ''), cp: cursorPosition ?? 0 };
      });
    else
      setVal((old) => {
        return { ...old, v: value, cp: cursorPosition ?? 0 };
      });
  }, [value]);

  const setText = (value: string, cursorPosition: number) => {
    let cursorAuxiliar = cursorPosition;
    if (reverseMask) {
      cursorAuxiliar = Mask.checkNewPosReverse(
        value ?? '',
        cursorPosition,
        value.length - val.v.length
      );
      setCursorPosition(cursorAuxiliar);
    } else {
      cursorAuxiliar = Mask.checkNewPos(
        value ?? '',
        cursorPosition,
        value.length - val.v.length
      );
      setCursorPosition(cursorAuxiliar);
    }
    if (Transform) {
      setVal({
        v: Transform(value ?? ''),
        cp: cursorAuxiliar,
      });
    }
  };

  const handleOnChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    let selectionStart = ev.target?.selectionStart ?? 0;

    if (textBuffer.text === '') {
      if (!reverseMask)
        while (
          selectionStart < ev.target.value.length &&
          (ev.target.value[selectionStart].match(/[^0-9]/g) || []).length
        ) {
          selectionStart += 1;
        }
      setText(ev.target.value, selectionStart ?? 0);
    } else {
      ev.target.value = textBuffer.text;
      setText(textBuffer.text, textBuffer.cp);
    }
    setTextBuffer({ text: '', cp: 0 });
    if (onChange) onChange(ev);
  };

  const handleOnKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') ev.preventDefault();
    else if (
      inputRef.current?.selectionStart !== inputRef.current?.selectionEnd
    ) {
      let text = '';
      let indexStart = 0;
      let indexEnd = 0;
      text = inputRef.current?.value ?? '';
      indexStart = inputRef.current?.selectionStart ?? 0;
      indexEnd = inputRef.current?.selectionEnd ?? 0;

      let newPos = 0;
      if (ev.key === 'Backspace' || ev.key === 'Delete') {
        text = `${text.substring(0, indexStart)}${text.substring(indexEnd)}`;
        newPos = Math.max(0, inputRef.current?.selectionStart ?? 0);
      } else {
        text = `${text.substring(0, indexStart)}${ev.key}${text.substring(
          indexEnd
        )}`;
        newPos = Math.max(0, inputRef.current?.selectionStart ?? 0) + 1;
      }

      setTextBuffer({ text: text, cp: newPos });
    } else if (ev.key === 'Backspace') {
      let text = '';
      let index = 0;
      let count = 1;
      text = inputRef.current?.value ?? '';
      index = inputRef.current?.selectionStart ?? 0;

      while (
        index - count > 0 &&
        (text[index - count].match(/[^0-9]/g) || []).length
      ) {
        text = `${text.substring(
          0,
          Math.max(index - count, 0)
        )}${text.substring(index - count + 1)}`;
        count += 1;
      }

      text = `${text.substring(0, Math.max(index - count, 0))}${text.substring(
        index - count + 1
      )}`;

      const newPos = Math.max(
        0,
        (inputRef.current?.selectionStart ?? 0) - count
      );
      setTextBuffer({ text: text, cp: newPos });
    } else if (ev.key === 'Delete') {
      let text = '';
      let index = 0;
      text = inputRef.current?.value ?? '';
      index = inputRef.current?.selectionStart ?? 0;

      while (
        index < text.length &&
        (text[index].match(/[^0-9]/g) || []).length
      ) {
        text = `${text.substring(0, index)}${text.substring(index + 1)}`;
      }
      text = `${text.substring(0, index)}${text.substring(index + 1)}`;
      const newPos = Math.max(0, inputRef.current?.selectionStart ?? 0);
      setTextBuffer({ text: text, cp: newPos });
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      if (reverseMask) {
        inputRef.current.selectionStart =
          val.cp === -1 ? val.v.length : val.v.length - val.cp;
        inputRef.current.selectionEnd =
          val.cp === -1 ? val.v.length : val.v.length - val.cp;
      } else {
        inputRef.current.selectionStart = val.cp === -1 ? val.v.length : val.cp;
        inputRef.current.selectionEnd = val.cp === -1 ? val.v.length : val.cp;
      }
    }
  }, [val]);

  return (
    <S.Wrapper
      width={width}
      style={{ maxWidth: `${width}` }}
      disabled={disabled}
    >
      {globalContext.isLoading ? (
        <S.SkelBox>
          <Skeleton />
        </S.SkelBox>
      ) : (
        <S.Label>{!!title && title}</S.Label>
      )}
      {globalContext.isLoading ? (
        <Skeleton />
      ) : (
        <>
          <S.Input
            ref={inputRef}
            name={props.name}
            readOnly={readOnly}
            type={type}
            onKeyDown={handleOnKeyDown}
            onChange={handleOnChange}
            onFocus={props.onFocus}
            value={val.v}
            width={width[width.length - 1] === '%' ? '100%' : width}
            inputTheme={inputTheme}
            error={error}
            valid={valid}
            maxLength={maxInputLength}
            disabled={disabled}
            inputMode={inputMethod}
            autoCorrect={'off'}
            placeholder={placeholder}
          />
          {!!valid && (
            <S.IconSuccess>
              <BsCheck size={14} color="#2E910C" />
            </S.IconSuccess>
          )}
          {!!error && (
            <>
              <S.MsgError>{errorMessage}</S.MsgError>
              <S.IconError>
                <BsExclamationCircle size={12} color="#f00909" />
              </S.IconError>
            </>
          )}
        </>
      )}
    </S.Wrapper>
  );
};

export default MaskedInput;
