import { ButtonHTMLAttributes } from 'react';
import * as S from './styles';

import * as CXT from 'context/globalContext';
import { useContext } from 'react';
import { ReactComponent as Voltar } from 'assets/icons/voltar.svg';

type DocButtonProps = {
  icone?: boolean;
  sumirOnLoad?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const DocButton = ({
  children,
  icone,
  sumirOnLoad,
  ...props
}: DocButtonProps) => {
  const globalContext = useContext(CXT.Context);

  return sumirOnLoad && globalContext.isLoading ? (
    <S.Wrapper {...props} className="Transparent"></S.Wrapper>
  ) : (
    <S.Wrapper {...props}>
      {icone ? <Voltar /> : ''}
      {globalContext.isLoading ? 'Carregando....' : !!children && children}
    </S.Wrapper>
  );
};

export default DocButton;
