import styled, { css } from 'styled-components';

export const WrapperLoading = styled.div`
  width: 0px;
  height: 0px;
`;

export const BackgroundBlur = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 120;
`;

export const LoadingEqualTable = styled.div`
  ${({ theme }) => css`
    width: 700px;
    height: 72px;
    background-color: ${theme.colors.grayFith};
    position: absolute;
    top: calc(50% - 36px);
    left: calc(50% - 350px);
    z-index: 121;
    box-shadow: 0px 0px 10px ${theme.colors.black};
    display: flex;
    align-items: center;
    padding: 0 24px;
  `}
`;

export const Spinner = styled.div`
  ${({ theme }) => css`
    width: 45px;
    height: 45px;
    background-color: transparent;
    border: solid 5px transparent;
    border-top-color: ${theme.colors.blueSecondary};
    border-radius: 50%;
    animation: rotationSpinner 0.8s 0s infinite;

    @keyframes rotationSpinner {
      from {
        transform: rotateZ(-360deg);
      }
    }
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.graySecond};
    font-size: 14px;
    margin-left: 20px;
    opacity: 0.7;
  `}
`;
