import * as S from './styles';
import React, { useContext, useEffect } from 'react';

import Check from 'assets/icons/Done.svg';
import Eye from 'assets/icons/Eye.svg';
import Warning from 'assets/icons/Warning.svg';
import BlueClip from 'assets/icons/Anexo-azul.svg';
import GrayClip from 'assets/icons/Anexo-gray.svg';
import { ReactComponent as Notas } from 'assets/icons/ballon_error.svg';
import { ReactComponent as RedNotas } from 'assets/icons/ballon_error_red.svg';

import Button from 'components/Button';
import ImgLabel from 'components/ImgLabel';
import * as CXT from 'context/globalContext';
import { Capitalize, cpfMask } from 'utils/mask';

export type ListInfo = {
  name: string;
  cpf: string;
  prop: number;
  date: string;
};

export type ListData = {
  contexto: string;
  dataEnvio: string;
  id: number;
  identifier: string;
  nome: string;
  parecer: string;
  status: string;
  usuarioEnvio: string;
};

export type DocListProps = {
  className?: string;
  activeAnexPopUp: (data: ListData) => void;
  activeNotePopUp: (data: ListData) => void;
  activeViewPopUp: (data: ListData, parecer: string) => void;
  listInfo?: ListInfo;
  listData?: Array<ListData>;
  docStatus?: string;
  readMod?: boolean;
  show: boolean;
  enablePopUpApprovalDocs(): boolean;
  setDocsApprovalData: React.Dispatch<React.SetStateAction<boolean>>;
};

const DocList = ({
  className = '',
  activeAnexPopUp,
  activeNotePopUp,
  activeViewPopUp,
  listInfo,
  listData,
  docStatus = '',
  readMod,
  show,
  enablePopUpApprovalDocs,
  setDocsApprovalData,
}: DocListProps) => {
  const globalContext = useContext(CXT.Context);

  const StatusParecer = (status: string) => {
    switch (status) {
      case 'ACCEPTED':
        return 'Aprovado';
      case 'REJECTED':
        return 'Rejeitado';
      case 'IN_ANALYSIS':
        return 'Aguardando';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (enablePopUpApprovalDocs() && show) {
      setDocsApprovalData(true);
    }
  }, [listData]);

  const cpfSize = 11;

  function addLeadingZeros(cpf: string, size: number) {
    const cpfString = `${cpf}`;
    const cpfFormatado = cpfString.padStart(size, '0');
    return cpfMask(cpfFormatado);
  }

  if (!show) return null;

  return (
    <S.Wrapper className={className}>
      <S.InfoBox>
        <S.InfoDataBox className="typeI">
          <ImgLabel className="merge" type="person">
            Nome e CPF
          </ImgLabel>
          <label>{!!listInfo?.name && Capitalize(listInfo.name)}</label>
          <label>
            {!!listInfo?.cpf && addLeadingZeros(listInfo.cpf, cpfSize)}
          </label>
        </S.InfoDataBox>
        <S.InfoDataBox className="typeII">
          <ImgLabel className="merge" type="paper">
            Proposta
          </ImgLabel>
          <label>#{!!listInfo?.prop && listInfo.prop}</label>
        </S.InfoDataBox>
        <S.InfoDataBox className="typeII">
          <ImgLabel className="merge" type="data">
            Data Inclusão
          </ImgLabel>
          <label>{!!listInfo?.date && listInfo.date}</label>
        </S.InfoDataBox>
        <S.TitleListBox className="merge">
          <span>DOCUMENTOS</span>
          <label>Pendente</label>
          <label>Enviado</label>
          <label>Visualizar</label>
          <label>Parecer</label>
          <Notas />
          <label>Anexar</label>
        </S.TitleListBox>
        <S.HorizontalLine className="merge longer" />
        <S.ListBox className="merge">
          {listData?.map((element, index) => {
            return (
              <React.Fragment key={index}>
                <span>{`${element.nome} - ${element.contexto}`}</span>
                {globalContext.isLoading ? (
                  <div />
                ) : element.status === 'PENDING_SENDING' ? (
                  <S.Img src={Warning} />
                ) : (
                  <S.Img src={Check} />
                )}

                {element.status === 'PENDING_SENDING' ||
                globalContext.isLoading ? (
                  <div />
                ) : element.status === 'SENDING' ? (
                  <S.Img src={Warning} />
                ) : (
                  <S.Img src={Check} />
                )}

                {/* {element.status === 'PENDING_SENDING' ||
                element.status === 'SENDING' ? ( */}
                {element.status === 'PENDING_SENDING' ||
                globalContext.isLoading ? (
                  <div />
                ) : (
                  <S.Img
                    src={Eye}
                    className="clickable"
                    onClick={() => {
                      activeViewPopUp(
                        element,
                        StatusParecer(element.status) ?? ''
                      );
                    }}
                  />
                )}
                {globalContext.isLoading ? (
                  <div />
                ) : (
                  <S.ParecerText className={StatusParecer(element.status)}>
                    {StatusParecer(element.status)}
                  </S.ParecerText>
                )}

                {element.status === 'REJECTED' ? (
                  globalContext.isLoading ? (
                    <div />
                  ) : (
                    <RedNotas
                      className="clickable"
                      onClick={() => !readMod && activeNotePopUp(element)}
                    />
                  )
                ) : (
                  <div />
                )}
                {globalContext.isLoading ? (
                  <div />
                ) : element.status === 'PENDING_SENDING' ? (
                  <S.Img
                    className="clickable"
                    src={readMod ? GrayClip : BlueClip}
                    onClick={() => !readMod && activeAnexPopUp(element)}
                  />
                ) : (
                  <S.Img src={GrayClip} />
                )}
                <S.HorizontalLine className="InternMerge" />
              </React.Fragment>
            );
          })}
        </S.ListBox>

        {docStatus === 'CRIADO' ? (
          <S.ButtonBox className="merge">
            <Button disabled marginTop="0px" height="50px">
              Aguarde aprovação de crédito para anexar documentos.
            </Button>
          </S.ButtonBox>
        ) : (
          ''
        )}
      </S.InfoBox>
    </S.Wrapper>
  );
};

export default DocList;
