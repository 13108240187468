import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { MdHomeWork } from 'react-icons/md';
import { AccessToAllStores } from './AccessToAllStores';
import { User } from '../../../../services/user';
import { Company } from 'components/Header/types/typesUsingInHeader';

type StoresProps = {
  openStoreExchange: boolean;
  setOpenStoreExchange: React.Dispatch<React.SetStateAction<boolean>>;
  onStoreSelected: () => void;
};

export interface ILoja extends Company {
  cpfcnpjEmpresa: number;
}

export const Stores: React.FC<StoresProps> = ({
  openStoreExchange,
  setOpenStoreExchange,
  onStoreSelected,
}) => {
  const [allStores, setAllStores] = useState<ILoja[]>([{} as ILoja]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (openStoreExchange) {
      setLoading(true);
      User.CompanyRequest()
        .then((response) => {
          const data = response.data.data;
          const groupOfStores = data[0].lojaAgrupamentoResponse;
          const arrayWithStores = [];
          for (let i = 0; i < groupOfStores.length; i++) {
            arrayWithStores.push(groupOfStores[i].lojas);
          }

          const mapArray = [].concat(...arrayWithStores) as ILoja[];
          setAllStores(mapArray);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [openStoreExchange]);

  return (
    <S.Wrapper>
      <div className="IconOfStore">
        <MdHomeWork
          className="icon-1"
          onClick={() => setOpenStoreExchange(!openStoreExchange)}
        />

        <AccessToAllStores
          onStoreSelected={onStoreSelected}
          openStoreExchange={openStoreExchange}
          setOpenStoreExchange={setOpenStoreExchange}
          allStores={allStores}
          loading={loading}
        />
      </div>
    </S.Wrapper>
  );
};
