import Input from 'components/Input';
import MaskedInput from 'components/MaskedInput';
import Select from 'components/Select';
import { FormikTouched } from 'formik';
import { EstadoCivilProps } from 'pages/Register/utils/personProps';
import { FormPropsType } from 'pages/Register/utils/types/FormPropType/FormPropType';
import React, { useState } from 'react';
import * as S from './styles';

type InputsAndSelectsOfBasicInformationProps = {
  values: FormPropsType;
  errorsForm: FormPropsType;
  successForm: FormPropsType;
  touched: FormikTouched<FormPropsType>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  maritalStatusList: EstadoCivilProps[];
};

export const InputsAndSelectsOfBasicInformation: React.FC<
  InputsAndSelectsOfBasicInformationProps
> = ({
  values,
  errorsForm,
  successForm,
  touched,
  setFieldValue,
  maritalStatusList,
}) => {
  const person = JSON.parse(localStorage.getItem('client') || '{}');

  const [editMother] = useState(person?.nomeMae ? true : false);
  const [editDate] = useState(person?.dataNascimento ? true : false);

  return (
    <S.Wrapper>
      <S.DivInput>
        <Input
          readOnly
          name="nome"
          title="Nome completo"
          value={values.nome}
          maxInputLength={50}
          error={errorsForm.nome === 'true' && successForm.nome === 'false'}
          valid={!!values.nome && !!touched.nome && errorsForm.nome === 'false'}
        />
        <MaskedInput
          readOnly={editDate}
          name="aniversario"
          title="Data de nascimento"
          inputMethod="numeric"
          value={values.aniversario}
          MaskType={'dateDMA'}
          maxInputLength={10}
          error={
            errorsForm.aniversario === 'true' &&
            successForm.aniversario === 'false'
          }
          valid={
            !!values.aniversario &&
            !!touched.aniversario &&
            errorsForm.aniversario === 'false'
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('aniversario', event.target.value);
          }}
        />
        <Select
          title="Sexo"
          name="sexo"
          value={values.sexo}
          error={errorsForm.sexo === 'true' && successForm.sexo === 'false'}
          valid={!!values.sexo && !!touched.sexo && errorsForm.sexo === 'false'}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            setFieldValue('sexo', event.target.value);
          }}
        >
          <option value="" hidden>
            Selecione uma opção
          </option>
          <option value="M">Masculino</option>
          <option value="F">Feminino</option>
          <option value="N">Não Declarado</option>
        </Select>

        <Select
          name="estadoCivil"
          title="Estado Civil"
          error={
            errorsForm.estadoCivil === 'true' &&
            successForm.estadoCivil === 'false'
          }
          valid={
            !!values.estadoCivil &&
            !!touched.estadoCivil &&
            errorsForm.estadoCivil === 'false'
          }
          value={values.estadoCivil}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            setFieldValue('estadoCivil', event.target.value);
          }}
        >
          <option value="" hidden>
            Selecione uma opção
          </option>
          {maritalStatusList.length > 0 &&
            maritalStatusList.map((item: EstadoCivilProps) => (
              <option value={item.descricao} key={item.id}>
                {item.descricao}
              </option>
            ))}
        </Select>

        <Input
          readOnly={editMother}
          name="mae"
          title="Nome da mãe"
          value={values.mae}
          maxInputLength={50}
          error={errorsForm.mae === 'true' && successForm.mae === 'false'}
          valid={!!values.mae && !!touched.mae && errorsForm.mae === 'false'}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('mae', event.target.value);
          }}
        />
      </S.DivInput>
    </S.Wrapper>
  );
};
