import React, { useContext } from 'react';
import PopUp from 'components/PopUp';
import * as CTX from '../../../../context/globalContext';
import { Pedido, pedidoIdForm } from 'services/pedido';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: '00621c4c68a1b2bd34e7458c0f8d29bc',
  plugins: [new BugsnagPluginReact()],
});

type ShowPopUpProps = {
  handleCheckId: (response: any) => void;
};

export const ShowPopUp: React.FC<ShowPopUpProps> = ({ handleCheckId }) => {
  const globalContext = useContext(CTX.Context);

  const update = () => {
    globalContext.setIsLoading(true);
    globalContext.setPopUpActive([false, false, false, false]);
    const form: pedidoIdForm = {
      pedidoId: globalContext.idPedido,
      versaoValidacao: '3',
      codPropostaAtualizar: globalContext.codPropostaExistente,
    };

    Pedido.PostIDPedidoV2(form)
      .then(handleCheckId)
      .catch((err) => Bugsnag.notify(err))
      .finally(() => globalContext.setIsLoading(false));
  };

  return (
    <div>
      {globalContext.popUpActive[0] && (
        <PopUp
          title="SALVAR"
          subTitle={`Pedido: ${globalContext.popUpNumPedido}`}
          type="double"
          isActive={true}
          setIsActive={globalContext.closePopUp}
          b1Label="CANCELAR"
          b1Func={globalContext.closePopUp}
          b2Label="ATUALIZAR PROPOSTA"
          b2Func={update}
        >
          <label>
            DESEJA ATUALIZAR A PROPOSTA?
            <br />
            <br />
            JÁ EXISTE UMA PROPOSTA EM ANDAMENTO
            <br />
            PARA ESSE MESMO CLIENTE E VEÍCULO.
            <br />
            <br />
            <br />
            DESEJA ALTERAR OS DADOS COM ESSA NOVA ALTERAÇÃO?
          </label>
        </PopUp>
      )}
      {globalContext.popUpActive[1] && (
        <PopUp
          title="SALVAR"
          subTitle={`Pedido: ${globalContext.popUpNumPedido}`}
          type="single"
          isActive={true}
          setIsActive={globalContext.closePopUp}
          b1Label="OK, ENTENDI"
          b1Func={globalContext.closePopUp}
        >
          <label>
            CLIENTE JÁ ESTÁ EM ANÁLISE!
            <br />
            <br />
            ESSE CLIENTE JÁ POSSUI UMA PROPOSTA
            <br />
            NA ETAPA DE ANÁLISE DE CRÉDITO.
            <br />
            <br />
            <br />
            AGUARDE O CRÉDITO FINALIZAR
            <br />
            PARA PODER ALTERAR OU FAZER UMA NOVA SOLICITAÇÃO.
          </label>
        </PopUp>
      )}
      {globalContext.popUpActive[2] && (
        <PopUp
          title="SALVAR"
          subTitle={`Pedido: ${globalContext.popUpNumPedido}`}
          type="single"
          isActive={true}
          setIsActive={globalContext.closePopUp}
          b1Label="OK, ENTENDI"
          b1Func={globalContext.closePopUp}
        >
          <label>
            ESSE CLIENTE JÁ POSSUI UMA PROPOSTA
            <br />
            EM ANDAMENTO!
            <br />
            <br />
            <br />
            PARA ENVIAR ESSA SOLICITAÇÃO,
            <br />
            VOCÊ DEVERÁ INICIALMENTE CANCELAR A PROPOSTA ANTERIOR
            <br />
            (NA TELA DO ACOMPANHAMENTO DE VENDAS).
          </label>
        </PopUp>
      )}
      {globalContext.popUpActive[3] && (
        <PopUp
          title="SALVAR"
          subTitle={`Pedido: ${globalContext.popUpNumPedido}`}
          type="single"
          isActive={true}
          setIsActive={globalContext.closePopUp}
          b1Label="OK, ENTENDI"
          b1Func={globalContext.closePopUp}
        >
          <label>
            O CRÉDITO DESSE CLIENTE FOI RECUSADO RECENTEMENTE.
            <br />
            <br />
            <br />
            SERÁ NECESSARIO AGUARDAR UM PERIODO DE ATÉ 30 DIAS
            <br />
            PARA UMA NOVA ANÁLISE
          </label>
        </PopUp>
      )}
    </div>
  );
};
