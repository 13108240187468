import { FormPropsType } from '../types/FormPropType/FormPropType';
import { validationMesAdmission } from './mesAnoAdmission';
import { EstadoCivilProps, ProfissaoProps } from '../personProps';

export const validation = (
  values: FormPropsType,
  arrayOfMaritalStatus: EstadoCivilProps[],
  arrayOfTypeOcupationValue: number[],
  arrayOfTypeComprovation: ProfissaoProps[],
  tagValidationForTypeOcupation: string,
  listaDeProfissoes: ProfissaoProps[]
): [FormPropsType, FormPropsType, boolean] => {
  const success = {} as FormPropsType;
  const errors = {} as FormPropsType;

  const sexoValues = ['M', 'F', 'N'];

  if (values.nome.length >= 3 && values.nome.length <= 65) {
    success.nome = 'true';
    errors.nome = 'false';
  } else {
    errors.nome = 'true';
    success.nome = 'false';
  }

  if (values.cpf.length === 14) {
    success.cpf = 'true';
    errors.cpf = 'false';
  } else {
    errors.cpf = 'true';
    success.cpf = 'false';
  }

  if (values.aniversario.length === 10) {
    success.aniversario = 'true';
    errors.aniversario = 'false';
  } else {
    errors.aniversario = 'true';
    success.aniversario = 'false';
  }

  if (sexoValues.includes(values.sexo)) {
    success.sexo = 'true';
    errors.sexo = 'false';
  } else {
    errors.sexo = 'true';
    success.sexo = 'false';
  }

  const maritalStatusValues = arrayOfMaritalStatus.map(
    (posicao) => posicao.descricao
  );

  if (maritalStatusValues.includes(values.estadoCivil)) {
    success.estadoCivil = 'true';
    errors.estadoCivil = 'false';
  } else {
    errors.estadoCivil = 'true';
    success.estadoCivil = 'false';
  }

  if (values.mae.length >= 3 && values.mae.length <= 65) {
    success.mae = 'true';
    errors.mae = 'false';
  } else {
    errors.mae = 'true';
    success.mae = 'false';
  }

  if (values.email.length >= 6 && values.email.length <= 65) {
    success.email = 'true';
    errors.email = 'false';
  } else {
    errors.email = 'true';
    success.email = 'false';
  }

  if (values.telefone.length >= 10 && values.telefone.length < 14) {
    success.telefone = 'true';
    errors.telefone = 'false';
  } else {
    errors.telefone = 'true';
    success.telefone = 'false';
  }

  if (values.cep.length === 8) {
    success.cep = 'true';
    errors.cep = 'false';
  } else {
    errors.cep = 'true';
    success.cep = 'false';
  }

  if (values.logradouro.length >= 3 && values.logradouro.length <= 65) {
    success.logradouro = 'true';
    errors.logradouro = 'false';
  } else {
    errors.logradouro = 'true';
    success.logradouro = 'false';
  }

  if (values.numero.length >= 1) {
    success.numero = 'true';
    errors.numero = 'false';
  } else {
    errors.numero = 'true';
    success.numero = 'false';
  }

  if (values.complemento.length >= 0) {
    success.complemento = 'true';
    errors.complemento = 'false';
  } else {
    errors.complemento = 'true';
    success.complemento = 'false';
  }

  if (values.bairro.length >= 3 && values.bairro.length <= 65) {
    success.bairro = 'true';
    errors.bairro = 'false';
  } else {
    errors.bairro = 'true';
    success.bairro = 'false';
  }

  if (values.cidade.length >= 2 && values.cidade.length <= 65) {
    success.cidade = 'true';
    errors.cidade = 'false';
  } else {
    errors.cidade = 'true';
    success.cidade = 'false';
  }

  if (values.uf.length === 2) {
    success.uf = 'true';
    errors.uf = 'false';
  } else {
    errors.uf = 'true';
    success.uf = 'false';
  }

  const profissoes = listaDeProfissoes.map((posicao) => posicao.descricao);

  if (profissoes.includes(values.profissao)) {
    success.profissao = 'true';
    errors.profissao = 'false';
  } else {
    errors.profissao = 'true';
    success.profissao = 'false';
  }

  if (arrayOfTypeOcupationValue.includes(Number(values.atividade))) {
    success.atividade = 'true';
    errors.atividade = 'false';
  } else {
    errors.atividade = 'true';
    success.atividade = 'false';
  }

  const typesOfComprovation = arrayOfTypeComprovation.map(
    (posicao) => posicao.descricao
  );

  if (typesOfComprovation.includes(values.comprovacao)) {
    success.comprovacao = 'true';
    errors.comprovacao = 'false';
  } else {
    errors.comprovacao = 'true';
    success.comprovacao = 'false';
  }

  if (
    tagValidationForTypeOcupation.match('#renda#') &&
    Number(values.renda.replace(/[,.]/g, '')) >= 1
  ) {
    success.renda = 'true';
    errors.renda = 'false';
  } else {
    errors.renda = 'true';
    success.renda = 'false';
  }

  if (
    tagValidationForTypeOcupation.match('#rp_nome_empresa#') &&
    values.empresa.length >= 3
  ) {
    success.empresa = 'true';
    errors.empresa = 'false';
  } else if (
    !tagValidationForTypeOcupation.match('#rp_nome_empresa#') &&
    values.empresa === ''
  ) {
    success.empresa = '';
    errors.empresa = '';
  } else {
    errors.empresa = 'true';
    success.empresa = 'false';
  }

  if (
    tagValidationForTypeOcupation.match('#rp_telefone#') &&
    values.telEmpresa.length >= 10 &&
    values.telEmpresa.length < 15
  ) {
    success.telEmpresa = 'true';
    errors.telEmpresa = 'false';
  } else if (
    !tagValidationForTypeOcupation.match('#rp_telefone#') &&
    values.telEmpresa === ''
  ) {
    success.telEmpresa = '';
    errors.telEmpresa = '';
  } else {
    errors.telEmpresa = 'true';
    success.telEmpresa = 'false';
  }

  if (
    tagValidationForTypeOcupation.match('#profissao#') &&
    values.cargo.length >= 3
  ) {
    success.cargo = 'true';
    errors.cargo = 'false';
  } else if (
    !tagValidationForTypeOcupation.match('#profissao#') &&
    values.cargo === ''
  ) {
    success.cargo = '';
    errors.cargo = '';
  } else {
    errors.cargo = 'true';
    success.cargo = 'false';
  }

  if (
    tagValidationForTypeOcupation.match('#rp_admissao#') &&
    values.mesAnoAdmissao.length === 6
  ) {
    if (validationMesAdmission(values)) {
      success.mesAnoAdmissao = 'true';
      errors.mesAnoAdmissao = 'false';
    } else {
      errors.mesAnoAdmissao = 'true';
      success.mesAnoAdmissao = 'false';
    }
  } else if (
    !tagValidationForTypeOcupation.match('#rp_admissao#') &&
    values.mesAnoAdmissao === ''
  ) {
    success.mesAnoAdmissao = '';
    errors.mesAnoAdmissao = '';
  } else {
    errors.mesAnoAdmissao = 'true';
    success.mesAnoAdmissao = 'false';
  }

  if (
    tagValidationForTypeOcupation.match('#rp_municipio#') &&
    values.cepEmpresa.length === 8
  ) {
    success.cepEmpresa = 'true';
    errors.cepEmpresa = 'false';
  } else if (
    !tagValidationForTypeOcupation.match('#rp_municipio#') &&
    values.cepEmpresa === ''
  ) {
    success.cepEmpresa = '';
    errors.cepEmpresa = '';
  } else {
    errors.cepEmpresa = 'true';
    success.cepEmpresa = 'false';
  }

  if (
    tagValidationForTypeOcupation.match('#rp_municipio#') &&
    values.ufEmpresa.length === 2
  ) {
    success.ufEmpresa = 'true';
    errors.ufEmpresa = 'false';
  } else if (
    !tagValidationForTypeOcupation.match('#rp_municipio#') &&
    values.ufEmpresa === ''
  ) {
    success.ufEmpresa = '';
    errors.ufEmpresa = '';
  } else {
    errors.ufEmpresa = 'true';
    success.ufEmpresa = 'false';
  }

  if (
    tagValidationForTypeOcupation.match('#rp_municipio#') &&
    values.cidadeEmpresa.length >= 3
  ) {
    success.cidadeEmpresa = 'true';
    errors.cidadeEmpresa = 'false';
  } else if (
    !tagValidationForTypeOcupation.match('#rp_municipio#') &&
    values.cidadeEmpresa === ''
  ) {
    success.cidadeEmpresa = '';
    errors.cidadeEmpresa = '';
  } else {
    errors.cidadeEmpresa = 'true';
    success.cidadeEmpresa = 'false';
  }

  const convertObjectInArray = Object.entries(errors);

  const verificationIfContentErrorsInArray = convertObjectInArray.filter(
    (position) => position[1] === 'true'
  );

  let validationResult = false;

  if (verificationIfContentErrorsInArray.length === 0) {
    validationResult = true;
    return [success, errors, validationResult];
  }

  return [success, errors, validationResult];
};
