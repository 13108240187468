import styled, { css } from 'styled-components';
import { transparentize, lighten, darken } from 'polished';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BlurWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    position: fixed;

    background-color: ${transparentize(0.3, theme.colors.blackTertiary)};

    z-index: 100;
  `}
`;

export const PopUpContainer = styled.div`
  ${({ theme }) => css`
    margin: 0 1.6rem;
    padding: 2rem 8rem;
    position: absolute;
    top: 25%;

    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 0.8rem;

    background-color: ${theme.colors.white};

    z-index: 110;
    animation: increaseScale 0.4s normal;

    @keyframes increaseScale {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }

    svg {
      position: absolute;
      top: 1.6rem;
      right: 1.6rem;

      cursor: pointer;
    }

    img {
      width: 56px;
    }

    h1 {
      margin-top: 1.6rem;

      font-family: 'Roboto', sans-serif;
      font-size: 2rem;
      font-weight: 500;

      color: ${lighten(0.3, theme.colors.blackTertiary)};
    }

    strong {
      margin-top: 3.2rem;

      font-family: 'Roboto', sans-serif;
      font-size: 1.6rem;
      font-weight: 700;

      color: ${lighten(0.3, theme.colors.blackTertiary)};

      text-transform: uppercase;
    }

    span {
      margin-top: 0.8rem;

      font-family: 'Roboto', sans-serif;
      font-size: 1.6rem;
      font-weight: 300;

      color: ${lighten(0.3, theme.colors.blackTertiary)};

      @media (max-width: 460px) {
        text-align: center;
      }
    }

    button {
      margin-top: 3.6rem;
      padding: 1.2rem 4rem;

      border: 0;
      border-radius: 0.4rem;

      background-color: ${theme.colors.blueSecondary};
      color: ${theme.colors.white};

      transition: all 0.2s;

      &:hover {
        background-color: ${darken(0.07, theme.colors.blueSecondary)};
      }

      @media (max-width: 460px) {
        width: 80%;
        padding: 1.6rem 4rem;

        font-size: 1.6rem;
      }
    }
  `}
`;
