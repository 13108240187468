import styled, { css } from 'styled-components';

export const Wrapper = styled.header`
  ${({ theme }) => css`
    width: 100%;
    height: 8rem;
    position: fixed;

    z-index: 100;

    background-color: ${theme.colors.bluePrimary};

    @media (max-width: 460px) {
      height: auto;
    }
  `}
`;

export const NavContent = styled.nav`
  width: 100%;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
  padding-left: 4.8rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > img {
    width: 200px;

    cursor: pointer;

    @media (max-width: 460px) {
      width: 170px;
    }
  }

  //Header improvisado (não oficial), irá precisar fazer manutenção na responsividade 460px para celular.
  @media (max-width: 460px) {
    padding: 1rem 0 0 1rem;

    display: block;
  }

  .navBarLinks {
    height: 100%;
    display: flex;

    flex: 1;

    //Header improvisado (não oficial), irá precisar fazer manutenção na responsividade 460px para celular.
    @media (max-width: 460px) {
      height: 6rem;

      justify-content: center;
    }
  }
`;

export const NavLinkButton = styled.div`
  ${({ theme }) => css`
    height: 100%;
    margin-left: 12rem;

    & + & {
      margin-left: 4rem;
    }

    @media (max-width: 1024px) {
      margin-left: 2rem;

      & + & {
        margin-left: 2rem;
      }
    }

    a {
      height: 100%;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: ${theme.font.sizes.x_small};

      color: ${theme.colors.white};

      transition: filter 0.2s;

      &.actived::after {
        content: '';
        width: 100%;
        height: 0.4rem;
        position: absolute;
        left: 0;
        bottom: 0;

        border-radius: 0.4rem 0.4rem 0 0;

        background-color: ${theme.colors.blueSecondary};
      }

      &:hover {
        filter: brightness(0.8);
      }
    }
  `}
`;

export const UserContainer = styled.div`
  height: 100%;

  display: flex;
`;

export const ContainerRefresh = styled.div`
  ${({ theme }) => css`
    margin-right: 2.4rem;

    display: flex;
    align-items: center;

    @media (max-width: 1024px) {
      display: none;
    }

    img {
      width: 18px;

      cursor: pointer;
    }

    span {
      margin-left: 0.8rem;

      font-size: ${theme.font.sizes.xxx_small};
      color: ${theme.colors.white};
    }
  `}
`;

export const ContainerLogout = styled.div`
  ${({ theme }) => css`
    min-width: 10rem;
    padding: 0 4rem;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: ${theme.font.sizes.x_small};

    background-color: ${theme.colors.blueThird};
    color: ${theme.colors.white};

    img {
      width: 20px;
      margin-left: 1.6rem;

      cursor: pointer;
    }

    //Header improvisado (não oficial), irá precisar fazer manutenção na responsividade 460px para celular.
    @media (max-width: 460px) {
      min-width: 10rem;
      padding: 2rem 2rem;
      position: absolute;
      top: 0;
      right: 0;
    }
  `}
`;

export const LinkAccessCP = styled.div`
  ${({ theme }) => css`
    width: max-content;
    height: 100%;
    margin-right: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4.3rem;

    a {
      font-size: ${theme.font.sizes.x_small};
      color: ${theme.colors.white};
      margin-left: 0.5rem;
      cursor: pointer;
      transition: color 0.5s;
    }

    &:hover {
      a {
        color: ${theme.colors.blueSecondary};
      }
    }
  `}
`;
