import * as Styled from './styles';

import { WarningType } from '../../';

import warningSvg from 'assets/icons/Warning.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

type PopUpWarningProps = {
  title: string;
  label: string;
  isVehicleName: boolean;
  outOfOrderVehicle: boolean;
  setWarningData: React.Dispatch<React.SetStateAction<WarningType>>;
};

export const PopUpWarning = ({
  title,
  label,
  isVehicleName,
  outOfOrderVehicle,
  setWarningData,
}: PopUpWarningProps) => {
  const vehiclePlate = JSON.parse(localStorage.getItem('vehiclePlate') || '{}');

  return (
    <Styled.Wrapper>
      <Styled.BlurWrapper
        onClick={() => {
          setWarningData((oldState) => ({
            ...oldState,
            isActive: false,
          }));
        }}
      />
      <Styled.PopUpContainer>
        <CloseIcon
          onClick={() => {
            setWarningData((oldState) => ({
              ...oldState,
              isActive: false,
            }));
          }}
        />
        <img src={warningSvg} alt="" />
        <h1>{title}</h1>
        <span>{label}</span>
        {isVehicleName && <strong>{vehiclePlate.nomeEspecie}</strong>}
        {outOfOrderVehicle && <strong>{vehiclePlate.consultaSituacao}</strong>}
        <button
          onClick={() => {
            setWarningData((oldState) => ({
              ...oldState,
              isActive: false,
            }));
          }}
        >
          OK, ENTENDI
        </button>
      </Styled.PopUpContainer>
    </Styled.Wrapper>
  );
};
