import * as S from './styles';
import { useState } from 'react';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { DocumentProxyMask } from 'utils/mask';

type PDFProps = {
  className?: string;
  url: string;
  width?: number;
  disableScroll?: boolean;
};

const ShowPDF = ({
  className = '',
  url,
  width = 300,
  disableScroll = false,
}: PDFProps) => {
  const [numPages, setNumPages] = useState(0);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  // useEffect(() => {
  //   Deals.DownloadDoc(DocumentProxyMask(url))
  //     .then((response) => console.log(response))
  //     .finally(() => {
  //       console.log('');
  //     });
  // }, []);

  return (
    <S.StyledDocument
      className={className}
      disableScroll={disableScroll}
      file={DocumentProxyMask(url)}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <S.StyledPage
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          width={width}
        />
      ))}
    </S.StyledDocument>
  );
};

export default ShowPDF;
