export const VENCIMENTO = 1;
export const VISUALIZAR_CONTRATO = 2;
export const CONFERIR_CONTRATO = 3;
export const ENVIAR_CONTRATO = 4;
export const ASSINAR_CONTRATO = 5;
export const ENVIAR_NF = 6;
export const SOLICITAR_PAGAMENTO = 7;
export const INCLUSAO_GRAVAME = 8;
export const ENVIADO_PAGAMENTO = 9;
export const CONTRATO_PAGO = 10;
