import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  padding-top: 37px;
  padding-bottom: 37px;
  ${media.lessThan('large')`
    padding: 3.7rem 2rem;
  `}
`;

export const TitleWrapper = styled.div`
  width: 100%;
  max-width: 961.5px;
  height: 45px;
  margin: 0 auto;
  margin-top: 8rem;
  padding: 0 8px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const StepInfo = styled.h1`
  ${({ theme }) => css`
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    margin-left: 1.2rem;
    margin-right: 0.4rem;
    color: ${theme.colors.blackTertiary};
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: ${theme.colors.blackTertiary};
  `}
`;

export const HardLine = styled.hr`
  ${({ theme }) => css`
    display: block;
    width: 961.5px;
    height: 0.2rem;
    border: 2px solid ${theme.colors.blueSecondary};
    margin: 1.35rem auto;
    background: ${theme.colors.blueSecondary};
    ${media.lessThan('large')`
      width: 100%;
      max-width: 960px;
    `}
  `}
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  width: 75.6px;
  height: 75.6px;
  align-items: center;
  background-color: #13a841;
  border-radius: 100%;
  margin: 98.7px auto 9.4px;
`;

export const Image = styled.img`
  width: 55.8;
  height: 29.4px;
`;

export const SubTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #747576;
  margin-bottom: 71.5px;
`;
export const Message = styled.span`
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: normal;
  text-align: left;
  color: #383838;

  ${media.lessThan('small')`
width: 100vw;
text-align: center;
`}
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 720px;
  max-width: 961.5px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;

  ${Title} {
    font-size: 35px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #13a841;
    margin-bottom: 9.4px;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
  margin-top: 88px;

  button {
    margin-top: 0;
  }
`;
