import * as S from './styles';
import Skeleton from 'react-loading-skeleton';
import * as CXT from 'context/globalContext';
import { useContext } from 'react';

export type parcelObj = {
  parcelNum: string;
  value: string;
  juros: string;
  entradaMin: string;
  total: string;
  totalFinancia: string;
  active: string;
  open: string;
};

export type parcelArray = Array<parcelObj>;

const Parcels = (props: {
  data: parcelArray;
  activeIndex: (number: number) => void;
  openInfo: (number: number) => void;
  control: number;
  disableParcels: (number: number) => void;
}) => {
  const globalContext = useContext(CXT.Context);
  return (
    <S.Wrapper>
      <S.Label>
        {globalContext.isLoading ? (
          <>
            <Skeleton width="0px" />
          </>
        ) : (
          <>ESCOLHER PARCELA</>
        )}
      </S.Label>
      <S.HorizontalBar />
      {props.data.slice(0, props.control).map((value, index) => (
        <S.Line key={index} className={value.open}>
          <S.ParcelNumber
            type="button"
            className={value.active}
            onClick={() => {
              props.activeIndex(index);
              props.disableParcels(index);
            }}
          >
            {globalContext.isLoading ? '' : <>{value.parcelNum}</>}
          </S.ParcelNumber>
          <S.ValueBox Border={value.active === 'active'}>
            <S.Value className={value.open} Border={value.active === 'active'}>
              {globalContext.isLoading ? (
                <>
                  <Skeleton width="85px" />
                </>
              ) : (
                <>{value.value}</>
              )}
            </S.Value>
          </S.ValueBox>
          <S.VerticalBar Border={value.active === 'active'}>
            <S.VerticalInside />
          </S.VerticalBar>
          <S.Info Border={value.active === 'active'}>
            <br />
            {globalContext.isLoading ? (
              <>
                <Skeleton width="85px" />
              </>
            ) : (
              <>Juros {value.juros} A.M.</>
            )}
            {/* Entrada Min. R${' '}
            {value.entradaMin.slice(0, value.entradaMin.length - 3)} */}
            <br />
            {/* <S.infoPlus type="button" onClick={(ev) => props.openInfo(index)}>
              {value.open === 'open' ? '-' : '+'} informações
            </S.infoPlus> */}
          </S.Info>
        </S.Line>
      ))}
    </S.Wrapper>
  );
};

export default Parcels;
