import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { find, first } from 'lodash';
import { Dialog, DialogTitle } from '@mui/material';

import Button from 'components/Button';
import { telMask } from 'utils/mask';
import Input from 'components/Input';
import Loading from 'components/Loading';
import {
  Person,
  SaveDataClientAndSendAntiFraud,
} from '../../../../../services/client';
import { Deals } from 'services/propostas';
import { DialogContentWrapper, DialogFooter } from './styles';
import { Proposta } from 'pages/Documents/types';

interface Props {
  open: boolean;
  handleClose: () => void;
  proposta: Proposta;
  reloadProposta: () => void;
}

const EditPhoneMailDialog = ({
  proposta,
  reloadProposta,
  handleClose,
  open,
}: Props) => {
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      loadData();
    } else {
      clearData();
    }
  }, [open]);

  const onChangePhone = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const newPhone = target.value.replace(/\D/g, '');
    const newPhoneMasked = telMask(newPhone);

    return setPhone(newPhoneMasked);
  };

  const clearData = () => {
    setEmail('');
    setPhone('');
  };

  const loadData = () => {
    setEditLoading(true);
    setEmail('');
    setPhone('');
    Person.ClientRequest(`${proposta.clienteCpfCnpj}`)
      .then((response) => {
        const {
          data: {
            data: { emails, telefones },
          },
        } = response;

        const email =
          find(emails, (it) => it.preferencia) ?? first(emails) ?? {};
        const telefone =
          find(telefones, (it) => it.preferencia) ?? first(telefones) ?? {};
        setEmail(email.email);
        setPhone(telMask(`${telefone.ddd}${telefone.telefone}`));
      })
      .catch((e) => {
        toast.error('Erro ao carregar dados, por favor tente novamente.');
      })
      .finally(() => {
        setEditLoading(false);
      });
  };

  const saveData = () => {
    setSaveLoading(true);

    SaveDataClientAndSendAntiFraud({
      newData: { email: email, phone: phone },
      cpf: proposta.clienteCpfCnpj,
      dealNumber: `${proposta.propostaCodigo}`,
    })
      .then(() => {
        Deals.SetContratoAlterado(
          proposta.propostaCodigo,
          proposta.pedidoId,
          proposta.clienteCpfCnpj
        )
          .then(() => {
            toast.success('Dados atualizados com sucesso!');
            reloadProposta();
            handleClose();
            setSaveLoading(false);
          })
          .catch(() => {
            toast.error(
              'Erro ao definir o contrato como atualizado! Por favor, tente novamente'
            );
            setSaveLoading(false);
          });
      })
      .catch((e) => {
        toast.error('Erro ao atualizar os dados!');
        setSaveLoading(false);
      });
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle fontSize={18}>Editar dados</DialogTitle>
        <DialogContentWrapper>
          {editLoading ? (
            <Loading size={48} />
          ) : (
            <>
              <Input
                value={phone}
                onChange={onChangePhone}
                name="phone"
                title="Telefone"
                inputMethod="tel"
                disabled={saveLoading}
              ></Input>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="mail"
                title="E-mail"
                inputMethod="email"
                disabled={saveLoading}
              ></Input>
            </>
          )}
        </DialogContentWrapper>
        <DialogFooter>
          <Button
            marginTop="0px"
            height="48px"
            width="152px"
            buttonTheme="goback"
            onClick={handleClose}
            disabled={saveLoading}
          >
            Cancelar
          </Button>
          <Button
            marginTop="0px"
            height="48px"
            width="152px"
            onClick={saveData}
            loading={saveLoading}
            disabled={editLoading}
          >
            Salvar
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default EditPhoneMailDialog;
