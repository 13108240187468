import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    font-size: ${theme.font.sizes.xx_small};
    text-align: center;
    label {
      border: 1px solid ${theme.colors.bluePrimary};
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &.RoundUp {
        border-bottom: 0px;
        border-radius: 8px 8px 0px 0px;
        color: ${theme.colors.grayThird};
        font-size: ${theme.font.sizes.x_r_small};
        line-height: 23px;
      }
      &.RoundDown {
        border-radius: 0px 0px 8px 8px;
        line-height: 27px;
        //O PROBLEMA ESTA AKI
        /* height: -webkit-fill-available; */
      }
      &.AllRound {
        border-radius: 8px;
        line-height: 27px;
      }
      &.variant {
        background-color: ${theme.colors.bluePrimary};
        color: ${theme.colors.white};
        margin: 3px 0px;
        border: 0px hidden;
      }
    }
  `}
`;
