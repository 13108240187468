export const returnsWithCapitalLettersAndWithoutAccent = (
  municipio: string
): string => {
  municipio.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  return municipio.toUpperCase();
};

export const removeWhiteSpace = (value: string): string => {
  if (!value) return '';
  const newValue = value.replaceAll(/\s/g, '');
  return newValue;
};
