import styled, { css } from 'styled-components';

type CheckBoxType = {
  checked?: boolean;
};

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    & + & {
      margin-top: 0.4rem;
    }

    input[type='checkbox'] {
      display: none;
    }

    label {
      margin-left: 0.8rem;

      font-family: 'Roboto', sans-serif;
      font-size: 1.2rem;
      font-weight: 500;

      color: ${theme.colors.blackTertiary};
    }
  `}
`;

export const Checkbox = styled.div<CheckBoxType>`
  ${({ theme, checked }) => css`
    width: 2rem;
    height: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid ${theme.colors.blueSecondary};
    border-radius: 0.4rem;

    cursor: pointer;

    svg {
      width: 1.2rem;
      color: ${theme.colors.thirdWhite};
    }

    ${checked &&
    css`
      background-color: ${theme.colors.blueSecondary};
    `}
  `}
`;
