import { Route } from 'react-router';
// import Login from 'pages/Login';

import * as CTX from 'context/globalContext';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'hooks/debouncer';

export default function CustomRoute({
  isPrivate,
  path,
  exact,
  component,
}: {
  isPrivate?: boolean;
  path: string;
  exact: boolean;
  component: any;
}) {
  const globalContext = useContext(CTX.Context);
  const history = useHistory();

  const Logout = useDebouncedCallback(() => {
    if (path === '/client') history.push('/');
    else if (path !== '/') history.push('/client');
  }, 60 * 60 * 1000);

  Logout();

  if (isPrivate && !globalContext.authenticated) {
    return <div />;
  }
  return <Route path={path} exact={exact} component={component} />;
}
