import apiAppdsv from '../api/api-appdsv';

const RESOURCE = '';

type DataUserRequest = {
  telaHome: string;
  empresa: string;
  nomeEmpresa: string;
  parceiroId?: any;
  cpfCnpjLojista: number;
  empresaMatrizLojistaId: number;
  apiBaseVeiculo: string;
  apiBasePessoa: string;
  permissao: string[];
  atributos: string[];
  situacaoLogin: string;
  mensagemLogin: string;
  cpfAgente: number;
  nomeAgente: string;
  foneAgente: string;
  emailAgente: string;
};

export type UserRequestType = {
  messages?: any;
  data: DataUserRequest;
};

export const User = {
  UserRequest: async (username: string) => {
    return await apiAppdsv.get<UserRequestType>(
      `${RESOURCE}/usuario?cpf=${username}`
    );
  },
  CompanyRequest: async () => {
    return await apiAppdsv.get(`${RESOURCE}/loja`);
  },
  CompanyListRequest: async () => {
    return await apiAppdsv.get(`${RESOURCE}/loja?tipoConsulta=ACV`);
  },
  UseTerms: async () => {
    return await apiAppdsv.get(`${RESOURCE}/login/termoaceite/consultar`);
  },
  AcceptTerms: async () => {
    return await apiAppdsv.post(`${RESOURCE}/login/termoaceite/true`, null);
  },
  DesAcceptTerms: async () => {
    return await apiAppdsv.post(`${RESOURCE}/login/termoaceite/false`, null);
  },
};
