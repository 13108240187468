import { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import { Deal } from "..";
import * as Styled from './style'

function Pagination(props) {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 30

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(props.proposta.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(props.proposta.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, props.proposta])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % props.proposta.length;
    setItemOffset(newOffset);
  };

  return (
    <div>
      {currentItems.map((element, index) => (
        <Deal key={index} data={element}/>
      ))}
      <Styled.Paginacao>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Próximo"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="Anterior"
          containerClassName={'pagination'}
          activeClassName={'active'}
          renderOnZeroPageCount={null}
        />
      </Styled.Paginacao>
    </div>
  );
}

export default Pagination;