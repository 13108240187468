import { PersonProps } from 'pages/Register/utils/personProps';
import apiAppdsv from '../api/api-appdsv';
import apiAntiFraud from '../api/api-car';
import { Deals } from './propostas';
import { Register } from './register';

const RESOURCE = '';

interface ISaveDataCleintAndSendAntiFraud {
  newData: {
    email: string;
    phone: string;
  };
  cpf: any;
  dealNumber: string;
}

type emails = {
  email: string;
  preferencia: boolean;
  status: number;
};

type phones = {
  ddd: string;
  preferencia: boolean;
  status: number;
  telefone: string;
  tipo: any;
};

export const Person = {
  ClientRequest: async (cpf: string) => {
    return await apiAppdsv.get(`${RESOURCE}/pessoa/fisica/${cpf}`);
  },
  JobsRequest: async () => {
    return await apiAppdsv.get(`${RESOURCE}/profissoes`);
  },
  ComprovantRequest: async () => {
    return await apiAppdsv.get(`${RESOURCE}/tiposcomprovanterenda`);
  },
  BudgetRequest: async (body: any) => {
    return await apiAppdsv.post(`${RESOURCE}/pedido/validar`, body);
  },
  SendUUID: async (uuid: {
    uuid: string;
    tipoDispositivo: string;
    versao: string;
  }) => {
    return await apiAppdsv.post(`/login/uuid`, uuid);
  },
};

export const SendDocumentAvalista = {
  SendDataToAntiFraud: async (idPedido: string) => {
    return await apiAntiFraud.post(`/caf/${idPedido}/avalista/link`, {});
  },
};

export const SendDocument = {
  SendDataToAntiFraud: async (idPedido: string) => {
    return await apiAntiFraud.post(`/caf/${idPedido}/link`, {});
  },
};

export const SaveDataClientAndSendAntiFraud = async ({
  newData,
  cpf,
  dealNumber,
}: ISaveDataCleintAndSendAntiFraud) => {
  let responseGetClient: any;
  try {
    responseGetClient = await Person.ClientRequest(cpf);
  } catch (error: any) {
    if (error?.response.status >= 400) {
      return 'Ops! Tivemos problemas ao buscar os dados do cliente';
    }
  }

  const arrayEmails = responseGetClient.data.data.emails as emails[];
  const newArrayChangeEmails = arrayEmails.map((posicao) =>
    posicao.email === newData.email
      ? {
          email: newData.email,
          preferencia: true,
          status: 1,
        }
      : {
          email: posicao.email,
          preferencia: false,
          status: 2,
        }
  );

  const checkPrefenciaIsEmailsTrue = newArrayChangeEmails.filter(
    (posicao) => posicao.preferencia === true
  );

  if (checkPrefenciaIsEmailsTrue.length === 0) {
    const newEmail = {
      email: newData.email,
      preferencia: true,
      status: 1,
    };

    newArrayChangeEmails.push(newEmail);
  }

  responseGetClient.data.data.emails = newArrayChangeEmails;

  const newDdd = newData.phone.substring(1, 3);
  const newNumberPhone = newData.phone.substring(4).replace(/\D/g, '');

  const arrayPhones = responseGetClient.data.data.telefones as phones[];
  const newArrayChangePhones = arrayPhones.map((posicao) =>
    posicao.ddd === newDdd && posicao.telefone === newNumberPhone
      ? {
          ddd: newDdd,
          preferencia: true,
          status: 1,
          telefone: newNumberPhone,
          tipo: posicao.tipo,
        }
      : {
          ddd: posicao.ddd,
          preferencia: false,
          status: 2,
          telefone: posicao.telefone,
          tipo: posicao.tipo,
        }
  );

  const checkPrefenciaIsPhonesTrue = newArrayChangePhones.filter(
    (posicao) => posicao.preferencia === true
  );

  if (checkPrefenciaIsPhonesTrue.length === 0) {
    const newPhone = {
      ddd: newDdd,
      preferencia: true,
      status: 1,
      telefone: newNumberPhone,
      tipo: null,
    };
    newArrayChangePhones.push(newPhone);
  }

  responseGetClient.data.data.telefones = newArrayChangePhones;
  responseGetClient.data.data.atualizarFinanceira = true;
  const clientUpdateObject = responseGetClient.data as PersonProps;

  try {
    await Register.Post(clientUpdateObject);
  } catch (error: any) {
    if (error?.response.status >= 400) {
      return 'Ops! Tivemos problemas ao atualizar os dados do cliente';
    }
  }

  try {
    const ultimateResponse = await SendDocument.SendDataToAntiFraud(dealNumber);

    if (ultimateResponse?.status === 200) {
      return 'Enviado com sucesso.';
    }
  } catch (error: any) {
    if (error?.response.status >= 400) {
      return 'Ops! Tivemos problemas ao enviar a verificação para o email.';
    }
  }
};

export const SaveDataClientAndSendAntiFraud2 = async ({
  newData,
  cpf,
  dealNumber,
}: ISaveDataCleintAndSendAntiFraud) => {
  // #region AtualizarDadosCadastro
  let responseGetClient: any;

  try {
    responseGetClient = await Person.ClientRequest(cpf);
  } catch (error: any) {
    if (error?.response.status >= 400) {
      return 'Ops! Tivemos problemas ao buscar os dados do avalista';
    }
  }

  const arrayEmails = responseGetClient.data.data.emails as emails[];
  const newArrayChangeEmails = arrayEmails.map((posicao) =>
    posicao.email === newData.email
      ? {
          email: newData.email,
          preferencia: true,
          status: 1,
        }
      : {
          email: posicao.email,
          preferencia: false,
          status: 2,
        }
  );

  const checkPrefenciaIsEmailsTrue = newArrayChangeEmails.filter(
    (posicao) => posicao.preferencia === true
  );

  if (checkPrefenciaIsEmailsTrue.length === 0) {
    const newEmail = {
      email: newData.email,
      preferencia: true,
      status: 1,
    };

    newArrayChangeEmails.push(newEmail);
  }

  responseGetClient.data.data.emails = newArrayChangeEmails;

  const newDdd = newData.phone.substring(1, 3);
  const newNumberPhone = newData.phone.substring(4).replace(/\D/g, '');

  const arrayPhones = responseGetClient.data.data.telefones as phones[];
  const newArrayChangePhones = arrayPhones.map((posicao) =>
    posicao.ddd === newDdd && posicao.telefone === newNumberPhone
      ? {
          ddd: newDdd,
          preferencia: true,
          status: 1,
          telefone: newNumberPhone,
          tipo: posicao.tipo,
        }
      : {
          ddd: posicao.ddd,
          preferencia: false,
          status: 2,
          telefone: posicao.telefone,
          tipo: posicao.tipo,
        }
  );

  const checkPrefenciaIsPhonesTrue = newArrayChangePhones.filter(
    (posicao) => posicao.preferencia === true
  );

  if (checkPrefenciaIsPhonesTrue.length === 0) {
    const newPhone = {
      ddd: newDdd,
      preferencia: true,
      status: 1,
      telefone: newNumberPhone,
      tipo: null,
    };
    newArrayChangePhones.push(newPhone);
  }

  if (
    checkPrefenciaIsPhonesTrue.length === 0 ||
    checkPrefenciaIsEmailsTrue.length === 0
  ) {
    responseGetClient.data.data.telefones = newArrayChangePhones;
    responseGetClient.data.data.atualizarFinanceira = true;
    const clientUpdateObject = responseGetClient.data as PersonProps;

    try {
      await Register.Post(clientUpdateObject);
    } catch (error: any) {
      if (error?.response.status >= 400) {
        return 'Ops! Tivemos problemas ao atualizar os dados do cliente';
      }
    }
  }
  // #endregion AtualizarDadosCadastro

  try {
    const ultimateResponse = await SendDocumentAvalista.SendDataToAntiFraud(
      dealNumber
    );

    if (ultimateResponse?.status === 200) {
      return 'Enviado com sucesso.';
    }
  } catch (error: any) {
    if (error?.response.status >= 400) {
      return 'Ops! Tivemos problemas ao enviar a verificação para o email.';
    }
  }
};
