import axios, { AxiosError } from 'axios';
import history from 'utils/history';

const apiCar = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_BACKEND_API,
  // headers: { 'Content-Type': 'application/json', charset: 'utf-8' },
});

apiCar.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      if (error.response?.data?.error === 'Unauthorized') {
        localStorage.removeItem('token');
        apiCar.defaults.headers.Authorization = undefined;
        history.push('/');
      }
    }
    return Promise.reject(error);
  }
);
export default apiCar;
