import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { find, first } from 'lodash';
import { Dialog, DialogTitle } from '@mui/material';

import Button from 'components/Button';
import { Deals } from 'services/propostas';
import { DialogContentWrapper, DialogFooter } from './styles';
import { Proposta } from 'pages/Documents/types';
import { EtapaDTO } from 'pages/Documents/Contract/types';
import { CONCLUIDO } from '../../../stageStatus';
import { formatTime, parseTimestamp } from 'utils/dateFns';
import { addMinutes, differenceInMinutes, isAfter, setSeconds } from 'date-fns';
import { ENVIAR_CONTRATO } from '../../../acoesProposta';

interface Props {
  open: boolean;
  handleClose: () => void;
  proposta: Proposta;
  etapa: EtapaDTO;
  goToEtapaAssinar: () => void;
}

const SendConfirmationDialog = ({
  proposta,
  etapa,
  handleClose,
  open,
  goToEtapaAssinar,
}: Props) => {
  const CONFIRMATION = 'CONFIRMATION';
  const SEND_NOT_AVAILABLE = 'SEND_NOT_AVAILABLE';
  const [status, setStatus] = useState(CONFIRMATION);
  const [loading, setLoading] = useState(false);
  const [dataUltimoEnvio, setDataUltimoEnvio] = useState<null | Date>(null);

  const handleCloseDialog = () => {
    setStatus(CONFIRMATION);
    setDataUltimoEnvio(null);
    handleClose();
  };

  const sendContract = () => {
    setLoading(true);
    Deals.GetDataUltimoEnvioContratoAssinatura(proposta.propostaCodigo)
      .then((response) => {
        const ultimoEnvio = new Date(response.data.data);
        if (ultimoEnvio && isAfter(addMinutes(ultimoEnvio, 10), Date.now())) {
          setStatus(SEND_NOT_AVAILABLE);
          setDataUltimoEnvio(ultimoEnvio);
          setLoading(false);
        } else {
          Deals.RealizarAcao(
            proposta.pedidoId,
            ENVIAR_CONTRATO,
            null,
            proposta.propostaCodigo
          )
            .then(() => {
              goToEtapaAssinar();
              handleCloseDialog();
            })
            .catch((err) => {
              console.error(err);
              toast.error('Erro ao enviar o contrato');
            })
            .finally(() => setLoading(false));
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('Erro ao buscar a última data de envio do contrato');
        setLoading(false);
      });
  };

  const getMinutesFromLastSend = () => {
    if (dataUltimoEnvio) {
      return differenceInMinutes(new Date(), dataUltimoEnvio);
    }
    return '0';
  };

  const getFormatedData = () => {
    if (dataUltimoEnvio) {
      return formatTime(addMinutes(setSeconds(dataUltimoEnvio as Date, 0), 11));
    }
    return '00:00';
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle fontSize={18}>Envio do contrato</DialogTitle>
        {status === CONFIRMATION && (
          <>
            <DialogContentWrapper>
              Você deseja {etapa.status === CONCLUIDO ? 'reenviar' : 'enviar'} o
              contrato para o cliente?
            </DialogContentWrapper>
            <DialogFooter>
              <Button
                marginTop="0px"
                height="48px"
                width="152px"
                buttonTheme="goback"
                onClick={handleClose}
                disabled={loading}
              >
                Não
              </Button>
              <Button
                marginTop="0px"
                height="48px"
                width="152px"
                onClick={sendContract}
                loading={loading}
              >
                Sim
              </Button>
            </DialogFooter>
          </>
        )}
        {status === SEND_NOT_AVAILABLE && (
          <>
            <DialogContentWrapper>
              Contrato enviado há {getMinutesFromLastSend()} minutos, aguarde
              até {getFormatedData()} para um novo envio
            </DialogContentWrapper>
            <DialogFooter>
              <Button
                marginTop="0px"
                height="48px"
                width="152px"
                buttonTheme="goback"
                onClick={handleCloseDialog}
              >
                OK
              </Button>
            </DialogFooter>
          </>
        )}
      </Dialog>
    </>
  );
};

export default SendConfirmationDialog;
