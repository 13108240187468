import styled, { css } from 'styled-components';
import { transparentize, lighten, darken } from 'polished';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BlurWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    position: fixed;

    background-color: ${transparentize(0.3, theme.colors.blackTertiary)};

    z-index: 100;
  `}
`;

export const PopUpContainer = styled.div`
  ${({ theme }) => css`
    width: max-content;
    height: max-content;
    margin: 0 1.6rem;
    padding: 4rem 6rem;
    position: absolute;
    top: 25%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 0.8rem;

    background-color: ${theme.colors.white};

    z-index: 110;
    animation: increaseScale 0.4s normal;

    @keyframes increaseScale {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }

    svg {
      position: absolute;
      top: 1.6rem;
      right: 1.6rem;

      cursor: pointer;
    }

    .marginTop {
      margin-top: 15px;
    }

    .marginBottom {
      margin-bottom: -15px;
    }

    .wrapperTextToUser {
      margin-left: -30px;
      display: flex;
      align-items: center;
      span {
        margin: 0 auto;
      }
    }

    strong {
      font-size: 2.2rem;
      color: ${theme.colors.blackSecondary};
    }

    img {
      width: 56px;
    }

    h1 {
      margin-top: 0.8rem;

      font-family: 'Roboto', sans-serif;
      font-size: 2rem;
      font-weight: 500;

      color: ${theme.colors.bluePrimary};
    }

    p {
      color: #000000;
      font-size: 1.6rem;
      margin-left: 10px;
    }

    .marginLeftAdjusting {
      margin-left: -64px;
    }

    .textOverflow {
      width: 233px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    input {
      color: #000000;
      font-size: 1.6rem;
      padding: 3px 10px;
      width: 300px;
      border: none;
      border-bottom: 1px solid ${theme.colors.gray};
      line-height: 1.21;
    }

    .marginTopInEditCamp {
      margin-top: 0.5rem;
    }

    span {
      margin-top: 3.2rem;

      font-family: 'Roboto', sans-serif;
      font-size: 1.6rem;
      font-weight: 300;

      text-align: center;

      color: ${lighten(0.3, theme.colors.blackTertiary)};

      @media (max-width: 460px) {
        text-align: center;
      }
    }
    form {
      margin: 0 auto;
      width: max-content;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    label {
      font-family: 'Roboto', sans-serif;
      font-size: 1.6rem;
      font-weight: 300;
      width: 70px;

      text-align: right;

      color: ${lighten(0.3, theme.colors.blackTertiary)};
    }

    span + strong {
      margin-top: 1.6rem;
    }

    strong {
      margin-top: 0.8rem;
      font-family: 'Roboto', sans-serif;
      font-size: 1.8rem;
      font-weight: 500;

      color: ${theme.colors.black};

      text-transform: uppercase;
    }

    .alignTextRight {
      text-align: right;
      width: 45px;
    }

    button {
      margin-top: 3.6rem;
      padding: 1.2rem 4rem;

      border: 0;
      border-radius: 0.4rem;

      &:disabled {
        opacity: 0.5;
      }

      background-color: ${theme.colors.blueSecondary};
      color: ${theme.colors.white};

      transition: all 0.2s;

      &:hover {
        background-color: ${darken(0.07, theme.colors.blueSecondary)};
      }

      @media (max-width: 460px) {
        width: 80%;
        padding: 1.6rem 4rem;

        font-size: 1.6rem;
      }
    }
  `}
`;
