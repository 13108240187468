import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const MainContainer = styled.main`
  width: 100%;
  height: 100vh;
  padding: 22rem 34rem;

  display: flex;

  @media (max-width: 1640px) {
    padding: 10rem;
  }

  @media (max-width: 940px) {
    padding: 4rem;
  }

  @media (max-width: 460px) {
    padding: 15rem 4rem;
    display: block;
  }
`;

export const LeftSideContainer = styled.div`
  width: 50%;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 460px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: 'Roboto', sans-serif;
    font-size: 2.8rem;
    font-weight: bold;

    color: ${theme.colors.bluePrimary};

    @media (max-width: 460px) {
      font-size: 2.4rem;
    }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    margin: 2rem 0;

    font-family: 'Roboto', sans-serif;
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.5;

    color: ${theme.colors.blackTertiary};

    @media (max-width: 460px) {
      font-size: 2rem;
    }
  `}
`;

export const StepList = styled.div`
  ${({ theme }) => css`
    .row + .row {
      margin-top: 0.8rem;
    }

    strong {
      color: ${theme.colors.bluePrimary};
    }

    span {
      font-size: 1.8rem;
      font-weight: 300;

      color: ${theme.colors.blackTertiary};
      opacity: 0.8;
    }
  `}
`;

export const Divisor = styled.div`
  ${({ theme }) => css`
    width: 0.5px;
    height: 100%;

    background-color: ${theme.colors.bluePrimary};

    @media (max-width: 460px) {
      width: 100%;
      height: 0.5px;

      margin-top: 4rem;
    }
  `}
`;

export const RightSideContainer = styled.div`
  width: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 460px) {
    width: 100%;
    margin-top: 4rem;
    padding-bottom: 4rem;
  }
`;
