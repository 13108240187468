import styled, { css } from 'styled-components';
import { get } from 'lodash';

type WrapperProps = {
  padding?: string;
  colorToken?: string;
};
export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, padding = '0px', colorToken = 'blueSecondary' }) => css`
    padding: ${padding};
    & path {
      stroke: ${get(theme.colors, colorToken, '#000000')};
    }
  `}
`;
