import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.main`
  width: 100%;
  min-height: 180px;
  display: flex;
  flex-direction: column;
`;

export const Line = styled.div`
  width: 100%;
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  align-items: normal;

  ${media.lessThan('large')`
      margin-top: 10px;
    `} ${media.lessThan('small')`
      margin-top: 10px;
    `} &.open {
    height: 120px;
    ${media.lessThan('small')`
      height: 120px;
      `}
  }
  &.close {
    height: 50px;
    ${media.lessThan('small')`
      height: 65px;
      `}
  }
`;

export const ParcelNumber = styled.button`
  ${({ theme }) => css`
    width: 92px;
    height: 50px;
    line-height: 49px;
    text-align: center;
    border-radius: 0.6rem 0 0 0.6rem;
    font-weight: 600;
    font-size: ${theme.font.sizes.xxxlarge};
    color: ${theme.colors.white};
    border: none;
    &.noneActive {
      background-color: ${theme.colors.bluePrimary};
    }
    &.active {
      background-color: ${theme.colors.green};
    }
    &.inactive {
      background-color: ${theme.colors.gray};
    }
    ${media.lessThan('small')`
      height: 65px;
      `}
  `}
`;

type ValueProps = {
  theme: DefaultTheme;
  Border?: boolean;
};

export const ValueBox = styled.div`
  ${({ theme, Border = false }: ValueProps) => css`
    display: flex;
    flex-direction: column;
    width: 172px;
    align-items: center;
    border-bottom: 1px solid ${theme.colors.gray};
    border-top: 1px solid ${theme.colors.gray};
    border-left: 1px solid ${theme.colors.gray};
    background-color: ${theme.colors.thirdWhite};
    border-color: ${Border ? theme.colors.green : theme.colors.gray};
  `}
`;

export const Value = styled.div`
  ${({ theme }: ValueProps) => css`
    width: 98px;
    height: 100%;

    text-align: left;
    font-weight: 600;
    font-size: ${theme.font.sizes.xlarge};

    &.open {
      line-height: 120px;
      border-radius: 0 0 0 0.6rem;

      ${media.lessThan('small')`
        line-height: 120px;
      `}
    }
    &.close {
      line-height: 50px;
      border-radius: 0;
      ${media.lessThan('small')`
        line-height: 65px;
      `}
    }
  `}
`;

export const Info = styled.div`
  ${({ theme, Border = false }: ValueProps) => css`
    width: 172px;
    height: 100%;

    line-height: 14px;
    padding-top: 5px;
    padding-left: 20px;
    text-align: left;
    font-size: ${theme.font.sizes.x_small};
    font-weight: 200;
    background-color: ${theme.colors.thirdWhite};

    border-bottom: 1px solid;
    border-top: 1px solid;
    border-right: 1px solid;

    border-color: ${Border ? theme.colors.green : theme.colors.gray};

    border-radius: 0 0.6rem 0.6rem 0;
  `}
`;

export const infoPlus = styled.button`
  ${({ theme }) => css`
    width: 70px;
    height: 10px;
    line-height: 9px;
    text-align: left;
    font-weight: 30;
    font-size: ${theme.font.sizes.x_r_small};
    color: ${theme.colors.blueSecondary};
    background-color: ${theme.colors.thirdWhite};
    border: none;
  `}
`;

export const Label = styled.div`
  ${({ theme }) => css`
    font-family: 'Roboto', sans-serif;
    color: ${theme.colors.graySecond};
    font-weight: 500;
    font-size: ${theme.font.sizes.xxx_small};
    line-height: 40px;
    margin-left: 10px;
  `}
`;

export const HorizontalBar = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 1px;
    border: none;
    background-color: ${theme.colors.gray};
    margin-bottom: 10px;
  `}
`;

export const VerticalBar = styled.div`
  ${({ theme, Border = false }: ValueProps) => css`
    width: 1px;
    height: 100%;
    display: flex;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: ${Border ? theme.colors.green : theme.colors.gray};

    background-color: ${theme.colors.thirdWhite};
    justify-content: space-around;
    align-items: center;
  `}
`;

export const VerticalInside = styled.div`
  ${({ theme }) => css`
    width: 1px;
    height: 80%;
    border: none;
    background-color: ${theme.colors.gray};
  `}
`;
