import { TextareaAutosize } from '@mui/material';
import * as S from './styles';

type ObsTextFieldProps = {
  disabled?: boolean;
  titulo?: string;
  minRows?: number;
  maxRows?: number;
  placeholder?: string;
  value?: string;
  onChange: (event: any) => void;
};

const ObsTextField = ({ titulo, ...props }: ObsTextFieldProps) => {
  return (
    <S.Wrapper>
      <span>{titulo}</span>
      <TextareaAutosize {...props} />
    </S.Wrapper>
  );
};

export default ObsTextField;
