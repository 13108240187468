import styled, { css } from 'styled-components';

type WrapperType = {
  notificationOn: boolean;
};

export const Wrapper = styled.aside<WrapperType>`
  ${({ theme, notificationOn }) => css`
    width: 210px;
    height: 135px;
    position: absolute;
    z-index: 20;
    top: 80%;
    right: 10px;
    background-color: white;
    border-radius: 3px;
    font-family: 'Roboto';
    animation-name: ${notificationOn && 'moveLeft'};
    animation-duration: 2s;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    animation-direction: normal;
    transform: translateX(120%);
    box-shadow: 0px 0.5px 2px 1px #cec9c8;

    @keyframes moveLeft {
      100% {
        transform: translateX(-0%);
      }
    }

    .floatingNotificationHead {
      margin: 0 auto;
      width: 100%;
      height: 35px;
      background-color: ${theme.colors.blueSecondary};
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;

      div {
        margin: 0 auto;
        width: 90%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      h5 {
        width: 75%;
        color: ${theme.colors.white};
        font-weight: 500;
        padding-right: 10px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        font-size: ${theme.font.sizes.small};
      }

      .iconCloseNotificationFloating {
        font-size: ${theme.font.sizes.large};
        color: ${theme.colors.white};
        cursor: pointer;
      }
    }

    .messagingFloating {
      width: 100%;
      height: 85px;
      overflow: hidden;
      p {
        width: 95%;
        padding: 10px;
        width: 95%;
        color: #000;
        font-weight: 500;
        font-size: ${theme.font.sizes.xx_small};
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
  `}
`;
