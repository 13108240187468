import * as S from './styles';
import Skeleton from 'react-loading-skeleton';
import * as CXT from 'context/globalContext';
import { useContext } from 'react';

export type parcelObjSpecial = {
  valorParcelas: string;
  parcelaValor: string;
  vezesAdicionais: string;
  valorAdicionais: string;
  percentualEntradaMinima: string;
  quantidadeMaximaParcelas: string;
  entradaMin: string;
  active: string;
  open: string;
  juros: string;
};

export type parcelArray = Array<parcelObjSpecial>;

const SpecialParcels = (props: {
  data: parcelArray;
  activeIndex: (number: number) => void;
  control: number;
  disableParcels: (number: number) => void;
  sizeParcels: number;
}) => {
  const globalContext = useContext(CXT.Context);
  return (
    <S.Wrapper>
      <S.Label>
        {globalContext.isLoading ? (
          <>
            <Skeleton width="0px" />
          </>
        ) : (
          <>PLANO ESPECIAL</>
        )}
      </S.Label>
      <S.HorizontalBar />
      {props.data.slice(0, props.control).map((value, index) => (
        <S.Line key={index} className={value.open}>
          <S.ParcelNumber
            type="button"
            className={value.active}
            onClick={() => {
              props.activeIndex(index);
              props.disableParcels(index + props.sizeParcels);
            }}
          >
            {globalContext.isLoading ? (
              ''
            ) : (
              <>{value.quantidadeMaximaParcelas}</>
            )}
          </S.ParcelNumber>
          <S.ValueBox Border={value.active === 'active'}>
            <S.Value className={value.open} Border={value.active === 'active'}>
              {globalContext.isLoading ? (
                <>
                  <Skeleton width="85px" />
                </>
              ) : (
                <>
                  <>
                    {value.vezesAdicionais}x {value.valorAdicionais} +
                  </>
                </>
              )}
            </S.Value>
            <S.ValueParcels>
              {globalContext.isLoading ? (
                <>
                  <Skeleton width="85px" />
                </>
              ) : (
                <>
                  {value.parcelaValor} {value.valorParcelas}
                </>
              )}
            </S.ValueParcels>
          </S.ValueBox>
          <S.VerticalBar Border={value.active === 'active'}>
            <S.VerticalInside />
          </S.VerticalBar>
          <S.Info Border={value.active === 'active'}>
            <br />
            {globalContext.isLoading ? (
              <>
                <Skeleton width="85px" />
              </>
            ) : (
              <>
                <S.TaxaInfo>Juros {value.juros} A.M.</S.TaxaInfo>
                <S.EntradaInfo>
                  Entrada minima R${value.entradaMin} (
                  {value.percentualEntradaMinima})
                </S.EntradaInfo>
              </>
            )}
            <br />
          </S.Info>
        </S.Line>
      ))}
    </S.Wrapper>
  );
};

export default SpecialParcels;
