import React, { SetStateAction, useEffect, useState } from 'react';
import { CardsNotification } from '../Cards';
import * as S from './styles';
import { LoadingNotification } from './LoadingNotification';
import { NotificationCards } from 'components/Header/types/typesUsingInHeader';
import { GetNotification } from '../../../../../services/notification';

type AccessToAllNotificationsType = {
  openAllNotification: boolean;
  setOpenAllNotification: React.Dispatch<React.SetStateAction<boolean>>;
  arrayWithAllNotificationsUnread: NotificationCards[];
  setArrayWithAllNotificationsUnread: React.Dispatch<
    SetStateAction<NotificationCards[]>
  >;
  shopkeepersCpf: string;
  totalNumberOfNotifications: number | '0';
  setError: React.Dispatch<SetStateAction<string>>;
  searchNotification(): Promise<void>;
};

export const AccessToAllNotifications: React.FC<
  AccessToAllNotificationsType
> = ({
  openAllNotification,
  setOpenAllNotification,
  arrayWithAllNotificationsUnread,
  totalNumberOfNotifications,
  shopkeepersCpf,
  setArrayWithAllNotificationsUnread,
  setError,
  searchNotification,
}) => {
  const [selectedUnreadOrArchived, setSelectedUnreadOrArchived] =
    useState('unread');

  const [loading, setLoading] = useState(false);

  const unreadSelected = selectedUnreadOrArchived === 'unread';
  const archivedSelected = selectedUnreadOrArchived === 'archived';
  const [allNotificationArchived, setAllNotificationArhived] = useState<
    NotificationCards[]
  >([]);

  useEffect(() => {
    if (openAllNotification) {
      setLoading(true);
      searchNotification();
    }
    setLoading(false);
  }, [openAllNotification]);

  function changeStateInSelected(selected: string) {
    setSelectedUnreadOrArchived(selected);

    if (selected === 'archived') {
      setLoading(true);
      GetNotification.GetAllArchived(shopkeepersCpf)
        .then((response) => {
          const data = response.data.data;
          setAllNotificationArhived(data);
          setLoading(false);
        })
        .catch(() => {
          setError(
            'Opss... Não conseguimos carregar suas notificações arquivadas.'
          );
          setLoading(false);
        });
    }

    if (selected === 'unread') {
      searchNotification();
    }
  }

  async function moveCardToArchived(id: string) {
    GetNotification.SendToArchived(id);

    const updateArray = arrayWithAllNotificationsUnread.filter(
      (notification) => notification.id !== Number(id)
    );
    setTimeout(() => {
      setArrayWithAllNotificationsUnread(updateArray || []);
    }, 700);
  }

  return (
    <S.WrappingComponent>
      {openAllNotification && (
        <>
          <S.BackgroundToCloseNotifications
            onClick={() => setOpenAllNotification(false)}
          />
          <S.Wrapper>
            <S.HeaderCard>
              <div className="centralizedElements">
                <h5>
                  Notificações{' '}
                  {arrayWithAllNotificationsUnread.length > 0 &&
                    `(${totalNumberOfNotifications})`}
                </h5>
                <div>
                  <div className="shape"></div>
                </div>
              </div>
            </S.HeaderCard>
            <S.BodyCard>
              <div className="archivedOrUnread">
                <S.UnreadOrArchived
                  selected={unreadSelected}
                  onClick={() => changeStateInSelected('unread')}
                >
                  NÃO LIDAS{' '}
                  {arrayWithAllNotificationsUnread.length > 0 &&
                    `(${totalNumberOfNotifications})`}
                </S.UnreadOrArchived>
                <S.UnreadOrArchived
                  selected={archivedSelected}
                  onClick={() => changeStateInSelected('archived')}
                >
                  ARQUIVADAS
                </S.UnreadOrArchived>
              </div>
              <div className="wrapperCardsNotification">
                {unreadSelected ? (
                  !loading && arrayWithAllNotificationsUnread.length > 0 ? (
                    arrayWithAllNotificationsUnread?.map((card, index) => (
                      <CardsNotification
                        key={card.id}
                        style={{ marginTop: index === 0 ? '1px' : '8px' }}
                        moveCardToArchived={moveCardToArchived}
                        id={`${arrayWithAllNotificationsUnread[index].id}`}
                        body={arrayWithAllNotificationsUnread[index]}
                      />
                    ))
                  ) : (
                    <div className="textNotification">
                      <span>Não há notificações</span>
                    </div>
                  )
                ) : archivedSelected &&
                  !loading &&
                  allNotificationArchived.length > 0 ? (
                  allNotificationArchived.map((card, index) => (
                    <CardsNotification
                      key={card.id}
                      style={{ marginTop: index === 0 ? '1px' : '8px' }}
                      moveCardToArchived={moveCardToArchived}
                      id={`${allNotificationArchived[index].id}`}
                      archived={true}
                      body={allNotificationArchived[index]}
                    />
                  ))
                ) : (
                  <div className="textNotification">
                    <span>Não há notificações</span>
                  </div>
                )}
                {loading && <LoadingNotification />}
              </div>
            </S.BodyCard>
          </S.Wrapper>
        </>
      )}
    </S.WrappingComponent>
  );
};
