import styled, { css } from 'styled-components';

export const InfoOne = styled.div`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;
    margin-top: 24px;
    font-size: 15px;
    font-weight: bold;
    color: ${theme.colors.graySecond};
  `}
`;

export const InfoTwo = styled.div`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin-top: 8px;
    color: ${theme.colors.graySecond};
  `}
`;

export const StatusWrapper = styled.div`
  width: 100%;
  padding: 56px 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    border-radius: 50%;
    width: 56px;
    height: 56px;

    color: white;
    background-color: ${theme.colors.orange};

    font-size: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `}
`;

export const StatusLabelWrapper = styled.div`
  ${({ theme }) => css`
    height: 56px;
    padding-left: 16px;
    font-size: 22px;

    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${theme.colors.orange};
  `}
`;

export const TabNameWrapper = styled.a`
  ${({ theme }) => css`
    line-height: 1.5;

    color: ${theme.colors.blueSecondary};
  `}
`;

export const ButtonWrapper = styled.div``;
