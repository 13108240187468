import styled, { css } from 'styled-components';

export const WrappingComponent = styled.div`
  width: 10px;
  position: relative;
`;

export const Wrapper = styled.div`
  width: 350px;
  height: 500px;
  position: absolute;
  top: 25px;
  left: -277px;
  z-index: 10;
  box-shadow: 0px 0px 1px 1px rgba(20, 19, 20, 0.26);

  @media (max-width: 420px) {
    margin: 17rem;
    margin-top: -15px;
    width: 3400%;
  }
`;

export const BackgroundToCloseNotifications = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
`;

export const HeaderCard = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 35px;
    background-color: ${theme.colors.blueSecondary};

    .centralizedElements {
      margin: 0 auto;
      width: 95%;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
    }

    h5 {
      color: ${theme.colors.white};
      font-size: ${theme.font.sizes.x_small};
      font-family: 'Roboto';
      font-weight: 400;
    }

    .shape {
      @media (max-width: 420px) {
        right: 66%;
      }

      background-color: ${theme.colors.blueSecondary};
      position: absolute;
      width: 16px;
      height: 16px;
      transform: rotateZ(45deg);
      top: -20%;
      right: 15%;
    }
  `}
`;

export const BodyCard = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 465px;
    background-color: ${theme.colors.grayEight};

    .archivedOrUnread {
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }

    .textNotification {
      margin-left: 25px;
      color: #908f8f;
      font-weight: bold;
    }

    .wrapperCardsNotification {
      width: 100%;
      height: 425px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${theme.colors.graySeventh};
        border-radius: 20px;
        border: 3px solid ${theme.colors.graySeventh};
      }
    }
  `}
`;

type UnreadOrArchivedType = {
  selected: boolean;
};

export const UnreadOrArchived = styled.h5<UnreadOrArchivedType>`
  ${({ theme, selected }) => css`
    color: ${selected ? theme.colors.blueSecondary : theme.colors.grayThird};
    font-weight: 400;
    font-family: 'Roboto';
    font-size: ${theme.font.sizes.x_small};
    cursor: pointer;
    padding: 5px 10px;
  `}
`;
