import {
  format as dateFormat,
  formatISO,
  fromUnixTime,
  isSameDay,
} from "date-fns";

export const parseTimestamp = (unixTimeMili) => {
  if (!unixTimeMili) return null;
  return fromUnixTime(unixTimeMili / 1000);
};

export const formatDate = (value, format = "dd/MM/yyyy") => {
  if (!value) return null;
  return dateFormat(value, format);
};

export const formatRequestDatetime = (date) => {
  if (!date) return null;

  return date.toISOString();
};

export const formatTime = (value, format = "HH:mm") => {
  if (!value) return null;
  return dateFormat(value, format);
};

export const isToday = (value) => {
  return isSameDay(value, new Date());
};
