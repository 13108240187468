import styled, { css } from 'styled-components';
import { Form } from 'formik';
import media from 'styled-media-query';
import Background from 'assets/images/background.jpg';
import Background2 from 'assets/images/background2.jpg';
import Background3 from 'assets/images/background3.jpg';

export const Wrapper = styled.main`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row-reverse;
    max-height: 100vh;
    max-width: 100vw;

    .notificationActive {
      width: 250px;
      height: 40px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 1%;
      left: 1%;
      border-radius: 3px;

      button {
        width: 90%;
        height: 90%;
        color: ${theme.colors.white};
        background-color: ${theme.colors.blueSecondary};
        font-weight: 400;
        font-family: 'Roboto';
        border-radius: 3px;
        outline: none;
        border: none;
        animation-name: moveRight;
        animation-duration: 1s;
        animation-delay: 0ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        transform: translateX(-140%);

        &:hover {
          transform: scale3d(1.05, 1.05, 1);
        }

        @keyframes moveRight {
          100% {
            transform: translateX(0%);
          }
        }
      }
    }

    ${media.greaterThan('large')`
        background-image: ${`url(${Background})`};
        background-size: 'cover';
        background-repeat: 'no-repeat';
        background-position : 'center' ;
      `}

    ${media.greaterThan('medium')`
        background-image: ${`url(${Background2})`};
        background-size: 'cover';
        background-repeat: 'no-repeat';
        background-position : 'center' ;
      `}

      ${media.lessThan('medium')`
        background-image: ${`url(${Background3})`};
        background-size: 'cover';
        background-repeat: 'no-repeat';
        background-position : 'center' ;
      `}
  `}
`;

export const GridRight = styled.div`
  height: 100vh;
  width: 50%;
  background-color: rgb(0, 0, 0, 0);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${media.lessThan('medium')`
      height: 100vh;
      width: 100%;
    `}
`;

export const GridRightData = styled.div`
  ${({ theme }) => css`
    height: 100%;
    /* min-height: 450px; */
    height: 520px;
    width: 410px;
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    border-radius: 7px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `}
`;
export const GridRightButtonBox = styled.div`
  ${({ theme }) => css`
    height: 15%;
    background-color: ${theme.colors.white};

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `}
`;

export const Image = styled.img`
  width: 265px;
  margin-top: 50px;

  ${media.lessThan('small')`
  width: 90vw;
    `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: 'Roboto', sans-serif;
    color: ${theme.colors.blackTertiary};
    font-weight: 200;
    font-size: ${theme.font.sizes.xxxlarge};
    margin-bottom: 1rem;

    &:after {
      content: '';
      display: block;
      width: 26.5rem;
      height: 0.3rem;
      margin-top: 1rem;
      border-bottom: 0.3rem solid ${theme.colors.blueSecondary};
    }

    span {
      display: block;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      color: ${theme.colors.blackTertiary};
      font-size: ${theme.font.sizes.xlarge};
      margin-bottom: 0.2rem;
    }
  `}
`;

export const FormLogin = styled(Form)``;

export const InputBox = styled.div`
  width: 260px;
  main {
    margin-top: 3rem;

    label {
      padding-left: 8px;
    }

    input {
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
      padding-left: 8px;
      padding-bottom: 3px;
    }
  }
`;

export const Text = styled.a`
  ${({ theme }) => css`
    /* position: absolute; */
    bottom: 4rem;
    color: ${theme.colors.blueSecondary};
    font-size: ${theme.font.sizes.xxx_small};
    margin: 5px 0px;

    ${media.lessThan('medium')`
    /* position: relative; */
    top: 3rem;
      height: auto;
      grid-template-columns: 1fr;
    `}
  `}
`;
