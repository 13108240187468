import * as Styled from './styles';

import { cpfMask } from 'utils/mask';

import blockIcon from 'assets/icons/Block.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

type PopUpDangerProps = {
  setPopUpDangerActive: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PopUpDanger = ({ setPopUpDangerActive }: PopUpDangerProps) => {
  const client = JSON.parse(localStorage.getItem('client') || '{}');

  return (
    <Styled.Wrapper>
      <Styled.BlurWrapper
        onClick={() => {
          setPopUpDangerActive(false);
        }}
      />
      <Styled.PopUpContainer>
        <CloseIcon
          onClick={() => {
            setPopUpDangerActive(false);
          }}
        />
        <img src={blockIcon} />
        <h1>NÃO PERMITIDO</h1>
        <span>Não é permitido salvar o orçamento para o cliente informado</span>
        <strong>{client.nome}</strong>
        <strong>(CPF {cpfMask(client.cpf)})</strong>
        <button
          onClick={() => {
            setPopUpDangerActive(false);
          }}
        >
          OK, ENTENDI
        </button>
      </Styled.PopUpContainer>
    </Styled.Wrapper>
  );
};
