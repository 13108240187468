import styled, { css } from 'styled-components';

export const RegularText = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    color: ${theme.colors.graySecond};
  `}
`;

export const InfoTwo = styled(RegularText)`
  width: 100%;
  text-align: center;
`;

type WrapperProps = {
  show?: boolean;
};
export const Wrapper = styled.div<WrapperProps>`
  ${({ show }) => (show ? '' : 'display: none;')}
`;

export const ContractViewContainer = styled.div`
  width: 100%;
  padding: 16px;

  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ContractViewWrapper = styled.div`
  ${({ theme }) => css`
    width: 400px;
    height: 330px;
    border: 1px solid ${theme.colors.grayFourth};
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  `}
`;

export const ActionsWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-around;

  & button {
    width: 40%;
    margin-top: 0;
  }
`;

export const FileWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;

  &:hover {
    .shadow {
      opacity: 0.3;
      transition: opacity 0.2s ease-out;
    }
    .message {
      opacity: 1;
      transition: opacity 0.2s ease-out;
    }
  }
`;

export const HoverShadow = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.3s ease-out;
`;

export const HoverMessageWrapper = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 110;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  opacity: 0;
  transition: opacity 0.3s ease-out;
`;
