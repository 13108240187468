import { toast } from 'react-toastify';

export const errorInSubmit = (error: any, plateOrChassi?: string) => {
  if (
    (error.response?.status === 404 && plateOrChassi === 'Placa') ||
    plateOrChassi === 'Chassi'
  ) {
    return toast.error(
      `${plateOrChassi} não encontrado(a). Verifique os dados digitados e tente novamente`
    );
  }

  if (error.response?.status === 404) {
    return toast.error(
      'Veiculo não encontrado com essa placa ou chassi. Verifique os dados digitados e tente novamente'
    );
  }

  if (error.response?.status === 500) {
    return toast.error(
      'Ops! Tivemos um problema ao prosseguir com a sua solicitação. ' +
        'Tente novamente mais tarde e se o problema persistir contate o suporte!'
    );
  }

  return toast.error(
    'Não foi possível prosseguir com a solicitação. Verifique os dados digitados e tente novamente'
  );
};
