import styled, { css } from 'styled-components';

import { darken } from 'polished';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 50%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;

    span {
      height: 0px;
      padding: 0px 1.2rem;
      position: absolute;
      top: 2.4rem;

      font-size: ${theme.font.sizes.r_small};
      text-align: left;

      color: ${theme.colors.gray};
    }

    textarea {
      width: 100%;
      margin: 1.8rem 0px;
      padding: 2.4rem 1rem 0px 1rem;

      border-color: ${theme.colors.gray};

      font-family: 'Roboto', sans-serif;
      font-size: ${theme.font.sizes.xx_small};

      border-radius: 0.8rem;

      color: ${darken(0.3, theme.colors.gray)};

      overflow: hidden;
      resize: none;
    }
  `}
`;
