import * as Styled from './styles';
import { useContext, useEffect, useState } from 'react';

import * as CTX from '../../../context/globalContext';
import { WarningType } from '../../../context/globalContext';

import warningSvg from '../../../assets/icons/Warning.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

type PopUpWarningProps = {
  title: string;
  label: string;
  isVehicleName: boolean;
  outOfOrderVehicle: boolean;
  returnMessageVehicle: string;
  setWarningData: React.Dispatch<React.SetStateAction<WarningType>>;
};

export const PopUpWarning = ({
  title,
  label,
  isVehicleName,
  outOfOrderVehicle,
  returnMessageVehicle,
  setWarningData,
}: PopUpWarningProps) => {
  const vehiclePlate = JSON.parse(localStorage.getItem('vehiclePlate') || '{}');
  const globalContext = useContext(CTX.Context);

  const [stateTextContentOrNotHTML, setStateTextContentOrNotHTML] =
    useState('');

  useEffect(() => {
    if (label.includes('<br>')) {
      setStateTextContentOrNotHTML('');
    } else {
      setStateTextContentOrNotHTML(label);
    }
  }, [label]);

  return (
    <Styled.Wrapper>
      <Styled.BlurWrapper
        onClick={() => {
          globalContext.setWarningData((oldState) => ({
            ...oldState,
            isActive: false,
          }));
        }}
      />
      <Styled.PopUpContainer>
        <CloseIcon
          onClick={() => {
            globalContext.setWarningData((oldState) => ({
              ...oldState,
              isActive: false,
            }));
          }}
        />
        <img src={warningSvg} alt="" />
        <h1>{title}</h1>
        <span>{stateTextContentOrNotHTML !== '' ?? ''}</span>
        <span dangerouslySetInnerHTML={{ __html: label }}></span>
        {isVehicleName && <strong>{vehiclePlate.nomeEspecie}</strong>}
        {outOfOrderVehicle && <strong>{returnMessageVehicle ?? ''}</strong>}
        <button
          onClick={() => {
            setWarningData((oldState) => ({
              ...oldState,
              isActive: false,
            }));
          }}
        >
          OK, ENTENDI
        </button>
      </Styled.PopUpContainer>
    </Styled.Wrapper>
  );
};
