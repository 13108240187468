import { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { IoBarcodeOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';

import { Context } from 'context/globalContext';

import ProgressBar from '../ProgressBar';

import ImgLabel from 'components/ImgLabel';

import { Deals } from 'services/propostas';

import { cpfMask } from 'utils/mask';
import history from 'utils/history';

import DefaultCar from 'assets/images/default-deal-car.png';
import BuildingHouse from 'assets/icons/predios.svg';
import Client from 'assets/icons/Cliente.svg';

import * as Styled from './styles';
import Timeline from '../Timeline';

type DealType = {
  data?: any;
};

export const Deal = ({ data }: DealType) => {
  const DealData = data;
  const UserData = JSON.parse(localStorage.getItem('userData') || '{}');

  const { setIsLoading } = useContext(Context);

  const testDocumentPage = (num: string) => {
    localStorage.setItem('numPedido', num);

    history.push('/documentos');
  };

  const handleGetTicket = () => {
    setIsLoading(true);

    Deals.GetTicketBarCode(DealData.propostaCodigo)
      .then((response) => {
        const link = document.createElement('a');
        link.href = response.data.url;

        link.setAttribute('download', 'boleto.pdf');

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response.status === 404) {
          return toast.error('boleto não encontrado');
        }

        return toast.error(
          'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Tente novamente mais tarde e se o problema persistir contate o suporte!'
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  let externoOuAvista = false;
  if (DealData) {
    externoOuAvista =
      DealData.decisaoCredito === 'FINANCIAMENTO EXTERNO' ||
      DealData.decisaoCredito === 'À VISTA';
  }

  let decisaoCredito = '';
  if (DealData) {
    if (DealData.decisaoCredito == 'FINANCIAMENTO EXTERNO') {
      decisaoCredito = 'FIN. EXTERNO';
    } else if (DealData.decisaoCredito == 'À VISTA') {
      decisaoCredito = 'À VISTA';
    } else {
      decisaoCredito = DealData.decisaoCredito || '';
    }
  }

  let acaoAbrirDocsHabilitada = false;
  if (DealData) {
    const etapa = DealData.etapaDescricao;
    const canceladoDescricao = 'Cancelado'.toLowerCase();
    const ID_SIN_FINANCEIRA = 750;
    acaoAbrirDocsHabilitada =
      DealData.financeiraId == ID_SIN_FINANCEIRA &&
      etapa.toLowerCase() != canceladoDescricao;
  }

  let placaOuChassi = <span></span>;
  let veiculoPlacaOuChassi = '';
  if (DealData) {
    veiculoPlacaOuChassi = DealData.veiculoPlaca;
    const placaVazia =
      DealData.veiculoPlaca === undefined ||
      DealData.veiculoPlaca === null ||
      DealData.veiculoPlaca == '';
    const chassiNovo = DealData.veiculoChassi.includes('Sob Encomenda');
    let textPlacaOuChassi = '';
    if (chassiNovo) {
      textPlacaOuChassi = 'Chassi';
      veiculoPlacaOuChassi = `${DealData.veiculoChassi}`;
    } else if (placaVazia) {
      textPlacaOuChassi = 'Chassi';
      veiculoPlacaOuChassi = `...${DealData.veiculoChassi.slice(-4)}`;
    } else {
      textPlacaOuChassi = 'Placa';
      veiculoPlacaOuChassi = DealData.veiculoPlaca;
    }

    placaOuChassi = (
      <span>
        {textPlacaOuChassi}: <strong>{veiculoPlacaOuChassi}</strong>
      </span>
    );
  }

  const cpfSize = 11;

  function addLeadingZeros(cpf: number, size: number) {
    const cpfString = `${cpf}`;
    const cpfFormatado = cpfString.padStart(size, '0');
    return cpfMask(cpfFormatado);
  }

  return DealData ? (
    <Styled.Wrapper>
      <Styled.LeftContainer>
        {externoOuAvista ? (
          <Styled.BorderCarContainer>
            <Styled.StatusLabel>
              {decisaoCredito.toUpperCase()}
            </Styled.StatusLabel>
            <img src={DefaultCar} />
          </Styled.BorderCarContainer>
        ) : (
          <Styled.ImageCarContainer
            status={
              DealData.etapaDescricao === 'CANCELADO'
                ? 'red'
                : DealData?.decisaoCreditoCor
            }
          >
            {DealData.etapaDescricao === 'CANCELADO' ? (
              <Styled.StatusLabel>CANCELADO</Styled.StatusLabel>
            ) : (
              <Styled.StatusLabel>
                {decisaoCredito.toUpperCase()}
              </Styled.StatusLabel>
            )}
            <img src={DefaultCar} />
          </Styled.ImageCarContainer>
        )}
        <Styled.InfoContainer>
          <Styled.InfoRow>
            <Styled.IconData src={BuildingHouse} />
            <Styled.InfoData>
              <h1>{DealData.nomeEmpresa}</h1>
              <span>{DealData.cidadeEmpresa}</span>
            </Styled.InfoData>
          </Styled.InfoRow>
          <Styled.InfoRow>
            <Styled.IconData src={Client} />
            <Styled.InfoData>
              <h1>Agente</h1>
              <span>
                {DealData.agenteNome ? DealData.agenteNome.toLowerCase() : 'ㅤ'}
              </span>
            </Styled.InfoData>
          </Styled.InfoRow>
          <Styled.InfoRow>
            <Styled.IconData src={Client} />
            <Styled.InfoData>
              <h1>Vendedor</h1>
              <span>{DealData.vendedorNome.toLowerCase()}</span>
            </Styled.InfoData>
          </Styled.InfoRow>
        </Styled.InfoContainer>
      </Styled.LeftContainer>
      <Styled.CenterContainer>
        <Styled.HeaderContainer>
          <div className="wrapperMarcaAndModel">
            <p className="vehicleBrand">{DealData.veiculoMarca}</p>
            <p className="vehicleModel">
              {`${DealData.veiculoLinha} ${DealData.veiculoModelo}`}
            </p>
            <div>
              {placaOuChassi}
              {DealData.veiculoAnoFabricacao == null ? (
                <span>
                  Modelo: <strong>{DealData.veiculoAnoModelo}</strong>
                </span>
              ) : (
                <span>
                  Ano/Modelo:
                  <strong>{`${DealData.veiculoAnoFabricacao}/${DealData.veiculoAnoModelo}`}</strong>
                </span>
              )}
            </div>
          </div>
          <div className="wrapperTextinformation">
            <p>
              {DealData?.dsProdutoFinanceira
                ?.replace(/\#{1,}/, '')
                .toLowerCase()}
            </p>
          </div>
        </Styled.HeaderContainer>
        <Styled.DescriptionContainer>
          <ImgLabel id="ballon" type="ballon">
            Parecer de crédito:
          </ImgLabel>
          <p>{DealData.descricaoParecer}</p>
        </Styled.DescriptionContainer>
        <Styled.BottomContainer>
          {/* <ProgressBar
            currentProgress={[2, 2, 0, 0, 0]}
            progressoProposta={DealData.propostaCodigo}
            progressoCredito={DealData.decisaoCredito}
            progressoDocs={DealData.documentoStatus}
            progressoContrato={DealData.situacaoDescricao}
            progressDescription={[
              DealData.propostaCodigo,
              DealData.decisaoCredito,
              DealData.documentoStatus,
              undefined,
              undefined,
            ]}
          /> */}
          <Timeline etapas={DealData.propostaLinhaTempo} />
          {DealData.situacaoDescricao === 'CONTRATO PAGO' && (
            <Styled.BarCodeContainer onClick={handleGetTicket}>
              <div>
                <IoBarcodeOutline size={24} />
              </div>
              <div>2º VIA BOLETO</div>
            </Styled.BarCodeContainer>
          )}
        </Styled.BottomContainer>
      </Styled.CenterContainer>
      <Styled.RightContainer>
        <header>Informações do Cliente</header>
        <div className="bodyData">
          <ImgLabel className="merge" id="person" type="person">
            Nome e CPF
          </ImgLabel>
          <p>{DealData.clienteNome.toLowerCase()}</p>
          {DealData.clienteCpfCnpj == null ? (
            <p>CPF: </p>
          ) : (
            <p>CPF: {addLeadingZeros(DealData.clienteCpfCnpj, cpfSize)}</p>
          )}
          <div className="flexData">
            <div>
              <ImgLabel id="deal_number" type="paper">
                Pedido
              </ImgLabel>
              <span>#{DealData.pedidoId}</span>
            </div>
            <div>
              <ImgLabel id="inclusion_date" type="data">
                Data inclusão
              </ImgLabel>
              <span>{DealData.dataInclusaoPedido}</span>
            </div>
          </div>
          <button
            disabled={
              UserData?.permissao?.findIndex(
                (val: string) => val === 'DOCUMENTOS_CHECKLIST_WEB'
              ) < 0 || !acaoAbrirDocsHabilitada
            }
            onClick={() => testDocumentPage(DealData.pedidoId)}
          >
            VERIFICAR DOCUMENTOS ANEXADOS
          </button>
        </div>
      </Styled.RightContainer>
    </Styled.Wrapper>
  ) : (
    <Styled.Wrapper>
      <div className="SkeletonBody">
        <Skeleton width={'100%'} height={'2rem'} />
        <Skeleton width={'100%'} height={'2rem'} />
        <Skeleton width={'100%'} height={'2rem'} />
        <Skeleton width={'100%'} height={'2rem'} />
      </div>
    </Styled.Wrapper>
  );
};
