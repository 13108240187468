import apiAppdsv from '../api/api-appdsv';

const RESOURCE = '/pedido';

export type pedidoForm = {
  cancelarPedidoOriginal: boolean;
  pessoaClienteId: number;
  criarReserva: boolean;
  opcaoSimulacaoFinanciamentoRequest: {
    agrupadorPrazo: string;
    condicaoRPadrao: string;
    dataFinalVigencia: string;
    entradaMinima: number;
    financeiraId: number;
    indEspecial: boolean;
    indExclusivo: boolean;
    indFrotista: boolean;
    indParcelaSemestral: boolean;
    nomeFinanceira: string;
    observacao: string;
    parcelaAdicionalFinal: number;
    percParcelaMaisEntrada: number;
    percentualEntradaMinima: number;
    percentualJuros: number;
    percentualRetorno: number;
    quantidadeParcelas: number;
    quantidadeParcelasMaisPrazo: number;
    quantidadeParcelasMenosPrazo: number;
    seguroFinan: boolean;
    seqTabelaFinanceira: number;
    tarifaUsado: number;
    taxaCadastro: number;
    tipoFinanciamentoId: number;
    tipoPessoa: string;
    tipoVeiculoId: number;
    valorEntrada: number;
    valorEntradaAVista: number;
    valorGravame: number;
    valorMultiplicador: number;
    valorMultiplicadorSpf: number;
    valorParcelaMaisEntrada: number;
    valorParcelaMaisPrazo: number;
    valorParcelaMenosPrazo: number;
    valorParcelaRevPlanejada: number;
    valorParcelaSpm: number;
    valorParcelas: number;
    valorRetornoTac: number;
    valorSeguroFinan: number;
    valorTotal: number;
  };
  observacao: string;
  origemPedido: string;
  valorDespachante: number;
  valorEntradaAVista: number;
  valorSpm: number;
  valorTotalVeiculo: number;
  veiculoEstoqueId: string;
};

export type pedidoIdForm = {
  pedidoId: string;
  versaoValidacao: string;
  codPropostaAtualizar: string | null;
};

export const Pedido = {
  PostPedido: async (form: pedidoForm) => {
    const urlencoded = <pedidoForm>{
      ...form,
    };
    return await apiAppdsv.post(RESOURCE, urlencoded);
  },
  PostIDPedido: async (id: string) => {
    return await apiAppdsv.post(`${RESOURCE}/nac/${id}`, null);
  },
  PostIDPedidoV2: async (idForm: pedidoIdForm) => {
    return await apiAppdsv.post(`${RESOURCE}/nac`, idForm);
  },
};
