import Button from 'components/Button';
import { ROLLBACK } from 'pages/Documents/Contract/acoesProposta';
import { Proposta } from 'pages/Documents/types';
import { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Deals } from 'services/propostas';
import {
  ButtonWrapper,
  IconWrapper,
  InfoTwo,
  StatusLabelWrapper,
  StatusWrapper,
} from './styles';

type PrazoExpiradoProps = {
  proposta: Proposta;
  readOnly: boolean;
  reloadEtapas: () => void;
};

const PrazoExpirado = ({
  proposta,
  readOnly,
  reloadEtapas,
}: PrazoExpiradoProps) => {
  const [rollbackLoading, setRollbackLoading] = useState<boolean>();

  const rollbackToVencimento = () => {
    setRollbackLoading(true);
    Deals.RealizarAcao(proposta.pedidoId, ROLLBACK)
      .then(() => {
        reloadEtapas();
      })
      .catch((err) => {
        console.error(err);
        toast.error('Erro ao voltar o status do vencimento');
      })
      .finally(() => setRollbackLoading(false));
  };

  return (
    <>
      <StatusWrapper>
        <IconWrapper>
          <FaIcons.FaExclamationTriangle />
        </IconWrapper>
        <StatusLabelWrapper>O prazo expirou!</StatusLabelWrapper>
      </StatusWrapper>
      <InfoTwo>
        Será necessário escolher novamente a data de vencimento das prestações
      </InfoTwo>

      <ButtonWrapper>
        <Button
          onClick={rollbackToVencimento}
          marginTop="0px"
          disabled={readOnly}
          loading={rollbackLoading}
        >
          Voltar para vencimento
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default PrazoExpirado;
