import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const TitleWrapperComponent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 961.5px;
    margin: 0 auto;
    height: 45px;
    margin-top: 8rem;

    .wrapper {
      width: 100%;
      padding: 0 8px;
      display: flex;
      align-items: flex-end;

      .wrapperSkeleton {
        display: flex;
        align-items: end;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 961.5px;
      height: 0.2rem;
      border: 2px solid ${theme.colors.blueSecondary};
      margin: 1.35rem auto;
      background: ${theme.colors.blueSecondary};

      ${media.lessThan('large')`
        width: 99%;
        max-width: 960px;
      `}
    }
  `}
`;

export const Image = styled.img`
  width: 24px;
  height: 31.6px;
  @media only screen and (max-width: 450px) {
    width: 19px;
    height: 26.6px;
  }
`;

export const StepInfo = styled.h1`
  ${({ theme }) => css`
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    margin-left: 1.2rem;
    margin-right: 0.4rem;
    color: ${theme.colors.blackTertiary};

    @media only screen and (max-width: 450px) {
      font-size: 15px;
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: ${theme.colors.blackTertiary};
    @media only screen and (max-width: 450px) {
      font-size: 15px;
    }
  `}
`;
