import { useEffect, useState } from 'react';

import Button from 'components/Button';
import CloseButton from 'components/CloseButton';
import { TutorialDataType } from 'components/PopUpTutorial';

import { Car } from 'services/car';

import * as S from './styles';

type PopUpProps = {
  data: TutorialDataType;
  dataLenght: number;

  isActive?: boolean;
  setIsActive: (item: boolean) => void;

  pageNum: number;
  next: () => void;
  previous: () => void;
};

const PageNth = ({
  data,
  dataLenght,
  setIsActive,
  pageNum,
  next,
  previous,
}: PopUpProps) => {
  const UserData = JSON.parse(localStorage.getItem('user') || '{}');

  const [page, setPage] = useState(pageNum);

  useEffect(() => {
    if (pageNum >= page) {
      Car.OnboardingUpdate(data.id, UserData.cpf);
      setPage(pageNum);
    }
  }, [pageNum]);

  return (
    <S.PopUpBody>
      <S.PopUpTitleBox>
        <div />
        <CloseButton type="button" onClick={() => setIsActive(false)} />
      </S.PopUpTitleBox>
      <S.Label className="typeI">{data.titulo}</S.Label>
      <S.Label className="typeII">{data.subtitulo}</S.Label>

      <S.ItemList>
        <S.Label className="typeIII">{data.descricao}</S.Label>
      </S.ItemList>
      <S.HorizontalBar />
      <S.PopUpButtonBox>
        <S.Label className="typeIV">
          Novidades {pageNum} de {dataLenght}{' '}
        </S.Label>
        {pageNum !== 1 && (
          <Button
            className="round"
            onClick={previous}
            type="button"
            height={'30px'}
            marginTop={'0px'}
          >
            {'<'}
          </Button>
        )}
        <div />
        <Button
          className="round"
          onClick={next}
          type="button"
          height={'30px'}
          marginTop={'0px'}
        >
          {'>'}
        </Button>
      </S.PopUpButtonBox>
    </S.PopUpBody>
  );
};

export default PageNth;
