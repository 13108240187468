import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { ReactSVG } from 'react-svg';

type ImageCarContainerType = {
  status?: string;
};

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 110%;
    padding: 0.8rem 1.6rem;

    display: flex;
    justify-content: space-between;

    border-radius: 0.8rem;

    background-color: ${theme.colors.white};

    & + & {
      margin-top: 1.6rem;
    }

    .SkeletonBody {
      width: 100%;

      > span {
        width: 100%;
      }
    }
  `}
`;

export const LeftContainer = styled.div`
  ${({ theme }) => css`
    width: 21%;

    background-color: ${theme.colors.white};
  `}
`;

export const BorderCarContainer = styled.div<ImageCarContainerType>`
  width: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid gray;
  border-radius: 0.8rem;

  > span {
    background-color: white;
    border: 1px solid gray;
    border-radius: 1.8rem;
    color: gray;
  }

  img {
    width: 15rem;
    height: 11.5rem;
  }
`;

export const ImageCarContainer = styled.div<ImageCarContainerType>`
  ${({ status }) => css`
    width: 100%;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid ${status};
    border-radius: 0.8rem;

    > span {
      background-color: ${status};
    }

    img {
      width: 15rem;
      height: 11.5rem;
    }
  `}
`;

export const StatusLabel = styled.span`
  ${({ theme }) => css`
    padding: 0.6rem 2rem;
    position: absolute;
    top: 0.8rem;

    font-family: 'Roboto', sans-serif !important;
    font-weight: 500 !important;
    font-size: ${theme.font.sizes.xx_small} !important;

    border-radius: 4rem;

    color: ${theme.colors.white};
  `}
`;

export const InfoContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 0.8rem;
    padding: 0.4rem 1.6rem;

    border: 1px solid ${theme.colors.gray};
    border-radius: 0.8rem;
  `}
`;

export const InfoRow = styled.div`
  width: 100%;
  display: flex;

  & + & {
    margin-top: 0.6rem;
  }
`;

export const InfoData = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-left: 1.2rem;

    display: flex;
    flex-direction: column;

    border-bottom: 1px solid ${theme.colors.gray};

    h1 {
      font-family: 'Roboto', sans-serif;
      font-size: 1.2rem;
      font-weight: 500;

      color: ${theme.colors.blueThird};
    }

    span {
      font-family: 'Roboto', sans-serif;
      font-size: 1rem;
      font-weight: 500;
      text-transform: capitalize;

      color: ${theme.colors.blueThird};
    }
  `}
`;

export const IconData = styled(ReactSVG)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 1.8rem;
    }

    path:nth-child(2),
    path:nth-child(4) {
      fill: ${theme.colors.bluePrimary};
    }
  `}
`;

export const CenterContainer = styled.div`
  width: 50%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HeaderContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 0.8rem;
    display: flex;

    .wrapperMarcaAndModel {
      width: 85%;
    }

    .vehicleBrand {
      font-family: 'Roboto', sans-serif;
      font-size: 1.4rem;
      font-weight: 500;

      color: ${theme.colors.grayThird};
    }

    .vehicleModel {
      font-family: 'Roboto', sans-serif;
      max-width: 350px;
      font-size: 1.4rem;
      font-weight: 700;

      color: ${theme.colors.blackTertiary};
    }

    span {
      font-family: 'Roboto', sans-serif;
      font-size: 1.2rem;
      font-weight: 400;

      color: ${theme.colors.grayThird};
    }

    span + span {
      margin-left: 1.2rem;
    }

    strong {
      font-size: 1.4rem;
    }
    .wrapperTextinformation {
      flex: 1;

      p {
        font-family: 'Roboto';
        color: ${theme.colors.purple};
        font-weight: 600;
        font-size: 1.2rem;
        width: 80px;
        margin-right: 10px;
        text-transform: capitalize;
      }
    }
  `}
`;

export const DescriptionContainer = styled.div`
  ${({ theme }) => css`
    padding: 0.8rem 0;

    border-top: 1px solid ${theme.colors.blueThird};
    border-bottom: 1px solid ${theme.colors.blueThird};

    p {
      margin-top: 0.8rem;

      font-family: 'Roboto', sans-serif;
      font-size: 1.2rem;
      font-weight: 400;

      color: ${theme.colors.grayThird};
    }
  `}
`;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BarCodeContainer = styled.button`
  ${({ theme }) => css`
    margin-top: 0.8rem;
    width: 7rem;
    height: 6.5rem;

    border: 1px solid ${theme.colors.blueSecondary};
    border-radius: 0.8rem;

    background-color: ${theme.colors.white};

    transition: filter 0.2s ease;

    svg {
      color: ${theme.colors.blueSecondary};
    }

    div:nth-child(1) {
      height: 55%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    div:nth-child(2) {
      width: 100%;
      height: 45%;

      font-family: 'Roboto', sans-serif;
      font-size: 1rem;
      font-weight: 500;
      text-align: center;

      border: 1px solid ${theme.colors.blueSecondary};
      border-radius: 0 0 0.8rem 0.8rem;

      background-color: ${theme.colors.blueSecondary};
      color: ${theme.colors.white};
    }

    &:hover {
      filter: brightness(0.97);
    }
  `}
`;

export const RightContainer = styled.div`
  ${({ theme }) => css`
    width: 25%;

    border-left: 2px solid ${theme.colors.secondWhite};

    header {
      width: 100%;
      padding: 0.4rem 0 0.8rem 2.4rem;

      font-family: 'Roboto', sans-serif;
      font-size: 1.4rem;
      font-weight: 700;

      border-bottom: 2px solid ${theme.colors.secondWhite};

      color: ${theme.colors.bluePrimary};
    }

    p,
    span {
      font-family: 'Roboto', sans-serif;
      font-size: 1.2rem;
      font-weight: 400;

      text-transform: capitalize;

      color: ${theme.colors.grayThird};
    }

    button {
      width: 100%;
      margin-top: 1.6rem;
      padding: 0.8rem 0;

      font-family: 'Roboto', sans-serif;
      font-size: 1rem;
      font-weight: 500;

      border: 0;
      border-radius: 0.4rem;

      background-color: ${theme.colors.blueSecondary};
      color: ${theme.colors.white};

      transition: all 0.2s ease;

      &:hover {
        background-color: ${darken(0.05, theme.colors.blueSecondary)};
      }

      &:disabled {
        background-color: ${theme.colors.gray};

        cursor: not-allowed;
      }
    }

    .bodyData {
      padding: 1.6rem;
    }

    .flexData {
      margin-top: 1.6rem;

      display: flex;

      div:nth-child(2) {
        margin-left: 1.2rem;
      }
    }
  `}
`;
