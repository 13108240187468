import { InputHTMLAttributes, useEffect, useState, useContext } from 'react';

import { ReactComponent as IconLupa } from 'assets/icons/lupa.svg';
import * as CTX from 'context/globalContext';

import * as Styled from './styles';

type SearchBarType = InputHTMLAttributes<HTMLInputElement>;

export const SearchBar = ({ ...props }: SearchBarType) => {
  const [searchInput, setSearchInput] = useState('');
  const globalContext = useContext(CTX.Context);

  useEffect(() => {
    if (globalContext.InputValueFetchExists) {
      setSearchInput('');
      globalContext.setInputValueFetchExists(false);
    }
  }, [globalContext.InputValueFetchExists]);

  return (
    <Styled.Wrapper>
      <input
        type="text"
        placeholder="Buscar"
        value={searchInput}
        onChange={({ target }) => setSearchInput(target.value)}
        {...props}
      />
      <IconLupa />
    </Styled.Wrapper>
  );
};
