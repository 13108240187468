import styled, { css } from 'styled-components';

export const Wrapper = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 5px;
    border: 0px hidden;
    border-radius: 8px;
    height: 40px;
    color: ${theme.colors.blueSecondary};
    font-size: ${theme.font.sizes.x_small};
    font-weight: 800;

    &.currentPage {
      color: ${theme.colors.graySecond};
    }
  `}
`;
