import Button from 'components/Button';
import { Proposta } from 'pages/Documents/types';
// import { useState } from 'react';
// import { toast } from 'react-toastify';
// import { ENVIAR_CONTRATO } from '../../acoesProposta';
// import { AGUARDANDO, CONCLUIDO } from '../../stageStatus';
import { EtapaDTO } from '../../types';
import {
  ButtonWrapper,
  IconWrapper,
  StatusLabelWrapper,
  StatusWrapper,
  TextWrapper,
  Wrapper,
} from './styles';
import { CONCLUIDO } from '../../stageStatus';
import * as FaIcons from 'react-icons/fa';

interface AssinarProps {
  show: boolean;
  readOnly: boolean;
  etapa: EtapaDTO;
  proposta: Proposta;
  emailCliente: string;
  goToEtapaEnviarNf: () => void;
}

const Assinar = ({
  show,
  readOnly,
  etapa,
  proposta,
  emailCliente,
  goToEtapaEnviarNf,
}: AssinarProps) => {
  // const [loadingAcao, setLoadingAcao] = useState<boolean>(false);

  // const enviarContrato = () => {
  //   setLoadingAcao(true);
  //   Deals.RealizarAcao(
  //     proposta.pedidoId,
  //     ENVIAR_CONTRATO,
  //     null,
  //     proposta.propostaCodigo
  //   )
  //     .then(() => {
  //       goToEtapaAssinar();
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       toast.error('Erro ao enviar o contrato');
  //     })
  //     .finally(() => setLoadingAcao(false));
  // };

  if (!show) return null;
  return (
    <>
      <Wrapper>
        {etapa.status === CONCLUIDO ? (
          <StatusWrapper>
            <IconWrapper color="#13a841">
              <FaIcons.FaCheckDouble />
            </IconWrapper>
            <StatusLabelWrapper>Contrato assinado!</StatusLabelWrapper>
          </StatusWrapper>
        ) : (
          <>
            <TextWrapper>
              O link para assinatura digital foi enviado para {emailCliente}
            </TextWrapper>

            <StatusWrapper>
              <IconWrapper>
                <FaIcons.FaHourglassEnd />
              </IconWrapper>
              <StatusLabelWrapper>
                AGUARDANDO <br /> ASSINATURA
              </StatusLabelWrapper>
            </StatusWrapper>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default Assinar;
