import React from 'react';
import * as Styled from './styles';

export const LoadingModal = () => {
  return (
    <Styled.WrapperLoading>
      <Styled.BackgroundBlur />

      <Styled.LoadingEqualTable>
        <Styled.Spinner />

        <Styled.Text>Carregando...</Styled.Text>
      </Styled.LoadingEqualTable>
    </Styled.WrapperLoading>
  );
};
