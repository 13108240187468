import styled, { css } from 'styled-components';

export const Wrapper = styled.button`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    color: ${theme.colors.blueSecondary};
    background-color: ${theme.colors.white};
    border: 0px hidden;
  `}
`;
