import { createGlobalStyle, css } from 'styled-components';

export default createGlobalStyle`


  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  * {
    margin: 0;
    padding: 0;
    outline:0;
    box-sizing: border-box;
  }


  ${({ theme }) => css`
    html {
      scroll-behavior: smooth;
      font-size: 62.5%;
    }

    body,
    #root {
      font-size: ${theme.font.sizes.medium};
      background: ${theme.colors.white};
      font-family: 'Roboto', sans-serif;
    }

    h1,
    button,
    a {
      font-weight: normal;
      font-family: 'Roboto', sans-serif;
    }

    button {
      cursor: pointer;
      &:disabled {
        cursor: default;
      }
    }

    svg,
    img {
      &.clickable {
        cursor: pointer;
      }
    }

    a {
      text-decoration: none;
    }
  `}
`;
