import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;
`;

export const BlurWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-width: 1280px;
  width: 100%;
  height: 100%;
  z-index: 1100;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
`;

export const PopUpBody = styled.div`
  ${({ theme }) => css`
    max-width: 670px;
    min-height: 435px;
    width: 100%;
    z-index: 1110;
    padding: 15px;
    padding-block: 10px;

    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    background-color: ${theme.colors.white};
  `}
`;

export const PopUpHeaderBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    height: 65px;
    background-color: ${theme.colors.white};
    /* border: 1px solid; */
  `}
`;

export const PopUpTitleBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    place-content: start;

    justify-content: space-between;
    width: 100%;
    height: 50%;
    background-color: ${theme.colors.white};
    /* border: 1px solid; */
  `}
`;

export const PopUpMainBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    column-gap: 20px;

    width: 100%;
    height: max-content;

    justify-items: center;
    align-items: center;

    place-content: end;
    background-color: ${theme.colors.white};

    .merge {
      grid-column: span 3;
    }

    ${media.lessThan('small')`
      grid-template-columns: 1fr;
      height: 58%;
    `}
  `}
`;

export const MainButtonBox = styled.div`
  width: 100%;
  display: flex;
  height: 200px;
  padding: 20px 110px;
  align-items: center;
  justify-content: center;
`;

export const ObsBox = styled.div`
  ${({ theme }) => css`
    min-height: 100px;
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0px 10px;
    border-top: 2px solid ${theme.colors.gray};
    min-width: 10px;
    min-height: 62px;
    margin-bottom: 0px;

    background-color: ${theme.colors.grayFith};

    label {
      color: ${theme.colors.orange};
      font-size: ${theme.font.sizes.x_small};
      text-align: center;
      line-height: 22px;
      max-width: 50%;
    }
  `}
`;

export const PopUpButtonBox = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 60px 1fr;
    justify-content: space-around;
    width: 100%;
    height: 45px;
    padding: 0px 15px;
    background-color: ${theme.colors.white};

    label {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: right;
      color: ${theme.colors.graySecond};
      font-size: ${theme.font.sizes.xx_small};
      a {
        color: ${theme.colors.blueSecondary};
        margin-left: 5px;
      }
    }

    ${media.lessThan('small')`
      grid-template-columns: 1fr;
      height: 30%;
    `}
  `}
`;

export const TitleLabel = styled.label`
  ${({ theme }) => css`
    font-family: 'Roboto';
    color: ${theme.colors.graySecond};
    font-weight: 500;
    font-size: ${theme.font.sizes.medium};
    /* margin-bottom: 1rem; */
    height: 18px;
  `}
`;

export const SubTitleLabel = styled.label`
  ${({ theme }) => css`
    font-family: 'Roboto';
    color: ${theme.colors.blueSecondary};
    font-weight: 500;
    font-size: ${theme.font.sizes.medium};
    /* margin-bottom: 1rem; */
    height: 14px;
  `}
`;

export const ImgPDF = styled.img`
  width: 50px;
  height: 50px;
`;
export const ImgCamera = styled.img`
  width: 50px;
  height: 50px;
`;

export const HorizontalBar = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 0px;
    border-top: 2px solid ${theme.colors.gray};
  `}
`;

export const VerticalBar = styled.div`
  ${({ theme }) => css`
    height: 100%;
    width: 0px;
    border-right: 1px solid ${theme.colors.gray};
  `}
`;
