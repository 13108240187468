import styled, { css } from 'styled-components';
import { Document, Page, DocumentProps } from 'react-pdf';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.black};
  `}
`;

interface StyledDocumentProps extends DocumentProps {
  disableScroll?: boolean;
}
export const StyledDocument = styled(Document)<StyledDocumentProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  place-content: initial;
  ${({ disableScroll = false }) =>
    disableScroll ? 'overflow: hidden;' : 'overflow-y: auto; overflow-x: auto;'}

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #e9e9e9;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;

    background-color: #a0a0a0;
  }
`;

export const StyledPage = styled(Page)``;
