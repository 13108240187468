import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
`;

export const BlurWrapper = styled.div`
  position: fixed;

  width: 100%;
  height: 100%;
  z-index: 1100;

  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
`;
