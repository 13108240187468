import { fetchToken, onMessageListener } from 'firebase';
import React, { useEffect, useState } from 'react';
import { IoMdNotifications } from 'react-icons/io';
import { toast } from 'react-toastify';
import { AccessToAllNotifications } from './AccessToAllNotifications';
import * as S from './style';
import { NotificationFloating } from './NotificationFloating';
import {
  NewNotificationType,
  NotificationCards,
} from 'components/Header/types/typesUsingInHeader';
import {
  GetNotification,
  SendNotification,
} from '../../../../services/notification';

type NotificationType = {
  openAllNotification: boolean;
  setOpenAllNotification: React.Dispatch<React.SetStateAction<boolean>>;
  shopkeepersCpf: string | null;
};

export const Notification: React.FC<NotificationType> = ({
  openAllNotification,
  setOpenAllNotification,
  shopkeepersCpf,
}) => {
  const [arrayWithAllNotificationsUnread, setArrayWithAllNotificationsUnread] =
    useState<NotificationCards[]>([]);

  const [notificationFloating, setNotificationFloating] = useState({
    isActive: false,
    title: '',
    body: '',
  });

  const [error, setError] = useState('');

  const [showEmptyArrayCase, setShowEmptyArrayCase] = useState(false);

  const [isTokenFound, setIsTokenFound] = useState('');

  async function searchNotification() {
    if (shopkeepersCpf !== null) {
      try {
        const { data } = await GetNotification.GetAllUnread(shopkeepersCpf);
        const response = data.data;
        setArrayWithAllNotificationsUnread(response);
      } catch (error) {
        if (error) {
          return setError(
            'Opss... Tivemos um erro ao buscar novas notificações.'
          );
        }
      }
    }
  }

  useEffect(() => {
    fetchToken(setIsTokenFound);

    searchNotification();
  }, []);

  useEffect(() => {
    if (isTokenFound === 'rejected' || isTokenFound === 'error') {
      toast.warning('Alerta: observe que suas notificações estão desativadas.');
      setIsTokenFound('');
    }
  }, []);

  useEffect(() => {
    if (error !== '') {
      toast.error(error);
      setTimeout(() => {
        setError('');
      }, 8000);
    }
  }, [error]);

  async function sendNewNotificationToBackend(
    newNotification: NewNotificationType
  ) {
    SendNotification.SendMessagingToBackend(newNotification);

    searchNotification();
  }

  if (
    isTokenFound !== '' &&
    isTokenFound !== 'error' &&
    isTokenFound !== 'rejected'
  ) {
    onMessageListener()
      .then((payload: any) => {
        setNotificationFloating({
          isActive: true,
          title: payload.notification.title,
          body: payload.notification.body,
        });

        if (shopkeepersCpf !== null) {
          const receivedNewNotification = {
            userCpf: shopkeepersCpf,
            titulo: payload.notification.title,
            corpo: payload.notification.body,
            area: null,
            status: null,
            arquivada: 'false',
            momentoEnviada: Date.now(),
          };

          sendNewNotificationToBackend(receivedNewNotification);
        }
      })
      .catch(() =>
        toast.error('Ops... Não conseguimos atualizar suas notificações.')
      );
  }

  if (notificationFloating.isActive) {
    setTimeout(() => {
      setNotificationFloating({
        isActive: false,
        title: '',
        body: '',
      });
    }, 8000);
  }

  const totalNumberOfNotifications =
    arrayWithAllNotificationsUnread.length !== 0
      ? arrayWithAllNotificationsUnread.length
      : 0;

  function checkArrayAndChangeState() {
    setOpenAllNotification(!openAllNotification);

    if (arrayWithAllNotificationsUnread.length === 0) {
      setShowEmptyArrayCase(false);
    }
  }

  const showCircleWithLengthOfArray =
    (!openAllNotification && arrayWithAllNotificationsUnread.length > 0) ||
    showEmptyArrayCase;

  return (
    <React.Fragment>
      <S.Wrapper>
        <NotificationFloating
          setNotificationFloating={setNotificationFloating}
          notificationFloating={notificationFloating}
        />
        <div
          className="wrapperIconNotification"
          onClick={checkArrayAndChangeState}
        >
          <IoMdNotifications className="iconNotification" />
          {showCircleWithLengthOfArray && (
            <div className="circleWithLengthOfArray">
              {totalNumberOfNotifications}
            </div>
          )}
        </div>
        <AccessToAllNotifications
          openAllNotification={openAllNotification}
          setOpenAllNotification={setOpenAllNotification}
          arrayWithAllNotificationsUnread={arrayWithAllNotificationsUnread}
          totalNumberOfNotifications={totalNumberOfNotifications}
          setArrayWithAllNotificationsUnread={
            setArrayWithAllNotificationsUnread
          }
          shopkeepersCpf={shopkeepersCpf ? shopkeepersCpf : ''}
          setError={setError}
          searchNotification={searchNotification}
        />
      </S.Wrapper>
    </React.Fragment>
  );
};
