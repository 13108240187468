import Button from 'components/Button';
import { find } from 'lodash';
import { ENVIAR_CONTRATO } from 'pages/Documents/Contract/stages';
import {
  AGUARDANDO,
  CONCLUIDO,
  EXPIRADO,
} from 'pages/Documents/Contract/stageStatus';
import { EtapaDTO } from 'pages/Documents/Contract/types';
import { Proposta } from 'pages/Documents/types';
import { currencyFormatV2 } from 'utils/mask';
import { InfoOne } from '../EscolherVencimento/styles';
import {
  BlueTextButton,
  BoldText,
  ButtonWrapper,
  DetailsColumn,
  DetailsEditColumn,
  DetailsRow,
  DetailsWrapper,
  InfoTwo,
  RegularText,
  Spacer,
  Wrapper,
} from './styles';

type VencimentoAprovadoProps = {
  proposta: Proposta;
  etapas: EtapaDTO[];
  readOnly: boolean;
  editVencimento: () => void;
  goToEtapaVisualizarContrato: () => void;
};

const VencimentoAprovado = ({
  proposta,
  etapas,
  readOnly,
  editVencimento,
  goToEtapaVisualizarContrato,
}: VencimentoAprovadoProps) => {
  const existeEtapaExpirada = find(etapas, (it) => it.status === EXPIRADO);
  const contratoEnviado = find(
    etapas,
    (it) =>
      it.id === ENVIAR_CONTRATO &&
      (it.status === CONCLUIDO || it.status === AGUARDANDO)
  );
  const editingAllowed = !existeEtapaExpirada && !contratoEnviado;
  const dataVencimento = proposta.dataPrimeiroVencimento
    ? `Todo dia ${proposta.dataPrimeiroVencimento.substring(0, 2)}`
    : 'Vencimento não selecionado';

  return (
    <Wrapper>
      <InfoTwo>
        Informações sobre a escolha da data selecionada para o vencimento das
        parcelas:
      </InfoTwo>
      <DetailsWrapper>
        <DetailsColumn>
          <DetailsRow>
            <RegularText>Data de vencimento:</RegularText>
            <Spacer width={8} />
            <BoldText>{dataVencimento}</BoldText>
          </DetailsRow>
          <DetailsRow>
            <RegularText>Vencimento da 1ª parcela: </RegularText>
            <Spacer width={8} />
            <BoldText>{proposta.dataPrimeiroVencimento}</BoldText>
          </DetailsRow>
          <DetailsRow>
            <RegularText>Valor atual da parcela:</RegularText>
            <Spacer width={8} />
            <BoldText>{currencyFormatV2(proposta.valorParcela)}</BoldText>
          </DetailsRow>
        </DetailsColumn>
        <DetailsEditColumn>
          <BlueTextButton onClick={editVencimento} disabled={!editingAllowed}>
            EDITAR
          </BlueTextButton>
        </DetailsEditColumn>
      </DetailsWrapper>
      <InfoOne>Aviso:</InfoOne>
      <Spacer height={8} />
      <InfoTwo>
        Os boletos serão enviados para o email do cliente após o pagamento do
        contrato
      </InfoTwo>
      <ButtonWrapper>
        <Button
          marginTop="32px"
          onClick={goToEtapaVisualizarContrato}
          disabled={readOnly}
        >
          Visualizar contrato
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default VencimentoAprovado;
