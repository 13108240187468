import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ClientForm } from './components/ClientForm';
import { PopUpWarning } from './components/PopUpWarning';
import { Header } from 'components/Header';
/* import MediaMatch from 'components/MediaMatch';
import NumberedLabel from 'components/NumberedLabel';
import TermoUso from 'components/TermoUso';
 */
import * as CXT from 'context/globalContext';

import { User } from 'services/user';

import * as Styled from './styles';
import step from './step';
import { PopUpDanger } from './components/PopUpDanger';

export type WarningType = {
  title: string;
  label: string;
  isVehicleName: boolean;
  isActive: boolean;
  outOfOrderVehicle: boolean;
};

export type DangerType = {
  isActive: boolean;
  label: string;
};

export default function Client() {
  const globalContext = useContext(CXT.Context);

  const [firstTime, setFirstTime] = useState(false);
  /* const [popUp, setPopUp] = useState(false); */
  const [warningData, setWarningData] = useState<WarningType>({
    title: '',
    label: '',
    isVehicleName: false,
    isActive: false,
    outOfOrderVehicle: false,
  });
  const [dangerData, setDangerData] = useState({
    isActive: false,
    label: '',
  });

  const UserData = JSON.parse(localStorage.getItem('user') || '{}');

  /* const SwitchPopUp = () => {
    setPopUp(!popUp);
  }; */

  /* const Accept = () => {
    setPopUp(false);
    globalContext.setIsLoading(true);
    User.AcceptTerms()
      .then(() => {
        setPopUp(false);
        setFirstTime(false);
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500)
          toast.error('Erro ao consultar o servidor.');
        else
          toast.error(
            'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Tente novamente mais tarde e se o problema persistir contate o suporte!'
          );
      })
      .finally(() => {
        globalContext.setIsLoading(false);
      });
  }; */

  useEffect(() => {
    globalContext.setIsLoading(true);

    User.UseTerms()
      .then((response: any) => {
        if (response.data.data.aceitou === '0') {
          return setFirstTime(true);
        }

        setFirstTime(false);
      })
      .catch(() => {
        toast.error('Servidor não respondendo');
      })
      .finally(() => {
        globalContext.setIsLoading(false);
      });
  }, []);

  return (
    <Styled.Wrapper>
      <Header firstLogin={firstTime} />
      <Styled.MainContainer>
        <Styled.LeftSideContainer>
          {firstTime ? (
            <>
              <Styled.Title>
                Olá <br /> {UserData.name}
              </Styled.Title>
              <Styled.Description>
                Seja muito bem vindo ao <br />
                Portal de atendimento do grupo <br />
                Sinosserra
              </Styled.Description>
            </>
          ) : (
            <>
              <Styled.Title>
                Como funciona <br />o processo de simulação <br />
                de financiamento?
              </Styled.Title>
              <Styled.Description>
                São apenas 4 etapas <br />
                para finalizar o processo.
              </Styled.Description>
              <Styled.StepList>
                {step.map((item) => (
                  <div className="row" key={item.id}>
                    <span>
                      <strong>{item.id}.</strong> {item.label}
                    </span>
                  </div>
                ))}
              </Styled.StepList>
            </>
          )}
        </Styled.LeftSideContainer>
        <Styled.Divisor />
        <Styled.RightSideContainer>
          <ClientForm
            setWarningData={setWarningData}
            setDangerData={setDangerData}
          />
        </Styled.RightSideContainer>
      </Styled.MainContainer>
      {dangerData.isActive && (
        <PopUpDanger setDangerData={setDangerData} label={dangerData.label} />
      )}
      {warningData.isActive && (
        <PopUpWarning
          title={warningData.title}
          label={warningData.label}
          isVehicleName={warningData.isVehicleName}
          setWarningData={setWarningData}
          outOfOrderVehicle={warningData.outOfOrderVehicle}
        />
      )}
    </Styled.Wrapper>
  );
}
