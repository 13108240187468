import styled from 'styled-components';

export const Up = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 436px;
  border-bottom: solid 2px #bfbfbf;
  margin-bottom: 2rem;

  @media only screen and (max-width: 1000px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    width: 100%;
  }
`;

export const CardTitle = styled.h1`
  width: 60%;
  padding-left: 8px;
  padding-bottom: 7.5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #747576;
  text-transform: uppercase;

  @media only screen and (max-width: 450px) {
    font-size: 10px;
  }

  svg {
    margin-right: 10px;
  }
`;

export const UserCpf = styled.p`
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  font-weight: 300;
  color: #747576;
  width: 35%;

  @media only screen and (max-width: 450px) {
    font-size: 10px;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: right;
    color: #383838;

    @media only screen and (max-width: 450px) {
      font-size: 10px;
    }
  }
`;
