import React, { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import IconFolha from '../../../../assets/images/icon_folha.png';
import * as CTX from '../../../../context/globalContext';
import * as S from './styles';

export const TitleWrapperRegister: React.FC = () => {
  const globalContext = useContext(CTX.Context);

  return (
    <S.TitleWrapperComponent>
      <div className="wrapper">
        {globalContext.isLoading ? (
          <div className="wrapperSkeleton">
            <Skeleton
              width="30px"
              height="40px"
              style={{ marginRight: '5px' }}
            />
            <Skeleton width="220px" />
          </div>
        ) : (
          <>
            <S.Image src={IconFolha} />
            <S.StepInfo>Etapa 3 de 4:</S.StepInfo>
            <S.Title>Cadastro do cliente</S.Title>
          </>
        )}
      </div>
    </S.TitleWrapperComponent>
  );
};
