export const returnOpcaoSimulacaoFinanciamento = (opcoes: any): any => {
  const object = {
    agrupadorPrazo: opcoes.agrupadorPrazo,
    condicaoRPadrao: opcoes.condicaoRPadrao,
    dataFinalVigencia: opcoes.dataFinalVigencia,
    entradaMinima: opcoes.entradaMinima,
    financeiraId: opcoes.financeiraId,
    indEspecial: opcoes.indEspecial,
    indExclusivo: opcoes.indExclusivo,
    indFrotista: opcoes.indFrotista,
    indParcelaSemestral: opcoes.indParcelaSemestral,
    nomeFinanceira: opcoes.nomeFinanceira,
    observacao: opcoes.observacao,
    parcelaAdicionalFinal: opcoes.parcelaAdicionalFinal,
    percParcelaMaisEntrada: opcoes.percParcelaMaisEntrada,
    percentualEntradaMinima: opcoes.percentualEntradaMinima,
    percentualJuros: opcoes.percentualJuros,
    percentualRetorno: opcoes.percentualRetorno,
    quantidadeParcelas: opcoes.quantidadeParcelas,
    quantidadeParcelasMaisPrazo: opcoes.quantidadeParcelasMaisPrazo,
    quantidadeParcelasMenosPrazo: opcoes.quantidadeParcelasMenosPrazo,
    seguroFinan: opcoes.seguroFinan,
    seqTabelaFinanceira: opcoes.seqTabelaFinanceira,
    tarifaUsado: opcoes.tarifaUsado,
    taxaCadastro: opcoes.taxaCadastro,
    tipoFinanciamentoId: opcoes.tipoFinanciamentoId,
    tipoPessoa: opcoes.tipoPessoa,
    tipoVeiculoId: opcoes.tipoVeiculoId,
    valorEntrada: opcoes.valorEntrada,
    valorEntradaAVista: opcoes.valorEntradaAVista,
    valorGravame: opcoes.valorGravame,
    valorMultiplicador: opcoes.valorMultiplicador,
    valorMultiplicadorSpf: opcoes.valorMultiplicadorSpf,
    valorParcelaMaisEntrada: opcoes.valorParcelaMaisEntrada,
    valorParcelaMaisPrazo: opcoes.valorParcelaMaisPrazo,
    valorParcelaMenosPrazo: opcoes.valorParcelaMenosPrazo,
    valorParcelaRevPlanejada: opcoes.valorParcelaRevPlanejada,
    valorParcelaSpm: opcoes.valorParcelaSpm,
    valorParcelas: opcoes.valorParcelas,
    valorRetornoTac: opcoes.valorRetornoTac,
    valorSeguroFinan: opcoes.valorSeguroFinan,
    valorTotal: opcoes.valorTotal,
  };

  return object;
};
