import * as S from './styles';
import NavButton from './NavButton';
import { ReactComponent as Avancar } from 'assets/icons/avancar.svg';
import { useHistory } from 'react-router';
import * as CXT from 'context/globalContext';
import React, { useContext } from 'react';

type NavBarProps = {
  buttons?: Array<string>;
  links?: Array<string>;
};

const NavBar = ({ buttons, links }: NavBarProps) => {
  const globalContext = useContext(CXT.Context);
  const history = useHistory();

  return (
    <S.Wrapper>
      {globalContext.isLoading ? (
        <NavButton>{''}</NavButton>
      ) : (
        buttons?.map((element, index) => {
          return (
            <React.Fragment key={index}>
              <NavButton
                className={index === buttons.length - 1 ? 'currentPage' : ''}
                onClick={() => {
                  history.push(links?.[index] ?? '');
                }}
              >
                {element}
              </NavButton>
              {index === buttons.length - 1 ? '' : <Avancar />}
            </React.Fragment>
          );
        })
      )}
    </S.Wrapper>
  );
};

export default NavBar;
