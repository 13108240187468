import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 24px;
`;

export const BoldText = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: bold;
    color: ${theme.colors.graySecond};
  `}
`;

export const RegularText = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    color: ${theme.colors.graySecond};
  `}
`;

type SpacerProps = {
  width?: number;
  height?: number;
};
export const Spacer = styled.div<SpacerProps>`
  width: ${({ width }) => width ?? 0}px;
  height: ${({ height }) => height ?? 0}px;
`;

export const InfoOne = styled(BoldText)`
  width: 100%;
  text-align: center;
  margin-top: 24px;
`;

export const InfoTwo = styled(RegularText)`
  width: 100%;
  text-align: center;
`;

export const BlueTextButton = styled.button`
  ${({ theme, disabled }) => css`
    background: none;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: ${disabled ? theme.colors.grayFourth : theme.colors.blueSecondary};
  `}
`;

export const DetailsWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DetailsColumn = styled.div`
  width: 280px;
`;

export const DetailsEditColumn = styled.div`
  width: 60px;
`;

export const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
