import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getPerformance } from 'firebase/performance';
import { onMessage, getMessaging, getToken } from 'firebase/messaging';

import { FirebaseConfig } from './firebase-config';

const appFirebase = initializeApp(FirebaseConfig);

const db = getFirestore(appFirebase);
const messaging = getMessaging(appFirebase);
const performanceFirebase = getPerformance(appFirebase);

export { db, messaging, performanceFirebase };

export const fetchToken = (
  setIsTokenFound: React.Dispatch<React.SetStateAction<string>>
) => {
  getToken(messaging, {
    vapidKey:
      'BDOU99-h67HcA6JeFXHbSNMu7e2yNNu3RzoMj8TM4W88jITfq7ZmPvIM1Iv-4_l2LxQcYwhqby2xGpWwzjfAnG4',
  })
    .then((currentToken) => {
      if (currentToken) {
        return setIsTokenFound(currentToken);
      } else {
        return setIsTokenFound('rejected');
      }
    })
    .catch(() => {
      return setIsTokenFound('error');
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
