import * as S from './styles';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import AnexarIcon from 'assets/icons/AnexarComTexto.svg';
import Button from 'components/Button';
import CloseButton from 'components/CloseButton';
import * as CXT from 'context/globalContext';
import { Deals } from 'services/propostas';

import AnexFile from '../AnexFile';
import { ListData } from '../DocList';
import ObsTextField from 'components/ObsTextField';

export type DocPopUpProps = {
  children?: React.ReactNode;
  data?: ListData;
  pedidoId: string;

  isActive: boolean;
  setIsActive?: (item: boolean) => void;
};

const DocAnexPopUp = ({
  data,
  pedidoId,

  isActive = true,
  setIsActive = () => {
    isActive = false;
  },
}: DocPopUpProps) => {
  const globalContext = useContext(CXT.Context);
  const [file, setFile] = useState(null);
  const [observacao, setObservacao] = useState('');
  const ActivePopup = useRef(null);

  const DesactivePopUp = () => {
    setIsActive(false);
  };

  useEffect(() => {
    if (!file) return;
    const fileInfo = {
      pedidoId: pedidoId,
      docId: data?.id ?? 0,
    };
    globalContext.setIsLoading(true);
    DesactivePopUp();
    Deals.UploadDoc(fileInfo.pedidoId, fileInfo.docId, observacao, file)
      .then(() => {
        toast.info('PDF Enviado com Sucesso');
        globalContext.RefreshPage();
        setIsActive(false);
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500)
          toast.error('Erro ao consultar o servidor.');
        else if (err.response.status === 500)
          toast.error(
            'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Entre em contato para inclusão de avalista!'
          );
        else
          toast.error(
            'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Tente novamente mais tarde e se o problema persistir contate o suporte!'
          );
      })
      .finally(() => {
        globalContext.setIsLoading(false);
        setFile(null);
        setObservacao('');
      });
  }, [file]);

  useEffect(() => {
    if (isActive) {
      if (ActivePopup.current) {
        window.location.href = '#popup';
      }
    }
  }, [isActive, ActivePopup]);

  return isActive ? (
    <S.Wrapper>
      <S.BlurWrapper onClick={() => setIsActive(false)} />
      <S.PopUpBody ref={ActivePopup} id="popup">
        <S.PopUpHeaderBox>
          <S.PopUpTitleBox>
            <S.TitleLabel>{data?.nome.toUpperCase()}</S.TitleLabel>
            <S.SubTitleLabel>Aguardando Envio</S.SubTitleLabel>
          </S.PopUpTitleBox>

          <CloseButton type="button" onClick={() => setIsActive(false)} />
        </S.PopUpHeaderBox>
        <S.HorizontalBar />
        <S.PopUpMainBox>
          <S.MainButtonBox>
            <AnexFile
              multiple
              img={AnexarIcon}
              ext={'.pdf, .jpg, .png'}
              setFile={(file) => {
                setFile(file);
              }}
            />
          </S.MainButtonBox>
          <S.ObsBox>
            <ObsTextField
              titulo="Observações"
              minRows={4}
              value={observacao}
              onChange={(ev) => setObservacao(ev.target.value)}
              placeholder="Adicione aqui suas observações sobre o documento anexado."
            />
            <label>
              OS DOCUMENTOS <br />
              DEVEM SER REFERENTES AO ENDEREÇO <br />
              ANTERIORMENTE INFORMADO.
            </label>
          </S.ObsBox>
        </S.PopUpMainBox>
        <S.HorizontalBar />
        <S.PopUpButtonBox>
          <Button
            onClick={() => setIsActive(false)}
            type="button"
            buttonTheme={'goback'}
            height={'30px'}
            marginTop={'10px'}
          >
            VOLTAR
          </Button>
        </S.PopUpButtonBox>
      </S.PopUpBody>
    </S.Wrapper>
  ) : (
    <div />
  );
};
export default DocAnexPopUp;
