import { WrapperProps } from './index';
import styled, { css, DefaultTheme } from 'styled-components';

const WrapperModifiers = {
  active: (theme: DefaultTheme) => css`
    background: ${theme.colors.blueSecondary};
    cursor: pointer;
  `,
  goback: (theme: DefaultTheme) => css`
    background: ${theme.colors.white};
    color: ${theme.colors.blueSecondary};
    cursor: pointer;
  `,
  clean: (theme: DefaultTheme) => css`
    background: ${theme.colors.white};
    color: ${theme.colors.blueSecondary};
    cursor: pointer;
    text-align: left;
    padding-left: 2px;
    background-color: rgb(0, 0, 0, 0);
    font-size: ${theme.font.sizes.medium};
  `,
  secondary: (theme: DefaultTheme) => css`
    background: ${theme.colors.white};
    color: ${theme.colors.blueSecondary};
    border-radius: 6px;
    border: solid 1px #00a2e0;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.87;
    letter-spacing: normal;
    text-align: center;
    color: #00a2e0;
  `,
  // disabled: (theme: DefaultTheme) => css`
  //   background: ${theme.colors.btnDisabled};
  //   cursor: not-allowed;
  // `,
};

export const Wrapper = styled.button<WrapperProps>`
  ${({
    theme,
    buttonTheme = 'active',
    height = '3rem',
    width = '100%',
    marginTop = '5.2rem',
  }) => css`
    width: ${width};
    min-height: ${height};
    margin-top: ${marginTop};
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-weight: 500;
    font-size: ${theme.font.sizes.x_small};

    border: none;
    border-radius: 0.6rem;

    background-color: ${theme.colors.blueSecondary};
    color: ${theme.colors.white};

    ${!!buttonTheme && WrapperModifiers[buttonTheme](theme)}

    span {
      color: ${theme.colors.white};
      font-weight: 300;
      font-size: ${theme.font.sizes.xxx_small};
    }

    &.SmallText {
      font-size: ${theme.font.sizes.x_r_small};
    }
    &:disabled {
      color: ${theme.colors.grayThird};
      background-color: ${theme.colors.grayFith};
    }
    &.round {
      border-radius: 100px;
    }
  `}
`;
