import styled, { css } from 'styled-components';
import theme from 'styles/theme';

const selectedStoreModifiers: any = {
  selected: (theme: any) => css`
    background-color: white;
  `,
  notSelected: (theme: any) => css`
    background-color: ${theme.colors.grayEight};
  `,
};

export const WrappingComponent = styled.div`
  width: 10px;
  position: relative;
`;

export const Wrapper = styled.div`
  width: 350px;
  height: 500px;
  position: absolute;
  top: 39px;
  left: -180px;
  z-index: 10;
  box-shadow: 0px 0px 1px 1px rgba(20, 19, 20, 0.26);

  @media (max-width: 420px) {
    margin: 14rem;
    margin-top: -12px;
    width: 3300%;
  }
`;

export const BackgroundToCloseTheStoreExchange = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
`;

function changeColorRating(letter: string) {
  if (letter == null) {
    return theme.colors.grayFourth;
  } else if (letter.includes('A')) {
    return theme.colors.thirdGreen;
  } else if (letter == 'B') {
    return theme.colors.yellow400;
  } else if (letter == 'C') {
    return theme.colors.red;
  } else {
    return theme.colors.grayFourth;
  }
}

export const HeaderStores = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 35px;
    background-color: ${theme.colors.blueSecondary};

    .centralizedElements {
      margin: 0 auto;
      width: 85%;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
    }

    h5 {
      color: ${theme.colors.white};
      font-size: ${theme.font.sizes.x_small};
      font-family: 'Roboto';
      font-weight: 400;
    }

    .shape {
      @media (max-width: 420px) {
        right: 96%;
      }

      background-color: ${theme.colors.blueSecondary};
      position: absolute;
      width: 16px;
      height: 16px;
      transform: rotateZ(45deg);
      top: -20%;
      right: 48%;
    }
  `}
`;

export const Body = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 465px;
    overflow-y: auto;
    background-color: ${theme.colors.grayEight};

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.graySeventh};
      border-radius: 20px;
      border: 3px solid ${theme.colors.graySeventh};
    }
  `}
`;

type StoreRepresentationCardType = {
  category: string;
  status: 'selected' | 'notSelected';
};

export const StoreRepresentationCard = styled.div<StoreRepresentationCardType>`
  ${({ theme, category, status }) => css`
    width: 100%;
    height: 90px;

    transition: Background 0.3s;
    cursor: pointer;
    border-bottom: 2px #e4e4e4 solid;

    .centralizedElements {
      margin: 0 18px;
      height: 100%;
      justify-content: space-between;
      display: flex;

      .titleAndSubTitle {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h4 {
          color: ${theme.colors.gray250};
          font-size: ${theme.font.sizes.medium};
          font-weight: ${theme.font.weight.normal};
          width: 265px
          
          // ellipsis, maxlines 2
          line-height: 1.5em;
          max-height: 3.2em;
          overflow: hidden;
          display: block;
          display: -webkit-box;
          max-width: 100%;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }

        h5 {
          font-weight: ${theme.font.weight.light};
          font-size: ${theme.font.sizes.x_small};
          color: ${theme.colors.gray250};
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
      }

      .category {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        h3 {
          font-size: ${theme.font.sizes.xxextraLarge};
          font-weight: ${theme.font.weight.bold};
          color: ${changeColorRating(category)};
        }
      }
    }

    &:hover {
      background-color: ${theme.colors.white};
    }
    ${status && selectedStoreModifiers[status](theme)};
  `}
`;
