import * as S from './styles';
import IconFolha from '../../assets/images/icon_folha.png';
import { Header } from 'components/Header';
import { BsCheckAll } from 'react-icons/bs';
import Button from 'components/Button';

import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

const step = 4;

export default function Success() {
  const history = useHistory();
  const PedidoId = localStorage.getItem('PedidoId');

  useEffect(() => {
    const PedidoId = localStorage.getItem('PedidoId');
    const carData = JSON.parse(localStorage.getItem('car') || '{}');
    const parcel = JSON.parse(localStorage.getItem('parcel') || '{}');
    if (
      carData.marcas === undefined ||
      parcel.prazo === undefined ||
      PedidoId === ''
    ) {
      history.push('/client');
    }
    localStorage.setItem('car', '');
    localStorage.setItem('client', '');
    localStorage.setItem('carData', '');
    localStorage.setItem('parcel', '');
    localStorage.setItem('parcelBck', '');
    localStorage.setItem('fipeDate', '');
  }, []);

  return (
    <>
      <Header
        onRefreshClick={() => {
          history.push('/');
        }}
      />
      <S.Wrapper>
        <S.TitleWrapper>
          <S.Image src={IconFolha} />
          <S.StepInfo>Etapa {step} de 4:</S.StepInfo>
          <S.Title>Enviar para crédito</S.Title>
        </S.TitleWrapper>
        <S.HardLine />
        <S.Content>
          <S.Circle>
            <BsCheckAll size={55.8} color="#FFF" />
          </S.Circle>
          <S.Title>Pronto!</S.Title>
          <S.SubTitle>
            Pedido nº {PedidoId} enviado para crédito com sucesso.
          </S.SubTitle>
          <S.Message>
            Obrigado por utilizar o Portal de lojistas Sinosserra.
          </S.Message>
          <S.ButtonWrapper>
            <Button
              type="button"
              buttonTheme="secondary"
              width="328px"
              height="40px"
              onClick={() => {
                history.push('/client');
              }}
            >
              FAZER NOVA SIMULAÇÂO
            </Button>
          </S.ButtonWrapper>
        </S.Content>
      </S.Wrapper>
    </>
  );
}
