import { TailSpin } from 'react-loader-spinner';
import { Wrapper } from './styles';

export type LoadingProps = {
  colorToken?: string;
  padding?: string;
  size?: number;
};

const Loading = ({ colorToken, padding, size }: LoadingProps) => {
  return (
    <Wrapper padding={padding} colorToken={colorToken}>
      <TailSpin width={size ?? 48} height={size ?? 48} />
    </Wrapper>
  );
};

export default Loading;
