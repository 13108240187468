import * as S from './styles';
import Button from 'components/Button';
import CloseButton from 'components/CloseButton';

type PopUpProps = {
  title?: string;
  subTitle?: string;
  type?: 'single' | 'double';
  children?: React.ReactNode;

  isActive?: boolean;
  setIsActive?: (item: boolean) => void;

  b1Label?: string;
  b1Func?: (item: any) => void;
  b2Label?: string;
  b2Func?: (item: any) => void;
};

const PopUp = ({
  title = 'Titulo',
  subTitle = 'Subtitulo',
  type = 'double',
  children = 'MENSAGEM AQUI',

  isActive = true,
  setIsActive = () => {
    isActive = false;
  },

  b1Label = type === 'single' ? 'OK' : 'SIM',
  b1Func = () => {
    isActive = false;
  },
  b2Label = 'NÃO',
  b2Func = () => {
    isActive = false;
  },
}: PopUpProps) => {
  return isActive ? (
    <S.Wrapper>
      <S.BlurWrapper>
        <S.PopUpBody>
          <S.PopUpTitleBox>
            <S.TitleLabel>{title.toUpperCase()}</S.TitleLabel>
            <CloseButton type="button" onClick={() => setIsActive(false)} />
          </S.PopUpTitleBox>
          <S.PopUpSubTitleBox>
            <S.SubTitleLabel>{subTitle}</S.SubTitleLabel>
          </S.PopUpSubTitleBox>
          <S.HorizontalBar />
          <S.PopUpMainBox>{children}</S.PopUpMainBox>
          <S.HorizontalBar />
          <S.PopUpButtonBox>
            <Button
              onClick={b1Func}
              type="button"
              height={'45px'}
              marginTop={'10px'}
            >
              {b1Label}
            </Button>
            {type === 'double' ? (
              <div style={{ minWidth: '15px', height: '0px' }} />
            ) : (
              ''
            )}
            {type === 'double' ? (
              <Button
                onClick={b2Func}
                type="button"
                height={'45px'}
                marginTop={'10px'}
              >
                {b2Label}
              </Button>
            ) : (
              ''
            )}
          </S.PopUpButtonBox>
        </S.PopUpBody>
      </S.BlurWrapper>
    </S.Wrapper>
  ) : (
    <div />
  );
};
export default PopUp;
