import * as S from './styles';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import PDFIcon from 'assets/icons/AnexarComTexto.svg';
import { ReactComponent as Dot } from 'assets/icons/dot.svg';
import { ReactComponent as Ballon } from 'assets/icons/Balloon_retorno_cinza.svg';

import Button from 'components/Button';
import CloseButton from 'components/CloseButton';
import * as CXT from 'context/globalContext';
import ShowPDF from 'utils/ShowPDF';
import { Deals } from 'services/propostas';
import { ListData } from '../DocList';
import AnexFile from '../AnexFile';

export type DocPopUpProps = {
  data?: ListData;
  pedidoId: string;
  url: string;
  obsNote?: string;

  isActive: boolean;
  setIsActive?: (item: boolean) => void;

  b1Func?: (item: any) => void;
};

const DocNotePopUp = ({
  data,
  pedidoId,
  url,
  isActive = true,
  setIsActive = () => {
    isActive = false;
  },
  obsNote,
}: DocPopUpProps) => {
  const globalContext = useContext(CXT.Context);

  const DecreaseNClose = () => {
    setIsActive(false);
  };

  const [file, setFile] = useState(null);

  const DesactivePopUp = () => {
    setIsActive(false);
  };

  useEffect(() => {
    if (!file) return;
    const fileInfo = {
      pedidoId: pedidoId,
      docId: data?.id ?? 0,
    };
    globalContext.setIsLoading(true);
    Deals.UploadDoc(fileInfo.pedidoId, fileInfo.docId, '', file)
      .then(() => {
        toast.info('PDF Enviado com Sucesso');
        globalContext.RefreshPage();
        setIsActive(false);
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500)
          toast.error('Erro ao consultar o servidor.');
        else
          toast.error(
            'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Tente novamente mais tarde e se o problema persistir contate o suporte!'
          );
      })
      .finally(() => {
        globalContext.setIsLoading(false);
        setFile(null);
        DesactivePopUp();
      });
  }, [file]);

  return isActive ? (
    <S.Wrapper>
      <S.BlurWrapper onClick={DecreaseNClose} />
      <S.PopUpBody>
        <S.PopUpHeaderBox>
          <S.PopUpTitleBox>
            <S.TitleLabel>{data?.nome?.toUpperCase() ?? ''}</S.TitleLabel>
            <S.SubTitleLabel>{'Não Aprovado'}</S.SubTitleLabel>
          </S.PopUpTitleBox>

          <CloseButton type="button" onClick={DecreaseNClose} />
        </S.PopUpHeaderBox>

        <S.HorizontalBar />
        <S.PopUpMainBox>
          <S.TopBodyBox>
            <S.PDFBox>
              <ShowPDF url={url} width={150} />
            </S.PDFBox>
            <S.VerticalBar />
            <S.PopUpReasonBox>
              <span>INFELIZMENTE SEU DOCUMENTO NÃO FOI ACEITO!</span>
              <S.ImgLabel className="line-one">
                <Ballon width={'22px'} height={'22px'} />
                Motivo:
              </S.ImgLabel>
              <S.ImgLabel className="line-two">
                <Dot width={'15px'} fill={'#707070'} />
                {obsNote}
              </S.ImgLabel>
            </S.PopUpReasonBox>
          </S.TopBodyBox>
          <S.ObsBox>
            <S.ObsRightBox>
              <span>QUEM ENVIOU:</span>
              <label>{data?.usuarioEnvio ?? ''}</label>
              <div />
              <span>QUANDO ENVIOU:</span>
              <label>{data?.dataEnvio ?? ''}</label>
            </S.ObsRightBox>
            <S.VerticalBar />
            <S.ObsLeftBox>
              <S.RetryBox>
                REENVIAR ARQUIVO
                <AnexFile
                  medium
                  multiple
                  img={PDFIcon}
                  ext={'.pdf, .jpg, .png'}
                  setFile={setFile}
                />
              </S.RetryBox>
            </S.ObsLeftBox>
          </S.ObsBox>
        </S.PopUpMainBox>
        <S.HorizontalBar />
        <S.PopUpButtonBox>
          <Button
            onClick={DecreaseNClose}
            type="button"
            buttonTheme={'goback'}
            height={'30px'}
            marginTop={'10px'}
          >
            VOLTAR
          </Button>
          <div />
          <Button
            onClick={DecreaseNClose}
            type="button"
            height={'30px'}
            marginTop={'10px'}
          >
            SALVAR E CONTINUAR
          </Button>
        </S.PopUpButtonBox>
      </S.PopUpBody>
    </S.Wrapper>
  ) : (
    <div />
  );
};
export default DocNotePopUp;
