import styled, { css } from 'styled-components';

type WrapperType = {
  clickedInputChecked: boolean;
  messageOfNotification: string;
  readMoreOrReadLess: string;
};

export const Wrapper = styled.div<WrapperType>`
  ${({
    theme,
    clickedInputChecked,
    messageOfNotification,
    readMoreOrReadLess,
  }) => css`
    margin: 0 auto;
    width: 90%;
    height: ${messageOfNotification.length >= 38 &&
    readMoreOrReadLess === 'close'
      ? 'max-content'
      : '70px'};
    background-color: ${theme.colors.white};
    border-radius: 8px;
    box-shadow: 0 0 0.2em rgba(6, 5, 6, 0.2);
    animation-name: ${clickedInputChecked && 'removeCard'};
    animation-duration: 0.5s;
    animation-delay: 50ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes removeCard {
      50% {
        transform: scale3d(0.95, 0.95, 3) translateY(-150%);
      }
      90% {
        visibility: hidden;
        opacity: 0;
      }
      100% {
        display: none;
        visibility: hidden;
        opacity: 0;
      }
    }

    .centralized {
      margin: 0 auto;
      width: 90%;
    }

    .headerCard {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        color: ${theme.colors.grayThird};
        font-size: ${theme.font.sizes.x_r_small};
        font-family: 'Roboto';
        font-weight: 500;
      }

      input {
        appearance: none;
        width: 15px;
        height: 15px;
        border-radius: 10%;
        background-color: ${messageOfNotification.length >= 38 &&
        readMoreOrReadLess === 'close'
          ? theme.colors.green
          : theme.colors.grayThird};
        position: relative;
        cursor: pointer;

        &::after {
          content: '✔';
          width: 100%;
          height: 100%;
          position: absolute;
          top: -15%;
          left: 15%;
          color: ${theme.colors.white};
        }
      }
    }

    .wrapperMessage {
      width: 100%;
      height: ${messageOfNotification.length >= 38 &&
      readMoreOrReadLess === 'close'
        ? 'max-content'
        : '20px'};
      display: flex;

      h6 {
        width: 79%;
        height: ${messageOfNotification.length >= 38 &&
        readMoreOrReadLess === 'close'
          ? 'max-content'
          : '15px'};
        color: ${theme.colors.grayThird};
        font-size: ${theme.font.sizes.xxx_small};
        font-family: 'Roboto';
        display: -webkit-box;
        -webkit-line-clamp: ${messageOfNotification.length >= 38 &&
        readMoreOrReadLess === 'close'
          ? 'none'
          : '1'};
        -webkit-box-orient: vertical;
        text-overflow: ${messageOfNotification.length >= 38 &&
        readMoreOrReadLess === 'close'
          ? 'none'
          : 'ellipsis'};
        overflow: ${messageOfNotification.length >= 38 &&
        readMoreOrReadLess === 'close'
          ? 'none'
          : 'hidden'};
      }

      div {
        width: 21%;
        height: ${messageOfNotification.length >= 38 &&
        readMoreOrReadLess === 'close'
          ? ''
          : '12px'};
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }

      span {
        font-size: ${theme.font.sizes.r_small};
        font-weight: 500;

        color: ${theme.colors.blueSecondary};
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .dateAndTime {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 20px;

      p {
        color: ${theme.colors.grayThird};
        font-size: ${theme.font.sizes.r_small};
        font-family: 'Roboto';
        font-weight: 500;
      }
    }
  `}
`;
