import apiKeyCloak from '../api/api-keycloak';

const RESOURCE = '';

export type dataForm = {
  username: string;
  password: string;
  grant_type: string;
  client_id: string;
  client_secret: string;
};

export const Authenticate = {
  SignInRequest: async ({
    username,
    password,
    grant_type = process.env.REACT_APP_GRAND_TYPE ?? '',
    client_id = process.env.REACT_APP_CLIENT_ID ?? '',
    client_secret = process.env.REACT_APP_CLIENT_SECRET ?? '',
  }: dataForm) => {
    const urlencoded = new URLSearchParams({
      username: username,
      password: password,
      grant_type: grant_type,
      client_id: client_id,
      client_secret: client_secret,
    });

    return await apiKeyCloak.post(RESOURCE, urlencoded);
  },
};
