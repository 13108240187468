import * as S from './styles';
import { LabelHTMLAttributes } from 'react';

type TextBoxProps = {
  className?: string;
  variant?: boolean;
  title?: string;
  children: React.ReactNode;
} & LabelHTMLAttributes<HTMLLabelElement>;

const TextBox = ({
  className = '',
  variant = false,
  title = '',
  children,
}: TextBoxProps) => {
  return (
    <S.Wrapper className={className}>
      {title.length > 0 && !variant ? (
        <label className="RoundUp">{title}</label>
      ) : (
        ''
      )}

      <label
        className={
          title.length > 0
            ? 'RoundDown ' + (variant ? 'variant' : '')
            : 'AllRound ' + (variant ? 'variant' : '')
        }
      >
        {children}
      </label>
    </S.Wrapper>
  );
};

export default TextBox;
