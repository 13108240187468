import styled, { css } from 'styled-components';

export const InfoTwo = styled.div`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin-top: 8px;
    color: ${theme.colors.graySecond};
  `}
`;

export const StatusWrapper = styled.div`
  width: 100%;
  padding: 56px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    border-radius: 50%;
    width: 56px;
    height: 56px;

    color: white;
    background-color: ${theme.colors.red};

    font-size: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 6px;
  `}
`;

export const StatusLabelWrapper = styled.div`
  ${({ theme }) => css`
    height: 48px;
    padding-top: 32px;
    font-size: 24px;

    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${theme.colors.red};
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 350px;
  margin-top: 40px;
`;
