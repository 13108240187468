import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 60%;
  ${media.lessThan('small')`
      width: 100%;
      min-height:340px;
    `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    font-family: 'Roboto', sans-serif;
    color: ${theme.colors.graySecond};
    font-weight: 500;
    font-size: ${theme.font.sizes.xxx_small};
    line-height: 50px;
    margin-left: 10px;
  `}
`;

export const Body = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.gray};
    border-radius: 0.6rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 88%;
    ${media.lessThan('small')`
      min-height:340px;
    `}
  `}
`;

export const LineImg = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 100%;
  height: 31%;
`;

export const ImgBox = styled.div`
  text-align: center;
  width: 70%;
  height: 100%;
`;

export const Img = styled.img`
  height: 100%;
  width: height;

  ${media.lessThan('large')`
      width: 100%;
    `}
  ${media.lessThan('small')`
      width: 100%;
    `}
`;

export const Label = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    color: ${theme.colors.graySecond};
    font-weight: 500;
    font-size: ${theme.font.sizes.x_small};
    line-height: 27px;

    span {
      display: block;
      font-family: 'Roboto', sans-serif;
      font-size: 20px;
      font-weight: 500;
      color: ${theme.colors.blackTertiary};
      font-size: ${theme.font.sizes.x_small};
    }

    &.plate {
      width: 30%;
    }

    &.name {
      text-align: left;
      width: 70%;
      @media only screen and (max-width: 450px) {
        padding-left: 0%;
      }
    }
    &.year {
      width: 30%;
    }
    &.color {
      width: 33%;
    }
    &.gearbox {
      width: 33%;
    }
    &.fuel {
      width: 33%;
    }
    &.fipe {
      width: 20%;
    }
    &.fipeVal {
      width: 20%;
    }
    &.legenda {
      width: 50%;

      font-size: ${theme.font.sizes.x_r_small};
    }
  `}
`;

export const LineName = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.thirdWhite};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
    @media only screen and (max-width: 450px) {
      min-height: 67px;
    }
  `}
`;

export const LineColor = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.gray};
    border-bottom: 1px solid ${theme.colors.gray};
    background-color: ${theme.colors.thirdWhite};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25%;
    @media only screen and (max-width: 450px) {
      min-height: 67px;
    }
  `}
`;

export const LineValue = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.thirdWhite};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
    @media only screen and (max-width: 450px) {
      min-height: 60px;
      border-radius: 4px;
    }
  `}
`;

export const VerticalBar = styled.div`
  ${({ theme }) => css`
    width: 1px;
    height: 80%;
    border-right: 1px solid ${theme.colors.gray};
  `}
`;
