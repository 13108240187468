import { InputHTMLAttributes } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as Styled from './styles';

type CheckBoxType = {
  id: string;
  label: string;
  onCheck: (name: string, value: boolean) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export const CheckBox = ({
  label,
  checked,
  onCheck,
  ...props
}: CheckBoxType) => {
  return (
    <Styled.Wrapper>
      <Styled.Checkbox
        onClick={() => {
          onCheck(props.id, !checked);
        }}
        checked={checked}
      >
        {checked && <FaIcons.FaCheck />}
      </Styled.Checkbox>
      <input
        type="checkbox"
        onChange={() => {
          onCheck(props.id, !checked);
        }}
        {...props}
      />
      <label htmlFor={props.id}>{label}</label>
    </Styled.Wrapper>
  );
};
