import styled from 'styled-components';

export const Paginacao = styled.div`
  .pagination {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    list-style-type: none;

    li a {
      border-radius: 7px;
      padding: 0.1rem 1rem;
      border: gray 1px solid;
      cursor: pointer;
      margin-left: 5px;
    }

    li.Anterior a,
    li.Próximo a,
    li.break a {
      border-color: transparent;
    }

    li.active a {
      background-color: #0366d6;
      border-color: transparent;
      color: white;
      min-width: 32px;
    }

    li.disabled a {
      color: grey;
    }
    li.disable,
    li.disabled a {
      cursor: default;
    }
  }
`;
