import styled, { css, DefaultTheme } from 'styled-components';

export type WrapperProps = {
  paddingLeft?: string;
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ paddingLeft = '0px' }) => css`
    margin-bottom: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;

    padding-left: ${paddingLeft};
  `}
`;

export const Img = styled.image``;

export type LabelProps = {
  paddingLeft?: string;
  fontSize?: 'normal' | 'small';
};

const StatusModifier = {
  normal: (theme: DefaultTheme, paddingLeft: string) => css`
    /* color: ${theme.colors.green}; */
    /* border-color: ${theme.colors.green}; */
    font-size: ${theme.font.sizes.xx_small};
    padding-left: ${paddingLeft};
  `,
  small: (theme: DefaultTheme) => css`
    /* color: ${theme.colors.yellow}; */
    border-color: ${theme.colors.yellow};
    font-size: ${theme.font.sizes.xx_small};
    padding-left: 2px;
  `,
};

export const Label = styled.label<LabelProps>`
  ${({ theme, paddingLeft = '6px', fontSize = 'normal' }) => css`
    width: max-content;
    color: ${theme.colors.bluePrimary};
    height: 16px;
    line-height: 20px;
    font-weight: 500;

    ${!!fontSize && StatusModifier[fontSize](theme, paddingLeft)}
  `};
`;
