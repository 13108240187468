export const mockStatus = {
  dateOrderData: [
    {
      date: 0,
      label: 'Hoje',
    },
    {
      date: 7,
      label: 'Últimos 7 dias',
    },
    {
      date: 15,
      label: 'Últimos 15 dias',
    },
    {
      date: 30,
      label: 'Últimos 30 dias',
    },
    {
      date: 60,
      label: 'Últimos 60 dias',
    },
    {
      date: 90,
      label: 'Últimos 90 dias',
    },
    {
      label: 'Qualquer data',
    },
  ],

  creditStatus: [
    'Aprovado',
    'Pré Aprovado',
    'Qualificado',
    'Recusado',
    'Em Análise',
    'Desenquadrado',
    'À Vista',
    'Financiamento Externo',
    'Sem Decisão',
  ],
  stageStatus: [
    'Todas propostas',
    'Todas (exceto canceladas)',
    'Todas (exceto canceladas e faturadas)',
    'Aprovação de crédito',
    'Aprovação Documentação',
    'Emissão do Contrato',
    'Pagamento do Contrato',
  ],
};
