import * as S from './styles';
import { ButtonHTMLAttributes } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const CloseButton = ({ ...props }: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <S.Wrapper {...props}>
      <CloseIcon />
    </S.Wrapper>
  );
};

export default CloseButton;
