import { FormEvent, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import Button from 'components/Button';
import MaskedInput from 'components/MaskedInput';

import { Context } from 'context/globalContext';

import { Person } from 'services/client';
import { Car, carFetchFormChassi, carFetchFormPlate } from 'services/car';
import { CarRegister } from 'services/carRegister';

import { cpfMask, unMask } from 'utils/mask';

import { clientValidation } from 'utils/validations/ClientValidation';
import { FormErrors } from 'utils/validations/getValidationError';

import { WarningType, DangerType } from '../../';

import IconFolha from 'assets/icons/icon_folha.png';

import * as Styled from './styles';

import firebase from 'firebase/compat/app';
import 'firebase/analytics';
import { errorInSubmit } from './submitFunctions/errorInSubmit';

type ClientFormProps = {
  setWarningData: React.Dispatch<React.SetStateAction<WarningType>>;
  setDangerData: React.Dispatch<React.SetStateAction<DangerType>>;
};

type bodyBudgetProps = {
  escopoValidacao: string;
  veiculoEstoqueId: any;
  pessoaClienteId: any;
};

export const ClientForm = ({
  setWarningData,
  setDangerData,
}: ClientFormProps) => {
  const globalContext = useContext(Context);
  const history = useHistory();

  const [formValues, setFormValues] = useState({
    cpf: '',
    vehiclePlate: '',
    vehicleChassi: '',
  });
  const [errors, setErrors] = useState({} as FormErrors);

  const analytics = firebase.analytics();
  const company = JSON.parse(localStorage.getItem('company') || '{}');
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');

  const [clickedLabelChassi, setClickedLabelChassi] = useState(false);

  const [
    userWithPermissionForInputChassi,
    setUserWithPermissionForInputChassi,
  ] = useState(false);

  useEffect(() => {
    const arrayPermission = userData && userData.permissao;
    if (arrayPermission.includes('CONSULTA_VEICULO_POR_CHASSI')) {
      setUserWithPermissionForInputChassi(true);
    } else {
      setUserWithPermissionForInputChassi(false);
    }
  }, []);

  const handleOnChangeFormValues = (
    name: string,
    value: string,
    typePattern?: string
  ) => {
    if (typePattern === 'chassi' || typePattern === 'plate') {
      const format = value.replace(/\W|_/g, '');
      value = format;
    }
    if (typePattern === 'chassi') {
      if (value.length === 17) {
        const regex1 = new RegExp(
          /[a-zA-Z1-9][a-zA-Z0-9]{8}[a-zA-Z0-9-]{2}[0-9]{6}/g
        );

        const regex2 = new RegExp(
          /[a-zA-Z1-9][a-zA-Z0-9]{9}[a-zA-Z0-9-]{2}[0-9]{5}/g
        );

        const valid1 = regex1.exec(value);
        const valid2 = regex2.exec(value);
        if (valid1 !== null && valid2 !== null) {
          errors.vehicleChassi = '';
          setErrors(errors);
        }
        if (valid1 === null && valid2 === null) {
          errors.vehicleChassi = 'Formato de chassi inválido.';
          setErrors(errors);
        }
      }
    }

    if (typePattern === 'plate') {
      if (value.length === 7) {
        const regex = new RegExp(/[A-Z]{3}[0-9][0-9A-Z][0-9]{2}/g);

        const valid = regex.exec(value);
        if (valid !== null) {
          errors.vehiclePlate = '';
          setErrors(errors);
        }
        if (valid === null) {
          errors.vehiclePlate = 'Formato de placa inválida.';
          setErrors(errors);
        }
      }
    }

    setFormValues({ ...formValues, [name]: value });
  };

  const clearAllData = () => {
    localStorage.setItem('car', '');
    localStorage.setItem('client', '');
    localStorage.setItem('carData', '');
    localStorage.setItem('parcel', '');
    localStorage.setItem('parcelBck', '');
    localStorage.setItem('fipeDate', '');
    localStorage.setItem('PedidoId', '');
  };

  const returnPopUpClient = (dataBudget: any): boolean => {
    if (dataBudget.statusRetorno === 'CLIENTE_IMPEDIDO') {
      globalContext.setIsLoading(false);

      setDangerData({
        isActive: true,
        label: 'está com crédito reprovado e sem possibilidade de reanálise.',
      });
      return false;
    }

    if (dataBudget.statusRetorno === 'CLIENTE_DESENQUADRADO') {
      globalContext.setIsLoading(false);

      setDangerData({
        isActive: true,
        label: 'não é possível iniciar o orçamento para o cliente informado.',
      });

      return false;
    }

    if (dataBudget.statusRetorno === 'VEICULO_DESENQUADRADO_SITUACAO') {
      globalContext.setIsLoading(false);

      setWarningData({
        title: 'AÇÃO INDISPONÍVEL',
        label:
          'Infelizmente não será possível iniciar o orçamento para o veículo:',
        isVehicleName: false,
        isActive: true,
        outOfOrderVehicle: true,
      });

      return false;
    }

    if (dataBudget.statusRetorno === 'VEICULO_DESENQUADRADO') {
      globalContext.setIsLoading(false);

      setWarningData({
        title: 'AÇÃO INDISPONÍVEL',
        label:
          'Infelizmente não será possível iniciar o orçamento para o veículo:',
        isVehicleName: true,
        isActive: true,
        outOfOrderVehicle: false,
      });

      return false;
    }

    return true;
  };

  const resumeSubmit = (
    bodyBudget: bodyBudgetProps,
    newCar?: carFetchFormPlate,
    newCarVersionFindChassi?: carFetchFormChassi
  ) => {
    Person.BudgetRequest(bodyBudget).then((response) => {
      const dataBudget = response.data.data;

      if (returnPopUpClient(dataBudget) === false) return;

      if (newCar !== undefined) {
        Car.CarRequest(newCar)
          .then((response) => {
            if (response.data.data.marcas[0].linhas.length === 0) return;

            localStorage.setItem('car', JSON.stringify(response.data.data));

            // analytics.setUserProperties(globalContext.properties);
            analytics.logEvent('CK_PHASE_ONE', {
              cliente: unMask(formValues.cpf, '[^0-9]'),
              placa: formValues.vehiclePlate,
            });

            history.push('/conditions');
          })
          .catch((error) => {
            globalContext.setIsLoading(false);
            errorInSubmit(error);
          });
      }

      if (newCarVersionFindChassi !== undefined) {
        Car.CarRequestForChassi(newCarVersionFindChassi)
          .then((response) => {
            if (response.data.data.marcas[0].linhas.length === 0) return;

            localStorage.setItem('car', JSON.stringify(response.data.data));

            // analytics.setUserProperties(globalContext.properties);
            analytics.logEvent('CK_PHASE_ONE', {
              cliente: unMask(formValues.cpf, '[^0-9]'),
              placa: formValues.vehiclePlate,
            });

            history.push('/conditions');
          })
          .catch((error) => {
            globalContext.setIsLoading(false);
            errorInSubmit(error);
          });
      }
    });
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const errors = clientValidation(formValues);
    if (
      errors.cpf !== '' ||
      errors.vehicleChassi !== '' ||
      errors.vehiclePlate !== ''
    ) {
      return setErrors(errors);
    }

    globalContext.setIsLoading(true);

    const newValues = { ...formValues };
    newValues.cpf = unMask(formValues.cpf, '\\.|-');

    Person.ClientRequest(newValues.cpf)
      .then(async (response) => {
        const clientId = response.data.data.id;

        localStorage.setItem('client', JSON.stringify(response.data.data));

        if (
          formValues.vehiclePlate !== '' &&
          formValues.vehiclePlate.length === 7
        ) {
          const regex1 = new RegExp(/[A-Z]{3}[0-9][0-9A-Z][0-9]{2}/g);

          const valid = regex1.exec(formValues.vehiclePlate);
          if (valid !== null) {
            errors.vehiclePlate = '';
            setErrors(errors);
          }
          if (valid === null) {
            errors.vehiclePlate = 'Formato de placa inválida.';
            globalContext.setIsLoading(false);
            return setErrors(errors);
          }

          await CarRegister.CarRegister(newValues.vehiclePlate)
            .then((response) => {
              if (response.data.data.tipoValidacao === 'EXCEDEU_CHAMADAS') {
                globalContext.setIsLoading(false);
                return setWarningData({
                  title: 'LIMITE EXCEDIDO.',
                  label:
                    'Foi utilizado o limite máximo de cadastros de veículos.',
                  isActive: true,
                  isVehicleName: false,
                  outOfOrderVehicle: false,
                });
              }

              localStorage.setItem(
                'fipeDate',
                JSON.stringify(response.data.data.fipeDataTabela)
              );
              localStorage.setItem(
                'vehiclePlate',
                JSON.stringify(response.data.data)
              );

              const bodyBudget = {
                escopoValidacao: 'ORCAMENTO_INICIAR',
                veiculoEstoqueId: response.data.data.id,
                pessoaClienteId: clientId,
              };

              const newCar: carFetchFormPlate = {
                cnpjLojista: company.cpfCnpjLojista ?? 12345678,
                placa: formValues.vehiclePlate,
                tipoRelatorio: '',
              };

              resumeSubmit(bodyBudget, newCar);
            })
            .catch((error) => {
              globalContext.setIsLoading(false);
              errorInSubmit(error, 'Placa');
            });
        }

        if (
          formValues.vehicleChassi !== '' &&
          formValues.vehicleChassi.length === 17 &&
          userWithPermissionForInputChassi
        ) {
          const regex1 = new RegExp(
            /[a-zA-Z1-9][a-zA-Z0-9]{8}[a-zA-Z0-9-]{2}[0-9]{6}/g
          );

          const regex2 = new RegExp(
            /[a-zA-Z1-9][a-zA-Z0-9]{9}[a-zA-Z0-9-]{2}[0-9]{5}/g
          );

          const valid1 = regex1.exec(formValues.vehicleChassi);
          const valid2 = regex2.exec(formValues.vehicleChassi);
          if (valid1 !== null && valid2 !== null) {
            errors.vehicleChassi = '';
            setErrors(errors);
          }
          if (valid1 === null && valid2 === null) {
            errors.vehicleChassi = 'Formato de chassi inválido.';
            globalContext.setIsLoading(false);
            return setErrors(errors);
          }

          await CarRegister.CarRegisterWithChassi(newValues.vehicleChassi)
            .then((response) => {
              if (response.data.data.tipoValidacao === 'EXCEDEU_CHAMADAS') {
                globalContext.setIsLoading(false);
                return setWarningData({
                  title: 'LIMITE EXCEDIDO.',
                  label:
                    'Foi utilizado o limite máximo de cadastros de veículos.',
                  isActive: true,
                  isVehicleName: false,
                  outOfOrderVehicle: false,
                });
              }

              localStorage.setItem(
                'fipeDate',
                JSON.stringify(response.data.data.fipeDataTabela)
              );
              localStorage.setItem(
                'vehiclePlate',
                JSON.stringify(response.data.data)
              );

              const newCarVersionFindChassi: carFetchFormChassi = {
                cnpjLojista: company.cpfCnpjLojista ?? 12345678,
                veiculoId: response.data.data.id,
                tipoRelatorio: '',
              };

              const bodyBudget = {
                escopoValidacao: 'ORCAMENTO_INICIAR',
                veiculoEstoqueId: response.data.data.id,
                pessoaClienteId: clientId,
              };

              resumeSubmit(bodyBudget, undefined, newCarVersionFindChassi);
            })
            .catch((error) => {
              globalContext.setIsLoading(false);
              errorInSubmit(error, 'Chassi');
            });
        }
        if (
          (formValues.vehiclePlate === '' && formValues.vehicleChassi === '') ||
          (formValues.vehiclePlate.length !== 7 &&
            formValues.vehicleChassi.length === 0) ||
          (formValues.vehicleChassi.length !== 17 &&
            formValues.vehiclePlate.length === 0)
        ) {
          globalContext.setIsLoading(false);
          toast.error('Verifique seus dados e tente novamente.');
          return;
        }
      })
      .catch((error) => {
        globalContext.setIsLoading(false);
        if (error.response?.status === 404) {
          return toast.error(
            'Cliente não encontrado com esse CPF. Verifique os dados digitados e tente novamente'
          );
        }

        if (error.response?.status === 500) {
          return toast.error(
            'Ops! Tivemos um problema ao prosseguir com a sua solicitação. ' +
              'Tente novamente mais tarde e se o problema persistir contate o suporte!'
          );
        }

        return toast.error(
          'Não foi possível prosseguir com a solicitação. Faça o Login novamente.'
        );
      });
  };

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <img src={IconFolha} />
        <div>
          <h1>Etapa 1 de 4: </h1>
          <span>Cliente e Veículo</span>
        </div>
      </Styled.Header>
      <Styled.Form onSubmit={handleSubmit}>
        {globalContext.isLoading ? (
          <>
            <Skeleton width={'280px'} height={'40px'} />
            <Skeleton width={'280px'} height={'40px'} />
          </>
        ) : (
          <>
            <Styled.RowInput error={errors.cpf}>
              <MaskedInput
                id="cpf"
                name="cpf"
                title="CPF do Cliente"
                value={formValues.cpf}
                onChange={(event) => {
                  handleOnChangeFormValues(
                    event.target.name,
                    cpfMask(event.target.value)
                  );
                }}
                onFocus={() => setErrors({})}
                placeholder="Cpf: 999.999.999-99..."
                MaskType={'cpf'}
              />
              {errors.cpf && <span>{errors.cpf}</span>}
            </Styled.RowInput>
            <Styled.RowInput
              error={errors.vehiclePlate}
              handleClickChangeColor={clickedLabelChassi}
            >
              <div className="wrapperTwoLabel">
                <div
                  className="leftPositionLabel"
                  onClick={() => setClickedLabelChassi(false)}
                >
                  <label htmlFor="vehiclePlate">Placa do veículo</label>
                </div>
                {userWithPermissionForInputChassi && (
                  <div
                    className="rightPositionLabel"
                    onClick={() => setClickedLabelChassi(true)}
                  >
                    <label htmlFor="vehicleChassi">Chassi</label>
                  </div>
                )}
              </div>

              {!clickedLabelChassi && (
                <div className="wrapperInput">
                  <input
                    id="vehiclePlate"
                    name="vehiclePlate"
                    type="text"
                    value={formValues.vehiclePlate}
                    onChange={(event) => {
                      formValues.vehicleChassi = '';
                      handleOnChangeFormValues(
                        event.target.name,
                        event.target.value.toUpperCase(),
                        'plate'
                      );
                    }}
                    placeholder="Placa: XXX9X99..."
                    onFocus={() => setErrors({})}
                    maxLength={7}
                  />
                  {errors.vehiclePlate && <span>{errors.vehiclePlate}</span>}
                </div>
              )}

              {userWithPermissionForInputChassi && clickedLabelChassi && (
                <div className="wrapperInput">
                  <input
                    id="vehicleChassi"
                    name="vehicleChassi"
                    type="text"
                    value={formValues.vehicleChassi}
                    onChange={(event) => {
                      formValues.vehiclePlate = '';
                      handleOnChangeFormValues(
                        event.target.name,
                        event.target.value.toUpperCase(),
                        'chassi'
                      );
                    }}
                    onFocus={() => setErrors({})}
                    placeholder="Chassi: 9XX999XX99X999999..."
                    maxLength={17}
                  />
                  {errors.vehicleChassi && <span>{errors.vehicleChassi}</span>}
                </div>
              )}
            </Styled.RowInput>
          </>
        )}
        <Button type="submit" onClick={clearAllData}>
          INICIAR SIMULAÇÃO
        </Button>
      </Styled.Form>
    </Styled.Wrapper>
  );
};
