import * as S from './styles';
import Button from 'components/Button';
import CloseButton from 'components/CloseButton';

import { TutorialDataType } from 'components/PopUpTutorial';

type PopUpProps = {
  data: TutorialDataType[];

  isActive?: boolean;
  setIsActive: (item: boolean) => void;

  next: () => void;
};

const PageZero = ({ data, setIsActive, next }: PopUpProps) => {
  return (
    <S.PopUpBody>
      <S.PopUpTitleBox>
        <div />
        <CloseButton type="button" onClick={() => setIsActive(false)} />
      </S.PopUpTitleBox>
      <S.Label className="typeI">
        Olá! <br />
        Nós temos algumas <br />
        novidades pra você.
      </S.Label>
      <S.Label className="typeII">
        Nesta nova versão do Portal
        <br />
        SIN, estamos trazendo algumas
        <br />
        melhorias para ajudar no seu <br />
        dia a dia
      </S.Label>

      <S.ItemList>
        {data.map((item, index) => (
          <S.Label key={index} className="typeIII">
            {' '}
            {item.titulo}
          </S.Label>
        ))}
      </S.ItemList>
      <S.HorizontalBar />
      <S.PopUpButtonBox>
        <Button onClick={next} type="button" height={'45px'} marginTop={'10px'}>
          QUERO CONHECER
        </Button>
      </S.PopUpButtonBox>
    </S.PopUpBody>
  );
};

export default PageZero;
