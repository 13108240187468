type Values = {
  [k: string]: string;
};

export const clientValidation = (values: Values) => {
  const objectError = {
    cpf: '',
    vehiclePlate: '',
    vehicleChassi: '',
  };

  if (values.cpf.length !== 14) {
    objectError.cpf = 'campo CPF deve conter 11 números';
  } else {
    objectError.cpf = '';
  }

  if (values.vehiclePlate.length !== 7 && values.vehicleChassi.length === 0) {
    objectError.vehiclePlate =
      'campo Placa do Veículo deve conter 7 caracteres';
  } else {
    objectError.vehiclePlate = '';
  }

  if (values.vehicleChassi.length !== 17 && values.vehiclePlate.length === 0) {
    objectError.vehicleChassi =
      'campo Chassi do Veículo deve conter 17 caracteres';
  } else {
    objectError.vehicleChassi = '';
  }

  return objectError;
};
