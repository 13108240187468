import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WrapperContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface TextWrapperType {
  textAlign?: 'center' | 'left';
}
export const TextWrapper = styled.div<TextWrapperType>`
  ${({ theme, textAlign = 'center' }) => css`
    text-align: ${textAlign};
    font-size: 14px;
    width: fit-content;

    color: ${theme.colors.graySecond};
  `}
`;

export const BoldTextWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: ${theme.colors.blackSecondary};
  `}
`;

export const InfoTwo = styled.div`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;
    font-size: 13px;
    margin-top: 8px;
    color: ${theme.colors.graySecond};
  `}
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
