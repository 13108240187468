import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-width: 1280px;
    min-height: 100vh;
    padding-bottom: 2.4rem;

    background-color: ${theme.colors.thirdWhite};
    overflow-x: hidden;
  `}
`;

export const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;

  display: flex;
  justify-content: center;

  main {
    width: 70%;
    max-width: 1290px;
    padding-top: 12rem;
    position: relative;

    display: flex;

    @media (max-width: 1366px) {
      width: 90%;
    }

    > div:nth-child(1) {
      width: 100%;
      padding: 0;
      position: fixed;

      z-index: 1;

      @media (max-width: 1280px) {
        position: absolute;
      }
    }
  }
`;

export const FilterContainer = styled.div`
  min-width: 30rem;

  height: 100vh;
  margin-top: 4.8rem;

  @media (max-width: 1366px) {
    min-width: 25rem;
    margin-top: 3.6rem;
  }
`;

export const DealContainer = styled.div`
  width: 100%;
  margin: 4.8rem 0 0 1.6rem;

  @media (max-width: 1366px) {
    margin-top: 3.6rem;
  }
`;
