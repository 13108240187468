import React from 'react';

import { cpfMask } from 'utils/mask';

import blockIcon from 'assets/icons/Block.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { DangerType } from '../../';

import * as Styled from './styles';

export type PopUpDangerType = {
  label: string;
  setDangerData: React.Dispatch<React.SetStateAction<DangerType>>;
  blockedStoreText?: boolean;
};

export const PopUpDanger = ({
  label,
  setDangerData,
  blockedStoreText,
}: PopUpDangerType) => {
  const client = JSON.parse(localStorage.getItem('client') || '{}');

  return (
    <Styled.Wrapper>
      <Styled.BlurWrapper
        onClick={() => {
          setDangerData((oldState) => ({
            ...oldState,
            isActive: false,
          }));
        }}
      />
      <Styled.PopUpContainer>
        <CloseIcon
          onClick={() => {
            setDangerData((oldState) => ({
              ...oldState,
              isActive: false,
            }));
          }}
        />
        <img src={blockIcon} alt="" />
        <h1>{blockedStoreText ? 'LOJA BLOQUEADA' : 'ENVIO BLOQUEADO'}</h1>
        <strong>
          {!blockedStoreText && String(client.cpf).length < 11 ? (
            <>
              {client.nome} (CPF {cpfMask(String(client.cpf).padStart(11, '0'))}
              )
            </>
          ) : !blockedStoreText && String(client.cpf).length < 11 === false ? (
            <>
              {client.nome} (CPF {cpfMask(client.cpf)})
            </>
          ) : (
            ''
          )}
        </strong>
        <span>{label}</span>
        <button
          onClick={() => {
            setDangerData((oldState) => ({
              ...oldState,
              isActive: false,
            }));
          }}
        >
          OK, ENTENDI
        </button>
      </Styled.PopUpContainer>
    </Styled.Wrapper>
  );
};
