import apiCar from '../api/api-car';

const RESOURCE_CAR = '/veiculo/estoque/consulta';
const RESOURCE_FIPE_MAX = '/parametro/WEB/PERCENTUAL_FIPE_MAXIMO';

export type carFetchFormPlate = {
  cnpjLojista: number;
  placa: string;
  tipoRelatorio: string;
};

export type carFetchFormChassi = {
  cnpjLojista: number;
  veiculoId: string;
  tipoRelatorio: string;
};

export const Car = {
  CarRequest: async (form: carFetchFormPlate) => {
    const urlencoded = <carFetchFormPlate>{
      cnpjLojista: form.cnpjLojista,
      placa: form.placa,
      tipoRelatorio: 'sem_estoque',
    };
    return await apiCar.post(RESOURCE_CAR, urlencoded);
  },
  CarRequestForChassi: async (form: carFetchFormChassi) => {
    const urlencoded = <carFetchFormChassi>{
      cnpjLojista: form.cnpjLojista,
      veiculoId: form.veiculoId,
      tipoRelatorio: 'sem_estoque',
    };
    return await apiCar.post(RESOURCE_CAR, urlencoded);
  },
  FipeMaxRequest: async () =>
    await apiCar.get(RESOURCE_FIPE_MAX, {
      data: {},
    }),
  OnboardingRequest: async (cpf: string) => {
    return await apiCar.get(
      `onboarding/plataforma/WEB/${cpf}/naovisualizadas`,
      {
        data: {},
      }
    );
  },
  OnboardingUpdate: async (id: number, cpf: string) => {
    return await apiCar.put(`onboarding/${id}/${cpf}/visualizada`, {});
  },
};
