import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const CustomInput = styled.input`
  ${({ theme }) => css`
    background-color: ${theme.colors.blueSecondary};
    display: none;
  `}
`;

export const Img = styled.img`
  width: 90px;
  height: 90px;
  &.small {
    width: 30px;
    height: 30px;
  }
  &.medium {
    width: 50px;
    height: 50px;
  }
`;
