import {
  NewNotificationType,
  NotificationCards,
} from 'components/Header/types/typesUsingInHeader';
import apiClient from '../api/api-car';

const RESOURCE_NOTIFICATION = '/mensagem';

type GetAllUnreadType = {
  messages?: any;
  data: NotificationCards[];
};

type GetAllArchivedType = {
  messages?: any;
  data: NotificationCards[];
};

export const GetNotification = {
  GetAllUnread: async (cpf: string) => {
    return await apiClient.get<GetAllUnreadType>(
      `${RESOURCE_NOTIFICATION}/${cpf}/naolidas`
    );
  },
  GetAllArchived: async (cpf: string) => {
    return await apiClient.get<GetAllArchivedType>(
      `${RESOURCE_NOTIFICATION}/${cpf}/arquivadas`
    );
  },
  SendToArchived: async (idNotification: string | number) => {
    return await apiClient.put(
      `${RESOURCE_NOTIFICATION}/${idNotification}/lida`
    );
  },
};

export const SendNotification = {
  SendMessagingToBackend: async (newNotification: NewNotificationType) => {
    return await apiClient.post(`${RESOURCE_NOTIFICATION}`, newNotification);
  },
};
