import { NotificationCards } from 'components/Header/types/typesUsingInHeader';
import React, { useState } from 'react';
import * as S from './style';

type CarsNotificationType = {
  style: React.CSSProperties;
  moveCardToArchived(id: string): void;
  id: string;
  archived?: boolean;
  body: NotificationCards;
};

export const CardsNotification: React.FC<CarsNotificationType> = ({
  style,
  moveCardToArchived,
  id,
  archived,
  body,
}) => {
  const [enableEffect, setEnableEffect] = useState(false);

  const [readMoreOrReadLess, setReadMoreOrReadLess] = useState('read more');

  const returnMessageReadMoreOrReadLess =
    readMoreOrReadLess === 'read more' ? '[Leia +]' : '[Fechar]';

  const timestamp = body.momentoEnviada;

  const dateSendMessaging = new Date(timestamp);

  const messageSendingDay = String(dateSendMessaging.getDate()).padStart(
    2,
    '0'
  );

  const monthOfSendingTheMessage = String(
    dateSendMessaging.getMonth() + 1
  ).padStart(2, '0');

  const messageYearSent = dateSendMessaging.getFullYear();

  const fullYearOfSendingMessage = `${messageSendingDay}/${monthOfSendingTheMessage}/${messageYearSent}`;

  const hours = String(dateSendMessaging.getHours()).padStart(2, '0');
  const minutes = String(dateSendMessaging.getMinutes()).padStart(2, '0');

  const exactTimeOfSendingMessage = `${hours}:${minutes}`;

  function changeInReadMore(id: string) {
    if (readMoreOrReadLess === 'read more') {
      return setReadMoreOrReadLess('close');
    }
    if (archived === undefined) {
      moveCardToArchived(id);
    }
    return setReadMoreOrReadLess('read more');
  }

  return (
    <React.Fragment>
      <S.Wrapper
        id={id}
        style={style}
        clickedInputChecked={enableEffect}
        messageOfNotification={body.corpo}
        readMoreOrReadLess={readMoreOrReadLess}
      >
        <div className="centralized">
          <div className="headerCard">
            <p>{body.titulo}</p>

            {!archived && (
              <input
                type="checkbox"
                onClick={() => {
                  moveCardToArchived(id);
                  setEnableEffect(true);
                }}
              />
            )}
          </div>
          <div className="wrapperMessage">
            <h6>{body.corpo}</h6>
            {body.corpo.length >= 38 && (
              <div>
                <span onClick={() => changeInReadMore(id)}>
                  {returnMessageReadMoreOrReadLess}
                </span>
              </div>
            )}
          </div>
          <div className="dateAndTime">
            <p>
              {fullYearOfSendingMessage} | {exactTimeOfSendingMessage}
            </p>
          </div>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
};
