import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 800px;
  padding-top: 37px;
  padding-bottom: 37px;
  ${media.lessThan('small')`
    height: auto;
    justify-content: flex-start;
    flex-direction: column;
    `}
`;

export const HardLine = styled.hr`
  ${({ theme }) => css`
    display: block;
    width: 961.5px;
    height: 0.2rem;
    border: 2px solid ${theme.colors.blueSecondary};
    margin: 1.35rem auto;
    background: ${theme.colors.blueSecondary};

    ${media.lessThan('large')`
      width: 99%;
      max-width: 960px;
    `}
  `}
`;

export const MainBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  height: 90%;
  ${media.lessThan('small')`
    height: auto;
    justify-content: flex-start;
    width: 100vw;
    margin-top: 0;
    `}
`;

export const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 10%;
  width: 100%;
`;

export const TitleBar = styled.div`
  padding-left: 13.5%;
  padding-right: 12.5%;
  padding-bottom: 1%;
  display: flex;
  flex-direction: row;
  justify-content: normal;
  align-items: flex-end;
  height: 11%;
  width: 100%;

  ${media.lessThan('small')`
    padding: 2rem 3rem 0;
    `}
`;

export const TitleWrapper = styled.div`
  width: 100%;
  max-width: 961.5px;
  height: 45px;
  margin: 0 auto;
  margin-top: 8rem;
  padding: 0 8px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const TitleImg = styled.img`
  width: 24px;
  height: 31.6px;
  @media only screen and (max-width: 450px) {
    width: 19px;
    height: 26.6px;
  }
`;

export const TitleLabel = styled.div`
  ${({ theme }) => css`
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: ${theme.colors.blackTertiary};
    @media only screen and (max-width: 450px) {
      font-size: 15px;
    }

    span {
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      margin-left: 1.2rem;
      margin-right: 0.4rem;
      color: ${theme.colors.blackTertiary};
      @media only screen and (max-width: 450px) {
        font-size: 15px;
      }
    }
  `}
`;

export const DataBody = styled.div`
  padding-left: 12.5%;
  padding-right: 12.5%;
  display: flex;
  justify-content: center;
  align-items: normal;
  height: 100%;
  width: 100%;
  min-width: 780px;
  ${media.lessThan('small')`
    min-width: 0px;
    height: auto;
    justify-content: flex-start;
    flex-direction: column;
    /* width: 100vw; */
    padding: 0rem 2rem;
    `}
`;

export const DataBodyLeft = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: normal;
  height: 100%;
  width: 50%;
  /* border-right: solid 1px #012d5c; */

  ${media.lessThan('large')`
      justify-content: flex-start;v
    `}
  ${media.lessThan('small')`
      height: auto;
      justify-content: flex-start;
      width: 100%;
    `}
    main {
    label {
      padding-left: 8px;
    }

    input {
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
      padding-left: 8px;
      padding-bottom: 3px;
    }
  }
`;

export const InputBox = styled.div`
  width: 90%;
  height: 10%;
  &.withMargin {
    margin-top: 5%;
  }

  @media only screen {
    width: 100%;
  }
`;

export const Label = styled.div`
  ${({ theme }) => css`
    font-family: 'Roboto', sans-serif;
    color: ${theme.colors.graySecond};
    font-weight: 500;
    font-size: ${theme.font.sizes.xxx_small};

    ${media.lessThan('large')`
    font-size: ${theme.font.sizes.x_r_small};
    `}
  `}
`;

export const DataBodyRight = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  width: 50%;

  ${media.lessThan('large')`
      height: auto;
      justify-content: flex-start;
    `}
  ${media.lessThan('small')`
      height: auto;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 4rem;
    `}
`;

export const ReturnBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: normal;
  width: 100%;
  height: 16%;
  margin-left: 10px;
`;

export const ReturnLabel = styled(Label)`
  margin-right: 5%;
  width: 30%;
`;

export const ReturnSelect = styled.select`
  ${({ theme }) => css`
    width: 210px;
    height: 32px;

    text-align: left;
    margin-top: 2%;
    margin-bottom: 2%;
    padding-left: 1%;
    border-radius: 0.6rem;

    font-family: 'Roboto', sans-serif;
    color: ${theme.colors.blueSecondary};
    font-weight: 800;
    font-size: ${theme.font.sizes.x_small};
    border: 1px solid ${theme.colors.blueSecondary};
    ${media.lessThan('small')`
    width: 160px;
    `}
  `}
`;

export const ReturnOpt = styled.option`
  ${({ theme }) => css`
    color: ${theme.colors.blackSecondary};
    text-align: left;
    margin-top: 2%;
    margin-bottom: 2%;
    border-radius: 0.6rem;
    left: 50px;
    /* width: 40%;
    height: 40%;

    text-align: center;
    padding-top: 1%;
    margin-top: 2%;
    margin-bottom: 2%;

    font-family: 'Roboto', sans-serif;
    color: ${theme.colors.blueSecondary};
    font-size: ${theme.font.sizes.large};
    border: 1px solid ${theme.colors.blueSecondary}; */

    font-weight: 300;
  `}
`;

export const ValueBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-width: 90%;
  min-height: 25%;
  ${media.lessThan('small')`
    margin-top: 1rem;
    `}
`;

export const Balance = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 40%;

    text-align: center;
    line-height: 60px;
    margin-top: 2%;
    margin-bottom: 2%;
    border-radius: 0.6rem;
    font-family: 'Roboto', sans-serif;
    color: ${theme.colors.blueSecondary};
    font-weight: 800;
    font-size: ${theme.font.sizes.xxlarge};
    border: 1px solid ${theme.colors.blueSecondary};
  `}
`;

type SideNoteProps = {
  theme: DefaultTheme;
  sideNoteTheme?: 'default' | 'valid' | 'invalid';
};

const SideNoteModifiers = {
  valid: (theme: DefaultTheme) => css`
    color: ${theme.colors.graySecond};
  `,
  invalid: (theme: DefaultTheme) => css`
    color: ${theme.colors.red};
  `,
  default: (theme: DefaultTheme) => css`
    color: ${theme.colors.graySecond};
  `,
};

export const SideNote = styled.div`
  ${({ theme, sideNoteTheme }: SideNoteProps) => css`
    width: 100%;
    height: 10%;

    text-align: end;
    margin-top: 5px;

    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: ${theme.font.sizes.x_r_small};
    font-style: italic;

    ${!!sideNoteTheme && SideNoteModifiers[sideNoteTheme](theme)}
  `}
`;

export const ParcelBox = styled.div`
  width: 90%;
`;

export const TotalBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  min-height: 15%;

  ${media.lessThan('small')`
    margin-top: 1rem;
    `}
`;
export const TotalBoxInside = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
`;

export const Total = styled.div`
  ${({ theme }) => css`
    width: 75%;
    height: 40%;

    text-align: center;
    line-height: 38px;
    margin-top: 2%;
    margin-bottom: 2%;

    border-radius: 0.6rem;
    font-family: 'Roboto', sans-serif;
    color: ${theme.colors.blackTertiary};
    font-weight: 800;
    font-size: ${theme.font.sizes.large};
    border: 1px solid ${theme.colors.gray};
    ${media.lessThan('large')`
      font-size: ${theme.font.sizes.medium};
      width: 90%;
      min-height: 40px;
    `}
  `}
`;

export const ButtonBox = styled.div`
  padding-block: 5%;
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 10%;
  min-height: 15%;
  ${media.lessThan('small')`
    button {padding: 1rem;}
  `}
`;

export const MoreParcels = styled.button`
  ${({ theme }) => css`
    margin-top: 5px;

    width: 260px;
    min-height: 10px;
    line-height: 0px;
    text-align: right;
    font-weight: 30;
    font-style: italic;
    font-size: ${theme.font.sizes.xxx_small};
    background-color: ${theme.colors.white};
    border: none;
    color: ${theme.colors.blackTertiary};
  `}
`;

export const MoreParcelsSubstitute = styled.div`
  ${({ theme }) => css`
    margin-top: 24px;

    width: 260px;
    min-height: 10px;
    line-height: 0px;
    text-align: right;
    font-weight: 30;
    font-style: italic;
    font-size: ${theme.font.sizes.xxx_small};
    background-color: ${theme.colors.white};
    border: none;
    color: ${theme.colors.blackTertiary};
  `}
`;
