import apiAppdsv from '../api/api-appdsv';

const RESOURCE = '';

export const CarRegister = {
  CarRegister: async (placa: string) => {
    return await apiAppdsv.get(`${RESOURCE}/veiculo/${placa}`);
  },
  CarRegisterWithChassi: async (chassi: string) => {
    return await apiAppdsv.get(`${RESOURCE}/veiculo/chassi/${chassi}`);
  },
};
