import { cepFix, cpfFix, dateFix, mask } from 'utils/mask';

export function initialData(
  person: any,
  enderecoIndex: any,
  phone: any,
  getEmail: () => string
) {
  const initialData = {
    nome: person?.nome ?? '',
    aniversario: person?.dataNascimento ?? '',
    sexo: person?.sexo ?? '',

    estadoCivil: person?.estadoCivil?.descricao
      ? person?.estadoCivil?.descricao
      : '',

    mae: person?.nomeMae ?? '',
    cpf: mask(cpfFix(person?.cpf), '###.###.###-##'),

    email: getEmail(),
    telefone: phone ? phone.ddd + phone.telefone : '',

    cep:
      enderecoIndex >= 0 && person?.enderecos?.[enderecoIndex]?.cep
        ? cepFix(person?.enderecos?.[enderecoIndex]?.cep)
        : '',

    logradouro:
      enderecoIndex >= 0 && person?.enderecos?.[enderecoIndex]?.endereco
        ? person?.enderecos?.[enderecoIndex]?.endereco
        : '',

    numero:
      enderecoIndex >= 0 && person?.enderecos?.[enderecoIndex]?.numero
        ? person?.enderecos?.[enderecoIndex]?.numero.toString()
        : '',

    complemento:
      enderecoIndex >= 0 && person?.enderecos?.[enderecoIndex]?.complemento
        ? person?.enderecos?.[enderecoIndex]?.complemento
        : '',

    bairro:
      enderecoIndex >= 0 && person?.enderecos?.[enderecoIndex]?.bairro
        ? person?.enderecos?.[enderecoIndex]?.bairro
        : '',
    cidade:
      enderecoIndex >= 0 && person?.enderecos?.[enderecoIndex]?.nomeMunicipio
        ? person?.enderecos?.[enderecoIndex]?.nomeMunicipio
        : '',

    uf:
      enderecoIndex >= 0 && person?.enderecos?.[enderecoIndex]?.codigoEstado
        ? person?.enderecos?.[enderecoIndex]?.codigoEstado
        : '',

    tipoLogradouro:
      enderecoIndex >= 0 && person?.enderecos?.[enderecoIndex]?.tipoLogradouro
        ? person?.enderecos?.[enderecoIndex]?.tipoLogradouro
        : '',

    profissao: person?.profissao?.descricao ?? '',

    atividade: person?.referenciasProfissionais?.[0]?.tipoOcupacao ?? '',

    renda: person?.pessoaRendas?.[0]?.valor.toFixed(2).toString() ?? '',

    comprovacao: person?.pessoaRendas?.[0]?.tipo?.descricao ?? '',

    cepEmpresa: person?.referenciasProfissionais?.[0]
      ? cepFix(person?.referenciasProfissionais?.[0]?.cep)
      : '',

    empresa: person?.referenciasProfissionais?.[0]?.nomeEmpresa ?? '',

    telEmpresa: person?.referenciasProfissionais?.[0]?.telefone
      ? person?.referenciasProfissionais?.[0]?.dddTelefone +
        person?.referenciasProfissionais?.[0]?.telefone
      : '',
    cargo: person?.referenciasProfissionais?.[0]?.cargo ?? '',

    mesAnoAdmissao: person?.referenciasProfissionais?.[0]?.mesAnoAdmissao
      ? dateFix(person?.referenciasProfissionais?.[0]?.mesAnoAdmissao)
      : '',

    ufEmpresa: '',
    cidadeEmpresa: '',
    observacoes: person?.pessoaRendas?.[0]?.observacao ?? '',
    ruaEmpresa: '',
    bairroEmpresa: '',
  };

  return initialData;
}
