import React, { useContext, useState } from 'react';

import warningIcon from 'assets/icons/Warning.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Deals } from 'services/propostas';
import { toast } from 'react-toastify';

import * as CXT from 'context/globalContext';

import * as Styled from './styles';
import { LoadingModal } from 'pages/Client/components/loadingModal';

export type DocsApprovalType = {
  isActive: boolean;
  setIsActive?: (item: boolean) => void;
  dealNumber: string;
};

export const PopUpDocsApproval = ({
  isActive = true,
  setIsActive = () => {
    isActive = false;
  },
  dealNumber,
}: DocsApprovalType) => {
  const globalContext = useContext(CXT.Context);
  const [loading, setLoading] = useState(false);

  const sendApprovalDocs = () => {
    Deals.ApprovalRequest(dealNumber)
      .then(() => {
        toast.info('Solicitação Realizada com Sucesso');
        globalContext.RefreshPage();
        globalContext.setIsLoading(false);
        setIsActive(false);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500)
          toast.error('Erro ao consultar o servidor.');
        else
          toast.error(
            'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Tente novamente mais tarde e se o problema persistir contate o suporte!'
          );
      })
      .finally(() => globalContext.setIsLoading(false));
  };

  return isActive ? (
    <Styled.Wrapper>
      {loading && <LoadingModal />}
      <Styled.BlurWrapper
        onClick={() => {
          setIsActive(false);
        }}
      />
      <Styled.PopUpContainer>
        <CloseIcon
          onClick={() => {
            setIsActive(false);
          }}
        />
        <img src={warningIcon} alt="" />
        <h1>SOLICITAR APROVAÇÃO</h1>
        <span style={{ textAlign: 'center' }}>
          Existem documento(s) pendente(s) de aprovação,
          <br /> envie o(s) documento(s) para a análise clicando em
          <br /> SOLICITAR APROVAÇÃO.
        </span>
        <button
          onClick={() => {
            setIsActive(false);
          }}
        >
          OK, ENTENDI
        </button>
      </Styled.PopUpContainer>
    </Styled.Wrapper>
  ) : (
    <></>
  );
};
