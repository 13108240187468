import * as FaIcons from 'react-icons/fa';
import {
  IconWrapper,
  InfoOne,
  InfoTwo,
  StatusLabelWrapper,
  StatusWrapper,
  TabNameWrapper,
} from './styles';

const VencimentoQualificado = () => {
  return (
    <>
      <StatusWrapper>
        <IconWrapper>
          <FaIcons.FaRegCopy />
        </IconWrapper>
        <StatusLabelWrapper>QUALIFICADO</StatusLabelWrapper>
      </StatusWrapper>

      <InfoOne>Aprovação de crédito não foi concluída</InfoOne>
      <InfoTwo>
        A visualização e emissão do contrato estará disponível após sua
        aprovação
      </InfoTwo>

      <InfoOne>Acompanhe o andamento na aba de:</InfoOne>
      <TabNameWrapper>APROVACÃO DE CRÉDITO</TabNameWrapper>
    </>
  );
};

export default VencimentoQualificado;
