import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.main`
  ${({ width, disabled }: InputProps) => css`
    display: flex;
    flex-direction: column;
    width: ${width ? width : '256px'};
    height: 45px;
    opacity: ${disabled && '0.3'};
    margin-top: 3rem;
    position: relative;
    justify-content: center;
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
    ${media.lessThan('small')`
    width: '256px';
    `}
  `}
`;

export const SkelBox = styled.div`
  margin-bottom: 5px;
`;

type InputProps = {
  error?: boolean;
  height?: string;
  width?: string;
  theme: DefaultTheme;
  inputTheme?: 'default' | 'valid' | 'invalid';
  valid?: boolean;
  disabled?: boolean;
};

const InputModifiers = {
  valid: (theme: DefaultTheme) => css`
    border-bottom: solid 1px ${theme.colors.secondGreen};
  `,
  invalid: (theme: DefaultTheme) => css`
    border-bottom: solid 1px ${theme.colors.red};
  `,
  default: (theme: DefaultTheme) => css`
    border-bottom: solid 1px ${theme.colors.gray};
  `,
};

export const Input = styled.input`
  ${({
    theme,
    inputTheme = 'default',
    error,
    valid,
    width = '100%',
  }: InputProps) => css`
    border: none;
    position: relative;
    width: ${width};
    @media only screen and (max-width: 1170px) {
      width: 100%;
    }
    ${media.lessThan('small')`
      width: 100%;
    `}

    font-size: ${theme.font.sizes.medium};
    ${!!inputTheme && InputModifiers[inputTheme](theme)}
    ${!!error && InputModifiers['invalid'](theme)}
    ${!!valid && InputModifiers['valid'](theme)}
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    font-family: 'Roboto';
    color: ${theme.colors.blackTertiary};
    font-weight: 500;
    font-size: ${theme.font.sizes.xxx_small};
    margin-bottom: 1rem;
    height: 14px;
  `}
`;

export const ContentPass = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  ${Input} {
    width: 26rem;
  }

  svg {
    position: absolute;
    right: 0;
    bottom: 30%;
    cursor: pointer;
  }
`;
export const IconSuccess = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    max-width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    pointer-events: none;
    bottom: 0.2rem;

    svg {
      color: #2a99fb;
    }
  `}
`;
export const IconError = styled(IconSuccess)`
  bottom: 0.5rem;
`;
export const MsgError = styled.p`
  ${({ theme }) => css`
    margin-top: 5.5px;
    color: ${theme.colors.red};
    font-size: 9px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    text-transform: lowercase;
    white-space: nowrap;

    position: absolute;
    bottom: -1.7rem;
  `}
`;
