import * as S from './styles';
import * as CXT from 'context/globalContext';

import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import { toast } from 'react-toastify';

import { FaUser } from 'react-icons/fa';
import { IoMdBriefcase } from 'react-icons/io';
import { VscHome } from 'react-icons/vsc';
import firebase from 'firebase/compat/app';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { Header } from 'components/Header';

import { Register } from 'services/register';
import { findData } from 'services/findCEP';
import { Person } from 'services/client';
import { MaritalStatus } from 'services/maritalStatus';
import { Pedido, pedidoForm, pedidoIdForm } from 'services/pedido';
import { PopUpDanger } from './components/PopUpDanger';
import { FormPropsType } from './utils/types/FormPropType/FormPropType';

import {
  EstadoCivilProps,
  ProfissaoProps,
  TipoOcupacaoProps,
} from './utils/personProps';

import convertDataToSubmit, {
  findEnderecoIndex,
} from './utils/convertDataToSubmit';

import 'firebase/analytics';
import { PopUpWarning } from './PopUpWarning';
import { validation } from './utils/validation/validation';
import { returnOpcaoSimulacaoFinanciamento } from './utils/returnObject/returnOpcaoSimulacaoFinanciamento';
import {
  returnsWithCapitalLettersAndWithoutAccent,
  removeWhiteSpace,
} from './utils/formFunctions/returnsWithCapitalLettersAndWithoutAccent';
import { ShowPopUp } from './components/ShowPopup';
import { initialData } from './utils/initialData/intialValuesForForm';
import { ContainerWithInformation } from './components/ComponentWithInformation';
import { TitleWrapperRegister } from './components/TitleWrapperRegister/index';
import { InputsAndSelectsOfBasicInformation } from './components/InputsAndSelectsOfBasicInformation';
import { InputsForInformationAddress } from './components/InputsForInformationAddress';
import { InputsAndSelectsOfProfissionalInformation } from './components/InputsAndSelectsOfProfissionalInformation';
import { ButtonsForm } from './components/ButtonsForm';

Bugsnag.start({
  apiKey: '00621c4c68a1b2bd34e7458c0f8d29bc',
  plugins: [new BugsnagPluginReact()],
});

export default function ClientRegister() {
  const carData = JSON.parse(localStorage.getItem('car') || '{}');
  const parcel = JSON.parse(localStorage.getItem('parcel') || '{}');
  const person = JSON.parse(localStorage.getItem('client') || '{}');
  const vehiclePlate = JSON.parse(localStorage.getItem('vehiclePlate') || '{}');
  const history = useHistory();
  const analytics = firebase.analytics();

  let Car: any;
  if (carData.marcas === undefined || parcel.prazo === undefined) {
    history.push('/client');
  } else {
    Car = carData.marcas[0].linhas[0].versoes[0].modelos[0].veiculos[0];
  }

  const [listaComprovantes, setListaComprovantes] = useState<ProfissaoProps[]>(
    []
  );

  const [listaProfissoes, setListaProfissoes] = useState<ProfissaoProps[]>([]);

  const [maritalStatusList, setMaritalStatusList] = useState<
    EstadoCivilProps[]
  >([] as EstadoCivilProps[]);

  const [ocupationList, setOcupationList] = useState<TipoOcupacaoProps[]>(
    [] as TipoOcupacaoProps[]
  );

  const [ocupationCurrentTagValidation, setOcupationCurrentTagValidation] =
    useState('');

  const [popUpDangerActive, setPopUpDangerActive] = useState<boolean>(false);

  const [tipoOcupacao, setTipoOcupation] = useState(
    person?.referenciasProfissionais?.[0]?.tipoOcupacao || 0
  );

  const [successForm, setSuccessForm] = useState({} as FormPropsType);
  const [errorsForm, setErrorsForm] = useState({} as FormPropsType);

  const globalContext = useContext(CXT.Context);

  let phone: any;

  try {
    phone = person.telefones.find((item: any) => {
      return item.status === 1 && item.preferencia === true;
    });
  } catch {
    history.push('/client');
  }

  const getEmail = () => {
    let aux = '';

    person?.emails?.forEach(
      (value: { email: string; preferencia: boolean; status: number }) => {
        if (value.preferencia === true && value.status === 1) {
          aux = value.email;
        }
      }
    );

    return aux;
  };

  const enderecoIndex = findEnderecoIndex(person?.enderecos);

  const formValuesInitial = initialData(
    person,
    enderecoIndex,
    phone,
    getEmail
  ) as FormPropsType;

  const [initialValues, setInitialValues] =
    useState<FormPropsType>(formValuesInitial);

  const ClearForm = () => {
    setInitialValues(formValuesInitial);
  };

  const fullDateOfBirth = initialValues.aniversario ?? 0;

  const birthdayMonth = Number(fullDateOfBirth.substring(3, 5));
  const yearOfBirth = Number(fullDateOfBirth.substring(6, 10));
  const yearOfValidation = yearOfBirth + 16;

  const [formSubmited, setFormSubmited] = useState(false);

  const fullDateValidationAdmission =
    birthdayMonth >= 10
      ? `${birthdayMonth}/${yearOfValidation}`
      : `0${birthdayMonth}/${yearOfValidation}`;

  let messageErrorValidationDateAdmission = `Data inferior à ${fullDateValidationAdmission}`;

  //ID-327 -> para trocar a mensagem do input basta comentar ou excluir
  //esta linha de número 166 e trocar o let da variável messageErrorValidationDateAdmission para const,
  // e atribuir a ela o valor da mensagem. Caso a mensagem seja definitiva, favor alterar a condição do input no atributo ou propriedade ErrorMessage
  //retornando apenas a variável com a condicional de que se caso ela existir exiba-a, ou seja(variavel && variavel).
  messageErrorValidationDateAdmission = '';

  const handleCheckId = (response: any) => {
    const dataResponse = response.data.data;

    if (dataResponse.status === 'OK') {
      globalContext.setIsLoading(false);
      history.push('/sucess');
    }

    if (dataResponse.status === 'NOK') {
      globalContext.setCodPropostaExistente(dataResponse.codPropostaExistente);

      switch (dataResponse.tipoValidacao) {
        case 'PROPOSTA_PARA_ATUALIZAR':
          globalContext.setPopUpActive([true, false, false, false]);
          globalContext.setIsLoading(false);
          break;
        case 'PROPOSTA_EM_ANALISE':
          globalContext.setPopUpActive([false, true, false, false]);
          globalContext.setIsLoading(false);
          break;
        case 'PROPOSTA_EM_ANDAMENTO':
          globalContext.setPopUpActive([false, false, true, false]);
          globalContext.setIsLoading(false);
          break;
        case 'PROPOSTA_RECUSADA':
          globalContext.setPopUpActive([false, false, false, true]);
          globalContext.setIsLoading(false);
          break;
        case 'LIMITE_PROPOSTAS_EM_ANDAMENTO':
          globalContext.setWarningData({
            title: 'AÇÃO INDISPONÍVEL',
            label: dataResponse.mensagemRetornoHtml ?? '',
            isVehicleName: false,
            isActive: true,
            outOfOrderVehicle: false,
            returnMessageVehicle: '',
          });
          globalContext.setIsLoading(false);
          break;
        default:
          globalContext.setIsLoading(false);
          toast.error(dataResponse.mensagem);
      }
    }
  };

  const handlePostPedido = (response: any) => {
    if (response.data === null) {
      globalContext.setIsLoading(false);
      return;
    }

    let opcoes = null;
    if (parcel && parcel.opcoes?.length > 0) {
      opcoes = parcel.opcoes[0];
    }

    let carOptions = null;
    if (carData && carData.marcas?.length > 0) {
      carOptions =
        carData.marcas[0].linhas[0].versoes[0].modelos[0].veiculos[0];
    }

    if (carOptions !== null && opcoes !== null) {
      const pedido: pedidoForm = {
        cancelarPedidoOriginal: false,
        pessoaClienteId: person.id,
        criarReserva: false,

        opcaoSimulacaoFinanciamentoRequest:
          returnOpcaoSimulacaoFinanciamento(opcoes),
        observacao: '',
        origemPedido: 'PORTAL_LOJISTA',
        valorDespachante: carOptions.valorDespachante,
        valorEntradaAVista: opcoes.valorEntradaAVista,
        valorSpm: 0,
        valorTotalVeiculo: carOptions.preco,
        veiculoEstoqueId: carOptions.veiculoId,
      };
      Pedido.PostPedido(pedido).then((event: any) => {
        const orderId = event.data.data.id;

        localStorage.setItem('PedidoId', orderId);
        // analytics.setUserProperties(globalContext.properties);
        analytics.logEvent('CK_PHASE_THREE', {
          idPedido: orderId,
          cliente: person.cpf,
          placa: Car.plate,
        });
        globalContext.setIdPedido(orderId);

        const form: pedidoIdForm = {
          pedidoId: orderId,
          versaoValidacao: '3',
          codPropostaAtualizar: null,
        };
        globalContext.setPopUpNumPedido(orderId);

        Pedido.PostIDPedidoV2(form)
          .then(handleCheckId)
          .catch((err) => Bugsnag.notify(err))
          .finally(() => globalContext.setIsLoading(false));
      });
    }
  };

  function resumeSubmit(
    formValues: FormPropsType,
    dataMunicipio: any,
    municipioEmpId?: number
  ) {
    const filterList = (
      list: EstadoCivilProps[] | ProfissaoProps[],
      valueInForm: string
    ): EstadoCivilProps[] | ProfissaoProps[] => {
      const filterList = list.filter(
        (posicao) => posicao.descricao === valueInForm
      );

      return filterList;
    };
    const convertToNumber = Number(formValues.atividade);
    formValues.atividade = convertToNumber as unknown as string;

    const updatedJson = convertDataToSubmit(
      formValues,
      filterList(maritalStatusList, formValues.estadoCivil)[0],
      filterList(listaProfissoes, formValues.profissao)[0],
      filterList(listaComprovantes, formValues.comprovacao)[0],
      dataMunicipio,
      municipioEmpId
    );

    const postData = {
      messages: null,
      data: updatedJson,
    };

    Register.Post(postData)
      .then((ev) => {
        handlePostPedido(ev);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        globalContext.setIsLoading(false);
        toast.error('Erro ao atualizar o cliente.');
      });
  }

  async function handleSubmit(formValues: FormPropsType) {
    setFormSubmited(true);

    if (
      listaProfissoes.length > 0 &&
      ocupationList.length > 0 &&
      ocupationCurrentTagValidation
    ) {
      if (
        validation(
          formValues,
          maritalStatusList,
          ocupationList.map((posicao) => Number(posicao.codigo)),
          listaComprovantes,
          ocupationCurrentTagValidation,
          listaProfissoes
        )[2]
      ) {
        globalContext.setIsLoading(true);
      } else {
        setSuccessForm(
          validation(
            formValues,
            maritalStatusList,
            ocupationList.map((posicao) => Number(posicao.codigo)),
            listaComprovantes,
            ocupationCurrentTagValidation,
            listaProfissoes
          )[0]
        );
        setErrorsForm(
          validation(
            formValues,
            maritalStatusList,
            ocupationList.map((posicao) => Number(posicao.codigo)),
            listaComprovantes,
            ocupationCurrentTagValidation,
            listaProfissoes
          )[1]
        );
        return false;
      }
    }

    const bodyBudget = {
      escopoValidacao: 'ORCAMENTO_INICIAR',
      veiculoEstoqueId: vehiclePlate?.id,
      pessoaClienteId: person.id,
    };

    Person.BudgetRequest(bodyBudget).then((response) => {
      const dataBudget = response.data.data;

      if (dataBudget.statusRetorno === 'CLIENTE_IMPEDIDO') {
        globalContext.setIsLoading(false);

        return setPopUpDangerActive(true);
      }

      if (dataBudget.statusRetorno === 'CLIENTE_DESENQUADRADO') {
        globalContext.setIsLoading(false);

        return setPopUpDangerActive(true);
      }

      if (dataBudget.statusRetorno === 'VEICULO_DESENQUADRADO') {
        globalContext.setIsLoading(false);

        return globalContext.setWarningData({
          title: 'AÇÃO INDISPONÍVEL',
          label:
            'Infelizmente não será possível iniciar o orçamento para o veículo:',
          isVehicleName: true,
          isActive: true,
          outOfOrderVehicle: false,
          returnMessageVehicle: dataBudget.veiculoConsultaSituacao ?? '',
        });
      }

      if (dataBudget.statusRetorno === 'VEICULO_DESENQUADRADO_SITUACAO') {
        globalContext.setIsLoading(false);

        return globalContext.setWarningData({
          title: 'AÇÃO INDISPONÍVEL',
          label: 'Infelizmente não será possível solicitar F&I para o veículo:',
          isVehicleName: false,
          isActive: true,
          outOfOrderVehicle: true,
          returnMessageVehicle: dataBudget.veiculoConsultaSituacao ?? '',
        });
      }

      findData
        .GetMunicipio()
        .then((response) => {
          const dataMunicipios = response.data.data;
          const dataCity = dataMunicipios.find(
            (posicao: any) =>
              removeWhiteSpace(
                returnsWithCapitalLettersAndWithoutAccent(posicao.descricao)
              ) ===
                removeWhiteSpace(
                  returnsWithCapitalLettersAndWithoutAccent(formValues.cidade)
                ) &&
              returnsWithCapitalLettersAndWithoutAccent(
                posicao.codigoEstado
              ) === returnsWithCapitalLettersAndWithoutAccent(formValues.uf)
          );
          const newDataMunucipioForSendResumeSubmit = {
            id: dataCity.id,
            descricao: dataCity.descricao,
            codigoEstado: dataCity.codigoEstado,
          };

          if (!dataCity) {
            formValues.cidade = '';
            formValues.uf = '';
            throw new Error();
          } else {
            const cityClient = removeWhiteSpace(
              returnsWithCapitalLettersAndWithoutAccent(dataCity.descricao)
            );

            const ufClient = returnsWithCapitalLettersAndWithoutAccent(
              dataCity.codigoEstado
            );

            const validCity =
              removeWhiteSpace(
                returnsWithCapitalLettersAndWithoutAccent(formValues.cidade)
              ) ===
              removeWhiteSpace(
                returnsWithCapitalLettersAndWithoutAccent(cityClient)
              );

            if (!validCity) {
              formValues.cidade = '';
              throw new Error();
            }

            const validUf = formValues.uf === ufClient;

            if (!validUf) {
              formValues.uf = '';
              throw new Error();
            }

            if (ocupationCurrentTagValidation.match('rp_municipio')) {
              findData
                .GetMunicipio()
                .then((response) => {
                  const newArrayWithCitys = response.data.data;
                  const dataMunicipioEmpresa = newArrayWithCitys.find(
                    (dataMunicipios: any) =>
                      removeWhiteSpace(
                        returnsWithCapitalLettersAndWithoutAccent(
                          dataMunicipios.descricao
                        )
                      ) ===
                        removeWhiteSpace(
                          returnsWithCapitalLettersAndWithoutAccent(
                            formValues.cidadeEmpresa
                          )
                        ) &&
                      returnsWithCapitalLettersAndWithoutAccent(
                        dataMunicipios.codigoEstado
                      ) ===
                        returnsWithCapitalLettersAndWithoutAccent(
                          formValues.ufEmpresa
                        )
                  );

                  if (!dataMunicipioEmpresa) {
                    formValues.cidadeEmpresa = '';
                    formValues.ufEmpresa = '';
                    throw new Error();
                  } else {
                    const cidadeEmpresa = removeWhiteSpace(
                      returnsWithCapitalLettersAndWithoutAccent(
                        dataMunicipioEmpresa.descricao
                      )
                    );

                    const stateEmpresa =
                      returnsWithCapitalLettersAndWithoutAccent(
                        dataMunicipioEmpresa.codigoEstado
                      );

                    const validCityEmpresa =
                      removeWhiteSpace(
                        returnsWithCapitalLettersAndWithoutAccent(
                          formValues.cidadeEmpresa
                        )
                      ) ===
                      removeWhiteSpace(
                        returnsWithCapitalLettersAndWithoutAccent(cidadeEmpresa)
                      );
                    if (!validCityEmpresa) {
                      formValues.cidadeEmpresa = '';
                      throw new Error();
                    }

                    const validUfEmpresa =
                      formValues.ufEmpresa === stateEmpresa;

                    if (!validUfEmpresa) {
                      formValues.ufEmpresa = '';
                      throw new Error();
                    }

                    if (validCityEmpresa && validUfEmpresa) {
                      resumeSubmit(
                        formValues,
                        newDataMunucipioForSendResumeSubmit,
                        dataMunicipioEmpresa.id
                      );
                    }
                  }
                })
                .catch((err) => {
                  const validCepEmpresaCityEmpresaAndUfEmpresa = validation(
                    formValues,
                    maritalStatusList,
                    ocupationList.map((posicao) => Number(posicao.codigo)),
                    listaComprovantes,
                    ocupationCurrentTagValidation,
                    listaProfissoes
                  );
                  Bugsnag.notify(err);
                  toast.error(
                    'Referencia Profissional: Cidade ou UF inexistente'
                  );
                  setSuccessForm(validCepEmpresaCityEmpresaAndUfEmpresa[0]);
                  setErrorsForm(validCepEmpresaCityEmpresaAndUfEmpresa[1]);
                  globalContext.setIsLoading(false);
                });
            } else {
              formValues.cepEmpresa = '';
              const validCepEmpresaCityEmpresaAndUfEmpresa = validation(
                formValues,
                maritalStatusList,
                ocupationList.map((posicao) => Number(posicao.codigo)),
                listaComprovantes,
                ocupationCurrentTagValidation,
                listaProfissoes
              );

              if (!validCepEmpresaCityEmpresaAndUfEmpresa[2]) {
                toast.error('Referencia Profissional: Cep inexistente');
                setSuccessForm(validCepEmpresaCityEmpresaAndUfEmpresa[0]);
                setErrorsForm(validCepEmpresaCityEmpresaAndUfEmpresa[1]);
                globalContext.setIsLoading(false);
              } else {
                resumeSubmit(formValues, newDataMunucipioForSendResumeSubmit);
                setSuccessForm(validCepEmpresaCityEmpresaAndUfEmpresa[0]);
                setErrorsForm(validCepEmpresaCityEmpresaAndUfEmpresa[1]);
              }
            }
          }
        })
        .catch((err) => {
          const validFormCityAndUf = validation(
            formValues,
            maritalStatusList,
            ocupationList.map((posicao) => Number(posicao.codigo)),
            listaComprovantes,
            ocupationCurrentTagValidation,
            listaProfissoes
          );

          setSuccessForm(validFormCityAndUf[0]);
          setErrorsForm(validFormCityAndUf[1]);

          toast.error('Endereço Pessoal: Cidade ou UF inexistente');
          Bugsnag.notify(err);
          globalContext.setIsLoading(false);
        });

      // END - CODIGO ALTERNATIVO
    });
  }

  async function getOptions() {
    globalContext.setIsLoading(true);
    ClearForm();
    const response = await MaritalStatus.Get();
    if (!response) {
      return;
    }
    setMaritalStatusList(response.data.data);

    findData
      .GetTipoOcupação()
      .then((response) => {
        setOcupationList(response.data.data);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        setOcupationList([
          {
            codigo: '1',
            descricao: 'Funcionário de Iniciativa Privada',
            abreviacao: '1',
            tagValidacao:
              '#renda##rp_nome_empresa##rp_municipio##rp_telefone##rp_admissao##profissao#',
          },
          {
            codigo: '10',
            descricao: 'Estudante',
            abreviacao: '10',
            tagValidacao: '#renda#',
          },
        ]);
      });

    Person.JobsRequest()
      .then((response: any) => {
        setListaProfissoes(response.data.data);
      })
      .catch((err) => {
        Bugsnag.notify(err);
      });

    Person.ComprovantRequest()
      .then((response: any) => {
        setListaComprovantes(response.data.data);
      })
      .catch((err) => {
        Bugsnag.notify(err);
      });

    globalContext.setIsLoading(false);
  }

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    if (tipoOcupacao && ocupationList) {
      const arrayPositionOcupation = ocupationList.filter(
        (posicao) => Number(posicao.codigo) === Number(tipoOcupacao)
      );
      if (arrayPositionOcupation.length > 0) {
        setOcupationCurrentTagValidation(
          arrayPositionOcupation[0].tagValidacao
        );
      }
    }
  }, [tipoOcupacao, ocupationList]);

  const validOnChangeAfterError = (
    campSelect: string,
    eventTargetValue: string,
    values: FormPropsType,
    listaDeProfissao: ProfissaoProps[]
  ) => {
    if (
      formSubmited &&
      eventTargetValue !== undefined &&
      ocupationCurrentTagValidation
    ) {
      const array = Object.entries(values);
      const arrayWithIndexOneOfArray = array.map((posicao) => posicao[0]);

      if (campSelect === 'mesAnoAdmissao') {
        const newFormatForCampSelect = eventTargetValue.replace('/', '');
        eventTargetValue = newFormatForCampSelect;
      }

      const indexForChange = arrayWithIndexOneOfArray.indexOf(campSelect);
      array[indexForChange][1] = eventTargetValue;

      const changeValueInArray = Object.fromEntries(array);
      const newObjectWithValues = changeValueInArray as FormPropsType;

      values = newObjectWithValues;

      const valid = validation(
        values,
        maritalStatusList,
        ocupationList.map((posicao) => Number(posicao.codigo)),
        listaComprovantes,
        ocupationCurrentTagValidation,
        listaDeProfissao
      );

      setSuccessForm(valid[0]);

      setErrorsForm(valid[1]);
    }
  };

  return (
    <>
      <Header onRefreshClick={getOptions} />
      <S.Wrapper>
        <TitleWrapperRegister />
        <Formik
          initialValues={initialValues}
          onSubmit={(values: FormPropsType) => handleSubmit(values)}
          enableReinitialize={true}
        >
          {({ touched, values, setFieldValue }: FormikProps<FormPropsType>) => (
            <Form>
              <S.Content>
                <S.Left>
                  <S.BasicInfo>
                    <ContainerWithInformation
                      Icon={FaUser}
                      text={'Informações Básicas do Cliente'}
                      cpf={values.cpf}
                    />
                    <InputsAndSelectsOfBasicInformation
                      values={values}
                      errorsForm={errorsForm}
                      successForm={successForm}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      maritalStatusList={maritalStatusList}
                    />
                  </S.BasicInfo>
                  <S.AddressContact>
                    <ContainerWithInformation
                      Icon={VscHome}
                      text={'Endereço e contato'}
                    />

                    <InputsForInformationAddress
                      values={values}
                      maritalStatusList={maritalStatusList}
                      listaComprovantes={listaComprovantes}
                      errorsForm={errorsForm}
                      successForm={successForm}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      validOnChangeAfterError={validOnChangeAfterError}
                      listaProfissoes={listaProfissoes}
                      formSubmited={formSubmited}
                      ocupationCurrentTagValidation={
                        ocupationCurrentTagValidation
                      }
                      ocupationList={ocupationList}
                      setSuccessForm={setSuccessForm}
                      setErrorsForm={setErrorsForm}
                    />
                  </S.AddressContact>
                </S.Left>
                <S.Right>
                  <S.ProfessionalInfo>
                    <ContainerWithInformation
                      Icon={IoMdBriefcase}
                      text={'Informações Profissionais'}
                    />
                    <InputsAndSelectsOfProfissionalInformation
                      values={values}
                      maritalStatusList={maritalStatusList}
                      errorsForm={errorsForm}
                      successForm={successForm}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      validOnChangeAfterError={validOnChangeAfterError}
                      listaProfissoes={listaProfissoes}
                      formSubmited={formSubmited}
                      ocupationCurrentTagValidation={
                        ocupationCurrentTagValidation
                      }
                      ocupationList={ocupationList}
                      setSuccessForm={setSuccessForm}
                      setErrorsForm={setErrorsForm}
                      listaComprovantes={listaComprovantes}
                      messageErrorValidationDateAdmission={
                        messageErrorValidationDateAdmission
                      }
                      setFormSubmited={setFormSubmited}
                      setTipoOcupation={setTipoOcupation}
                    />
                  </S.ProfessionalInfo>

                  {globalContext.isLoading ? '' : <ButtonsForm />}
                </S.Right>
              </S.Content>
            </Form>
          )}
        </Formik>
        {popUpDangerActive && (
          <PopUpDanger setPopUpDangerActive={setPopUpDangerActive} />
        )}
      </S.Wrapper>
      {globalContext.warningData.isActive && (
        <PopUpWarning
          title={globalContext.warningData.title}
          label={globalContext.warningData.label}
          isVehicleName={globalContext.warningData.isVehicleName}
          setWarningData={globalContext.setWarningData}
          outOfOrderVehicle={globalContext.warningData.outOfOrderVehicle}
          returnMessageVehicle={globalContext.warningData.returnMessageVehicle}
        />
      )}
      <ShowPopUp handleCheckId={handleCheckId} />
    </>
  );
}
