import { FormPropsType } from '../types/FormPropType/FormPropType';

export const validationMesAdmission = (values: FormPropsType): boolean => {
  const birthDate = values.aniversario;

  const yearOfValidation = birthDate && Number(birthDate.substring(6, 10)) + 16;

  const birthdayMonth = birthDate && Number(birthDate.substring(3, 5));

  const monthAdmission = Number(values.mesAnoAdmissao.substring(0, 2));

  const yearOfAdmission = Number(values.mesAnoAdmissao.substring(2, 6));

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const year1900 = 1900;

  if (monthAdmission <= 0 || monthAdmission > 12) return false;

  if (
    yearOfAdmission < currentYear &&
    yearOfAdmission >= year1900 &&
    yearOfAdmission === yearOfValidation &&
    monthAdmission >= birthdayMonth
  ) {
    return true;
  }

  if (
    yearOfAdmission < currentYear &&
    yearOfAdmission >= year1900 &&
    yearOfAdmission > yearOfValidation &&
    monthAdmission > 0 &&
    monthAdmission <= 12
  ) {
    return true;
  }

  if (
    yearOfAdmission === currentYear &&
    yearOfAdmission >= year1900 &&
    yearOfAdmission === yearOfValidation &&
    monthAdmission >= birthdayMonth &&
    monthAdmission <= currentMonth
  ) {
    return true;
  }

  if (
    yearOfAdmission === currentYear &&
    yearOfAdmission >= year1900 &&
    yearOfAdmission > yearOfValidation &&
    monthAdmission <= currentMonth
  ) {
    return true;
  }

  return false;
};
