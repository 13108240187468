import styled, { css, DefaultTheme } from 'styled-components';

//#####################################################################################################

type ColorProps = {
  ColorTheme: 'green' | 'red' | 'orange' | 'blue' | 'gray';
};

const ColorModifier = {
  green: (theme: DefaultTheme) => css`
    border-color: ${theme.colors.green};
    color: ${theme.colors.green};
  `,
  red: (theme: DefaultTheme) => css`
    border-color: ${theme.colors.red};
    color: ${theme.colors.red};
  `,
  orange: (theme: DefaultTheme) => css`
    border-color: ${theme.colors.yellow};
    color: ${theme.colors.yellow};
  `,
  blue: (theme: DefaultTheme) => css`
    border-color: ${theme.colors.blueSecondary};
    color: ${theme.colors.blueSecondary};
  `,
  gray: (theme: DefaultTheme) => css`
    border-color: ${theme.colors.gray};
    color: ${theme.colors.graySecond};
  `,
};

//#####################################################################################################

export const Wrapper = styled.div`
  position: relative;
  right: 15px;
  display: flex;
  flex-direction: column;
  color: black;

  margin-top: 5px;
`;

//#####################################################################################################

export const ImgBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 19px;
  margin-bottom: 2px;
`;

export const LabelBar = styled.div`
  display: flex;
  flex-direction: row;
`;

//#####################################################################################################

export const IconPlusBarBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
`;

export const StatusText = styled.label<ColorProps>`
  ${({ theme, ColorTheme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 69.2px;
    margin-right: 0px;
    font-size: ${theme.font.sizes.xxx_r_small};
    font-weight: 300;
    color: ${theme.colors.grayThird};

    span {
      font-size: ${theme.font.sizes.xxx_r_small};
      ${!!ColorTheme && ColorModifier[ColorTheme](theme)}
      font-weight: 500;
    }
  `};
`;

export const StatusTextBox = styled.div`
  display: flex;
  flex-direction: column;
`;

//#####################################################################################################

export const HorizontalBar = styled.div<ColorProps>`
  ${({ theme, ColorTheme }) => css`
    width: 35px;
    height: 0px;
    border-bottom: 3px solid;
    border-radius: 15px;
    margin-right: 6px;

    ${!!ColorTheme && ColorModifier[ColorTheme](theme)}
  `}
`;
