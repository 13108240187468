import * as S from './styles';
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Input from 'components/Input';
import MaskedInput from 'components/MaskedInput';
import Button from 'components/Button';
import Logo from 'assets/images/logotipo_blue.jpg';
import { mask, unMask } from 'utils/mask';

import { Authenticate, dataForm } from 'services/authenticate';
import * as CXT from 'context/globalContext';

import { Formik } from 'formik';
import * as Yup from 'yup';
import 'firebase/analytics';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import apiKeyCloak from 'api/api-keycloak';
import apiAppdsv from 'api/api-appdsv';
import apiCar from 'api/api-car';
import jwt_decode from 'jwt-decode';
import { User } from 'services/user';
import { PopUpDanger } from 'pages/Client/components/PopUpDanger';
import { DangerType } from 'pages/Client';
import { CredentialType, TokenDataType } from './types/typesLogin';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { getDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db, fetchToken } from 'firebase';
import { Person } from 'services/client';

Bugsnag.start({
  apiKey: '00621c4c68a1b2bd34e7458c0f8d29bc',
  plugins: [new BugsnagPluginReact()],
});

const SignupSchema = Yup.object().shape({
  username: Yup.string()
    .matches(/[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/, 'declined')
    .required('Required'),
  password: Yup.string().required('Required'),
});

export default function Login() {
  const globalContext = useContext(CXT.Context);
  const history = useHistory();
  const [dangerData, setDangerData] = useState<DangerType>({
    isActive: false,
    label: '',
  });

  const [isTokenFound, setIsTokenFound] = useState<string>('');
  const [notificationPermission, setNotificationPermission] = useState(false);
  const refPermissionActive = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // globalContext.HandleLogout();
  }, []);

  function firebaseAuthAndRegisterTokenFCM() {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(async (credentialsFirebase) => {
        const uuid = credentialsFirebase.user.uid;
        const objectForSendLoginToBackend = {
          uuid,
          tipoDispositivo: 'W',
          versao: '',
        };

        Person.SendUUID(objectForSendLoginToBackend);

        const docFirebaseRef = doc(db, 'users', uuid);
        const docSnap = await getDoc(docFirebaseRef);

        if (docSnap.exists()) {
          const arrayTokens = docSnap.data().registrationTokens;

          if (Array.isArray(arrayTokens)) {
            if (arrayTokens.includes(isTokenFound) === false) {
              arrayTokens.push(isTokenFound);
              await updateDoc(docFirebaseRef, {
                registrationTokens: arrayTokens,
              });
            }
          } else {
            const arrayWithTokensFCM = Array(arrayTokens);
            if (arrayWithTokensFCM.includes(isTokenFound) === false) {
              arrayWithTokensFCM.push(isTokenFound);
              await updateDoc(docFirebaseRef, {
                registrationTokens: arrayWithTokensFCM,
              });
            }
          }
        } else {
          await setDoc(doc(db, 'users', uuid), {
            registrationTokens: [isTokenFound],
          });
        }
      })
      .catch((error) => {
        if (error) {
          globalContext.HandleLogout();
          globalContext.setIsLoading(false);
          globalContext.setAuthenticated(false);
          return toast.error(
            'Opss... Tivemos problemas ao autenticar seus dados.'
          );
        }
      });
  }

  async function HandleLogin(tokenData: TokenDataType) {
    const emptyToken = tokenData === undefined;
    if (emptyToken) return;

    globalContext.setActiveTab(2);

    const acessTokenFull = `Bearer ${tokenData.access_token}`;

    apiKeyCloak.defaults.headers.Authorization = acessTokenFull;
    apiAppdsv.defaults.headers.Authorization = acessTokenFull;
    apiCar.defaults.headers.Authorization = acessTokenFull;

    localStorage.setItem('tokenData', JSON.stringify(tokenData));
    const credentialUser: CredentialType = jwt_decode(tokenData.access_token);

    if (isTokenFound) {
      await firebaseAuthAndRegisterTokenFCM();
    }

    localStorage.setItem('user', JSON.stringify(credentialUser));

    User.UserRequest(credentialUser.cpf)
      .then(({ data }) => {
        const user = data;

        localStorage.setItem(
          'CNPJLojista',
          JSON.stringify(user.data.cpfCnpjLojista)
        );

        localStorage.setItem('userData', JSON.stringify(user.data));

        globalContext.setAuthenticated(true);

        const salesTrackingPermission =
          user.data.permissao.findIndex(
            (permission: string) => permission === 'ACOMPANHAMENTO_VENDAS_WEB'
          ) >= 0;

        if (salesTrackingPermission)
          globalContext.setNumTab(globalContext.numTab + 1);

        const props = {
          cpf: unMask(credentialUser.cpf, '[^0-9]') ?? 'vazio',
          user: credentialUser.name ?? 'vazio',
          rede: credentialUser.company ?? 'vazio',
          departmento: credentialUser.department ?? 'vazio',
          // loja: Company.codigo,
        };

        const isUserLocked = user.data.situacaoLogin === 'B';

        if (isUserLocked) {
          setDangerData({
            isActive: true,
            label: user.data.mensagemLogin,
          });

          globalContext.HandleLogout();
          globalContext.setIsLoading(false);
          globalContext.setAuthenticated(false);
          return;
        }

        User.CompanyRequest()
          .then((ev) => {
            globalContext.HandleCompanies(ev, user.data, props);
            history.push('/client');
            globalContext.setIsLoading(false);
          })
          .catch((err) => Bugsnag.notify(err));
      })
      .catch((err) => {
        toast.error(
          '=( Não foi possível realizar o login no sistema. \nEntre em contato com o suporte!'
        );
        Bugsnag.notify(err);
        globalContext.setAuthenticated(false);
        globalContext.setIsLoading(false);
      });
  }

  const HandleSubmit = (values: any) => {
    const unmaskedValues: dataForm = {
      ...values,
      username: unMask(values.username, '\\.|-'),
    };

    globalContext.setIsLoading(true);
    Authenticate.SignInRequest(unmaskedValues)
      .then((ev: any) => {
        Bugsnag.setUser(unmaskedValues.username);
        HandleLogin(ev.data);
        // history.push('/client');
      })
      .catch((ev) => {
        Bugsnag.notify(ev);
        toast.error('Login ou Senha incorretos');
        globalContext.HandleLogout();
        globalContext.setIsLoading(false);
      });
  };

  async function requestPermission() {
    setNotificationPermission(true);
    const permission = await Notification.requestPermission();

    if (permission === 'denied') {
      toast.warning(
        'Infelizmente não será possível enviar novas notificações.'
      );
    }
    if (permission === 'granted') {
      toast.success('As notificações estão ativadas.');
      fetchToken(setIsTokenFound);
    }
  }

  return (
    <S.Wrapper
      style={{
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {!notificationPermission && (
        <div className="notificationActive">
          <button ref={refPermissionActive} onClick={requestPermission}>
            Vamos ativar as notificações?
          </button>
        </div>
      )}
      {dangerData.isActive && (
        <PopUpDanger
          label={dangerData.label}
          setDangerData={setDangerData}
          blockedStoreText={true}
        />
      )}
      <S.GridRight>
        <S.GridRightData>
          <S.Image src={Logo} />
          {/* <S.Title>
            <span>Bem vindo ao SIN!</span>
            Portal Lojistas Sinosserra
          </S.Title> */}
          <Formik
            initialValues={{ username: '', password: '' }}
            validationSchema={SignupSchema}
            onSubmit={async (values) => HandleSubmit(values)}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <S.FormLogin>
                <S.InputBox>
                  <MaskedInput
                    name="username"
                    title="CPF do usuário"
                    width="100%"
                    value={values.username}
                    MaskType={'cpf'}
                    maxInputLength={14}
                    inputMethod="numeric"
                    error={!!errors.username && !!touched.username}
                    valid={
                      !!values.username &&
                      !!touched.username &&
                      !errors.username
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue(
                        'username',
                        mask(
                          unMask(e.target.value, '\\.|-'),
                          '###.###.###-##',
                          true
                        )
                      );
                    }}
                  />
                </S.InputBox>
                <S.InputBox>
                  <Input
                    name="password"
                    title="Senha"
                    type="password"
                    width="100%"
                    error={!!errors.password && !!touched.password}
                    valid={
                      !!values.password &&
                      !!touched.password &&
                      !errors.password
                    }
                    value={values.password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('password', e.target.value);
                    }}
                  />
                </S.InputBox>
                <Button type="submit">ENTRAR</Button>
              </S.FormLogin>
            )}
          </Formik>
          <S.GridRightButtonBox>
            <S.Text href={process.env.REACT_APP_RECOVERY_URL}>
              Esqueci minha senha.
            </S.Text>
            <S.Text href="https://forms.office.com/Pages/ResponsePage.aspx?id=g5A5b86nC0WaSkkaIhKIVjZx9JJ-KohOjMEVpKsezC1UMzhFUk9OREZKTUw5NTU0M0MwR0c1SExCWC4u">
              Não tenho acesso ao sistema.
            </S.Text>
          </S.GridRightButtonBox>
        </S.GridRightData>
      </S.GridRight>
    </S.Wrapper>
  );
}
