import * as FaIcons from 'react-icons/fa';
import {
  IconWrapper,
  InfoOne,
  InfoTwo,
  StatusLabelWrapper,
  StatusWrapper,
  TabNameWrapper,
} from './styles';

const AguardandoAtualizacao = () => {
  return (
    <>
      <StatusWrapper>
        <IconWrapper>
          <FaIcons.FaHourglassEnd />
        </IconWrapper>
        <StatusLabelWrapper>
          AGUARDANDO <br /> ATUALIZAÇÃO
        </StatusLabelWrapper>
      </StatusWrapper>
      <InfoTwo>
        O vencimento padrão das parcelas foi alterado, e o contrato está sendo
        atualizado.
      </InfoTwo>

      <InfoOne>Acompanhe o andamento na aba de:</InfoOne>
      <TabNameWrapper>APROVACÃO DE CRÉDITO</TabNameWrapper>
    </>
  );
};

export default AguardandoAtualizacao;
