import { ButtonHTMLAttributes } from 'react';
import { TailSpin } from 'react-loader-spinner';

import * as S from './styles';

import * as CXT from 'context/globalContext';
import { useContext } from 'react';
import Loading from 'components/Loading';

export type WrapperProps = {
  height?: string;
  width?: string;
  buttonTheme?: 'active' | 'goback' | 'secondary' | 'clean';
  marginTop?: string;
};
type ButtonProps = WrapperProps &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    loading?: boolean;
  };

const Button = ({
  children,
  type,
  width,
  height,
  marginTop,
  disabled,
  loading,
  onClick,
  buttonTheme = 'active',
  ...props
}: ButtonProps) => {
  const globalContext = useContext(CXT.Context);

  return (
    <S.Wrapper
      type={type}
      width={width}
      height={height}
      buttonTheme={buttonTheme}
      // disabled={globalContext.isLoading}
      marginTop={marginTop}
      onClick={onClick}
      disabled={disabled || loading}
      {...props}
    >
      {(globalContext.isLoading && buttonTheme === 'active') || loading ? (
        <Loading size={24} />
      ) : (
        !!children && children
      )}
    </S.Wrapper>
  );
};

export default Button;
