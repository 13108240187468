import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 26rem;
  margin-top: 3rem;
  position: relative;
`;

export const SkelBox = styled.div`
  margin-bottom: 5px;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    font-family: 'Roboto';
    color: ${theme.colors.blackTertiary};
    font-weight: 500;
    font-size: ${theme.font.sizes.xxx_small};
    margin-bottom: 1rem;
    padding-left: 8px;
  `}
`;
type SelectProps = {
  width?: string;
  height?: string;
  error?: boolean;
  theme: DefaultTheme;
  selectTheme?: 'default' | 'valid' | 'invalid';
  valid?: boolean;
};
const SelectModifiers = {
  valid: (theme: DefaultTheme) => css`
    border-bottom: solid 1px ${theme.colors.secondGreen};
  `,
  invalid: (theme: DefaultTheme) => css`
    border-bottom: solid 1px ${theme.colors.red};
  `,
  default: (theme: DefaultTheme) => css`
    border-bottom: 1px solid ${theme.colors.gray};
  `,
};

export const Select = styled.select`
  ${({
    theme,
    width = '100%',
    height = '100%',
    selectTheme = 'default',
    error,
    valid,
  }: SelectProps) => css`
    border: 0px none;
    background: ${theme.colors.white};
    border-bottom: 1px solid ${theme.colors.gray};
    appearance: none;
    width: ${width};
    height: ${height};
    line-height: 1.21;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    padding-left: 9px;
    padding-bottom: 3px;

    .selected {
      font-size: 13px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
    ${!!selectTheme && SelectModifiers[selectTheme](theme)}
    ${!!error && SelectModifiers['invalid'](theme)}
    ${!!valid && SelectModifiers['valid'](theme)}

    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
    ${media.lessThan('small')`
    width: 100%;
    `}
  `}
`;

export const Icon = styled.div`
  ${({ theme }: SelectProps) => css`
    background-color: ${theme.colors.white};
    max-width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-right: -3px;
    right: 0px;
    bottom: 0.2rem;
    pointer-events: none;

    svg {
      color: #2a99fb;
    }

    @media (min-width: 1600px) {
      right: 0.5rem;
      max-width: 6rem;
    }
  `}
`;

export const Option = styled.option`
  color: #555;
  font-family: Roboto;
  color: red;
`;
export const MsgError = styled.p`
  ${({ theme }) => css`
    margin-top: 5.5px;
    color: ${theme.colors.red};
    font-size: 9px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    text-transform: lowercase;

    position: absolute;
    bottom: -1.7rem;
  `}
`;
