import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  position: absolute;

  width: 100vw;
  height: 100vh;
`;

export const BlurWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
`;

export const PopUpBody = styled.div`
  ${({ theme }) => css`
    max-width: 600px;
    height: 450px;
    width: 100%;
    padding: 15px;
    padding-block: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    background-color: ${theme.colors.white};
  `}
`;

export const PopUpTitleBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 7%;
    background-color: ${theme.colors.white};
    /* border: 1px solid; */
  `}
`;

export const PopUpSubTitleBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: normal;
    width: 100%;
    height: 7%;
    background-color: ${theme.colors.white};
  `}
`;

export const PopUpMainBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 73%;
    background-color: ${theme.colors.white};
    label {
      color: ${theme.colors.orange};
      font-size: ${theme.font.sizes.x_small};
      text-align: center;
      line-height: 22px;
    }
    ${media.lessThan('small')`
      flex-direction: column;
      height: 58%;
    `}
  `}
`;

export const PopUpButtonBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 14%;
    background-color: ${theme.colors.white};

    ${media.lessThan('small')`
      flex-direction: column;
      height: 30%;
    `}
  `}
`;

export const TitleLabel = styled.label`
  ${({ theme }) => css`
    font-family: 'Roboto';
    color: ${theme.colors.graySecond};
    font-weight: 500;
    font-size: ${theme.font.sizes.medium};
    /* margin-bottom: 1rem; */
    height: 14px;
  `}
`;

export const SubTitleLabel = styled.label`
  ${({ theme }) => css`
    font-family: 'Roboto';
    color: ${theme.colors.blueSecondary};
    font-weight: 500;
    font-size: ${theme.font.sizes.medium};
    /* margin-bottom: 1rem; */
    height: 14px;
  `}
`;

export const HorizontalBar = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 2px;
    border-bottom: 1px solid ${theme.colors.gray};
  `}
`;
