import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

import { CheckBox } from 'components/CheckBox';
import { SearchBar } from 'components/SearchBar';

import { SelectFilter } from '../SelectFilter';

import { mockStatus } from '../../mockStatus';
import { FilterParamsType } from '../../';

import { User } from 'services/user';

import * as Styled from './styles';
import * as CTX from 'context/globalContext';

export type FilterValuesType = {
  dateValue: string;
  creditValue: string;
  stageValue: string;
  paydayValue: string;
  storeValue: string[];
  agentValue: string;
  paid: boolean;
  canceled: boolean;
};

type AvailableStoreType = {
  codigo: number;
  descricao: string;
};

type FilterType = {
  dateTarget: string;
  setFilterParams: React.Dispatch<React.SetStateAction<FilterParamsType>>;
};

export const Filter = ({ dateTarget, setFilterParams }: FilterType) => {
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const [lastNameSearched, setLastNameSearched] = useState('');

  const globalContext = useContext(CTX.Context);

  const [filterValues, setFilterValues] = useState<FilterValuesType>({
    dateValue: 'Últimos 15 dias',
    creditValue: '',
    stageValue: 'Todas (exceto canceladas)',
    paydayValue: '',
    storeValue: [],
    agentValue: '',
    paid: false,
    canceled: false,
  });
  const [dateStatus, setDateStatus] = useState<string[]>([]);
  const [storeStatus, setStoreStatus] = useState<string[]>([]);
  const [availableStore, setAvailableStore] = useState<AvailableStoreType[]>(
    []
  );
  const [agentStatus, setAgentStatus] = useState<string[]>([]);

  // dateTarget
  // setFilterParams

  const handleNameSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value != lastNameSearched) {
      setLastNameSearched(event.target.value);
      return setFilterParams((oldState) => {
        return {
          ...oldState,
          nameSearch: `textoPesquisa=all="*${
            event.target.value
              ?.toUpperCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '') ?? ''
          }*";`,
        };
      });
      //setFilterParams((oldState) => ({ ...oldState, nameSearch: `` }));
    }
  };

  const handleChangeDate = (item: string, name: string) => {
    const value = mockStatus.dateOrderData.find(
      (element) => element.label === item
    );

    if (value && value.date != null) {
      dateTarget = format(
        new Date().setDate(new Date().getDate() - value.date),
        'dd-MM-yyyy'
      );
    } else {
      dateTarget = '';
    }

    if (name === 'dateValue' && dateTarget === '') {
      setFilterParams((oldState) => ({
        ...oldState,
        [name]: '',
      }));
    } else if (name === 'dateValue') {
      setFilterParams((oldState) => ({
        ...oldState,
        [name]: `dataInclusaoPedido=ge=${dateTarget};`,
      }));
    } else {
      setFilterParams((oldState) => ({
        ...oldState,
        [name]: `dataPagamento=ge=${dateTarget};`,
      }));
    }
  };

  const handleChangeCredit = (item: string, name: string) => {
    setFilterParams((oldState) => ({
      ...oldState,
      [name]: `decisaoCredito=="${item.toUpperCase()}";`,
    }));
  };

  const handleChangeStage = (item: string, name: string) => {
    if (item === mockStatus.stageStatus[0]) {
      return setFilterParams((oldState) => ({
        ...oldState,
        [name]: '',
      }));
    }

    if (item.includes('canceladas')) {
      return setFilterParams((oldState) => ({
        ...oldState,
        [name]: 'etapaDescricao!="CANCELADO";',
      }));
    }

    setFilterParams((oldState) => ({
      ...oldState,
      [name]: `etapaDescricao=="${item.toUpperCase()}";`,
    }));
  };

  const handleChangeStore = (item: string, name: string) => {
    if (filterValues.storeValue.length === 0) {
      return setFilterParams((oldState) => ({
        ...oldState,
        [name]: '',
      }));
    }

    const cods: any[] = [];

    filterValues.storeValue.forEach((element) => {
      const cod = availableStore.find(
        (store) => element === store.descricao
      )?.codigo;

      cods.push(cod);
    });

    setFilterParams((oldState) => ({
      ...oldState,
      [name]: `empresaId=in=(${[...cods].join(',')});`,
    }));
  };

  const handleChangeAgent = (item: string, name: string) => {
    setFilterParams((oldState) => ({
      ...oldState,
      [name]: `agenteNome=="${item.toUpperCase()}";`,
    }));
  };

  const handleCleanUp = () => {
    dateTarget = format(
      new Date().setDate(new Date().getDate() - 15),
      'dd-MM-yyyy'
    );

    setFilterParams({
      nameSearch: '',
      dateValue: `dataInclusaoPedido=ge=${dateTarget};`,
      creditValue: '',
      stageValue: 'etapaDescricao!="CANCELADO";',
      paydayValue: '',
      storeValue: '',
      agentValue: '',
      conditionValue: '',
    });

    globalContext.setInputValueFetchExists(true);

    setFilterValues({
      dateValue: 'Últimos 15 dias',
      creditValue: '',
      stageValue: 'Todas (exceto canceladas)',
      paydayValue: '',
      storeValue: [],
      agentValue: '',
      paid: false,
      canceled: false,
    });
  };

  const handleChangeCheck = (name: string, value: boolean) => {
    setFilterValues((oldState) => ({
      ...oldState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (filterValues.canceled) {
      setFilterParams((oldState) => ({
        ...oldState,
        stageValue: 'etapaDescricao=="CANCELADO";',
      }));
    }

    if (filterValues.paid) {
      setFilterParams((oldState) => ({
        ...oldState,
        conditionValue: "situacaoDescricao=in=('FATURADO', 'CONTRATO PAGO');",
      }));
    } else {
      setFilterParams((oldState) => ({
        ...oldState,
        conditionValue: '',
      }));
    }
  }, [filterValues.paid, filterValues.canceled]);

  useEffect(() => {
    mockStatus.dateOrderData.map((item) => {
      setDateStatus((oldState) => [...oldState, item.label]);
    });

    User.CompanyListRequest()
      .then((response) => {
        const availableStoreData: any[] = [];
        const storeData: string[] = [];

        const agentData: string[] = [];

        response.data.data?.[0]?.lojaAgrupamentoResponse?.forEach(
          (element: any) => {
            element.lojas.forEach((element: any) => {
              availableStoreData.push({
                codigo: element.codigo,
                descricao: element.descricao,
              });

              storeData.push(element.descricao);

              if (element.nomeAgente) {
                if (agentData.find((item) => item === element.nomeAgente))
                  return;

                agentData.push(element.nomeAgente);
              }
            });
          }
        );

        setStoreStatus(storeData);
        setAvailableStore(availableStoreData);
        setAgentStatus(agentData);
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500)
          toast.error('Erro ao consultar o servidor.');
        else
          toast.error(
            'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Tente novamente mais tarde e se o problema persistir contate o suporte!'
          );
      });
  }, []);

  function eventActiveRedirectToSearch(
    event: React.KeyboardEvent<HTMLInputElement>
  ) {
    const target = event.target as HTMLInputElement;
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      setLastNameSearched(target.value);
      if (target.value != lastNameSearched) {
        return setFilterParams((oldState) => ({
          ...oldState,
          nameSearch: `textoPesquisa=all="*${
            target.value
              ?.toUpperCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '') ?? ''
          }*";`,
        }));
        //setFilterParams((oldState) => ({ ...oldState, nameSearch: `` }));
      }
    }
  }

  return (
    <Styled.Wrapper>
      <SearchBar
        onBlur={handleNameSearch}
        onKeyUp={eventActiveRedirectToSearch}
      />
      <SelectFilter
        name="dateValue"
        type="date"
        title="Data Cadastramento Pedido"
        value={filterValues.dateValue}
        setValue={setFilterValues}
        options={dateStatus}
        onHandleChange={handleChangeDate}
      />
      <SelectFilter
        name="creditValue"
        type="options"
        title="Decisão de crédito"
        value={filterValues.creditValue}
        setValue={setFilterValues}
        options={mockStatus.creditStatus}
        onHandleChange={handleChangeCredit}
      />
      <SelectFilter
        name="stageValue"
        type="options"
        title="Etapa/Situação"
        value={filterValues.stageValue}
        setValue={setFilterValues}
        options={mockStatus.stageStatus}
        onHandleChange={handleChangeStage}
      />
      <SelectFilter
        name="paydayValue"
        type="date"
        title="Data do Pagamento"
        value={filterValues.paydayValue}
        setValue={setFilterValues}
        options={dateStatus}
        onHandleChange={handleChangeDate}
      />

      <SelectFilter
        name="storeValue"
        type="options"
        title="Loja"
        value={filterValues.storeValue}
        setValue={setFilterValues}
        options={storeStatus}
        onHandleChange={handleChangeStore}
        controllerCheckBox={filterValues.storeValue}
        multiple
        isShop
      />

      {userData?.permissao.find(
        (item: string) => item === 'ADV_FILTRO_AGENTE'
      ) && (
        <SelectFilter
          name="agentValue"
          type="options"
          title="Agente"
          value={filterValues.agentValue}
          setValue={setFilterValues}
          options={agentStatus}
          onHandleChange={handleChangeAgent}
        />
      )}

      <CheckBox
        id="paid"
        label="Mostrar contratos pagos"
        checked={filterValues.paid}
        onCheck={handleChangeCheck}
      />
      <CheckBox
        id="canceled"
        label="Mostrar propostas canceladas"
        checked={filterValues.canceled}
        onCheck={handleChangeCheck}
      />
      <Styled.CleanButton onClick={handleCleanUp}>LIMPAR</Styled.CleanButton>
    </Styled.Wrapper>
  );
};
