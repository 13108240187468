import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    position: relative;

    input {
      width: 100%;
      height: 100%;
      margin-bottom: 0.8rem;
      padding: 1.6rem 4.8rem 1.6rem 1.2rem;

      font-family: 'Roboto', sans-serif;
      font-size: 1.4rem;

      border: 0;
      border-radius: 0.4rem;

      color: ${theme.colors.blackTertiary};

      &::placeholder {
        color: ${theme.colors.grayThird};
      }
    }

    svg {
      position: absolute;
      top: 1.2rem;
      right: 1.2rem;
    }
  `}
`;
