import React, { SetStateAction } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import * as S from './style';

type StateNotificationFloating = {
  isActive: boolean;
  title: string;
  body: string;
};

type NotificationFloatingProps = {
  notificationFloating: StateNotificationFloating;
  setNotificationFloating: React.Dispatch<
    SetStateAction<StateNotificationFloating>
  >;
};

export const NotificationFloating = ({
  notificationFloating,
  setNotificationFloating,
}: NotificationFloatingProps) => {
  return (
    <S.Wrapper notificationOn={notificationFloating.isActive}>
      <div className="floatingNotificationHead">
        <div>
          <h5>{notificationFloating.title}</h5>
          <IoCloseSharp
            className="iconCloseNotificationFloating"
            onClick={() =>
              setNotificationFloating({
                isActive: false,
                title: '',
                body: '',
              })
            }
          />
        </div>
      </div>
      <div className="messagingFloating">
        <p>{notificationFloating.body}</p>
      </div>
    </S.Wrapper>
  );
};
