import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* grid-gap: 5px; */
  align-items: center;
  padding: 0px 22px;
  svg {
    margin-left: 3px;
    padding-right: -3px;
    width: 20px;
    height: 20px;
  }
`;
