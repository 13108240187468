import Button from 'components/Button';
import React from 'react';
import * as S from './styles';
import { ReactComponent as IconWarning } from 'assets/icons/Warning.svg';
import { useHistory } from 'react-router-dom';

export const ButtonsForm: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <S.TextoAlertaDocumento>
        <IconWarning style={{ marginRight: '8px' }} width={18} height={18} />
        <b>Atualmente a Sinosserra aceita apenas clientes que possuam CNH</b>
      </S.TextoAlertaDocumento>
      <S.ButtonWrapper>
        <S.VoltarButtonWrapper onClick={() => history.push('/conditions')}>
          <Button type="button" buttonTheme="goback" height="40px">
            VOLTAR
          </Button>
        </S.VoltarButtonWrapper>
        <S.AvancarButtonWrapper>
          <Button type="submit" height="40px">
            ENVIAR PARA CRÉDITO
          </Button>
        </S.AvancarButtonWrapper>
      </S.ButtonWrapper>
    </>
  );
};
