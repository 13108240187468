import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  width: 436px;

  @media only screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;

    div {
      max-width: 28rem;
      main {
        max-width: 100%;
      }
    }
  }

  ${media.lessThan('small')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;

    div {
      max-width: 28rem;
      main {
        max-width: 100%;
      }
    }
    `}
`;

export const DivInput = styled.div`
  display: grid;
  grid-template-columns: 252px 156px;
  width: 436px;
  grid-gap: 28px;
  grid-row-gap: 20px;
  ${media.lessThan('large')`
    height: auto;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 2rem;
  `}
  @media only screen and (max-width: 1000px) {
    height: auto;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    align-items: center;
  }
  ${media.lessThan('small')`
    height: auto;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    align-items: center;
  `}

  main {
    margin-top: 0;

    label {
      padding-left: 8px;
    }

    input {
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
      padding-left: 8px;
      padding-bottom: 3px;
    }
  }
`;

export const CEPWrapper = styled.div`
  display: grid;
  grid-template-columns: 137px 1fr;
  grid-gap: 20px;

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 165px 1fr;
  }

  main {
    label {
      padding-left: 8px;
    }
    input {
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
      padding-left: 8px;
    }
  }
`;

export const CEPSearch = styled.div`
  width: 100px;
  height: 20px;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  padding-bottom: 3px;
  align-items: flex-end;
  :hover {
    cursor: pointer;
  }
  svg {
    margin-right: 3px;
  }
`;

export const DivAddressOne = styled(DivInput)`
  display: grid;
  grid-template-columns: 211px 78px 115px;
  width: 436px;
  grid-gap: 15px;
  grid-row-gap: 20px;
  margin-top: 20px;

  @media only screen and (max-width: 1000px) {
    height: auto;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    align-items: center;
  }
`;

export const DivAddressTwo = styled(DivInput)`
  display: grid;
  grid-template-columns: 211px 143px 46px;
  width: 436px;
  grid-gap: 15px;
  grid-row-gap: 20px;
  margin-top: 20px;

  @media only screen and (max-width: 1000px) {
    height: auto;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    align-items: center;
  }
`;

export const CEPSearchText = styled.span`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #00a2e0;
`;

export const CEPEmpWrapper = styled(CEPWrapper)`
  grid-template-columns: 85px 115px 46px;
  grid-gap: 8px;

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 95px 125px 56px;
  }
`;

export const CEPEmpSearch = styled.div`
  width: 100%;
  height: 20px;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  padding-bottom: 3px;
  align-items: flex-end;
  margin-left: 5px;
  :hover {
    cursor: pointer;
  }
  svg {
    margin-right: 3px;
  }
`;
