import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    width: 45px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 45px;
    margin-left: 10px;

    .wrapperIconNotification {
      width: max-content;
      height: max-content;
      cursor: pointer;
      position: relative;
    }

    .iconNotification {
      color: ${theme.colors.white};
      font-size: 25px;
    }

    .circleWithLengthOfArray {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: ${theme.colors.red};
      color: ${theme.colors.white};
      font-size: ${theme.font.sizes.xxx_small};
      font-family: 'Roboto';
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -25%;
      right: -25%;
    }
  `}
`;
