import * as S from './styles';
import { useContext, useEffect } from 'react';

import { Context } from 'context/globalContext';

import PageNth from './PageNth';
import PageZero from './PageZero';

export type TutorialDataType = {
  id: number;
  titulo: string;
  subtitulo: string;
  descricao: string;
  link: string;
  tipoAnexo: string;
  visualizado: boolean;
};

type PopUpProps = {
  data: TutorialDataType[];
  isActive?: boolean;
  setIsActive: (item: boolean) => void;
};

const PopUpTutorial = ({ data, isActive, setIsActive }: PopUpProps) => {
  const { page, setPage } = useContext(Context);

  const nextPage = () => {
    if (page + 1 > data.length) {
      setIsActive(false);
      setPage(0);
      return;
    }
    setPage((old) => old + 1);
  };

  const previousPage = () => {
    setPage((old) => old - 1);
  };

  const ShowPopUpPage = () => {
    switch (page) {
      case 0:
        return (
          <PageZero data={data} setIsActive={setIsActive} next={nextPage} />
        );
      default:
        return (
          <PageNth
            data={data[page - 1]}
            dataLenght={data.length}
            setIsActive={setIsActive}
            pageNum={page}
            next={nextPage}
            previous={previousPage}
          />
        );
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      const valueIndex = data.findIndex((item) => !item.visualizado);

      if (valueIndex === 0) return;

      setPage(valueIndex + 1);
    }
  }, [data]);

  return isActive ? (
    <S.Wrapper>
      <S.BlurWrapper
        onClick={() => {
          if (page === 0) {
            return setIsActive(false);
          }

          if (page + 1 > data.length) {
            setPage(0);
            setIsActive(false);
            return;
          }

          setIsActive(false);
          setPage((old) => old + 1);
        }}
      />
      {ShowPopUpPage()}
    </S.Wrapper>
  ) : (
    <div />
  );
};

export default PopUpTutorial;
