import * as S from './styles';
import Skeleton from 'react-loading-skeleton';
import * as CXT from 'context/globalContext';
import { useContext } from 'react';

type EmptyParcelsType = {
  emptyData: any;
};

const EmptyParcels = ({ emptyData }: EmptyParcelsType) => {
  const globalContext = useContext(CXT.Context);

  return (
    <S.Wrapper>
      <S.ErrorBox>
        {globalContext.isLoading ? (
          <>
            <br />
            <Skeleton width="20px" />
            <div style={{ height: '10px' }} />
            <Skeleton count={2} width="325px" />
            <br />
            <Skeleton count={6} width="325px" />
            <br />

            <br />
          </>
        ) : (
          <>
            <b>
              <br />
              =(
              <br />
              Infelizmente Nenhuma tabela foi encontrada
              <br />
              para este perfil de veículo e cliente...
            </b>
            <br />
            <p
              style={{ fontWeight: '400 !important' }}
              dangerouslySetInnerHTML={{
                __html: emptyData.mensagemRetornoHtml,
              }}
            ></p>
          </>
        )}
      </S.ErrorBox>
    </S.Wrapper>
  );
};

export default EmptyParcels;
