import axios, { AxiosError } from 'axios';
import history from 'utils/history';

const apiAppdsv = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_BACKEND_APPDSV,
});

apiAppdsv.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      if (error.response?.data?.error === 'Unauthorized') {
        localStorage.removeItem('token');
        apiAppdsv.defaults.headers.Authorization = undefined;
        history.push('/');
      }
    }
    return Promise.reject(error);
  }
);
export default apiAppdsv;
