import styled, { css } from 'styled-components';

type ContainerProps = {
  error: string | boolean;
  handleClickChangeColor?: boolean;
};

export const Wrapper = styled.div``;

export const Header = styled.header`
  ${({ theme }) => css`
    width: 28rem;

    padding: 1.2rem;
    position: relative;

    display: flex;
    align-items: center;

    &::after {
      content: '';
      width: 100%;
      height: 4px;
      position: absolute;
      left: 0;
      bottom: 0;

      border-radius: 3rem;

      background-color: ${theme.colors.blueSecondary};
    }

    img {
      width: 27px;
      height: 35px;
    }

    div {
      margin-left: 1.6rem;

      h1 {
        font-size: 1.8rem;
        font-weight: 500;
        color: ${theme.colors.blackTertiary};
      }

      span {
        font-size: 2rem;
        font-weight: 300;
        color: ${theme.colors.blackTertiary};
      }
    }
  `}
`;

export const Form = styled.form`
  margin-top: 6rem;

  display: flex;
  flex-direction: column;

  button {
    padding: 1.2rem 0;
  }

  span + span {
    margin-top: 3.2rem;
  }
`;

export const RowInput = styled.div<ContainerProps>`
  ${({ theme, error, handleClickChangeColor }) => css`
    position: relative;
    display: flex;
    flex-direction: column;

    & + & {
      margin-top: 4rem;
    }

    label {
      padding: 0 0.8rem;
      font-family: 'Roboto', sans-serif;
      font-size: 1.2rem;
      font-weight: 500;
      color: ${theme.colors.blackTertiary};
    }

    .wrapperTwoLabel {
      width: 100%;
      display: flex;
      justify-content: space-between;

      div {
        width: 48%;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: ${theme.border.radius.small};
        border-top-right-radius: ${theme.border.radius.small};
      }

      .leftPositionLabel {
        background-color: ${!handleClickChangeColor
          ? theme.colors.grayFith
          : theme.colors.white};
        cursor: ${handleClickChangeColor ? `pointer` : 'default'};

        label {
          cursor: ${handleClickChangeColor ? `pointer` : 'default'};
          color: ${!handleClickChangeColor
            ? theme.colors.blueSecondary
            : theme.colors.blackTertiary};
        }
      }

      .rightPositionLabel {
        background-color: ${handleClickChangeColor
          ? theme.colors.grayFith
          : theme.colors.white};
        cursor: ${!handleClickChangeColor ? `pointer` : 'default'};
        label {
          cursor: ${!handleClickChangeColor ? `pointer` : 'default'};
          color: ${handleClickChangeColor
            ? theme.colors.blueSecondary
            : theme.colors.blackTertiary};
        }
      }
    }

    .wrapperInput {
      width: 100%;
      height: 3rem;
      background-color: ${theme.colors.grayFith};
    }

    span {
      position: absolute;
      bottom: -2.3rem;
      left: 3%;

      font-size: 1.1rem;
      color: ${theme.colors.red};
    }

    input {
      width: 100%;
      padding: 0.8rem;
      background-color: transparent;
      background-color: ${theme.colors.grayFith};

      font-size: 1.4rem;
      margin-top: 0.5rem;

      border: 0;
      border-bottom: 1px solid ${theme.colors.gray};

      color: ${theme.colors.blackTertiary};

      text-transform: uppercase;

      outline: 0;
    }

    ${!!error &&
    css`
      label {
        color: ${theme.colors.red};
      }

      input {
        border-bottom: 1px solid ${theme.colors.red};
      }
    `}
  `}
`;
