import * as S from './styles';
import { ReactComponent as IconPerson } from 'assets/icons/Cliente.svg';
import { ReactComponent as IconPaper } from 'assets/icons/Blue_paper.svg';
import { ReactComponent as IconData } from 'assets/icons/Calendar_blue.svg';
import { ReactComponent as IconBallon } from 'assets/icons/Balloon_retorno.svg';
import { LabelHTMLAttributes } from 'react';

export type ImgProps = {
  id?: string;
  fontSize?: 'normal' | 'small';
  type: 'person' | 'paper' | 'data' | 'ballon';
};
type LabelProps = ImgProps &
  S.WrapperProps &
  LabelHTMLAttributes<HTMLLabelElement>;

const ImgLabel = ({
  id,
  type,
  className,
  children,
  paddingLeft,
  fontSize = 'normal',
  ...props
}: LabelProps) => {
  const Icon = () => {
    switch (type) {
      case 'person':
        return (
          <IconPerson
            id={id}
            name={id}
            style={{ marginBottom: '4px' }}
          ></IconPerson>
        );
      case 'paper':
        return (
          <IconPaper
            id={id}
            name={id}
            style={{ marginBottom: '5px' }}
          ></IconPaper>
        );
      case 'data':
        return (
          <IconData
            id={id}
            name={id}
            style={{ marginBottom: '3px', top: '10px' }}
          ></IconData>
        );
      case 'ballon':
        return (
          <IconBallon
            id={id}
            name={id}
            width="20px"
            height="20px"
            style={{ marginBottom: '-4px' }}
          ></IconBallon>
        );
    }
  };

  return (
    <S.Wrapper className={className} paddingLeft={paddingLeft}>
      {Icon()}
      <S.Label htmlFor={id} fontSize={fontSize} {...props}>
        {children}
      </S.Label>
    </S.Wrapper>
  );
};

export default ImgLabel;
