import styled, { css } from 'styled-components';

export const Wrapper = styled.main`
  ${({ theme }) => css`
    min-width: 1280px;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    background-color: ${theme.colors.thirdWhite};

    @media (max-width: 1366px) {
      height: 100%;
    }
  `}
`;

export const NavBarBox = styled.div`
  min-width: 1280px;
  margin-top: 12rem;
  margin-bottom: 1rem;
`;

export const BodyBox = styled.div`
  position: relative;
  padding: 0px 5rem 0px 2.5rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  min-width: 1280px;
  width: 1280px;
`;

export const FooterBox = styled.div`
  width: 100%;
  padding: 1.6rem 0;

  display: flex;
  justify-content: space-between;

  button:nth-child(1) {
    margin-left: 26.5rem;
  }
`;

export const DataBox = styled.div`
  ${({ theme }) => css`
    width: 255px;
    display: grid;
    align-content: start;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    background-color: ${theme.colors.white};
    padding 10px;
    border-radius: 8px;
    .merge {
      grid-column: span 2;
    }
  `}
`;

export const DataBoxTitle = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 10fr 60fr;
    align-items: center;

    span {
      font-size: ${theme.font.sizes.x_small};
      font-weight: 400;
    }
    label {
      font-size: ${theme.font.sizes.xxx_small};
      font-weight: 200;
    }

    .mergeRow {
      grid-row: span 2;
    }
  `}
`;

export const ImgTitle = styled.img``;

//#########################################################################################

export const DocBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    .mergeRow {
      grid-column: span 3;
    }
    &.selected {
      border-bottom: 2px solid ${theme.colors.blueSecondary};
    }
  `}
`;

export const DocButtonBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;
`;

export const DocList = styled.div`
  width: 900px;
  height: 525px;
`;

//#########################################################################################

export const HorizontalBar = styled.div`
  ${({ theme }) => css`
    margin: 0px -10px;
    border-bottom: 1px solid ${theme.colors.gray};
  `}
`;
