import Button from 'components/Button';
import { find } from 'lodash';
import { Proposta } from 'pages/Documents/types';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Deals } from 'services/propostas';
import { CONCLUIDO } from '../../stageStatus';
import { EtapaDTO } from '../../types';
import {
  ButtonWrapper,
  HighlightedTextWrapper,
  TextWrapper,
  Wrapper,
  WrapperContainer,
} from './styles';
import SendConfirmationDialog from './SendConfirmationDialog';

interface EnviarProps {
  show: boolean;
  readOnly: boolean;
  etapa: EtapaDTO;
  proposta: Proposta;
  goToEtapaAssinar: () => void;
}

const IN_ANALYSIS = 'IN_ANALYSIS';
const ACCEPTED = 'ACCEPTED';

const Enviar = ({
  show,
  readOnly,
  etapa,
  proposta,
  goToEtapaAssinar,
}: EnviarProps) => {
  const [loadingAcao, setLoadingAcao] = useState<boolean>(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);

  const isSelfAndCNHWaitingOrAccepted = () => {
    const acceptedSelfie =
      find(
        proposta.documentos,
        (it) =>
          it.contexto === 'Cliente' &&
          it.nome === 'Selfie' &&
          (it.status === IN_ANALYSIS || it.status === ACCEPTED)
      ) != undefined;
    const acceptedCNH =
      find(
        proposta.documentos,
        (it) =>
          it.contexto === 'Cliente' &&
          it.nome === 'CNH' &&
          (it.status === IN_ANALYSIS || it.status === ACCEPTED)
      ) != undefined;
    return acceptedSelfie && acceptedCNH;
  };

  const enviarContrato = () => {
    setConfirmationDialogOpen(true);
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  if (!show) return null;
  return (
    <>
      <Wrapper>
        <TextWrapper>
          Assinatura será 100% digital através da plataforma D4Sign.
        </TextWrapper>
        <WrapperContainer>
          <TextWrapper>
            O cliente {proposta.clienteNome}, irá receber um e-mail com as
            instruções e um SMS com a chave de acesso através dos dados de
            contato cadastrados:
          </TextWrapper>
        </WrapperContainer>
        <WrapperContainer>
          <TextWrapper textAlign="left">
            <div>
              Email:{' '}
              <HighlightedTextWrapper>
                {proposta.clienteEmail}
              </HighlightedTextWrapper>
            </div>
            <div>
              Telefone:{' '}
              <HighlightedTextWrapper>
                {proposta.clienteTelefoneFormatado}
              </HighlightedTextWrapper>
            </div>
          </TextWrapper>
        </WrapperContainer>
        <WrapperContainer>
          <TextWrapper>
            Avise seu cliente que ele terá até 48h para fazer a assinatura.
          </TextWrapper>
        </WrapperContainer>
        <WrapperContainer>
          <TextWrapper>
            Você será avisado assim que o processo for finalizado
          </TextWrapper>
        </WrapperContainer>
        <ButtonWrapper>
          <Button
            marginTop="0px"
            onClick={enviarContrato}
            disabled={readOnly || !isSelfAndCNHWaitingOrAccepted()}
            loading={loadingAcao}
          >
            {etapa.status === CONCLUIDO
              ? 'Reenviar contrato'
              : 'Enviar contrato'}
          </Button>
        </ButtonWrapper>
      </Wrapper>
      <SendConfirmationDialog
        open={confirmationDialogOpen}
        handleClose={closeConfirmationDialog}
        proposta={proposta}
        etapa={etapa}
        goToEtapaAssinar={goToEtapaAssinar}
      ></SendConfirmationDialog>
    </>
  );
};

export default Enviar;
