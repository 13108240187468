import React, { useContext } from 'react';
import { IconType } from 'react-icons/lib';
import Skeleton from 'react-loading-skeleton';
import * as CTX from '../../../../context/globalContext';
import * as S from './styles';

type ContainerWithInformationProps = {
  text: string;
  Icon: IconType;
  cpf?: string;
};

export const ContainerWithInformation: React.FC<
  ContainerWithInformationProps
> = ({ text, Icon, cpf }) => {
  const globalContext = useContext(CTX.Context);

  return (
    <>
      <S.Up>
        <S.CardTitle>
          {globalContext.isLoading ? (
            <>
              <Skeleton
                circle={true}
                height={15}
                width={15}
                style={{ marginRight: '12px' }}
              />
              <Skeleton width="127px" />
            </>
          ) : (
            <>
              <Icon size={17} color={'#012d5c'} />
              {text}
            </>
          )}
        </S.CardTitle>
        {cpf && (
          <S.UserCpf>
            {globalContext.isLoading ? (
              ''
            ) : (
              <>
                CPF: <span>{cpf}</span>
              </>
            )}
          </S.UserCpf>
        )}
      </S.Up>
    </>
  );
};
