import styled from 'styled-components';
import media from 'styled-media-query';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  align-self: center;
  height: 60px;

  @media only screen and (max-width: 1000px) {
    max-width: 400px;
    padding: 0 4rem 4rem 2rem;
  }
`;

export const VoltarButtonWrapper = styled.div`
  height: 100px;
  width: 30%;
  display: 'flex';
  justify-content: 'flex-end';
  ${media.lessThan('small')`
      padding: 0 0 4rem 0;
    `};
`;

export const AvancarButtonWrapper = styled.div`
  height: 100px;
  width: 70%;
  display: 'flex';
  justify-content: 'flex-end';
  ${media.lessThan('small')`
      padding: 0 0 4rem 0;
    `};
`;

export const TextoAlertaDocumento = styled.div`
  color: #ffc000;
  margin-top: 60px;
  margin-left: 10px;
  font-size: 13px;
  display: flex;
  align-items: center;
`;
