import React from 'react';
import * as S from './style';

export const LoadingNotification: React.FC = () => {
  return (
    <S.LoadingComponent>
      <div className="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </S.LoadingComponent>
  );
};
