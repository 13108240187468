import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 30rem;
  position: fixed;

  @media (max-width: 1366px) {
    max-width: 25rem;
  }

  @media (max-width: 1280px) {
    position: relative;
  }
`;

export const CleanButton = styled.button`
  ${({ theme }) => css`
    margin-top: 1.6rem;

    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    font-weight: 500;

    border: none;

    background-color: transparent;
    color: ${theme.colors.blueSecondary};
  `}
`;
