import * as S from './styles';
import { ButtonHTMLAttributes } from 'react';
import { ReactComponent as ExpandIcon } from 'assets/icons/expand.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';

type ButtonNLabelProps = {
  variant?: 'expand' | 'download' | 'share';
} & ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonNLabel = ({ variant = 'expand', ...props }: ButtonNLabelProps) => {
  const renderModel = () => {
    switch (variant) {
      case 'expand':
        return (
          <>
            <ExpandIcon />
            Expandir
          </>
        );
      case 'download':
        return (
          <>
            <DownloadIcon />
            Download
          </>
        );
      case 'share':
        return (
          <>
            <ExpandIcon />
            Compartilhar
          </>
        );
      default:
        return (
          <>
            <ExpandIcon />
            None
          </>
        );
    }
  };

  return <S.Wrapper {...props}>{renderModel()}</S.Wrapper>;
};

export default ButtonNLabel;
