import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    width: 80px;
    height: 100%;

    .IconOfStore {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-1 {
        color: ${theme.colors.white};
        margin-right: -5px;
        font-size: 23px;
        cursor: pointer;
      }
    }
  `}
`;
