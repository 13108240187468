import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const InfoOne = styled.div`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;
    margin-top: 24px;
    font-size: 14px;
    font-weight: bold;
    color: ${theme.colors.graySecond};
  `}
`;

export const InfoTwo = styled.div`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;
    font-size: 13px;
    margin-top: 8px;
    color: ${theme.colors.graySecond};
  `}
`;

export const ListContainer = styled.div`
  padding: 24px 16px;
`;

export const ListTitleContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 48px;
    border: 1px solid ${theme.colors.grayFourth};
    border-radius: 8px;

    display: flex;
    flex-direction: row;
  `}
`;

interface ListTitleProps {
  width: string;
  showDivider?: boolean;
}
export const ListTitle = styled.div<ListTitleProps>`
  ${({ theme, width = '100%', showDivider = false }) => css`
    width: ${width};
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-size: 12px;
    color: ${theme.colors.grayThird};

    ${showDivider ? `border-right: 1px solid ${theme.colors.grayFourth};` : ''}
  `}
`;

export const ListContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

type ListEntryContainerProps = {
  showDivider?: boolean;
};
export const ListEntryContainer = styled.button<ListEntryContainerProps>`
  ${({ theme, showDivider }) => css`
    border: none;
    background: none;

    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    ${showDivider ? `border-top: 2px solid ${theme.colors.grayFourth};` : ''}
  `}
`;

export const CheckboxWrapper = styled.div`
  width: 50px;
`;

type DisabledProp = {
  disabled: boolean;
};
export const LabelWrapper = styled.div<DisabledProp>`
  ${({ theme, disabled }) => css`
    width: calc(60% - 50px);
    font-size: 14px;
    font-weight: bold;
    text-align: start;
    padding: 8px;
    color: ${disabled ? theme.colors.graySixth : theme.colors.blackSecondary};
  `}
`;

export const DateWrapper = styled.div<DisabledProp>`
  ${({ theme, disabled }) => css`
    width: 40%;

    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 8px;
    color: ${disabled ? theme.colors.graySixth : theme.colors.graySecond};
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
