import * as S from './styles';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import AnexarIcon from 'assets/icons/AnexarMaisComTexto.svg';
import Button from 'components/Button';
import CloseButton from 'components/CloseButton';
import ObsTextField from 'components/ObsTextField';
import * as CXT from 'context/globalContext';
import { Deals } from 'services/propostas';
import { DocumentProxyMask } from 'utils/mask';
import ShowPDF from 'utils/ShowPDF';

import ButtonNLabel from './ExpandButton';
import { ListData } from '../DocList';
import AnexFile from '../AnexFile';

export type DocPopUpProps = {
  data?: ListData;
  subTitle: string;
  pedidoId: string;
  url?: string;
  obs: string;
  readMod?: boolean;
  deleteDoc: (data: ListData) => void;

  isActive: boolean;
  setIsActive?: (item: boolean) => void;
};

const DocViewPopUp = ({
  data,
  subTitle,
  url = 'url',
  obs = '',
  pedidoId = '0',
  readMod,

  deleteDoc,
  isActive = true,
  setIsActive = () => {
    isActive = false;
  },
}: DocPopUpProps) => {
  const globalContext = useContext(CXT.Context);
  const [isBig, setIsBig] = useState(false);
  const [file, setFile] = useState(null);
  const [observacao, setObservacao] = useState(obs);

  const DecreaseNClose = () => {
    if (isBig) {
      setIsBig(false);
    } else {
      setIsActive(false);
    }
  };

  const DownloadPDF = () => {
    globalContext.setIsLoading(true);

    Deals.DownloadDoc(DocumentProxyMask(url))
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `document.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500)
          toast.error('Erro ao tentar baixar o arquivo.');
        else
          toast.error(
            'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Tente novamente mais tarde e se o problema persistir contate o suporte!'
          );
      })
      .finally(() => {
        globalContext.setIsLoading(false);
      });
  };

  const AnexDoc = (file: Array<any>) => {
    globalContext.setIsLoading(true);
    Deals.DownloadDoc(DocumentProxyMask(url))
      .then((response) => {
        const blobObj = new Blob([response.data], { type: 'application/pdf' });

        let baseURL = '';
        const reader = new FileReader();
        reader.readAsDataURL(blobObj);
        reader.onload = () => {
          baseURL = typeof reader.result === 'string' ? reader.result : '';
          if (baseURL) {
            const newFile = [
              { arquivo: baseURL.split(',')[1], tipo: 'PDF' },
              ...file,
            ];

            Deals.DeleteDoc(pedidoId, data?.id ?? 0)
              .then(() => {
                Deals.UploadDoc(pedidoId, data?.id ?? 0, observacao, newFile)
                  .then(() => {
                    toast.info('Arquivo Enviado com Sucesso');
                    globalContext.RefreshPage();
                    setIsActive(false);
                  })
                  .catch((err) => {
                    if (err.response.status >= 400 && err.response.status < 500)
                      toast.error('Erro ao consultar o servidor.');
                    else if (err.response.status === 500)
                      toast.error(
                        'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Entre em contato para inclusão de avalista!'
                      );
                    else
                      toast.error(
                        'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Tente novamente mais tarde e se o problema persistir contate o suporte!'
                      );
                  })
                  .finally(() => {
                    globalContext.setIsLoading(false);
                    setFile(null);
                  });
              })
              .catch((err) => {
                if (err.response.status >= 400 && err.response.status < 500)
                  toast.error('Erro ao consultar o servidor.');
                else
                  toast.error(
                    'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Tente novamente mais tarde e se o problema persistir contate o suporte!'
                  );
                globalContext.setIsLoading(false);
              });
          }
        };
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500)
          toast.error('Erro ao tentar baixar o arquivo.');
        else
          toast.error(
            'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Tente novamente mais tarde e se o problema persistir contate o suporte!'
          );
        globalContext.setIsLoading(false);
      });
  };

  const SalvarObs = () => {
    globalContext.setIsLoading(true);
    Deals.SetDocObs(pedidoId, data?.id ?? 0, observacao)
      .then(() => toast.info('Observação alterada com sucesso'))
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500)
          toast.error('Erro ao tentar baixar o arquivo.');
        else
          toast.error(
            'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Tente novamente mais tarde e se o problema persistir contate o suporte!'
          );
      })
      .finally(() => globalContext.setIsLoading(false));
  };

  useEffect(() => {
    if (!file) return;
    DecreaseNClose();
    AnexDoc(file);
  }, [file]);

  useEffect(() => {
    setObservacao(obs);
  }, [obs]);

  return isActive ? (
    <S.Wrapper>
      <S.BlurWrapper onClick={DecreaseNClose}></S.BlurWrapper>
      <S.PopUpBody className={isBig ? 'Bigger' : ''}>
        {!isBig ? (
          <>
            <S.PopUpHeaderBox>
              <S.PopUpTitleBox>
                <S.TitleLabel>{data?.nome?.toUpperCase() ?? ''}</S.TitleLabel>
                <S.SubTitleLabel className={subTitle}>
                  {subTitle.length > 0 ? subTitle : 'Aguardando Parecer'}
                </S.SubTitleLabel>
              </S.PopUpTitleBox>

              {data?.nome?.toUpperCase().match('CCB') ? (
                <ButtonNLabel
                  type="button"
                  variant="download"
                  onClick={() => {
                    DownloadPDF();
                  }}
                />
              ) : (
                ''
              )}
              <ButtonNLabel
                type="button"
                variant="expand"
                onClick={() => {
                  setIsBig(true);
                }}
              />
              <CloseButton type="button" onClick={DecreaseNClose} />
            </S.PopUpHeaderBox>
          </>
        ) : (
          ''
        )}

        <S.HorizontalBar />
        <S.PopUpMainBox>
          <ShowPDF
            url={url}
            width={
              isBig ? (800 < window.innerWidth ? 800 : window.innerWidth) : 500
            }
          />
        </S.PopUpMainBox>
        {!isBig ? (
          <S.ObsBox>
            <ObsTextField
              titulo="Observações"
              minRows={4}
              value={observacao}
              onChange={(ev) => setObservacao(ev.target.value)}
              placeholder="Adicione aqui suas observações sobre o documento anexado."
              disabled={data?.status === 'ACCEPTED' || readMod ? true : false}
            />

            <label>
              OS DOCUMENTOS <br />
              DEVEM SER REFERENTES AO ENDEREÇO <br />
              ANTERIORMENTE INFORMADO.
            </label>
          </S.ObsBox>
        ) : (
          ''
        )}
        <S.HorizontalBar />
        {!isBig ? (
          <S.PopUpButtonBox>
            {subTitle !== 'Aguardando' && subTitle !== 'Aprovado' ? (
              <Button
                onClick={() => {
                  if (data) deleteDoc(data);
                }}
                type="button"
                buttonTheme={'goback'}
                height={'30px'}
                marginTop={'10px'}
                disabled={readMod}
              >
                DELETAR
              </Button>
            ) : (
              <div />
            )}
            <div />
            {subTitle !== 'Aguardando' &&
            subTitle !== 'Aprovado' &&
            subTitle !== 'Rejeitado' &&
            subTitle !== 'Não aprovado' &&
            !readMod ? (
              <AnexFile
                multiple
                medium
                img={AnexarIcon}
                ext={'.pdf, .jpg, .png'}
                setFile={(file) => {
                  setFile(file);
                }}
              />
            ) : (
              <div />
            )}

            <div />
            <Button
              onClick={() => {
                // DecreaseNClose();
                SalvarObs();
              }}
              type="button"
              buttonTheme={'goback'}
              height={'30px'}
              marginTop={'10px'}
              disabled={readMod}
            >
              SALVAR
            </Button>
          </S.PopUpButtonBox>
        ) : (
          <div />
        )}
      </S.PopUpBody>
    </S.Wrapper>
  ) : (
    <div />
  );
};
export default DocViewPopUp;
