import { Proposta } from 'pages/Documents/types';
import { useState } from 'react';
import {
  AGUARDANDO,
  CONCLUIDO,
  EM_ANDAMENTO,
  EXPIRADO,
  PENDENTE,
  RECUSADO,
} from '../../stageStatus';
import { EtapaDTO } from '../../types';
import AguardandoAtualizacao from './AguardandoAtualizacao';
import EscolherVencimento from './EscolherVencimento';
import Expirado from './PrazoExpirado';
import VencimentoAprovado from './VencimentoAprovado';
import VencimentoQualificado from './VencimentoQualificado';

interface VencimentoProps {
  show: boolean;
  readOnly: boolean;
  etapa: EtapaDTO;
  etapas: EtapaDTO[];
  proposta: Proposta;
  reloadEtapas: () => void;
  reloadProposta: () => void;
  goToAprovacaoTab: () => void;
  goToEtapaVisualizarContrato: () => void;
  isVencimentoDataUpToDate: boolean;
}

const Vencimento = ({
  show,
  readOnly,
  etapa,
  etapas,
  proposta,
  reloadEtapas,
  reloadProposta,
  goToAprovacaoTab,
  goToEtapaVisualizarContrato,
  isVencimentoDataUpToDate,
}: VencimentoProps) => {
  const [editingVencimento, setEditingVencimento] = useState<boolean>(false);

  const editVencimento = () => {
    setEditingVencimento(true);
  };

  const finishEditing = () => {
    setEditingVencimento(false);
  };

  if (!show) return null;

  return (
    <>
      {(editingVencimento ||
        etapa.status === EM_ANDAMENTO ||
        etapa.status === PENDENTE ||
        (etapa.status == CONCLUIDO && !isVencimentoDataUpToDate)) && (
        <EscolherVencimento
          etapa={etapa}
          proposta={proposta}
          readOnly={readOnly}
          editing={editingVencimento}
          reloadProposta={reloadProposta}
          finishEditing={finishEditing}
        />
      )}
      {!editingVencimento &&
        etapa.status === CONCLUIDO &&
        isVencimentoDataUpToDate && (
          <VencimentoAprovado
            etapas={etapas}
            proposta={proposta}
            readOnly={readOnly}
            editVencimento={editVencimento}
            goToEtapaVisualizarContrato={goToEtapaVisualizarContrato}
          />
        )}
      {etapa.status === RECUSADO && <VencimentoQualificado />}
      {etapa.status === AGUARDANDO && <AguardandoAtualizacao />}
      {etapa.status === EXPIRADO && (
        <Expirado
          proposta={proposta}
          readOnly={readOnly}
          reloadEtapas={reloadEtapas}
        />
      )}
    </>
  );
};

export default Vencimento;
