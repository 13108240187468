import styled from 'styled-components';

export const DialogContentWrapper = styled.div`
  width: 500px;
  padding: 0px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DialogFooter = styled.div`
  padding: 32px 16px 16px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  & > button {
    margin-left: 16px;
  }
`;
