import * as S from './styles';

import { ReactComponent as WarningIcon } from 'assets/icons/Warning_Blue.svg';
import Button from 'components/Button';
import CloseButton from 'components/CloseButton';

import { ListData } from '../DocList';

export type DocPopUpProps = {
  children?: React.ReactNode;
  data?: ListData;

  isActive: boolean;
  setIsActive?: (item: boolean) => void;
};

const DocAvalistPopUp = ({
  data,

  isActive = true,
  setIsActive = () => {
    isActive = false;
  },
}: DocPopUpProps) => {
  return isActive ? (
    <S.Wrapper>
      <S.BlurWrapper onClick={() => setIsActive(false)} />
      <S.PopUpBody>
        <S.PopUpHeaderBox>
          <S.PopUpTitleBox>
            <S.TitleLabel>{data?.nome.toUpperCase()}</S.TitleLabel>
            <S.SubTitleLabel>Aguardando Envio</S.SubTitleLabel>
          </S.PopUpTitleBox>

          <CloseButton type="button" onClick={() => setIsActive(false)} />
        </S.PopUpHeaderBox>
        <S.HorizontalBar />
        <S.PopUpMainBox>
          <S.PopUpInfoBox>
            <WarningIcon />
            <label>Inclua um avalista para essa proposta</label>
            <label>
              Caso já tenha incluido, aguarde alguns minutos para anexar os
            </label>
            <label>documentos</label>
          </S.PopUpInfoBox>
          <S.ObsBox>
            OS DOCUMENTOS DEVEM SER REFERENTES AO ENDEREÇO ANTERIORMENTE
            INFORMADO.
          </S.ObsBox>
        </S.PopUpMainBox>
        <S.HorizontalBar />
        <S.PopUpButtonBox>
          <Button
            onClick={() => setIsActive(false)}
            type="button"
            buttonTheme={'goback'}
            height={'30px'}
            marginTop={'10px'}
          >
            VOLTAR
          </Button>
          <div />
        </S.PopUpButtonBox>
      </S.PopUpBody>
      {/* {showPopUp()} */}
    </S.Wrapper>
  ) : (
    <div />
  );
};
export default DocAvalistPopUp;
