import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

type OptionItemType = {
  isActive: boolean;
};

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 0.8rem 1.2rem;
    position: relative;

    border-radius: 0.4rem;

    background-color: ${theme.colors.white};

    cursor: pointer;

    div + & {
      margin-bottom: 0.8rem;
    }

    @media (max-width: 1366px) {
      padding: 0.4rem 0.8rem;
    }

    span {
      font-family: 'Roboto', sans-serif;
      font-size: 1.1rem;
      font-weight: 500;

      color: ${theme.colors.blackTertiary};
    }

    svg {
      position: absolute;
      top: 1.2rem;
      right: 1.2rem;
    }
  `}
`;

export const ValueSelect = styled.p`
  ${({ theme }) => css`
    margin-top: 0.4rem;

    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;

    color: ${theme.colors.bluePrimary};

    @media (max-width: 1366px) {
      margin-top: 0.2rem;
    }
  `}
`;

type OptionsProps = {
  isShop?: boolean;
};

export const OptionsContainer = styled.div<OptionsProps>`
  @media (min-width: 1240px) {
    ${(props) => css`
      height: ${props.isShop && '240px'};
      max-height: ${props.isShop && '240px'};
      top: ${props.isShop && '-240px'};
      overflow-y: ${props.isShop && 'auto'};
      z-index: ${props.isShop && '1000'};
    `}
  }

  ${({ theme }) => css`
    width: 100%;
    padding: 1.2rem 0;
    position: absolute;
    left: 0;

    border-radius: 0.4rem;

    background-color: ${theme.colors.white};

    box-shadow: 0 0 0.8rem 0.2rem ${theme.colors.gray};

    z-index: 1;
  `}
`;

export const OptionItem = styled.div<OptionItemType>`
  ${({ theme, isActive }) => css`
    padding: 0.8rem 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;

    color: ${theme.colors.blackTertiary};

    transition: all 0.2s ease;

    input[type='checkbox' i]:not(:checked) {
      appearance: none;
      width: 15px;
      height: 15px;
      border: 1px solid ${theme.colors.btnPrev};
      border-radius: 3px;
      background-color: ${theme.colors.white};
      cursor: pointer;
    }

    input[type='checkbox' i]:checked {
      appearance: none;
      width: 15px;
      height: 15px;
      border: none;
      color: ${theme.colors.white};
      border-radius: 3px;
      background-color: ${theme.colors.blueSecondary};
      cursor: pointer;

      &::after {
        content: '✔';
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &:hover {
      background-color: ${theme.colors.graySeventh};
    }

    ${isActive &&
    css`
      background-color: ${transparentize(0.9, theme.colors.bluePrimary)};
      color: ${theme.colors.bluePrimary};

      &:hover {
        background-color: ${transparentize(0.95, theme.colors.bluePrimary)};
      }
    `}
  `}
`;
