import { SelectHTMLAttributes, useContext } from 'react';
import * as S from './styles';
import { RiArrowDownSLine } from 'react-icons/ri';
import Skeleton from 'react-loading-skeleton';
import * as CXT from 'context/globalContext';

type SelectProps = {
  title?: string;
  height?: string;
  width?: string;
  error?: boolean;
  errorMessage?: string;
  selectTheme?: 'default' | 'valid' | 'invalid';
  valid?: boolean;
} & SelectHTMLAttributes<HTMLSelectElement>;
const Select = ({
  title = 'Digite a Label',
  children,
  width,
  height,
  errorMessage = 'Campo obrigatório',
  error = false,
  selectTheme,
  valid = false,
  ...rest
}: SelectProps) => {
  const globalContext = useContext(CXT.Context);

  return (
    <S.Wrapper>
      {globalContext.isLoading ? (
        <>
          <S.SkelBox>
            <Skeleton />
          </S.SkelBox>
          <Skeleton />
        </>
      ) : (
        <>
          <S.Label>{!!title && title}</S.Label>
          <S.Select
            {...rest}
            width={width}
            height={height}
            error={error}
            selectTheme={selectTheme}
            valid={valid}
          >
            {children}
          </S.Select>
          {error && <S.MsgError>{errorMessage}</S.MsgError>}

          <S.Icon error={error}>
            <RiArrowDownSLine size={26} color="#2a99fb" />
          </S.Icon>
        </>
      )}
    </S.Wrapper>
  );
};

export default Select;
