/* eslint-disable react/jsx-key */
import * as S from './styles';

import { ReactComponent as IconEmpty } from 'assets/icons/Inativa_semNUMERO.svg';
import { ReactComponent as IconOnGoing } from 'assets/icons/Andamento.svg';
import { ReactComponent as IconAccept } from 'assets/icons/Check_green.svg';
import { ReactComponent as IconQualified } from 'assets/icons/Check_orange.svg';
import { ReactComponent as IconReject } from 'assets/icons/Error_red.svg';
import { ReactComponent as IconUnqualified } from 'assets/icons/Desenquadrado.svg';

export interface PropostaTimelineItem {
  id: number;
  seq: number;
  etapa: string;
  status: string;
  texto: string;
}

export type TimelineProps = {
  etapas: Array<PropostaTimelineItem>;
};

const iconSize = '23px';

const Concluida = 'CONCLUÍDA';
// Compatibilidade
const ConcluidaEscritaErrada = 'CONLCUÍDA';
const EmAndamento = 'INICIADA';
const Reprovada = 'REPROVADA';
const NaoIniciada = 'NÃO INICIADO';
const NaoIniciado = 'NÃO INICIADA';
const PreAprovado = 'PRE-APROVADO';

const Timeline = ({ etapas }: TimelineProps) => {
  const getColorForStatus = (
    etapaStatus: string
  ): 'green' | 'red' | 'orange' | 'blue' | 'gray' => {
    switch (etapaStatus) {
      case ConcluidaEscritaErrada:
      case Concluida:
        return 'green';
      case EmAndamento:
        return 'blue';
      case Reprovada:
        return 'red';
      case NaoIniciado:
      case NaoIniciada:
        return 'gray';
      case PreAprovado:
        return 'green';
      default:
        return 'gray';
    }
  };

  const getIconeForStatus = (etapaStatus: string): JSX.Element => {
    switch (etapaStatus) {
      case ConcluidaEscritaErrada:
      case Concluida:
        return <IconAccept width={iconSize} height={iconSize} />;
      case EmAndamento:
        return <IconOnGoing width={iconSize} height={iconSize} />;
      case Reprovada:
        return <IconReject width={iconSize} height={iconSize} />;
      case NaoIniciado:
      case NaoIniciada:
        return <IconEmpty width={iconSize} height={iconSize} />;
      case PreAprovado:
        return <IconAccept width={iconSize} height={iconSize} />;
      default:
        return <IconEmpty width={iconSize} height={iconSize} />;
    }
  };

  const getTextForStatus = (etapaStatus: string): string => {
    switch (etapaStatus) {
      case ConcluidaEscritaErrada:
      case Concluida:
        return 'Concluída';
      case EmAndamento:
        return 'Em Andamento';
      case Reprovada:
        return 'Reprovada';
      case NaoIniciado:
      case NaoIniciada:
        return 'Não Iniciada';
      case PreAprovado:
        return 'Pré-Aprovado';
      default:
        return '';
    }
  };

  return (
    <S.Wrapper className="hereIAm">
      <S.ImgBar>
        {etapas.length >= 1 && (
          <S.IconPlusBarBox>
            {getIconeForStatus(etapas[0].status)}
          </S.IconPlusBarBox>
        )}
        {etapas.length >= 2 && (
          <S.IconPlusBarBox>
            <S.HorizontalBar ColorTheme={getColorForStatus(etapas[1].status)} />
            {getIconeForStatus(etapas[1].status)}
          </S.IconPlusBarBox>
        )}
        {etapas.length >= 3 && (
          <S.IconPlusBarBox>
            <S.HorizontalBar ColorTheme={getColorForStatus(etapas[2].status)} />
            {getIconeForStatus(etapas[2].status)}
          </S.IconPlusBarBox>
        )}
        {etapas.length >= 4 && (
          <S.IconPlusBarBox>
            <S.HorizontalBar ColorTheme={getColorForStatus(etapas[3].status)} />
            {getIconeForStatus(etapas[3].status)}
          </S.IconPlusBarBox>
        )}
        {etapas.length >= 5 && (
          <S.IconPlusBarBox>
            <S.HorizontalBar ColorTheme={getColorForStatus(etapas[4].status)} />
            {getIconeForStatus(etapas[4].status)}
          </S.IconPlusBarBox>
        )}
        {etapas.length >= 6 && (
          <S.IconPlusBarBox>
            <S.HorizontalBar ColorTheme={getColorForStatus(etapas[5].status)} />
            {getIconeForStatus(etapas[5].status)}
          </S.IconPlusBarBox>
        )}
      </S.ImgBar>

      <S.LabelBar>
        {etapas.length >= 1 && (
          <S.StatusTextBox>
            <S.StatusText ColorTheme={getColorForStatus(etapas[0].status)}>
              <span>{etapas[0].etapa}</span>
            </S.StatusText>
            <S.StatusText ColorTheme={getColorForStatus(etapas[0].status)}>
              <span>{etapas[0].texto}</span>
            </S.StatusText>
          </S.StatusTextBox>
        )}
        {etapas.length >= 2 && (
          <S.StatusTextBox>
            <S.StatusText ColorTheme={getColorForStatus(etapas[1].status)}>
              <span>{etapas[1].etapa}</span>
            </S.StatusText>
            <S.StatusText ColorTheme={getColorForStatus(etapas[1].status)}>
              <span>{etapas[1].texto}</span>
            </S.StatusText>
          </S.StatusTextBox>
        )}
        {etapas.length >= 3 && (
          <S.StatusTextBox>
            <S.StatusText ColorTheme={getColorForStatus(etapas[2].status)}>
              <span>{etapas[2].etapa}</span>
            </S.StatusText>
            <S.StatusText ColorTheme={getColorForStatus(etapas[2].status)}>
              <span>{etapas[2].texto}</span>
            </S.StatusText>
          </S.StatusTextBox>
        )}
        {etapas.length >= 4 && (
          <S.StatusTextBox>
            <S.StatusText ColorTheme={getColorForStatus(etapas[3].status)}>
              <span>{etapas[3].etapa}</span>
            </S.StatusText>
            <S.StatusText ColorTheme={getColorForStatus(etapas[3].status)}>
              <span>{etapas[3].texto}</span>
            </S.StatusText>
          </S.StatusTextBox>
        )}
        {etapas.length >= 5 && (
          <S.StatusTextBox>
            <S.StatusText ColorTheme={getColorForStatus(etapas[4].status)}>
              <span>{etapas[4].etapa}</span>
            </S.StatusText>
            <S.StatusText ColorTheme={getColorForStatus(etapas[4].status)}>
              <span>{etapas[4].texto}</span>
            </S.StatusText>
          </S.StatusTextBox>
        )}
        {etapas.length >= 6 && (
          <S.StatusTextBox>
            <S.StatusText ColorTheme={getColorForStatus(etapas[5].status)}>
              <span>{etapas[5].etapa}</span>
            </S.StatusText>
            <S.StatusText ColorTheme={getColorForStatus(etapas[5].status)}>
              <span>{etapas[5].texto}</span>
            </S.StatusText>
          </S.StatusTextBox>
        )}
      </S.LabelBar>
    </S.Wrapper>
  );
};

export default Timeline;
