import * as S from './styles';
import { InputHTMLAttributes, useEffect, useRef, useState } from 'react';

type AnexFileProps = {
  img: any;
  ext: string;
  small?: boolean;
  medium?: boolean;
  setFile: (item: any) => void;
} & InputHTMLAttributes<HTMLInputElement>;

const AnexFile = ({
  img,
  ext = '.*',
  small = false,
  medium = false,
  setFile,
  ...props
}: AnexFileProps) => {
  const hiddenFileInput = useRef(null);
  const [array64, setArray64] = useState<
    Array<{ arquivo: string; tipo: string }>
  >([]);
  const [array64Flag, setArray64Flag] = useState(0);
  const [array64Lenght, setArray64Lenght] = useState(-1);

  const getBase64 = (file: File, num: number) => {
    return new Promise(() => {
      let baseURL = '';
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = typeof reader.result === 'string' ? reader.result : '';

        if (baseURL) {
          const aux = array64;
          aux.push({
            arquivo: baseURL.split(',')[1],
            tipo: baseURL.split(',')[0].match('pdf') ? 'PDF' : 'IMAGE',
          });
          setArray64(aux);
          setArray64Flag(num);
        }
      };
    });
  };
  // eslint-disable-next-line
  const handleClick = (event: any) => {
    const aux: any = hiddenFileInput?.current;
    aux.click();
  };

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files;
    setArray64Lenght(fileUploaded.length);

    for (let index = 0; index < fileUploaded.length; index++) {
      const element = fileUploaded[index];
      getBase64(element, index);
    }
  };

  useEffect(() => {
    if (array64.length === array64Lenght) {
      setFile(array64);
      setArray64([]);
      setArray64Lenght(-1);
    }
  }, [array64Flag]);

  useEffect(() => {
    setArray64([]);
    setArray64Lenght(-1);
    setArray64Flag(-1);
  }, []);

  return (
    <S.Wrapper>
      <S.CustomInput
        type={'File'}
        accept={ext}
        ref={hiddenFileInput}
        onChange={handleChange}
        {...props}
      ></S.CustomInput>
      <S.Img
        className={
          small ? 'clickable small' : medium ? 'clickable medium' : 'clickable'
        }
        src={img}
        onClick={handleClick}
      />
    </S.Wrapper>
  );
};

export default AnexFile;
