import { InputHTMLAttributes, useState, useContext } from 'react';
import * as S from './styles';
import { IoEyeOff, IoEye } from 'react-icons/io5';
import { BsCheck, BsExclamationCircle } from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import * as CXT from 'context/globalContext';

type InputProps = {
  title?: string;
  type?: string;
  onChange?: (item: any) => void;
  Transform?: (item: any, param?: any) => string;
  isCurrency?: boolean;
  value?: string;
  error?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  inputTheme?: 'default' | 'valid' | 'invalid';
  valid?: boolean;
  width?: string;
  maxInputLength?: number;
  skellSize?: string;
  inputMethod?:
    | 'text'
    | 'none'
    | 'search'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | undefined;
} & InputHTMLAttributes<HTMLInputElement>;

const Input = ({
  title = 'Digite a Label',
  type = 'text',
  onChange,
  value = '',
  errorMessage = 'Campo obrigatório',
  error = false,
  inputTheme,
  valid = false,
  width = '100%',
  disabled,
  maxInputLength = 50,
  inputMethod = 'text',
  readOnly = false,
  skellSize,
}: InputProps) => {
  const globalContext = useContext(CXT.Context);
  const [statusPass, setStatusPass] = useState(false);

  const handleStatus = () => {
    setStatusPass((statusPass) => !statusPass);
  };

  return (
    <S.Wrapper
      width={width}
      style={{ maxWidth: `${width}` }}
      disabled={disabled}
    >
      {globalContext.isLoading ? (
        <S.SkelBox>
          <Skeleton width={skellSize ?? ''} />
        </S.SkelBox>
      ) : (
        <S.Label>{!!title && title}</S.Label>
      )}
      {type === 'password' ? (
        globalContext.isLoading ? (
          <Skeleton width={skellSize ?? ''} />
        ) : (
          <S.ContentPass>
            <S.Input
              type={statusPass ? 'text' : 'password'}
              onChange={onChange}
              value={value}
              width={width[width.length - 1] === '%' ? '100%' : width}
              inputTheme={inputTheme}
              error={error}
              valid={valid}
              disabled={disabled}
              maxLength={maxInputLength}
            />
            {statusPass ? (
              <IoEyeOff color="#00a2e0" size="23px" onClick={handleStatus} />
            ) : (
              <IoEye color="#00a2e0" size="23px" onClick={handleStatus} />
            )}
          </S.ContentPass>
        )
      ) : (
        <>
          {globalContext.isLoading ? (
            <Skeleton width={skellSize ?? ''} />
          ) : (
            <>
              <S.Input
                readOnly={readOnly}
                type={type}
                onChange={onChange}
                value={value}
                width={width[width.length - 1] === '%' ? '100%' : width}
                inputTheme={inputTheme}
                error={error}
                valid={valid}
                maxLength={maxInputLength}
                disabled={disabled}
                inputMode={inputMethod}
                autoCorrect={'off'}
              />
              {!!valid && (
                <S.IconSuccess>
                  <BsCheck size={14} color="#2E910C" />
                </S.IconSuccess>
              )}
              {!!error && (
                <>
                  <S.MsgError>{errorMessage}</S.MsgError>
                  <S.IconError>
                    <BsExclamationCircle size={12} color="#f00909" />
                  </S.IconError>
                </>
              )}
            </>
          )}
        </>
      )}
    </S.Wrapper>
  );
};

export default Input;
