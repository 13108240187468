import { FormPropsType } from './types/FormPropType/FormPropType';
import { unMask } from 'utils/mask';
import { EstadoCivilProps, ProfissaoProps, CidadeProps } from './personProps';

export function findEnderecoIndex(enderecoArray: any) {
  if (enderecoArray === undefined) return -1;
  let index = -1;
  let type = 5;
  //type = 5 not found
  //type = 4 Contato
  //type = 3 Entrega
  //type = 2 Comercial
  //type = 1 Residencial
  //type = 0 Cobrança
  enderecoArray.forEach((ref: any, ind: number) => {
    if (type > 0 && ref.cobranca) {
      index = ind;
      type = 0;
    } else if (type > 1 && ref.residencial) {
      index = ind;
      type = 1;
    } else if (type > 2 && ref.comercial) {
      index = ind;
      type = 2;
    } else if (type > 3 && ref.entrega) {
      index = ind;
      type = 3;
    } else if (type > 4 && ref.contato) {
      index = ind;
      type = 4;
    }
  });
  return index;
}

export default function convertDataToSubmit(
  formValues: FormPropsType,
  estadoCivilSelected: EstadoCivilProps,
  profissaoSelected: ProfissaoProps,
  comprovanteSelected: ProfissaoProps,
  cidadeSelected: CidadeProps,
  municipioEmpId?: number
) {
  const person = JSON.parse(localStorage.getItem('client') || '{}');

  let allReferenciaProfissional: any[] = [];
  if (
    person &&
    person.referenciasProfissionais &&
    person.referenciasProfissionais.length > 0
  ) {
    allReferenciaProfissional = person.referenciasProfissionais;
    allReferenciaProfissional = allReferenciaProfissional.map(
      (ref: any, index: any) => {
        if (index === 0) {
          return {
            pessoaId: person?.id,
            referenciaProfissionalId:
              person?.referenciasProfissionais[index].referenciaProfissionalId,
            nomeEmpresa: formValues.empresa,
            endereco: person?.referenciasProfissionais[index].endereco,
            numeroEndereco:
              person?.referenciasProfissionais[index].numeroEndereco,
            complemento: person?.referenciasProfissionais[index].complemento,
            bairro: person?.referenciasProfissionais[index].bairro,
            cep: Number(formValues.cepEmpresa),
            tipoLogradouro:
              person?.referenciasProfissionais[index].tipoLogradouro,
            usuarioMovimentacao:
              person?.referenciasProfissionais[index].usuarioMovimentacao,
            dddTelefone: formValues.telEmpresa.substring(2, -1),
            telefone: formValues.telEmpresa.substring(2),
            municipioId:
              municipioEmpId ??
              person?.referenciasProfissionais[index].municipioId,
            tempoServico: person?.referenciasProfissionais[index].tempoServico,
            observacao: person?.referenciasProfissionais[index].observacao,
            cargo: formValues.cargo,
            mesAnoAdmissao: formValues.mesAnoAdmissao,

            cnpj: person?.referenciasProfissionais[index].cnpj,
            telefoneEmpresaAnterior:
              person?.referenciasProfissionais[index].telefoneEmpresaAnterior,
            empresaAnterior:
              person?.referenciasProfissionais[index].empresaAnterior,
            ativo: person?.referenciasProfissionais[index].ativo,
            tipoOcupacao: formValues.atividade,
          };
        }

        return ref;
      }
    );
  } else {
    allReferenciaProfissional = [
      {
        pessoaId: person?.id,
        referenciaProfissionalId: 1,
        nomeEmpresa: formValues.empresa,
        endereco: formValues.ruaEmpresa ?? '',
        numeroEndereco: 0,
        complemento: null,
        bairro: formValues.bairroEmpresa ?? '',
        cep: Number(formValues.cepEmpresa),
        tipoLogradouro: null,
        usuarioMovimentacao: null,
        dddTelefone: formValues.telEmpresa.substring(2, -1),
        telefone: formValues.telEmpresa.substring(2),
        municipioId: municipioEmpId ?? null,
        tempoServico: null,
        observacao: null,
        cargo: formValues.cargo,
        mesAnoAdmissao: formValues.mesAnoAdmissao,
        cnpj: null,
        telefoneEmpresaAnterior: null,
        empresaAnterior: null,
        ativo: null,
        tipoOcupacao: formValues.atividade,
      },
    ];
  }

  let allEmails: any[] = [];
  if (person && person.emails && person.emails.length > 0) {
    if (
      person.emails.findIndex((val: any) => {
        val.email === formValues.email;
      }) === -1
    ) {
      allEmails = [
        ...person.emails,
        {
          email: formValues.email,
          preferencia: true,
          status: 1,
        },
      ];
    }
  } else {
    allEmails = [
      {
        email: formValues.email,
        preferencia: true,
        status: 1,
      },
    ];
  }

  allEmails = allEmails.map((item: any) =>
    item.email === formValues.email
      ? { ...item, preferencia: true, status: 1 }
      : { ...item, status: 2 }
  );

  let allTelefones: any[] = [];
  if (person && person.telefones && person.telefones.length > 0) {
    if (
      person.telefones.findIndex(
        (item: any) => item.status === 1 && item.preferencia === true
      ) < 0
    ) {
      allTelefones = [
        {
          ddd: formValues.telefone.substring(0, 2),
          preferencia: true,
          status: 1,
          telefone: formValues.telefone.substring(2, 11),
          tipo: null,
        },
      ];
    } else {
      allTelefones = person.telefones.map((item: any) =>
        item.status === 1 && item.preferencia === true
          ? {
              ...item,
              ddd: formValues.telefone.substring(0, 2),
              telefone: formValues.telefone.substring(2, 11),
            }
          : item
      );
    }
  } else {
    allTelefones = [
      {
        ddd: formValues.telefone.substring(0, 2),
        preferencia: true,
        status: 1,
        telefone: formValues.telefone.substring(2, 11),
        tipo: null,
      },
    ];
  }

  let allEnderecos: any[] = [];
  if (person && person.enderecos && person.enderecos.length > 0) {
    allEnderecos = person.enderecos;

    const index = findEnderecoIndex(allEnderecos);
    if (index === -1) {
      allEnderecos = [
        ...allEnderecos,
        {
          enderecoId: null,
          residencial: true,
          cobranca: true,
          comercial: false,
          entrega: false,
          contato: false,
          endereco: formValues.logradouro,
          numero: Number(formValues.numero),
          complemento: formValues.complemento ?? null,
          bairro: formValues.bairro,
          cep: Number(formValues.cep),
          tipoMoradia: null,
          tipoLogradouro: formValues.tipoLogradouro,
          resideDesdeMesAno: null,
          codigoEstado: cidadeSelected.codigoEstado,
          nomeMunicipio: cidadeSelected.descricao,
          tipoMoradiaDescricao: null,
          tipoLogradouroDescricao: null,
        },
      ];
    } else {
      allEnderecos = allEnderecos.map((ref: any, ind: any) => {
        if (index === ind) {
          return {
            enderecoId: person?.enderecos[index].enderecoId,
            residencial: person?.enderecos[index].residencial,
            cobranca: true,
            comercial: person?.enderecos[index].comercial,
            entrega: person?.enderecos[index].entrega,
            contato: person?.enderecos[index].contato,
            endereco: formValues.logradouro,
            numero: Number(formValues.numero),
            complemento: formValues.complemento ? formValues.complemento : null,
            bairro: formValues.bairro,
            cep: Number(formValues.cep),
            tipoMoradia: person?.enderecos[index].tipoMoradia,
            tipoLogradouro: formValues.tipoLogradouro,
            resideDesdeMesAno: person?.enderecos[index].resideDesdeMesAno,
            codigoEstado: cidadeSelected.codigoEstado,
            nomeMunicipio: cidadeSelected.descricao,
            tipoMoradiaDescricao: person?.enderecos[index].tipoMoradiaDescricao,
            tipoLogradouroDescricao:
              person?.enderecos[index].tipoLogradouroDescricao,
          };
        }

        return ref;
      });
    }
  } else {
    allEnderecos = [
      {
        enderecoId: null,
        residencial: true,
        cobranca: true,
        comercial: false,
        entrega: false,
        contato: false,
        endereco: formValues.logradouro,
        numero: Number(formValues.numero),
        complemento: formValues.complemento ? formValues.complemento : null,
        bairro: formValues.bairro,
        cep: Number(formValues.cep),
        tipoMoradia: null,
        tipoLogradouro: formValues.tipoLogradouro,
        resideDesdeMesAno: null,
        codigoEstado: cidadeSelected.codigoEstado,
        nomeMunicipio: cidadeSelected.descricao,
        tipoMoradiaDescricao: null,
        tipoLogradouroDescricao: null,
      },
    ];
  }

  let allPessoaRenda: any[] = [];
  if (person && person.pessoaRendas && person.pessoaRendas.length > 0) {
    allPessoaRenda = person.pessoaRendas.map((ref: any, index: any) => {
      if (index === 0) {
        return {
          valor: Number(unMask(formValues.renda, '[R$ .,]')) / 100,
          tipo: comprovanteSelected,
          observacao: formValues.observacoes ?? '',
          indRendaConfirmada: comprovanteSelected.id,
        };
      }

      return ref;
    });
  } else {
    allPessoaRenda = [
      {
        valor: Number(unMask(formValues.renda, '[R$ .,]')) / 100,
        tipo: comprovanteSelected,
        observacao: formValues.observacoes ?? '',
        indRendaConfirmada: comprovanteSelected.id,
      },
    ];
  }

  const personData = {
    ...person,
    nome: formValues.nome,
    dataNascimento: formValues.aniversario,
    sexo: formValues.sexo,
    estadoCivil: estadoCivilSelected,
    nomeMae: formValues.mae,
    cpf: person?.cpf,
    emails: allEmails,
    telefones: allTelefones,
    enderecos: allEnderecos,
    profissao: profissaoSelected,
    referenciasProfissionais: allReferenciaProfissional,
    pessoaRendas: allPessoaRenda,
    observacao: formValues.observacoes,
  };

  return personData;
}
