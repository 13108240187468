import Button from 'components/Button';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { find } from 'lodash';

import { Proposta } from 'pages/Documents/types';
import { Deals } from 'services/propostas';
import { formatDate, formatTime } from 'utils/dateFns';
import { cpfFix, cpfMask, currencyFormatV2 } from 'utils/mask';
import { CONTRATO_CONFERIDO } from '../../acoesProposta';
import { CONCLUIDO } from '../../stageStatus';
import { EtapaDTO } from '../../types';

import {
  BoldTextWrapper,
  ButtonsWrapper,
  TextWrapper,
  Wrapper,
  WrapperContainer,
} from './styles';

import { ASSINAR_CONTRATO } from '../../stages';
import EditPhoneMailDialog from '../EditPhoneEmailDialog';

interface ConferirProps {
  show: boolean;
  readOnly: boolean;
  etapa: EtapaDTO;
  etapas: EtapaDTO[];
  proposta: Proposta;
  emailCliente: string;
  telefoneCliente: string;
  reloadProposta: () => void;
  goToEtapaEnviar: () => void;
}

const Conferir = ({
  show,
  readOnly,
  etapa,
  etapas,
  proposta,
  emailCliente,
  telefoneCliente,
  reloadProposta,
  goToEtapaEnviar,
}: ConferirProps) => {
  const [loadingAcao, setLoadingAcao] = useState<boolean>(false);

  const [editingDialogOpen, setEditingDialogOpen] = useState<boolean>(false);

  if (!show) return null;

  const loggedUser = JSON.parse(localStorage.getItem('user') || '{}');

  const declararContratoComoConferido = () => {
    setLoadingAcao(true);
    Deals.RealizarAcao(
      proposta.pedidoId,
      CONTRATO_CONFERIDO,
      null,
      proposta.propostaCodigo
    )
      .then(() => {
        goToEtapaEnviar();
      })
      .catch((err) => {
        console.error(err);
        toast.error('Erro ao declarar o contrato como conferido.');
      })
      .finally(() => setLoadingAcao(false));
  };

  const editData = () => {
    setEditingDialogOpen(true);
  };

  const handleClose = () => {
    setEditingDialogOpen(false);
  };

  const getCurrentUserName = (): string => {
    return loggedUser.given_name;
  };
  const getCurrentUserCPF = (): string => {
    return cpfMask(cpfFix(loggedUser.cpf));
  };

  const isContratoAssinado = (): boolean => {
    return (
      find(etapas, (it) => it.id === ASSINAR_CONTRATO)?.status === CONCLUIDO
    );
  };

  const dateNow = new Date();
  return (
    <Wrapper>
      <TextWrapper>
        Evite retrabalho! Confira todas as informações do contrato
      </TextWrapper>
      <WrapperContainer>
        <TextWrapper>
          Eu, usuário <b>{getCurrentUserName()}</b> (CPF{' '}
          <b>{getCurrentUserCPF()}</b>
          ), confirmo que no dia <b>{formatDate(dateNow)}</b> às{' '}
          <b>{formatTime(dateNow)}</b> validei todas as informações do contrato
          de financiamento <b>{proposta.propostaCodigo}</b> do cliente{' '}
          <b>{proposta.clienteNome}</b>, e afirmo que estão corretas conforme os
          documentos apresentados pelo cliente.
        </TextWrapper>
      </WrapperContainer>
      <WrapperContainer>
        <TextWrapper>
          Confirmo também que os seguintes dados abaixo estão validados:
        </TextWrapper>
      </WrapperContainer>
      <WrapperContainer>
        <BoldTextWrapper>Informações do cliente:</BoldTextWrapper>
      </WrapperContainer>
      <WrapperContainer>
        <TextWrapper textAlign="left">
          <div>
            Renda Comprovada: <b>{currencyFormatV2(proposta.clienteRenda)}</b>
          </div>
          <div>
            Email: <b>{emailCliente}</b>
          </div>
          <div>
            Telefone: <b>{telefoneCliente}</b>
          </div>
        </TextWrapper>
      </WrapperContainer>
      <WrapperContainer>
        <ButtonsWrapper>
          <Button
            marginTop="0px"
            height="48px"
            width="152px"
            onClick={editData}
            disabled={isContratoAssinado()}
          >
            Editar dados
          </Button>
          <EditPhoneMailDialog
            open={editingDialogOpen}
            handleClose={handleClose}
            proposta={proposta}
            reloadProposta={reloadProposta}
          ></EditPhoneMailDialog>
          <Button
            marginTop="0px"
            height="48px"
            width="152px"
            onClick={declararContratoComoConferido}
            disabled={readOnly || etapa.status === CONCLUIDO}
            loading={loadingAcao}
          >
            OK <br /> Contrato conferido
          </Button>
        </ButtonsWrapper>
      </WrapperContainer>
    </Wrapper>
  );
};

export default Conferir;
