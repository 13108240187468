import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.black};
  `}
`;

export const PopUpBody = styled.div`
  ${({ theme }) => css`
    position: fixed;
    right: 160px;
    bottom: 10px;
    z-index: 1110;

    max-width: 300px;
    width: 100%;
    padding: 15px 35px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;

    background-color: ${theme.colors.white};
  `}
`;

export const PopUpTitleBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 7%;
    background-color: ${theme.colors.white};
    svg {
      margin-right: -20px;
    }
  `}
`;

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: space-between;
  width: 100%;
`;

export const PopUpButtonBox = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 30px 5px 30px;
    align-items: center;

    width: 100%;
    height: 40px;
    background-color: ${theme.colors.white};
    padding: 0px 10px;
    margin-top: 10px;

    ${media.lessThan('small')`
      flex-direction: column;
      height: 30%;
    `}

    button {
      min-width: 3rem;
    }
  `}
`;

export const HorizontalBar = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 2px;
    border-bottom: 1px solid ${theme.colors.gray};
    margin-top: 60px;
  `}
`;

export const TitleLabel = styled.label`
  ${({ theme }) => css`
    font-family: 'Roboto';
    color: ${theme.colors.graySecond};
    font-weight: 500;
    font-size: ${theme.font.sizes.medium};
    /* margin-bottom: 1rem; */
    height: 14px;
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
    &.typeI {
      font-size: ${theme.font.sizes.xxlarge};
      color: ${theme.colors.bluePrimary};
      font-weight: bold;
    }
    &.typeII {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.graySecond};
      font-weight: 300;
    }
    &.typeIII {
      margin-bottom: 5px;
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.blackTertiary};
      font-weight: normal;
    }
    &.typeIV {
      margin-bottom: 0px;
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.blackTertiary};
      font-weight: normal;
    }
  `};
`;
