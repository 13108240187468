import * as S from './styles';

import { currencyFormat, mask, Capitalize } from 'utils/mask';
import DefaultCar from 'assets/images/default-car.png';
import Skeleton from 'react-loading-skeleton';
import * as CXT from 'context/globalContext';

import { useState, useEffect, useContext } from 'react';

const moths = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

type carType = {
  placa: string;
  marca: string;
  linha: string;
  descricaoModelo: string;
  anoModelo: number;
  anoFabricacao: number;
  cor: string;
  cambioAutomatico: boolean;
  combustivel: string;
  precoFipe: number;
  fipeId: string;
  urlFotoLinha: string;
  urlImagens: Array<string>;
};

const CarDetails = () => {
  const [Car, setCar] = useState<carType>({
    placa: '',
    marca: '',
    linha: '',
    descricaoModelo: '',
    anoModelo: 0,
    anoFabricacao: 0,
    cor: '',
    cambioAutomatico: false,
    combustivel: '',
    precoFipe: 0,
    fipeId: '',
    urlFotoLinha: '',
    urlImagens: [],
  });
  const [Data, setData] = useState('');
  const globalContext = useContext(CXT.Context);

  useEffect(() => {
    const car = JSON.parse(localStorage.getItem('car') || '{}');
    const data = Number(localStorage.getItem('fipeDate') || 0);
    const Day: Date = new Date();
    Day.setTime(data);

    // setData(data);
    setData(`(${moths[Day.getMonth()]}/${Day.getFullYear()})`);

    setCar(car.marcas[0].linhas[0].versoes[0].modelos[0].veiculos[0]);
  }, []);

  // globalContext.setIsLoading(true);

  return (
    <S.Wrapper>
      <S.Title>
        {globalContext.isLoading ? <Skeleton width="0px" /> : <>VEÍCULO</>}
      </S.Title>
      <S.Body>
        <S.LineImg>
          <S.ImgBox>
            <S.Img
              // src={Car.urlImagens.length > 0 ? Car.urlImagens[0] : DefaultCar}
              src={DefaultCar}
            ></S.Img>
          </S.ImgBox>
          <S.VerticalBar />
          <S.Label className="plate">
            {globalContext.isLoading ? (
              <>
                <Skeleton width="50px" />
                <div style={{ height: '10px' }} />
                <Skeleton width="50px" />
              </>
            ) : (
              <>
                PLACA
                <span>
                  {Car?.placa
                    ? mask(Car.placa, '###-####')
                    : 'Veículo sem placa'}
                </span>
              </>
            )}
          </S.Label>
        </S.LineImg>
        <S.LineName>
          <S.Label className="name">
            {globalContext.isLoading ? (
              <>
                <Skeleton width="70px" />
                <div style={{ height: '10px' }} />
                <Skeleton width="100px" />
              </>
            ) : (
              <>
                {Car.marca}
                <span>
                  {Capitalize(Car.linha.toLocaleLowerCase())} -{' '}
                  {Car.descricaoModelo}
                </span>
              </>
            )}
          </S.Label>
          <S.Label className="year">
            {globalContext.isLoading ? (
              <>
                <Skeleton width="80px" />
              </>
            ) : (
              <>
                <span>
                  {Car.anoFabricacao && Car.anoModelo < 1
                    ? 'Sem Informação'
                    : `${Car.anoFabricacao} / ${Car.anoModelo}`}
                </span>
              </>
            )}
          </S.Label>
        </S.LineName>
        <S.LineColor>
          <S.Label className="color">
            {globalContext.isLoading ? (
              <>
                <Skeleton width="50px" />
                <div style={{ height: '10px' }} />
                <Skeleton width="50px" />
              </>
            ) : (
              <>
                COR
                <span>
                  {Capitalize(Car.cor.toLocaleLowerCase()).length < 1
                    ? 'Sem Informação'
                    : Capitalize(Car.cor.toLocaleLowerCase())}
                </span>
              </>
            )}
          </S.Label>
          <S.VerticalBar />
          <S.Label className="gearbox">
            {globalContext.isLoading ? (
              <>
                <Skeleton width="50px" />
                <div style={{ height: '10px' }} />
                <Skeleton width="50px" />
              </>
            ) : (
              <>
                CÂMBIO
                <span>{Car.cambioAutomatico ? 'Automático' : 'Manual'}</span>
              </>
            )}
          </S.Label>
          <S.VerticalBar />
          <S.Label className="fuel">
            {globalContext.isLoading ? (
              <>
                <Skeleton width="50px" />
                <div style={{ height: '10px' }} />
                <Skeleton width="50px" />
              </>
            ) : (
              <>
                COMBUSTÍVEL
                <span>
                  {Capitalize(Car.combustivel).length < 1
                    ? 'Sem Informação'
                    : Capitalize(Car.combustivel)}
                </span>
              </>
            )}
          </S.Label>
        </S.LineColor>
        <S.LineValue>
          <S.Label className="fipe">
            {globalContext.isLoading ? (
              <>
                <Skeleton width="70px" />
              </>
            ) : (
              <>VALOR FIPE</>
            )}
          </S.Label>

          <S.Label className="fipeVal">
            {globalContext.isLoading ? (
              <>
                <Skeleton width="60px" />
              </>
            ) : (
              <>
                <span>R$ {currencyFormat(Car.precoFipe ?? 0)}</span>
              </>
            )}
          </S.Label>

          <S.Label className="legenda">
            {globalContext.isLoading ? (
              <>
                <Skeleton width="150px" />
              </>
            ) : (
              <>
                Base Fipe{' '}
                {Car.precoFipe ? `${Car.fipeId} ${Data}` : `Não Encontrado`}
              </>
            )}
          </S.Label>
        </S.LineValue>
      </S.Body>
    </S.Wrapper>
  );
};

export default CarDetails;
