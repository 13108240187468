import { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

import * as CXT from 'context/globalContext';

import { Header } from 'components/Header';
import PopUpTutorial, { TutorialDataType } from 'components/PopUpTutorial';
import NavBar from 'components/NavBar';

import { Deal } from './components/Deal';
import { Filter } from './components/Filter';

import { Deals } from 'services/propostas';
import { Car } from 'services/car';

import * as Styled from './styles';
import Pagination from './components/Deal/Pagination/Pagination';

export type FilterParamsType = {
  nameSearch: string;
  dateValue: string;
  creditValue: string;
  stageValue: string;
  paydayValue: string;
  storeValue: string;
  agentValue: string;
  conditionValue: string;
};

const buttonsNavBar = ['Sinosserra', 'Minhas Propostas'];
const linksNavBar = ['/client', '/mydeals'];

function Proposta(allDeals: any) {
  return <Pagination proposta={allDeals?.propostas} />;
}

export default function MyDeals() {
  const history = useHistory();

  const globalContext = useContext(CXT.Context);

  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const userInfo = JSON.parse(localStorage.getItem('user') || '{}');

  const dateTarget = format(
    new Date().setDate(new Date().getDate() - 15),
    'dd-MM-yyyy'
  );

  const [allDeals, setAllDeals] = useState<any>([]);
  const [filterParams, setFilterParams] = useState<FilterParamsType>({
    nameSearch: '',
    dateValue: `dataInclusaoPedido=ge=${dateTarget};`,
    creditValue: '',
    stageValue: 'etapaDescricao!="CANCELADO";',
    paydayValue: '',
    storeValue: '',
    agentValue: '',
    conditionValue: '',
  });
  const [onboardingData, setOnboardingData] = useState<TutorialDataType[]>([]);

  const showOnBoard = () => {
    return (
      <PopUpTutorial
        data={onboardingData}
        isActive={globalContext.popUpTutorial}
        setIsActive={globalContext.setPopUpTutorial}
      />
    );
  };

  useEffect(() => {
    const checkPermission = userData?.permissao?.find(
      (item: string) => item === 'ACOMPANHAMENTO_VENDAS_WEB'
    );

    if (!checkPermission) {
      return history.push('/');
    }

    Car.OnboardingRequest(userInfo.cpf)
      .then((response) => {
        const itemsVisualized = [];

        response.data.data[0].itens.forEach((item: TutorialDataType) => {
          if (item.visualizado) {
            itemsVisualized.push(item);
          }
        });

        if (itemsVisualized.length === response.data.data[0].itens.length) {
          return setOnboardingData([]);
        }

        setOnboardingData(response.data.data[0].itens);
      })
      .catch(() => {
        toast.error('Dados onboarding não respondendo');
      });
  }, []);

  useEffect(() => {
    globalContext.setIsLoading(true);

    //Analisar lógica para uma melhor refatoração
    let filter = '';
    filter = filter + filterParams.nameSearch;
    filter = filter + filterParams.dateValue;
    filter = filter + filterParams.creditValue;
    filter = filter + filterParams.stageValue;
    filter = filter + filterParams.paydayValue;
    filter = filter + filterParams.storeValue;
    filter = filter + filterParams.agentValue;
    filter = filter + filterParams.conditionValue;

    filter = filter.substring(0, filter.length - 1);
    // Encodes as base64 because filter string contains some HTTP reserved characters
    filter = btoa(filter);

    Deals.GetFilteredDeals(filter)
      .then((response) => {
        globalContext.setIsLoading(false);
        setAllDeals(response.data.data);
      })
      .catch(() => {
        globalContext.setIsLoading(false);
        toast.error(
          'Ops! Tivemos um problema ao prosseguir com a sua solicitação. ' +
            'Tente novamente mais tarde e se o problema persistir contate o suporte!'
        );
      });
  }, [filterParams]);

  return (
    <Styled.Wrapper>
      <Header />
      <Styled.MainContainer>
        <main>
          <NavBar buttons={buttonsNavBar} links={linksNavBar} />
          <Styled.FilterContainer>
            <Filter dateTarget={dateTarget} setFilterParams={setFilterParams} />
          </Styled.FilterContainer>
          <Styled.DealContainer>
            {globalContext.isLoading
              ? [...Array(10)].map((_, index) => <Deal key={index} />)
              : allDeals?.propostas !== undefined && Proposta(allDeals)}
          </Styled.DealContainer>
        </main>
        {onboardingData.length !== 0 && showOnBoard()}
      </Styled.MainContainer>
    </Styled.Wrapper>
  );
}
