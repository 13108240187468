import React from 'react';
import Routes from 'routes';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/theme';

import GlobalStyles from '../styles/global';
import * as Auth from 'context/globalContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SkeletonTheme } from 'react-loading-skeleton';

import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

import { FirebaseConfig } from 'firebase-config';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
Bugsnag.start({
  apiKey: '00621c4c68a1b2bd34e7458c0f8d29bc',
  // apiKey: '000000',
  plugins: [new BugsnagPluginReact()],
});

function App() {
  // var bugsnagClient = bugsnag('YOUR_API_KEY')

  // const ErrorBoundary = bugsnagClient.getPlugin('react');

  const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);
  if (!firebase.apps.length) {
    firebase.initializeApp(FirebaseConfig);
  }

  return (
    <>
      {ErrorBoundary ? (
        <ErrorBoundary>
          <SkeletonTheme color="#ccc" highlightColor="#ddd">
            <Auth.Provider>
              <ThemeProvider theme={theme}>
                <GlobalStyles />
                <Routes />
              </ThemeProvider>
            </Auth.Provider>
          </SkeletonTheme>
        </ErrorBoundary>
      ) : (
        <SkeletonTheme color="#ccc" highlightColor="#ddd">
          <Auth.Provider>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              <Routes />
            </ThemeProvider>
          </Auth.Provider>
        </SkeletonTheme>
      )}
      <ToastContainer
        position="top-center"
        autoClose={8000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </>
  );
}

export default App;
