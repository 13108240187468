import apiAppdsv from '../api/api-appdsv';

const RESOURCE = '/financiamento';

export type simulationFetchForm = {
  valorTotal: number;
  valorEntrada: number;
  veiculoId: number;
  pessoaClienteId: number;
  percentualRetorno: string;
  valorDespachante?: number;
  valorMaximoParcela?: number;
  valorRevPlan?: number;
  valorSpm?: number;
};

export type returnFetchForm = {
  valorTotal: number;
  veiculoId: number;
  valorDespachante?: number;
  valorMaximoParcela?: number;
  valorRevPlan?: number;
  valorSpm?: number;
};

export type cedFetchForm = {
  pessoaFinanceiraId: number;
  seqTabelaFinanceira: number;
  prazo: number;
  valorTotal: number;
};

export type ltvFetchForm = {
  veiculoId: number;
};

export const Simulation = {
  MessageSimulation: async (form: any) => {
    return await apiAppdsv.post('/financiamento/simulacao', form);
  },

  SimulationRequest: async (form: simulationFetchForm) => {
    const urlencoded = <simulationFetchForm>{
      ...form,
      valorDespachante: 0,
      valorMaximoParcela: 0,
      valorRevPlan: 0,
      valorSpm: 0,
    };
    return await apiAppdsv.post(
      `${RESOURCE}/simulacao/menorParcela`,
      urlencoded
    );
  },
  SimulationRequestSpecial: async (form: simulationFetchForm) => {
    const urlencoded = <simulationFetchForm>{
      ...form,
      valorDespachante: 0,
      valorMaximoParcela: 0,
      valorRevPlan: 0,
      valorSpm: 0,
    };
    return await apiAppdsv.post(
      `${RESOURCE}/simulacao/menorParcela/taxaEspecial`,
      urlencoded
    );
  },
  CEDRequest: async (form: cedFetchForm) => {
    const urlencoded = <cedFetchForm>{
      ...form,
    };

    return await apiAppdsv.post(`${RESOURCE}/cet/buscar`, urlencoded);
  },
  LTVRequest: async (form: ltvFetchForm) => {
    const urlencoded = <ltvFetchForm>{
      ...form,
    };

    return await apiAppdsv.post(`${RESOURCE}/ltv/buscar`, urlencoded);
  },
  ReturnsRequest: async (form: returnFetchForm) => {
    const urlencoded = <returnFetchForm>{
      ...form,
      valorDespachante: 0,
      valorMaximoParcela: 0,
      valorRevPlan: 0,
      valorSpm: 0,
    };

    return await apiAppdsv.post(`${RESOURCE}/simulacao/retornos`, urlencoded);
  },
};
